import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faInfoCircle,
  faExclamationTriangle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import LinearProgress from '@material-ui/core/LinearProgress';
import {withStyles} from '@material-ui/core/styles';

import {inputDebounce} from 'constants/Config';
import {withProsemirror} from "prosemirror/components/ProsemirrorProvider";
import {withClient} from "reducers/client";
import {validateHTML} from "reducers/client/requestTypes";

import {getDocumentHTML} from "prosemirror/commands/readers";

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  },
  progress: {
    flexShrink: 0,
  },
  container: {
    flexGrow: 1,
    overflowY: 'scroll',
    padding: theme.spacing(1),
  }
});

const sortByWarningLevel = (a,b) => {
  if (a.warningLevel === 'Error') return b.warningLevel === 'Error' ? 0 : -1;
  if (b.warningLevel === 'Error') return 1;
  if (a.warningLevel === 'Warning') return b.warningLevel === 'Warning' ? 0 : -1;
  if (b.warningLevel === 'Warning') return 1;
  if (a.warningLevel === 'Notice') return b.warningLevel === 'Notice' ? 0 : -1;
  if (b.warningLevel === 'Notice') return 1;
  return 0;
};

const getIcon = level => {
  if (level === 'Error') return <FontAwesomeIcon icon={faTimesCircle} color="#ff0000" />;
  if (level === 'Warning') return <FontAwesomeIcon icon={faExclamationTriangle} color="#ffcc00" />;
  if (level === 'Notice') return <FontAwesomeIcon icon={faInfoCircle} color="#013adf" />;
  return <b>{level}</b>;
};

class WcagReport extends React.Component {
  state = {
    loadedFirstTime: false,
    isLoading: false,
    requirements: [],
    pendingRequest: false,
  };

  componentDidMount() {
    const {validateHTML} = this.props;
    if (validateHTML.isLoaded()) this.updateRequirements(validateHTML.get());
    if (validateHTML.isLoading()) this.setState({validateHTML: true});
    this.unsubscribe = this.props.prosemirror.observe((state, tr) => {
      if (tr.docChanged) {
        const {prosemirror, validateHTML} = this.props;
        if (!validateHTML.isLoading()) {
          validateHTML.sendRequest(getDocumentHTML(prosemirror.state));
        } else if (!this.state.pendingRequest) {
          this.setState({pendingRequest: true});
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {validateHTML} = this.props;
    const {validateHTML: prevHTML} = prevProps;
    if (validateHTML.isLoaded() && validateHTML.hasChanged(prevHTML)) {
      this.updateRequirements(validateHTML.get());
    } else if (!this.state.isLoading && (validateHTML.isLoading())) {
      this.setState({
        isLoading: true
      });
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateRequirements(requirements) {
    if (this.state.pendingRequest) {
      const {prosemirror, validateHTML} = this.props;
      validateHTML.sendRequest(getDocumentHTML(prosemirror.state));
    }
    this.setState({
      loadedFirstTime: true,
      isLoading: false,
      requirements: requirements.sort(sortByWarningLevel),
      pendingRequest: false,
    });
  }

  render() {
    const {classes} = this.props;
    const {loadedFirstTime, isLoading, requirements} = this.state;

    return (
      <div className={classes.root}>
        {isLoading && <LinearProgress classes={{root:classes.progress}} />}
        <div className={classes.container}>
          {loadedFirstTime ? (
            requirements.length === 0 ? (
              <p>
                <FontAwesomeIcon icon={faCheckCircle} color="#31b404" /> No issues detected!
              </p>
            ) : (
              requirements.map((note, i) => (
                <div key={i}>
                  {getIcon(note.warningLevel)} {note.description}
                </div>
              ))
            )
          ) : (
            <p>
              Please wait...
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default withProsemirror(withClient({
  hooks: {
    validateHTML
  }
})(withStyles(
  styles
)(WcagReport)));