import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {StandardRoutes} from './standard/index';

export const StandardsRoutes = ({match}) => (
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.path}/list`} />
      <Route path={`${match.path}/:standardId`} component={StandardRoutes} />
      <Redirect to="/404" />
    </Switch>
);