const initialSettings = {
  activeMenuItem: {},
};

// ACTION TYPES
export const SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM';

// ACTION CREATORS
export const setActiveMenuItem = (menuItem) => {
  return {type: SET_ACTIVE_MENU_ITEM, payload: menuItem}
};

export default (state = initialSettings, action) => {
  switch(action.type){
    case SET_ACTIVE_MENU_ITEM:
      return {...state, activeMenuItem: action.payload};
    default:
      return state
  }
};