import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {
  tabbedViewTabs,
  tabbedViewCreateTab,
  updateTab,
  deleteTab, tabElements, chapterLessonOrder, tabbedViewTabOrder
} from "reducers/client/requestTypes";

import {draggableTable} from 'components/common/Table/DraggableTable';
import {ElementLinks} from "components/common/Table/TableControls";
import {formatDate} from "constants/Date";
import {paging} from "reducers/table";

const tabElementsHook = tabElements();
const TabElementLinks = ({tabId}) => {
  const tabElements = useClientHook(tabElementsHook,tabId);
  const elementId = useMemo(() => {
    if (!tabElements.isLoaded()) return null;
    return tabElements.get()[0].id;
  });
  return <ElementLinks elementId={elementId} />;
}

const InternalTable = draggableTable([
  {key: 'id', hidden: true},
  {key: 'elements', hidden: true},
  {key: 'name', sort: 'name', name: 'Name'},
  {key: 'createdAt', sort: 'createdAt,name', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', sort: 'updatedAt,name', name: "Last Updated", render: formatDate, readOnly: true},
  {
    key: 'links',
    name: '',
    get: tab => tab.id,
    Editor: ({value}) => value,
    render: tabId => <TabElementLinks tabId={tabId} />,
    cellProps: {
      padding: 'none'
    },
    readOnly: true,
  }
], {
  draggable: true,
  getRowKey: row => row.id,
  getRowName: row => row.name,
});

const tabbedViewTabsHook = tabbedViewTabs();
const tabbedViewTabOrderHook = tabbedViewTabOrder();
const tabbedViewCreateTabHook = tabbedViewCreateTab();
const updateTabHook = updateTab();
const deleteTabHook = deleteTab();
const TabbedViewTabsTable = (props) => {
  const history = useHistory();

  const tabbedViewTabs = useClientHook(tabbedViewTabsHook, props.tabbedViewId);
  const tabbedViewTabOrder = useClientHook(tabbedViewTabOrderHook, props.tabbedViewId);
  const tabbedViewCreateTab = useClientHook(tabbedViewCreateTabHook, props.tabbedViewId);
  const updateTab = useClientHook(updateTabHook);
  const deleteTab = useClientHook(deleteTabHook);

  const handleSave = useCallback(tab => {
    if (tab.id) {
      return updateTab.sendRequest(tab.id, tab)
        .then(() => tabbedViewTabs.sendRequest());
    } else {
      return tabbedViewCreateTab.sendRequest(tab)
        .then(() => tabbedViewTabs.sendRequest());
    }
  }, [tabbedViewTabs, updateTab, tabbedViewCreateTab]);
  const handleDelete = useCallback(tab => {
    if (tab.id) {
      return deleteTab.sendRequest(tab.id)
        .then(() => tabbedViewTabs.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [tabbedViewTabs, deleteTab]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/tabs/${tabbedViewTabs.get()[index].id}/editor`);
  }, [history,tabbedViewTabs]);
  const handleOrder = useCallback(order => {
    const currentTabs = tabbedViewTabs.get();
    tabbedViewTabOrder.sendRequest(order.map(i => currentTabs[i].id))
      .then(() => tabbedViewTabs.sendRequest());
  }, [tabbedViewTabs, tabbedViewTabOrder])

  const isWaiting = useMemo(
    () =>
      tabbedViewTabs.isLoading() ||
      tabbedViewCreateTab.isLoading() ||
      updateTab.isLoading() ||
      deleteTab.isLoading(),
    [tabbedViewTabs, tabbedViewCreateTab, updateTab, deleteTab]
  );

  return (
    <InternalTable
      initialState={tabbedViewTabs.get()}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      onOrder={handleOrder}
      waiting={isWaiting}
    />
  )
};
TabbedViewTabsTable.propTypes = {
  tabbedViewId: PropTypes.string.isRequired,
}

export {TabbedViewTabsTable}
