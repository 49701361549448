import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from "react-redux";

import {IconButton} from "@material-ui/core";
import IconTransfer from "@material-ui/icons/Publish";

import {useClientHook} from "reducers/client";
import {
  categoryBooks,
  categoryBookOrder,
  categoryCreateBook,
  updateBook,
  deleteBook
} from "reducers/client/requestTypes";
import {dialogOpen} from "reducers/dialog/actions";
import {dialogs} from "reducers/dialog";

import {draggableTable} from 'components/common/Table/DraggableTable';
import {formatDate} from "constants/Date";
import {CategoryTransferBookDialog} from "components/category/CategoryTransferBookDialog";

const InternalTable = draggableTable([
  {key: 'id', hidden: true},
  {key: 'name', name: 'Short Name'},
  {key: 'displayName', name: 'Display Name'},
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
], {
  draggable: true,
  getRowKey: row => row.id,
  getRowName: row => row.displayName,
  actions: {
    Component: ({classes, isNew, index, state, disabled}) => {
      const {editing, page} = state;
      const editMode = editing === index;
      const row = page[index];

      const reduxDispatch = useDispatch();

      const handleClickTransfer = useCallback(() => {
        reduxDispatch(dialogOpen(dialogs.CATEGORY_TRANSFER_BOOK, {bookId: row.id}));
      }, [row]);

      return !isNew && editMode && (
        <IconButton onClick={handleClickTransfer} disabled={disabled} className={classes.button}>
          <IconTransfer fontSize="small"/>
        </IconButton>
      );
    }
  }
});

const booksHook = categoryBooks();
const bookOrderHook = categoryBookOrder();
const createBookHook = categoryCreateBook();
const updateBookHook = updateBook();
const deleteBookHook = deleteBook();
export const CategoryBooksTable = ({categoryId}) => {
  const history = useHistory();

  const books = useClientHook(booksHook, categoryId);
  const bookOrder = useClientHook(bookOrderHook, categoryId);
  const createBook = useClientHook(createBookHook, categoryId);
  const updateBook = useClientHook(updateBookHook);
  const deleteBook = useClientHook(deleteBookHook);

  const handleSave = useCallback(book => {
    if (book.id) {
      return updateBook.sendRequest(book.id, book)
        .then(() => books.sendRequest());
    } else {
      return createBook.sendRequest(book)
        .then(() => books.sendRequest());
    }
  }, [books, updateBook, createBook]);
  const handleDelete = useCallback(book => {
    if (book.id) {
      return deleteBook.sendRequest(book.id)
        .then(() => books.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [books, deleteBook]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/books/${books.get()[index].id}/details`);
  }, [history,books]);
  const handleOrder = useCallback(order => {
    const currentBooks = books.get();
    bookOrder.sendRequest(order.map(i => currentBooks[i].id))
      .then(() => books.sendRequest());
  }, [books, bookOrder])

  const isWaiting = useMemo(
    () =>
      books.isLoading() ||
      createBook.isLoading() ||
      updateBook.isLoading() ||
      deleteBook.isLoading() ||
      bookOrder.isLoading(),
    [books, createBook, updateBook, deleteBook, bookOrder]
  );

  return (
    <React.Fragment>
      <CategoryTransferBookDialog />
      <InternalTable
        initialState={books.get()}
        onSave={handleSave}
        onDelete={handleDelete}
        onClick={handleClick}
        onOrder={handleOrder}
        waiting={isWaiting}
      />
    </React.Fragment>
  )
};
