import {useMemo, useEffect, useState, useCallback, useRef} from 'react';
import {useStore} from "react-redux";
import {RequestState, defaults} from "./util";
import {clearRequest, registerRequest, sendRequest} from "./actions";

const getKeyGenerator = (hookKey, propkey) => {
  if (typeof propkey === 'function') {
    if (typeof hookKey === 'function') return state => propkey(props => hookKey(state, props));
    return () => propkey(hookKey);
  }
  else if (propkey != null) return () => propkey;
  if (typeof hookKey === 'function') return state => hookKey(state);
  return () => hookKey;
};
const getClientActions = (clientRef, request, hookName, dispatch) => ({
  sendRequest: (...content) => {
    const derivedKey = clientRef.getKey(clientRef.store.getState());
    let func;
    if (derivedKey) func = request(derivedKey, ...content);
    else if (content && content.length > 0) func = request(...content);
    if (func) return dispatch(sendRequest(hookName, clientRef.transform, func, derivedKey));
  },
  clear: () => dispatch(clearRequest(hookName, clientRef.getKey()))
});
const getClientSelector = (clientRef) => prefetchedClientState => {
  const derivedKey = clientRef.getKey(clientRef.store.getState());
  const clientState = prefetchedClientState || clientRef.getState();
  const hookState = clientState && clientState.find(r => r.metadata.params === derivedKey);
  const req = new RequestState(hookState || null, derivedKey);
  // console.log('useClientHook -- getClientSelector() --',clientRef.props.hook.hookName,'-- ', derivedKey, clientState, hookState, req);
  return Object.assign(req, clientRef.actions);
};
const getSubscription = (clientRef, notify, auto) => {
  let attempts = 0;
  return () => {
    // Get the current request state
    const clientState = clientRef.getState();
    const nextRequest = clientRef.selector(clientState);

    // If the state has changed, notify the React component
    if (!nextRequest.equals(clientRef.prevRequest)) {
      // console.log('useClientHook -- ',clientRef.props.hook.hookName,'-- update ', clientRef.prevRequest, '->', nextRequest);
      attempts = 0;
      clientRef.prevRequest = nextRequest;
      notify(nextRequest);
    }
    // Perform auto-fetch if client state is initialized and fetch is required
    if (clientState && auto && nextRequest.isFetchRequired()) {
      if (attempts < 5) {
        ++attempts;
        // console.log('useClientHook -- ',hookName,'-- send ',attempts, clientState, nextRequest);
        clientRef.store.dispatch(() => nextRequest.sendRequest());
      } else {
        console.error('useClientHook -- ',clientRef.props.hook.hookName,'-- ERROR exceeded limit of ',attempts, 'attempts to send');
      }
    }
  };
};

export const useClientHook = (hook, key, options = {}) => {
  const store = useStore();

  let destructiveUpdates = 0;


  // Use a ref for everything that needs to be accessed by Redux
  const client = useRef(null);
  // Create a callback to notify the React component of Redux updates
  const [cachedRequest, notifyRequest] = useState(() => {
    let clientRef = {store};
    // Cache options
    clientRef.props = {hook, key, options};
    let getState = options.getState || defaults.getState;
    clientRef.getState = () => getState(store.getState())[hook.hookName];
    clientRef.transform = Object.assign({}, defaults.transform, hook.transform, options.transform);
    // Key generator
    clientRef.getKey = getKeyGenerator(hook.key, key);
    // Actions
    clientRef.actions = getClientActions(clientRef, hook.request, hook.hookName, store.dispatch);
    // Selector
    clientRef.selector = getClientSelector(clientRef);
    clientRef.prevRequest = clientRef.selector();
    client.current = clientRef;
    // console.log('useClientHook -- ',hook.hookName,'-- component init ',clientRef);
    return clientRef.prevRequest;
  });

  if (!client.current.unsubscribe) {
    // Subscription
    client.current.unsubscribe = store.subscribe(getSubscription(client.current, notifyRequest, hook.auto));
  } else {
    // Update with new options
    const clientProps = client.current.props;
    if (options !== clientProps.options) {
      if (options.getState !== clientProps.options.getState) {
        let getState = options.getState || defaults.getState;
        client.current.getState = () => getState(store.getState())[hook.hookName];
        ++destructiveUpdates;
      }
      if (options.transform !== clientProps.options.transform) {
        client.current.transform = Object.assign({}, defaults.transform, hook.transform, options.transform);
        ++destructiveUpdates;
      }
      clientProps.options = options;
    }
    // Update based on new hook
    if (hook !== clientProps.hook) {
      if (hook.hookName !== clientProps.hook.hookName) {
        let getState = options.getState || defaults.getState;
        client.current.getState = () => getState(store.getState())[hook.hookName];
        client.current.actions = getClientActions(client.current, hook.request, hook.hookName, store.dispatch);
        ++destructiveUpdates;
      }
      if (hook.transform !== clientProps.hook.transform) {
        client.current.transform = Object.assign({}, defaults.transform, hook.transform, options.transform);
        ++destructiveUpdates;
      }
      if (hook.key !== clientProps.hook.key) {
        client.current.getKey = getKeyGenerator(hook.key, key);
        ++destructiveUpdates;
      }
      if (hook.request !== clientProps.hook.request) {
        client.current.actions = getClientActions(client.current, hook.request, hook.hookName, store.dispatch);
        ++destructiveUpdates;
      }
      if (hook.auto !== clientProps.hook.auto) {
        client.current.unsubscribe();
        client.current.unsubscribe = store.subscribe(getSubscription(client.current, notifyRequest, hook.auto));
      }
      clientProps.hook = hook;
    }
    // Update based on new key
    if (key !== clientProps.key) {
      client.current.getKey = getKeyGenerator(hook.key, key);
      ++destructiveUpdates;
      clientProps.key = key;
    }
  }

  // Update the request state from Redux if any prop updates would affect the client request value
  if (destructiveUpdates > 0) {
    const updatedRequest = client.current.selector();
    if (!updatedRequest.equals(client.current.prevRequest)) {
      if (hook.auto && updatedRequest.isFetchRequired()) {
        client.current.store.dispatch(() => updatedRequest.sendRequest());
      }
      // console.log('useClientHook -- ',hook.hookName,'-- update ('+destructiveUpdates+' affected properties) ', client.current.prevRequest, '->', updatedRequest);
      client.current.prevRequest = updatedRequest;
      notifyRequest(updatedRequest);
    }
  }

  // INIT -- run only once
  useEffect(() => {
    // Check the initial client state
    const clientState = client.current.getState();
    // If the request has not yet been registered, register the request
    if (!clientState) store.dispatch(registerRequest(hook.hookName));
    // Perform auto-fetch if necessary
    else if (hook.auto && cachedRequest.isFetchRequired()) {
      cachedRequest.sendRequest();
    }

    return () => client.current.unsubscribe();
  }, []);

  return cachedRequest;
};
