import {createSelector} from "reselect";
import {combineReducers} from 'redux';

import {apiClient} from "../constants/Clients";
import ClientRequest, {RequestState} from "./util/clientRequest";

// ACTION TYPES
export const CLASSIFICATION_LIST = 'CLASSIFICATION_LIST';

// CHILD REDUCERS
const list = ClientRequest(CLASSIFICATION_LIST);

// ACTION CREATORS
export function requestClassificationList() {
  const req = apiClient.getClassifications();
  req.limit(9999);
  return list.sendRequest(req);
}

// SELECTORS
const getClassificationListRequest = state => state.classifications.list;
export const getClassificationList = createSelector(
  [getClassificationListRequest],
  classifications => new RequestState(1, classifications)
);

// REDUCER
export default combineReducers({
  list,
});