import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  CircularProgress,
  Button,
  withStyles
} from '@material-ui/core';
import IconCheckCircle from '@material-ui/icons/CheckCircle';

import {withProsemirror} from "prosemirror/components/ProsemirrorProvider";
import {updateNodeAttrs} from "prosemirror/commands";

import {withClient} from "reducers/client";
import {mediaDetails, createMedia} from "reducers/client/requestTypes";
import {createDialogDataSelector, reduxDialog, dialogs} from "reducers/dialog";

import MathJax from 'components/common/Math/MathJax';

import MediaTypes from 'constants/MediaTypes';

const styles = theme => ({
  root: {},
  latexInput: {
    fontFamily: 'monospace',
  },
});

class MathmlDialog extends React.Component {
  componentDidMount() {
    const {mediaDetails, dialog: {actions}} = this.props;
    if (mediaDetails.isLoaded()) {
      const media = mediaDetails.get();
      if (media && media.latex) {
        actions.setState({latex: media.latex});
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const {mediaDetails, dialog: {actions}} = this.props;
    const {mediaDetails: prevMediaDetails} = prevProps;
    if (mediaDetails.isLoaded() && mediaDetails.hasChanged(prevMediaDetails)) {
      const media = mediaDetails.get();
      if (media && media.latex) {
        actions.setState({latex: media.latex});
      } else {
        actions.setState({latex: ''});
      }
    }
  }

  handleClose = () => {
    const {dialog: {actions}} = this.props;
    actions.close();
  };
  handleChange = ev => {
    const {dialog: {actions}} = this.props;
    actions.setState({latex: ev.target.value});
  };
  handleAccept() {
    const {mediaDetails, createMedia, dialog:{state:{latex}}} = this.props;
    const media = mediaDetails.get();

    createMedia.sendRequest({
      ...media,
      latex,
      id: undefined,
      type: MediaTypes.FORMULA.name,
    }).then(media => {
      this.insertMedia(media);
      this.handleClose();
    });
  }
  insertMedia(media) {
    const {prosemirror, dialog: {data}} = this.props;
    if (data) {
      const {attrs, pos} = data;
      prosemirror.execute(updateNodeAttrs({...attrs, 'media-id': media.id}, pos));
    }
  }

  render() {
    const { classes, dialog: {isOpen, state: {latex}}, mediaDetails, createMedia } = this.props;

    let mml = '';
    if (mediaDetails.isLoaded()) {
      mml = mediaDetails.get().originalUrl;
    }

    return (<Dialog open={isOpen} maxWidth={'md'} fullWidth disableRestoreFocus>
      <DialogTitle>MathML</DialogTitle>
      <DialogContent>
        {(!mediaDetails.isLoaded() || createMedia.isLoading()) ? (
          <CircularProgress />
          ) : (
            <div>
              <div className="row">
                <div className="col-sm-6">
                  <h6>Original MathML</h6>
                </div>
                <div className="col-sm-6">
                  <h6>Autogenerated LaTeX</h6>
                </div>
              </div>
              <div className="row" style={{backgroundColor: '#eeeeee', margin: `${10}px 0`}}>
                <div className="col-sm-6" style={{textAlign:'center'}}>
                  <MathJax type="math/mml" text={mml} />
                </div>
                <div className="col-sm-6" style={{textAlign:'center'}}>
                  <MathJax type="math/tex" text={latex} />
                </div>
              </div>
              <div className="row" style={{backgroundColor: '#eeeeee', margin: `${10}px 0`}}>
                <div className="col-sm-6" />
                <div className="col-sm-6" style={{textAlign:'center'}}>
                  <TextField
                    onChange={this.handleChange}
                    InputProps={{classes:{input: classes.latexInput}}}
                    value={latex}
                    multiline
                    fullWidth
                  />
                </div>
              </div>
            </div>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={e => this.handleClose(e)}>Cancel</Button>
        <Button color="primary" onClick={() => this.handleAccept()}>
          <IconCheckCircle/>
          Accept LaTeX
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

const getDialogData = createDialogDataSelector(dialogs.PROSEMIRROR_MATHML);
const getMediaId = state => {
  let data = getDialogData(state);
  return data && data.attrs && data.attrs['media-id'];
};
export default withClient({
  hooks: {
    mediaDetails: mediaDetails(getMediaId),
    createMedia
  }
})(reduxDialog({
  dialog: dialogs.PROSEMIRROR_MATHML,
  initialState: {latex: ''}
})(withStyles(styles)(withProsemirror(MathmlDialog))));
