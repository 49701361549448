import React, {useCallback, useEffect, useMemo, useReducer} from "react";

import {IconButton, styled, Toolbar, Typography} from "@material-ui/core";
import IconBack from '@material-ui/icons/ArrowBack';

const Crumb = styled(props => <Typography variant="button" {...props}/>)(({theme}) => ({
  margin: theme.spacing(1)
}), {name: 'Crumb'});

const getBreadcrumb = (steps, onClick) => (steps && steps.length > 0 ? (
  [<IconButton key={'Back'} onClick={onClick}><IconBack fontSize="small"/></IconButton>]
    .concat(steps.flatMap((step, i) =>
      (i > 0 ? [' > '] : []).concat(<Crumb key={step.key}>{step.name}</Crumb>)
    ))
) : null);

export const makeBrowser = steps => {
  return props => {
    const [state, dispatch] = useReducer((state, action) => {
      switch(action.type) {
        case 'back':
          return state.length > 0
            ? state.slice(0,state.length-1)
            : state;
        case 'select':
          return state.length < steps.length
            ? state.concat(action.payload)
            : state.slice(0,state.length-1).concat(action.payload);
        default: return state;
      }
    }, []);

    useEffect(() => {
      if ((state.length === steps.length) && (typeof props.onChange === 'function')) {
        props.onChange([state[state.length-1]]);
      }
    }, [state]);

    const handleClickItem = useCallback(
      (ev, item) => dispatch({type: 'select', payload: item}),
      [props.onChange]
    );
    const handleClickBack = useCallback(
      () => dispatch({type: 'back'}),
      []
    );
    const breadcrumbSteps = useMemo(() => state.map((item, i) => ({
      key: steps[i].getKey(item),
      name: steps[i].getName(item)
    })), [state]);
    const breadcrumb = useMemo(
      () => getBreadcrumb(breadcrumbSteps, handleClickBack),
      [breadcrumbSteps, handleClickBack]
    );

    let ActiveTable, activeProps;
    if (state.length < steps.length) {
      ActiveTable = steps[state.length].Component;
      activeProps = {
        onClick: handleClickItem,
        blacklist: props.blacklist,
        terminal: state.length === steps.length - 1,
        ...steps[state.length].getProps(state[state.length-1])
      };
    } else {
      ActiveTable = steps[steps.length-1].Component;
      activeProps = {
        onClick: handleClickItem,
        blacklist: props.blacklist,
        terminal: true,
        selected: steps[steps.length-1].getKey(state[steps.length-1]),
        ...steps[steps.length-1].getProps(state[state.length-2]),
      };
    }

    if (breadcrumb) {
      return (<React.Fragment>
        <Toolbar>
          {breadcrumb}
        </Toolbar>
        <ActiveTable {...activeProps} />
      </React.Fragment>);
    }
    return <ActiveTable {...activeProps} />;
  };
};
