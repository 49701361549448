import React, {useCallback, useMemo} from "react";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import IconDelete from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import {EditModeToggle} from "./EditModeToggle";
import {makeTableCell} from "./TableCellBase";
import IconHandle from "@material-ui/icons/DragHandle";
import {ActionCreators} from "components/common/Table/reducer";
import {makePendingChangesSelector} from "components/common/Table/TableUtils";

const makeTableRowActions = ({visibleColumns, actions, readOnly}) => {
  if (actions.hidden || (!actions.Component && readOnly)) return () => null;

  const getPendingChanges = makePendingChangesSelector(visibleColumns);

  const TableRowActionsBase = ({classes, isNew, index, state, dispatch, onSave, disabled}) => {
    const {adding, editing} = state;
    const editMode = (isNew && adding) || editing === index;
    const editingAnother = (!isNew && adding) || (editing >= 0 && editing !== index);
    const pendingChanges = useMemo(() => getPendingChanges(state), [state]);

    const handleClickEdit = useCallback(() => {
      if (isNew) {
        if (pendingChanges) onSave();
        else dispatch(ActionCreators.setAdding(false));
      } else if (editing === index) {
        if (pendingChanges) onSave(index);
        else dispatch(ActionCreators.selectToEdit(-1));
      }
      else dispatch(ActionCreators.selectToEdit(index));
    }, [dispatch, editing, pendingChanges, onSave, index]);
    const handleClickDelete = useCallback(() => dispatch(ActionCreators.setDeleting(true)), [dispatch]);

    return (
      <React.Fragment>
        {(!isNew && editMode) && (
          <IconButton onClick={handleClickDelete} disabled={disabled} className={classes.button}>
            <IconDelete fontSize="small"/>
          </IconButton>
        )}
        {!editingAnother && (
          <EditModeToggle
            className={classes.button}
            editMode={editMode}
            pendingChanges={pendingChanges}
            onClick={handleClickEdit}
            disabled={disabled}
          />
        )}
      </React.Fragment>
    );
  };

  if (actions.override) {
    return props => (
      <TableCell padding="none" align="right">
        <actions.Component {...props} />
      </TableCell>
    );
  } else if (actions.Component) {
    return props => (
      <TableCell padding="none" align="right">
        <actions.Component {...props} />
        {!readOnly && <TableRowActionsBase {...props} />}
      </TableCell>
    );
  }
  return props => (
    <TableCell padding="none" align="right">
      <TableRowActionsBase {...props} />
    </TableCell>
  );
};

export const makeTableRow = options => {
  const {visibleColumns, actions, draggable, readOnly} = options;
  const firstEditableColumnIndex = visibleColumns.findIndex(c => !c.readOnly);

  const cellComponentTypes = visibleColumns.map(
    (column, i) => makeTableCell({...column, autoFocus: i === firstEditableColumnIndex})
  );
  const ActionsComponent = makeTableRowActions(options);

  const TableRowBase = props => {
    const {
      classes,
      state,
      dispatch,
      index,
      item,
      onSave,
      disabled,
      isNew,
      ...innerProps
    } = props;
    const {adding, editing, page, newRow} = state;
    const row = isNew ? newRow : page[index];
    const editMode = (isNew && adding) || editing === index;

    const rowCells = visibleColumns.map((column, j) => {
      if (column.Component) {
        return (
          <column.Component
            key={column.key}
            classes={classes}
            state={state}
            dispatch={dispatch}
            disabled={disabled}
            index={index}
            isNew={isNew}
          />
        );
      }
      if (!row || (isNew && column.readOnly)) return <TableCell key={column.key} />;
      const CellComponent = cellComponentTypes[j];
      return (
        <CellComponent
          key={column.key}
          rowKey={index}
          isNew={isNew}
          value={column.get(row)}
          editMode={editMode}
          disabled={disabled}
          dispatch={dispatch}
        />
      )
    });

    return (
      <TableRow hover {...innerProps}>
        {draggable && (
          <TableCell padding="checkbox">
            {!isNew && <IconHandle className={classes.dragHandle} />}
          </TableCell>
        )}
        {rowCells}
        <ActionsComponent
          classes={classes}
          state={state}
          dispatch={dispatch}
          disabled={disabled}
          index={index}
          isNew={isNew}
          onSave={onSave}
        />
      </TableRow>
    )
  };
  TableRowBase.propTypes = {
    isNew: PropTypes.bool,
    classes: PropTypes.shape({
      dragHandle: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired
    }),
    index: PropTypes.number,
    state: PropTypes.shape({
      adding: PropTypes.bool.isRequired,
      editing: PropTypes.number.isRequired,
      page: PropTypes.array.isRequired,
      newRow: PropTypes.object,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  const customColumns = visibleColumns.filter(column => column.Component != null);
  const baseMemoize = (prevProps, nextProps) => {
    if (prevProps.isNew !== nextProps.isNew) return false;
    if (prevProps.classes !== nextProps.classes) return false;
    if (prevProps.index !== nextProps.index) return false;
    if (prevProps.dispatch !== nextProps.dispatch) return false;
    if (prevProps.disabled !== nextProps.disabled) return false;
    if (!prevProps.state) return !nextProps.state;
    if (!nextProps.state) return false;
    if (prevProps.state.adding !== nextProps.state.adding) return false;
    if (prevProps.state.editing !== nextProps.state.editing) return false;
    if (nextProps.isNew) {
      if (prevProps.state.newRow !== nextProps.state.newRow) {
        return false;
      }
    } else {
      if (prevProps.state.page[prevProps.index] !== nextProps.state.page[nextProps.index]) {
        return false;
      }
    }
    return true;
  };

  if (customColumns.length > 0) {
    const columnProps = rowProps => ({
      state: rowProps.state,
      index: rowProps.index,
      isNew: rowProps.isNew,
    })
    return React.memo(TableRowBase, (prevProps, nextProps) => {
      if (!baseMemoize(prevProps, nextProps)) return false;
      return customColumns.every(column => {
        if (column.memo) return column.memo(columnProps(prevProps), columnProps(nextProps));
        else return prevProps.state === nextProps.state;
      });
    });
  } else {
    return React.memo(TableRowBase, baseMemoize);
  }
};
