import {apiClient} from "constants/Clients";

export const sectionDetails = key => ({
  hookName: 'sectionDetails',
  request: sectionId => apiClient.getSection(sectionId),
  key,
  auto: true,
});
export const updateSection = key => ({
  hookName: 'updateSection',
  request: (sectionId, section) => apiClient.updateSection({id: sectionId, ...section}),
  key,
});
export const deleteSection = key => ({
  hookName: 'deleteSection',
  request: sectionId => apiClient.deleteSection(sectionId),
  key,
});
export const sectionCreateElement = key => ({
  hookName: 'sectionCreateElement',
  request: (sectionId, element) => apiClient.createSectionElement(sectionId, element),
  key,
});
export const sectionElements = key => ({
  hookName: 'sectionElements',
  request: sectionId => apiClient.getSectionElements(sectionId)
    .limit(100)
    .setSortFields(['elementType'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const sectionCreateTabbedView = key => ({
  hookName: 'sectionCreateTabbedView',
  request: (sectionId, tabbedView) => apiClient.createSectionTabbedView(sectionId, tabbedView),
  key,
});
export const sectionTabbedViews = key => ({
  hookName: 'sectionTabbedViews',
  request: sectionId => apiClient.getSectionTabbedViews(sectionId)
    .limit(100),
  key,
  auto: true,
});

export const mergeSections = {
  request: mergeSpec =>  apiClient.mergeSectionElements(mergeSpec)
};
