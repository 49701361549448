import { keymap } from 'prosemirror-keymap';
import { baseKeymap, chainCommands } from 'prosemirror-commands';

import {baseKeys} from "./base";
import {blocksKeys} from "./blocks";
import {listKeys} from "./list";
import {marksKeys} from "./marks";
import {tabKeys} from "./tab";

function mergeKeys(...keyMaps) {
  const merged = {};
  keyMaps.forEach(map => {
    Object.keys(map).forEach((key) => {
      if (merged[key]) {
        merged[key] = chainCommands(merged[key], map[key]);
      } else {
        merged[key] = map[key];
      }
    });
  });
  return merged;
}

export const keysPlugin = (...extKeys) => keymap(mergeKeys(
  ...extKeys,
  listKeys,
  marksKeys,
  blocksKeys,
  tabKeys,
  baseKeys,
  baseKeymap
));

export {
  baseKeys,
  blocksKeys,
  listKeys,
  marksKeys,
  tabKeys
}
