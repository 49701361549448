import React from 'react';
import {connect} from 'react-redux';

import {isUserInRole} from "../../reducers/profile";

const Restrict = props => {
  if (props.isInRole) return props.children;
  return false;
};

const mapStateToProps = (state, props) => {
  let isInRole;
  if (Array.isArray(props.role)) {
    isInRole = props.role.some(role => isUserInRole(state, role));
  } else {
    isInRole = isUserInRole(state, props.role);
  }

  return {isInRole};
};

export default connect(mapStateToProps)(Restrict);