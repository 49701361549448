import React, {useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Tooltip, makeStyles, createStyles} from '@material-ui/core';

import IconPencil from "@material-ui/icons/Create";
import IconVisibility from "@material-ui/icons/Visibility";

import {setEditorMode} from "reducers/Prosemirror";
import {sidebarModes} from "constants/Prosemirror";
import {ToggleIconButton} from "../../Button/ToggleButton";

const useStyles = makeStyles(
  createStyles({
    root: {display: 'inline-flex'}
  }),
  {name: "ProsemirrorEditModeToggle"}
);
const canEdit = mode => !sidebarModes[mode].disableWhenDirty;

const ProsemirrorEditModeToggle = (innerProps) => {
  const classes = useStyles(innerProps);
  const dispatch = useDispatch();
  const {docChanged, editMode, sidebarMode} = useSelector(state => state.prosemirror);

  const setEditModeOn = useCallback(() => dispatch(setEditorMode(true)), [dispatch]);
  const setEditModeOff = useCallback(() => dispatch(setEditorMode(false)), [dispatch]);

  return (
    <div className={classes.root} {...innerProps}>
      <Tooltip title="Edit">
        <ToggleIconButton onClick={setEditModeOn} checked={editMode} disabled={!canEdit(sidebarMode)}>
          <IconPencil />
        </ToggleIconButton>
      </Tooltip>
      <Tooltip title="Preview">
        <ToggleIconButton onClick={setEditModeOff} checked={!editMode} disabled={docChanged}>
          <IconVisibility />
        </ToggleIconButton>
      </Tooltip>
    </div>
  );
};

export {ProsemirrorEditModeToggle}
