import React from 'react';
import {useSelector} from "react-redux";
import IconArrowBack from '@material-ui/icons/ArrowBack';

import {SectionBreadcrumb} from 'components/common/Breadcrumb'
import MergeHistoryTable from 'components/common/Merge/MergeHistoryTable';
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";

const getNavSection = createNavRouteSelector(navRoutes.SECTION);
export const SectionMergeHistoryView = props => {
  const {history} = props;
  const selectedSection = useSelector(getNavSection);
  return (
    <ViewportRoot>
      <SectionBreadcrumb sectionId={selectedSection}/>
      <h1>Merge History</h1>
      <ButtonBox>
        <ButtonBoxButton title="Back" onClick={() => history.goBack()}>
          <IconArrowBack />
        </ButtonBoxButton>
      </ButtonBox>
      <MergeHistoryTable contentableId={selectedSection} />
    </ViewportRoot>
  )
};

