import React from 'react';
import {connect} from "react-redux";

import IconHistory from '@material-ui/icons/History';
import IconHourglass from '@material-ui/icons/HourglassEmpty';
import IconCallMerge from '@material-ui/icons/CallMerge';
import IconCheckCircle from '@material-ui/icons/CheckCircle'

import {withStyles} from '@material-ui/core';

import {defaultElementTypeForContentable} from 'constants/ElementTypes';

import LessonContentContainerNavigation from 'components/Navigation/LessonContentContainerNavigation';
import {LinkedLessonContentContainers} from 'components/LinkList';

import {desmos, mathjax, homework} from 'prosemirror/processors';

import {withClient} from "reducers/client";
import {lessonContentContainerDetails, elementDetails} from "reducers/client/requestTypes";
import PublishStatusIndicator from "components/editor/PublishStatusIndicator";
import {createNavRouteSelector, navRoutes} from "reducers/nav";

import {ElementEditor} from "components/editor/ElementEditor";
import {ContainerMenu} from "prosemirror/menu";
import {setContext} from "reducers/Prosemirror";
import {ButtonBox, ButtonBoxButton, EditorViewport, FlexColumns} from "components/layout";

const defaultElementType = defaultElementTypeForContentable.lessonContentContainer;

const styles = theme => ({
  spacer: {
    width: theme.spacing(1),
  },
});

class LessonContentContainerEditor extends React.Component {
  componentDidMount() {
    const {lessonContentContainerDetails, prosemirrorContextId, setProsemirrorContext} = this.props;
    if (lessonContentContainerDetails.isLoaded()) {
      const {id, bookId} = lessonContentContainerDetails.get();
      if (id !== prosemirrorContextId) {
        setProsemirrorContext(bookId, 'lessonContentContainer', id);
      }
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {lessonContentContainerDetails, prosemirrorContextId, setProsemirrorContext} = this.props;
    if (lessonContentContainerDetails.isLoaded()) {
      const {id, bookId} = lessonContentContainerDetails.get();
      if (id !== prosemirrorContextId) {
        setProsemirrorContext(bookId, 'lessonContentContainer', id);
      }
    }
  }

  navigateTo(path) {
    if (path.startsWith('/')) {
      this.props.history.push(path);
    } else {
      const {lessonContentContainerDetails} = this.props;
      let containerId = lessonContentContainerDetails.getParams();
      this.props.history.push(`/app/lessonContentContainers/${containerId}/${path}`);
    }
  }

  render() {
    const {
      classes,
      lessonContentContainerDetails,
      elementDetails,
    } = this.props;

    const elementType = this.props.match.params.elementType || defaultElementType.name;
    let containerId = lessonContentContainerDetails.getParams();
    let contentVersionId = '';
    if (elementDetails.isLoaded()) {
      contentVersionId = elementDetails.get().currentVersionId;
    }

    return (
      <EditorViewport>
        <ButtonBox>
          <PublishStatusIndicator contentVersionId={contentVersionId} />
          <ButtonBoxButton title="Version History" onClick={() => this.navigateTo(`versions/${elementType}`)}>
            <IconHistory />
          </ButtonBoxButton>
          <ButtonBoxButton title="Share with another Lesson Content Container" onClick={() => this.navigateTo(`merge`)}>
            <IconCallMerge />
          </ButtonBoxButton>
          <ButtonBoxButton title="Merge History" onClick={() => this.navigateTo(`mergeHistory`)}>
            <IconHourglass />
          </ButtonBoxButton>
          <ButtonBoxButton title="Show Accessibility Compliance" onClick={() => this.navigateTo(`accessibility`)}>
            <IconCheckCircle />
          </ButtonBoxButton>
        </ButtonBox>
        <FlexColumns noShrink>
          <LessonContentContainerNavigation containerId={containerId} elementType={elementType} />
          <div className={classes.spacer} />
          <LinkedLessonContentContainers containerId={containerId} elementType={elementType} />
        </FlexColumns>
        <ElementEditor
          elementId={elementDetails.getParams()}
          menu={ContainerMenu}
          previewProps={{ postProcess: [mathjax, homework, desmos] }}
        />
      </EditorViewport>);
  }
}

const getNavLessonContentContainer = createNavRouteSelector(navRoutes.LESSON_CONTENT_CONTAINER);
const getNavElementType = createNavRouteSelector(navRoutes.ELEMENT_TYPE);
const getNavElement = createNavRouteSelector(navRoutes.ELEMENT);
const mapStateToProps = (state, props) => ({
  prosemirrorContextId: state.prosemirror.contextId,
});
const mapDispatchToProps = dispatch => ({
  setProsemirrorContext: (...context) => dispatch(setContext(...context))
});

export const LessonContentContainerEditorView = withClient({
  hooks: {
    lessonContentContainerDetails: lessonContentContainerDetails(getNavLessonContentContainer),
    elementDetails: elementDetails(getNavElement),
  }
})(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(
  LessonContentContainerEditor
)));
