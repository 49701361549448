import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import {
  Collapse,
} from '@material-ui/core';
import {
  Add,
  Remove,
} from '@material-ui/icons';

import {
  LessonContentContainerReference,
  ElementReference,
  TabReference,
  GlossaryItemReference
} from "components/ContentReference";

const styles = theme => ({
  rowExpanded: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  expansionSubtitle: {
    padding: 4,
    paddingLeft: theme.spacing(2),
    borderBottom: '1px solid rgba(236, 236, 236, 1)',
    fontWeight: 500,
    fontSize: `115%`
  },
  ADD: {
    color: '#00a000'
  },
  REMOVE: {
    color: '#a00000'
  }
});

const findComplement = (op, ops) => {
  return ops.find(other => {
    return other.elementId === op.elementId &&
      other.contentableId !== op.contentableId &&
      other.operation !== op.operation;
  })
};

const makeReference = ({contentable, contentableId}) => {
  switch(contentable) {
    case 'lessonContentContainer':
      return <LessonContentContainerReference containerId={contentableId} />;
    case 'tab':
      return <TabReference tabId={contentableId} />;
    case 'glossaryItem':
      return <GlossaryItemReference glossaryItemId={contentableId} />;
    default:
      return null;
  }
}

class MergeHistoryTableExpansion extends React.Component {
  getSummary = () => {
    let {item} = this.props;
    let summary = [];
    item.operations.forEach(op => {
      if (op.operation === 'ADD') {
        let other = findComplement(op, item.operations);
        if (other) {
          let refFrom = makeReference(other);
          let refTo = makeReference(op);
          summary.push((
            <div key={op.id}>
              <ElementReference elementId={op.elementId} />:
              Merge from {refFrom} to {refTo}
            </div>
          ));
        }
      }
    });
    return summary;
  };
  renderOperation = (op) => {
    const {classes} = this.props;
    let icon;
    switch(op.operation) {
      case 'ADD': icon = <Add fontSize="small" />; break;
      case 'REMOVE': icon = <Remove fontSize="small" />; break;
    }
    let ref;
    switch(op.contentable) {
      case 'lessonContentContainer': ref = <LessonContentContainerReference containerId={op.contentableId} />; break;
      case 'tab': ref = <TabReference tabId={op.contentableId} />; break;
      default: ref = null;
    }
    return (
      <div key={op.id} className={classes[op.operation]}>
        {icon} {ref} <ElementReference elementId={op.elementId} /> {op.elementId}
      </div>
    );
  };

  render() {
    const {classes, item, expanded} = this.props;
    let summary = [];
    if (expanded) summary = this.getSummary();
    return (
      <Collapse in={expanded} classes={{container: classes.rowExpanded}} unmountOnExit>
        <div className={classes.expansionSubtitle}>Summary</div>
        <div>
          {summary.length > 0 ? summary : (<div>Summary not available</div>)}
        </div>
        <div className={classes.expansionSubtitle}>Details</div>
        <div>
          {item.operations.filter(op => op.operation !== "NOOP").map(this.renderOperation)}
        </div>
      </Collapse>
    );
  }
}

export default withStyles(styles)(MergeHistoryTableExpansion);