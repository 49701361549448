import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import {useClientHook} from "reducers/client";
import {
  bookChildren,
  bookChildOrder,
  bookCreateChapter,
  updateChapter,
  deleteChapter,
  bookCreateSection,
  updateSection,
  deleteSection
} from "reducers/client/requestTypes";

import {draggableTable} from 'components/common/Table/DraggableTable';
import {formatDate} from "constants/Date";
import {select} from "components/common/Table/TableControls";

import {BookChildName} from "./BookChildName";

const InternalTable = draggableTable([
  {key: 'id', hidden: true},
  {key: 'name', hidden: true},
  {key: 'type', hidden: true},
  {
    key: 'contentable',
    name: 'Type',
    Editor: select([
      {value:"section"},
      {value:"chapter"}
    ]),
    createOnly: true
  },
  {
    key: 'displayName',
    name: 'Name',
    Component: BookChildName,
    memo: (prevProps, nextProps) => {
      if (prevProps.isNew !== nextProps.isNew) return false;
      const prevState = prevProps.state;
      const nextState = nextProps.state;
      if (!prevState) return !nextState;
      if (!nextState) return false;
      if (nextProps.isNew) {
        if (!prevState.newPage) return !nextState.newPage;
        if (!nextState.newPage) return false;
        if (prevState.newPage.name !== nextState.newPage.name) return false;
        if (prevState.newPage.type !== nextState.newPage.type) return false;
      } else {
        const prevRow = prevState.page[prevProps.index];
        const nextRow = nextState.page[nextProps.index];
        if (!prevRow) return !nextRow;
        if (!nextRow) return false;
        if (prevRow.name !== nextRow.name) return false;
        if (prevRow.type !== nextRow.type) return false;
        return true;
      }
    },
    readOnly: true
  },
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
], {
  draggable: true,
  getRowKey: row => row.id,
  getRowName: row => `${row.contentable} ${row.name || row.type}`,
});

const childrenHook = bookChildren();
const childOrderHook = bookChildOrder();

const createChapterHook = bookCreateChapter();
const updateChapterHook = updateChapter();
const deleteChapterHook = deleteChapter();

const createSectionHook = bookCreateSection();
const updateSectionHook = updateSection();
const deleteSectionHook = deleteSection();
export const BookTableOfContents = ({bookId}) => {
  const history = useHistory();

  const children = useClientHook(childrenHook, bookId);
  const childOrder = useClientHook(childOrderHook, bookId);

  const createChapter = useClientHook(createChapterHook, bookId);
  const updateChapter = useClientHook(updateChapterHook);
  const deleteChapter = useClientHook(deleteChapterHook);

  const createSection = useClientHook(createSectionHook, bookId);
  const updateSection = useClientHook(updateSectionHook);
  const deleteSection = useClientHook(deleteSectionHook);

  const handleSave = useCallback(child => {
    if (child.contentable === "section") {
      if (child.id) {
        return updateSection.sendRequest(child.id, child)
          .then(() => children.sendRequest());
      } else {
        return createSection.sendRequest(child)
          .then(() => children.sendRequest());
      }
    } else if (child.contentable === "chapter") {
      if (child.id) {
        return updateChapter.sendRequest(child.id, child)
          .then(() => children.sendRequest());
      } else {
        return createChapter.sendRequest(child)
          .then(() => children.sendRequest());
      }
    }
  }, [children, updateChapter, createChapter]);
  const handleDelete = useCallback(child => {
    if (child.id) {
      if (child.contentable === "section") {
        return deleteSection.sendRequest(child.id)
          .then(() => children.sendRequest());
      } else if (child.contentable === "chapter") {
        return deleteChapter.sendRequest(child.id)
          .then(() => children.sendRequest());
      }
    } else {
      return Promise.reject();
    }
  }, [children, deleteChapter]);
  const handleClick = useCallback((ev, index) => {
    const child = children.get()[index];
    if (child.contentable === "section") {
      history.push(`/app/sections/${child.id}/details`);
    } else if (child.contentable === "chapter") {
      history.push(`/app/chapters/${child.id}/details`);
    }
  }, [history,children]);
  const handleOrder = useCallback(order => {
    const currentChildren = children.get();
    childOrder.sendRequest(order.map((oldIndex, newIndex) => {
      const {id, contentable} = currentChildren[oldIndex];
      return {
        contentableId: id,
        contentable,
        displayOrder: newIndex
      };
    }))
      .then(() => children.sendRequest());
  }, [children, childOrder]);

  const isWaiting = useMemo(
    () =>
      children.isLoading() ||
      createChapter.isLoading() ||
      updateChapter.isLoading() ||
      deleteChapter.isLoading() ||
      childOrder.isLoading(),
    [children, createChapter, updateChapter, deleteChapter, childOrder]
  );

  return (
    <InternalTable
      initialState={children.get()}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      onOrder={handleOrder}
      waiting={isWaiting}
    />
  )
};
