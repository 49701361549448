import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/core";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconAdd from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';

import AddMenuItemDialog from "components/Dialogs/MenuItems/AddMenuItem";
import {openMenuItemDialog, setMenuItemDialogData} from "reducers/LegacyDialogs/MenuItem";
import {withClient} from "reducers/client";
import {menuDetails, menuItemsForMenu} from "reducers/client/requestTypes";

import MenuItem from './MenuItem';
import {createNavRouteSelector, navRoutes} from "../../reducers/nav";

const styles = theme => ({
  dragHandle: {
    cursor: "move"
  },
  itemButton: {},
  dragging: {
    cursor: "move",
    background: 'white',
    boxShadow: `0 2px 4px 0 rgba(0,0,0,0.2)`,
  },
});

class Menu extends Component {
  renderMenuItems = (menuItems) => {
    return menuItems.map((menuItem) => {
      const children = menuItem.childMenuItems || [];
      if(children.length > 0){
        return (
            <MenuItem key={`menuItem-${menuItem.id}`} menuItem={menuItem}>
              {this.renderMenuItems(children)}
            </MenuItem>
        )
      } else {
          return <MenuItem key={`menuItem-${menuItem.id}`} menuItem={menuItem}/>
      }
    })
  };

  openMenuItemDialog = (menu) => {
    this.props.setMenuItemDialogData({menuId: menu.id});
    this.props.openMenuItemDialog()
  };

  render(){
    const {menuDetails:menuRequest, menuItemsForMenu:menuItemsRequest} = this.props;

    if (!menuRequest.isLoaded() ) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Menu details...</h2>
      </div>);
    }

    const menu = menuRequest.get();
    let menuItems = [];
    if (menuItemsRequest.isLoaded()) menuItems = menuItemsRequest.get();

    return (
      <div>
        <Card >
        <AddMenuItemDialog />
        <CardHeader
          action={
          <IconButton onClick={()=>this.openMenuItemDialog(menu)}>
            <IconAdd />
          </IconButton>
          }
          title={menu.name}
          subheader={menu.type}
        />
          {this.renderMenuItems(menuItems)}
        </Card>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  openMenuItemDialog: () => dispatch(openMenuItemDialog()),
  setMenuItemDialogData: (payload) => dispatch(setMenuItemDialogData(payload))
});

const getNavBookMenu = createNavRouteSelector(navRoutes.BOOK_MENU);
export default withClient({
  hooks: {
    menuDetails: menuDetails(getNavBookMenu),
    menuItemsForMenu: menuItemsForMenu(getNavBookMenu),
  },
})(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Menu)));