import React from 'react';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {withStyles} from '@material-ui/core/styles';

import {ElementBreadcrumb} from 'components/common/Breadcrumb';
import {ElementVersionsTable} from 'components/element/ElementVersionsTable';

import {withClient} from "reducers/client";
import {elementDetails} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ContentPreview} from "components/editor/previews/ContentPreview";
import {desmos, mathjax} from "prosemirror/processors";
import {Paper} from "@material-ui/core";

const gutter = 30;

const styles = theme => ({
  root: {
    paddingLeft: `${gutter}px`,
    paddingRight: `${gutter}px`,
    paddingBottom: `${gutter}px`,
  },
  buttonBox: {
    position: 'fixed',
    top: `${65}px`,
    right: `${20}px`,
    textAlign: 'right',
  },
  button: {
    margin: theme.spacing(1),
    marginBottom: `${gutter}px`
  },
  versionsContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  previewPane: {
    minHeight: `${180}px`,
    marginRight: `${gutter}px`,
    padding: `${10}px`,
    flex: 1,
    border: `${1}px solid #dddddd`,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    backgroundColor: '#ffffff'
  },
  listSpacer: {
    flex: `0 ${550}px`
  }
});

class ElementVersions extends React.Component {
  state = {
    selected: null
  }

  showElementEditor = () => {
    const {elementDetails} = this.props;
    if (elementDetails.isLoaded()) {
      this.props.history.push(`/app/elements/${elementDetails.get().id}/editor`);
    }
  };

  getSelectedVersionId() {
    const {selected} = this.state;
    return selected && selected.id;
  }

  handleSelectionChange = selected => {
    this.setState({selected});
  };

  render() {
    const {elementDetails:elementRequest, classes} = this.props;

    if (!elementRequest.isLoaded()) {
      return <div />;
    }

    const elementDetails = elementRequest.get();
    return (
      <div className={classes.root}>
        <div className={classes.buttonBox}>
          <Tooltip title="Back">
            <Fab color="primary" className={classes.button}>
              <ArrowBack onClick={this.showElementEditor} />
            </Fab>
          </Tooltip>
          <Paper>
            <ElementVersionsTable
              elementId={elementRequest.getParams()}
              onSelectionChange={this.handleSelectionChange}
            />
          </Paper>
        </div>
        <h2 className="article-title">Element '{elementDetails.title}' versions</h2>
        <div>
          <ElementBreadcrumb elementId={elementDetails.id} />
        </div>
        <div className={classes.versionsContainer}>
          <div className={classes.previewPane}>
            <ContentPreview contentVersionId={this.getSelectedVersionId()} postProcess={[mathjax, desmos]} />
          </div>
          <div className={classes.listSpacer} />
        </div>
      </div>
    );
  }
}

const getNavElement = createNavRouteSelector(navRoutes.ELEMENT);
export const ElementVersionsView = withClient({
  hooks: {
    elementDetails: elementDetails(getNavElement),
  }
})(withStyles(styles)(ElementVersions));
