import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {
  sectionTabbedViews,
  sectionCreateTabbedView,
  updateTabbedView,
  deleteTabbedView
} from "reducers/client/requestTypes";

import {pagedTable} from 'components/common/Table/PagedTable';
import {formatDate} from "constants/Date";
import {paging} from "reducers/table";

const InternalTable = pagedTable([
  {key: 'id', hidden: true},
  {key: 'type', sort: 'type', name: 'Type'},
  {key: 'createdAt', sort: 'createdAt,title', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', sort: 'updatedAt,title', name: "Last Updated", render: formatDate, readOnly: true},
], {
  table: 'SectionTabbedViewsTable',
  initialState: {
    searchColumns: ['type']
  },
  getRowKey: row => row.id,
  getRowName: row => row.type,
});

const sectionTabbedViewsHook = paging('SectionTabbedViewsTable')(sectionTabbedViews((state, props) => props.sectionId));
const sectionCreateTabbedViewHook = sectionCreateTabbedView();
const updateTabbedViewHook = updateTabbedView();
const deleteTabbedViewHook = deleteTabbedView();
const SectionTabbedViewsTable = (props) => {
  const [totalCount, setTotalCount] = useState(0);
  const [tablePage, setTablePage] = useState([]);
  const history = useHistory();

  const clientKey = useCallback(selector => selector(props), [props]);
  const sectionTabbedViews = useClientHook(sectionTabbedViewsHook, clientKey);
  const sectionCreateTabbedView = useClientHook(sectionCreateTabbedViewHook, props.sectionId);
  const updateTabbedView = useClientHook(updateTabbedViewHook);
  const deleteTabbedView = useClientHook(deleteTabbedViewHook);

  const handleSave = useCallback(tabbedView => {
    if (tabbedView.id) {
      return updateTabbedView.sendRequest(tabbedView.id, tabbedView)
        .then(() => sectionTabbedViews.sendRequest());
    } else {
      return sectionCreateTabbedView.sendRequest(tabbedView)
        .then(() => sectionTabbedViews.sendRequest());
    }
  }, [sectionTabbedViews, updateTabbedView, sectionCreateTabbedView]);
  const handleDelete = useCallback(tabbedView => {
    if (tabbedView.id) {
      return deleteTabbedView.sendRequest(tabbedView.id)
        .then(() => sectionTabbedViews.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [sectionTabbedViews, deleteTabbedView]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/tabbedViews/${tablePage[index].id}/details`);
  }, [history,tablePage]);

  const isWaiting = useMemo(
    () =>
      sectionTabbedViews.isLoading() ||
      sectionCreateTabbedView.isLoading() ||
      updateTabbedView.isLoading() ||
      deleteTabbedView.isLoading(),
    [sectionTabbedViews, sectionCreateTabbedView, updateTabbedView, deleteTabbedView]
  );

  useEffect(() => {
    if (sectionTabbedViews.isLoaded()) {
      const {totalCount, results} = sectionTabbedViews.get();
      setTotalCount(totalCount);
      setTablePage(results);
    }
  }, [sectionTabbedViews, setTotalCount, setTablePage]);

  return (
    <InternalTable
      page={tablePage}
      totalCount={totalCount}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      waiting={isWaiting}
    />
  )
};
SectionTabbedViewsTable.propTypes = {
  sectionId: PropTypes.string.isRequired,
}

export {SectionTabbedViewsTable}
