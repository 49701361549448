import Desmos from 'Desmos';
import {Player} from "lib/desmos-activity-api/api.es";

export const desmos = (dom, options) => {
  const calculators = dom.getElementsByClassName('desmos-calculator');

  for (let i = 0; i < calculators.length; ++i) {
    let type = calculators[i].getAttribute('data-type');
    let innerView;
    switch(type) {
      case 'sci':
        var degreeMode = !!calculators[i].getAttribute('data-degree-mode');
        innerView = Desmos.ScientificCalculator(calculators[i], {
          degreeMode: degreeMode
        });
        break;
      case 'ff':
        innerView = Desmos.FourFunctionCalculator(calculators[i]);
        break;
      case 'graph':
        innerView = Desmos.GraphingCalculator(calculators[i],{
          administerSecretFolders: false,
          keypad: false,
          expressions: false,
          settingsMenu: false,
          zoomButtons: false,
          lockViewport: true
        });
        break;
    }

    let state = calculators[i].getAttribute('data-state');
    if (state && state !== 'null') {
      innerView.setState(state);
    } else {
      if (type === 'ap') {
        $.getJSON(calculators[i].getAttribute('data-src')).then(res => {
          innerView = new Player({
            activityConfig: res,
            targetElement: calculators[i]
          });
        });
      } else {
        $.getJSON(calculators[i].getAttribute('data-src')).then(res => innerView.setState(res.state));
      }
    }
  }
};
