import {toggleMark as pmToggleMark} from "prosemirror-commands";
import {NodeSelection, TextSelection} from "prosemirror-state";
import {findWrapping, canSplit} from 'prosemirror-transform';
import {
  typeNameMiddleware,
  typeNamesMiddleware,
  findAncestor,
  findNodesInRange,
  getMarkedRange,
  matchTypes
} from "./helpers";
import {logProsemirror, LogLevels} from "prosemirror/log";

export const insertNode = typeNameMiddleware('nodes', (nodeType, attrs, meta) =>
  (state, dispatch) => {
    logProsemirror(
      `insertNode(${nodeType.name})`,
      {nodeType, attrs, meta, selection: state.selection},
      LogLevels.DEBUG
    );
    if (dispatch) {
      let {tr} = state;
      tr.replaceSelectionWith(nodeType.createAndFill(attrs));
      if (meta) Object.keys(meta).forEach(key => tr.setMeta(key, meta[key]));
      dispatch(tr.scrollIntoView());
    }
    return true;
  }
);

export const toggleBlock = typeNameMiddleware('nodes',(type, attrs) => {
  let match = matchTypes(type);
  return (state, dispatch) => {
    const {$from, $to} = state.selection;
    const $block = findAncestor($from, match);
    if ($block) {
      const content = $block.nodeAfter.slice(0);
      const above = $block.parent;
      const index = $block.index();
      if (!above.canReplace(index,index,content)) return false;
      const from = $block.pos;
      const to = $block.pos + $block.nodeAfter.nodeSize;
      if (dispatch) {
        logProsemirror(
          'toggleBlock() - removing',
          {$block, above, index, from, to, content},
          LogLevels.DEBUG
        );
        let tr = state.tr.replace(from, to, content);
        tr.setSelection(TextSelection.create(tr.doc, state.selection.anchor - 1, state.selection.head - 1));
        dispatch(tr.scrollIntoView());
      }
    } else {
      const range = $from.blockRange($to);
      const wrapping = range && findWrapping(range, type, typeof attrs === 'function' ? attrs() : attrs);
      if (!wrapping) { return false }
      if (dispatch) {
        logProsemirror(
          'toggleBlock() - wrapping',
          {wrapping, range},
          LogLevels.DEBUG
        );
        dispatch(state.tr.wrap(range, wrapping).scrollIntoView());
      }
    }
    return true;
  }
});

export const toggleMark = typeNameMiddleware('marks', pmToggleMark);

export const splitOn = typeNamesMiddleware('nodes', types => (state, dispatch) => {
  const {$from, $to} = state.selection;
  let matchDepth = -1;
  for (let d = $from.depth; d >= 0; --d) {
    if (types.includes($from.node(d).type)) {
      matchDepth = d;
      break;
    }
  }

  if (matchDepth === -1) return false;

  const splitDepth = $from.depth - matchDepth + 1;

  if (!canSplit(state.doc, $from.pos, splitDepth)) {
    return false;
  }

  if (dispatch) {
    dispatch(state.tr.split($from.pos, splitDepth));
  }

  return true;
});

const removeMarksInRange = match => (doc, tr, from, to) => {
  let anyInRange = false;
  doc.nodesBetween(from, to, (node, pos) => {
    let marks = node.marks.filter(match);
    if (marks.length > 0) {
      anyInRange = true;
      if (tr) {
        marks.forEach(mark => {
          const {start, end} = getMarkedRange(doc, mark, pos);
          tr.removeMark(start, end, mark);
        });
      }
    }
  });
  return anyInRange;
};
export const removeMark = typeNamesMiddleware('marks', markTypes => {
  let remove = removeMarksInRange(m => markTypes.includes(m.type));
  return (state, dispatch) => {
    const {doc, tr, selection: {from, to}} = state;
    if (!remove(doc, tr, from, to)) return false;
    if (dispatch) dispatch(tr);
    return true;
  };
});

export const setMark = typeNameMiddleware('marks', (type, attrs) => {
  let remove = removeMarksInRange(m => m.type === type);
  return (state, dispatch) => {
    const {doc, tr, selection: {from, to}} = state;
    remove(doc, tr, from, to);
    tr.addMark(from, to, type.create(attrs));
    if (dispatch) dispatch(tr);
    return true;
  };
});

export const setAttributes = typeNamesMiddleware('nodes',(nodeTypes, attrs) => {
  const match = matchTypes(nodeTypes);

  return (state, dispatch) => {
    const {$from, $to} = state.selection;
    let nodes;
    if (state.selection.node && match(state.selection.node)) {
      nodes = [state.selection.$from];
    } else {
      nodes = findNodesInRange($from, $to, match);
    }
    if (nodes.length === 0) return false;
    if (dispatch) {
      const {tr} = state;
      nodes.forEach($node => {
        tr.setNodeMarkup($node.pos, null, {...$node.nodeAfter.attrs, ...attrs})
      });
      if (state.selection.node) tr.setSelection(NodeSelection.create(tr.doc,$from.pos));
      dispatch(tr);
    }
    return true;
  };
});

export const updateNodeAttrs = (attrs, pos) =>
  (state, dispatch) =>
    dispatch(state.tr.setNodeMarkup(pos, null, attrs));

export const updateMarkAttrs = typeNamesMiddleware('marks', (markTypes, attrs, start, end) =>
  (state, dispatch) => {
    const {doc, tr} = state;
    let activeMark = doc.resolve(start+1).marks().find(m => markTypes.includes(m.type));
    tr.removeMark(start, end, activeMark);
    tr.addMark(start, end, activeMark.type.create(attrs));
    dispatch(tr);
  }
);
