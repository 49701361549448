import React, {useCallback, useEffect, useReducer, useState} from "react";
import PropTypes from 'prop-types';
import {arrayOfLength} from "PropTypes";

import {Button, makeStyles} from "@material-ui/core";
import IconClose from '@material-ui/icons/Close';
import IconSave from '@material-ui/icons/Save';

import {ContentPreview} from "components/editor/previews";
import {MergeElements} from "./MergeElements";

const useStyles = makeStyles(theme => ({
  buttonBox: {
    position: 'fixed',
    top: `${65}px`,
    right: `${20}px`,
    textAlign: 'right',
  },
  button: {
    margin: theme.spacing(1),
    marginBottom: `${30}px`
  },

  compare: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: `0 0 ${3}px rgba(0,0,0,0.3)`,
  },
  'compare-row': {
    display: 'flex',
    flexDirection: 'row',
  },
  'compare-title': {
    flex: 1,
    padding: '4px 0',
    borderBottom: `${1}px solid #dddddd`,
    height: '58px',
    textAlign: 'center',
    backgroundColor: '#f8f8f8',
    '&:first-of-type': {
      borderRight: `${1}px solid #dddddd`,
    }
  },
  'compare-preview': {
    flex: 1,
    borderTop: `${1}px solid #dddddd`,
    padding: `${16}px`,
    backgroundColor: '#ffffff',
    '&:first-of-type': {
      borderRight: `${1}px solid #dddddd`,
    }
  },
  'compare-pane': {
    minHeight: `${180}px`,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
  },
  'compare-subtitle': {
    padding: '4px 0',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    color: '#ff0000',
    fontWeight: 'bold',
    boxShadow: `0 ${2}px ${4}px 0 rgba(0,0,0,0.2)`,
  },
  'compare-button-left': {
    margin: theme.spacing(1),
    float: 'left',
  },
  'confirm-button': {
    textAlign: 'center',
    padding: theme.spacing(1),
  }
}), {name: 'MergeView'});

const getPublishedVersionId = (elements, elementType) => {
  let selectedElement = elements.find(e => e.elementType === elementType);
  return selectedElement ? selectedElement.publishedVersionId : null;
};

const DefaultMergePreview = ({containerId, element}) => {
  let contentVersionId = element ? element.publishedVersionId : null;
  return <ContentPreview contentVersionId={contentVersionId} />
}

const MergeView = props => {
  const classes = useStyles(props);
  const {
    sources: [
      {id: mainId, elements: mainElements},
      {id: mergeId, elements: mergeElements}
    ],
    onCancel,
    onSubmit,
    Breadcrumb,
    Preview,
    initSelected,
  } = props;

  const [elementType, setElementType] = useState('');
  const [selection, dispatchSelection] = useReducer((state, action) => {
    let nextState;
    switch(action.type) {
      case 'init':
        const {mainElements, mergeElements} = action;
        nextState = {};
        if (initSelected) {
          mergeElements.forEach(({id, elementType}) => nextState[elementType] = id);
          mainElements.forEach(({id, elementType}) => nextState[elementType] = id);
        } else {
          mergeElements.forEach(({elementType}) => nextState[elementType] = null);
          mainElements.forEach(({elementType}) => nextState[elementType] = null);
        }
        return nextState;
      case 'change':
        if (state[action.elementType] === action.elementId) return { ...state, [action.elementType]: '' };
        return { ...state, [action.elementType]: action.elementId };
    }
  }, {});

  useEffect(() => {
    if (mainElements.length > 0) setElementType(mainElements[0].elementType);
    else if (mergeElements.length > 0) setElementType(mergeElements[0].elementType);
    dispatchSelection({type: 'init', mainElements, mergeElements});
  }, [mainElements, mergeElements]);

  const handleSubmit = useCallback(() => onSubmit(selection), [onSubmit, selection]);
  const handleChangeSelection = useCallback((elementType, elementId) => {
    dispatchSelection({type: 'change', elementType, elementId});
  }, []);

  let mainSelectedElement = mainElements.find(e => e.elementType === elementType);
  let mergeSelectedElement = mergeElements.find(e => e.elementType === elementType);

  return (
    <div>
      <div className={classes.compare}>
        <div className={classes['compare-row']}>
          <div className={classes['compare-title']}>
            <Breadcrumb id={mainId} />
          </div>
          <div className={classes['compare-title']}>
            <Button
              className={classes['compare-button-left']}
              variant="outlined"
              size="small"
              onClick={onCancel}
            >
              <IconClose />
              Cancel
            </Button>
            <Breadcrumb id={mergeId} />
          </div>
        </div>
        <MergeElements
          mainElements={mainElements}
          mergeElements={mergeElements}
          elementType={elementType}
          selection={selection}
          onChangePreview={setElementType}
          onChangeSelection={handleChangeSelection}
        />
        <div className={classes['compare-row']}>
          <div className={classes['compare-preview']}>
            <Preview containerId={mainId} element={mainSelectedElement} />
          </div>
          <div className={classes['compare-preview']}>
            <Preview containerId={mergeId} element={mergeSelectedElement} />
          </div>
        </div>
      </div>
      <div className={classes['confirm-button']}>
        <Button color="primary" variant="contained" size="large" onClick={handleSubmit} style={{margin:`0 ${10}px`}}>
          <IconSave /> Submit
        </Button>
      </div>
    </div>
  )
};

const elementPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  elementType: PropTypes.string.isRequired,
  // publishedVersionId: PropTypes.string.isRequired
});

MergeView.propTypes = {
  sources: arrayOfLength(PropTypes.shape({
    id: PropTypes.string.isRequired,
    elements: PropTypes.arrayOf(elementPropType).isRequired
  }), 2).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  Breadcrumb: PropTypes.elementType.isRequired,
  Preview: PropTypes.elementType,
  initSelected: PropTypes.bool,
};
MergeView.defaultProps = {
  Preview: DefaultMergePreview,
  initSelected: true,
};

export {MergeView}
