import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import {useClientHook} from "reducers/client";
import {
  classifications as classificationsHook,
  classificationOrder as classificationOrderHook,
  createClassification as createClassificationHook,
  updateClassification,
  deleteClassification
} from "reducers/client/requestTypes";

import {draggableTable} from 'components/common/Table/DraggableTable';
import {formatDate} from "constants/Date";

const InternalTable = draggableTable([
  {key: 'id', hidden: true},
  {key: 'name', name: 'Classification'},
  {key: 'description', name: 'Description', optional: true},
  {key: 'url', name: 'URL'},
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
], {
  draggable: true,
  getRowKey: row => row.id,
  getRowName: row => row.name,
});

const updateClassificationHook = updateClassification();
const deleteClassificationHook = deleteClassification();
export const ClassificationsTable = () => {
  const history = useHistory();

  const classifications = useClientHook(classificationsHook);
  const updateClassification = useClientHook(updateClassificationHook);
  const createClassification = useClientHook(createClassificationHook);
  const deleteClassification = useClientHook(deleteClassificationHook);
  const classificationOrder = useClientHook(classificationOrderHook);

  const handleSave = useCallback(classification => {
    if (classification.id) {
      return updateClassification.sendRequest(classification.id, classification)
        .then(() => classifications.sendRequest());
    } else {
      return createClassification.sendRequest(classification)
        .then(() => classifications.sendRequest());
    }
  }, [classifications, updateClassification]);
  const handleDelete = useCallback(classification => {
    if (classification.id) {
      return deleteClassification.sendRequest(classification.id)
        .then(() => classifications.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [classifications, deleteClassification]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/classifications/${classifications.get()[index].id}/details`);
  }, [history,classifications]);
  const handleOrder = useCallback(order => {
    const currentClassifications = classifications.get();
    classificationOrder.sendRequest(order.map(i => currentClassifications[i].id))
      .then(() => classifications.sendRequest());
  }, [classifications, classificationOrder])

  const isWaiting = useMemo(
    () =>
      classifications.isLoading() ||
      createClassification.isLoading() ||
      updateClassification.isLoading() ||
      deleteClassification.isLoading() ||
      classificationOrder.isLoading(),
    [classifications, createClassification, updateClassification, deleteClassification, classificationOrder]
  );

  return (
    <InternalTable
      initialState={classifications.get()}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      onOrder={handleOrder}
      waiting={isWaiting}
    />
  )
};
