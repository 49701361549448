import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom'

import { Button, Dialog, DialogTitle, DialogActions } from '@material-ui/core';

import {useClientHook} from "reducers/client";
import {
    mergeGlossaryItems as mergeGlossaryItemsHook,
    glossaryItemElements
} from "reducers/client/requestTypes";
import {useDialog} from "reducers/dialog";
import {MERGE_GLOSSARY_ITEMS_CONFIRMATION} from "reducers/dialog/dialogIds";

import {GlossaryItemReference} from "components/ContentReference";
import {MergeInformationTable} from 'components/common/Merge/MergeInformationTable';

const glossaryItemElementsHook = glossaryItemElements();
const MergeGlossaryItemsConfirmationDialog = props => {
    const history = useHistory();
    const {isOpen, data, actions} = useDialog({dialog: MERGE_GLOSSARY_ITEMS_CONFIRMATION});
    const mainId = data && data.glossaryItemIds && data.glossaryItemIds[0];
    const mergeId = data && data.glossaryItemIds && data.glossaryItemIds[1];

    const mainGlossaryItemElements = useClientHook(glossaryItemElementsHook, mainId);
    const mergeGlossaryItemElements = useClientHook(glossaryItemElementsHook, mergeId);
    const mergeGlossaryItems = useClientHook(mergeGlossaryItemsHook);

    const handleConfirm = useCallback(() => {
        mergeGlossaryItems.sendRequest(data)
            .then(() => {
                // Refresh data that may be affected by the merge
                if (mainGlossaryItemElements.isLoaded()) mainGlossaryItemElements.sendRequest();
                if (mergeGlossaryItemElements.isLoaded() && mainId !== mergeId) mergeGlossaryItemElements.sendRequest();
                actions.close();
                history.push(`/app/glossaryItems/${mainId}/editor`);
            });
    }, [mergeGlossaryItems, mainGlossaryItemElements, mergeGlossaryItemElements, mainId, mergeId, history, actions]);

    if (!data) return null;
    return (<Dialog open={isOpen} onClose={actions.close} {...props}>
        <DialogTitle>Confirm merge</DialogTitle>
        <MergeInformationTable
            mainId={mainId}
            mergeId={mergeId}
            mainElements={mainGlossaryItemElements.isLoaded() ? mainGlossaryItemElements.get() : []}
            mergeElements={mergeGlossaryItemElements.isLoaded() ? mergeGlossaryItemElements.get() : []}
            selection={data && data.selection}
            ReferenceComponent={({contentableId, ...props}) => <GlossaryItemReference glossaryItemId={contentableId} {...props} />}
        />
        <DialogActions>
            <Button onClick={actions.close} color="default" disabled={mergeGlossaryItems.isLoading()}>
                Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" disabled={mergeGlossaryItems.isLoading()}>
                Confirm
            </Button>
        </DialogActions>
    </Dialog>);
};

export {MergeGlossaryItemsConfirmationDialog}
