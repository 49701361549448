import {matchTypes} from "prosemirror/commands/helpers";

const mapNodes = (node, tr, oldTypes, newType, mapAttrs) => {
  if (!oldTypes) return tr;
  const check = matchTypes(oldTypes);
  node.descendants((descNode, pos) => {
    if (check(descNode)) {
      let newNode = newType.create(mapAttrs(descNode.attrs), descNode.content, descNode.marks);
      tr.replaceWith(pos, pos + descNode.nodeSize, newNode);
    }
  });
  return tr;
};

// This function should be run on a doc as it is loaded into Prosemirror
// to convert deprecated NodeTypes into the current supported ones
export const updateDoc = (state, dispatch) => {
  const {doc, tr, schema} = state;

  // 11-20-2019 problemReference -> contentContainerReference
  mapNodes(
    doc,
    tr,
    schema.nodes.cpmProblem,
    schema.nodes.contentContainerReference,
    ({elementType, problemId}) => ({
      containerType:'lessonContentContainer',
      containerId:problemId,
      elementType
    })
  );

  if (dispatch) dispatch(tr);
  return true;
};