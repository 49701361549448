import {apiClient} from "constants/Clients";

export const tabDetails = key => ({
  hookName: 'tabDetails',
  request: tabId => apiClient.getTab(tabId),
  key,
  auto: true,
});
export const updateTab = key => ({
  hookName: 'updateTab',
  request: (tabId, tab) => apiClient.updateTab({id: tabId, ...tab}),
  key,
});
export const deleteTab = key => ({
  hookName: 'deleteTab',
  request: tabId => apiClient.deleteTab(tabId),
  key,
});
export const mergeTabs = {
  hookName: 'mergeTabs',
  request: mergeSpec =>  apiClient.mergeTabs(mergeSpec)
};
export const tabCreateElement = key => ({
  hookName: 'tabCreateElement',
  request: (tabId, element) => apiClient.createTabElement(tabId, element),
  key,
});
export const tabElements = key => ({
  hookName: 'tabElements',
  request: tabId => apiClient.getTabElements(tabId)
    .limit(100)
    .setSortFields(['elementType'])
    .setOrderByAsc(),
  key,
  auto: true,
});
