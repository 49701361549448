import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {GradeDetailsView} from "views/grade/GradeDetails";

import {navRoutes, useNav} from "reducers/nav";

export const GradeRoutes = ({match: {url, path, params: {gradeId}}}) => {
  const selectedGrade = useNav(navRoutes.GRADE, gradeId);
  if (!selectedGrade) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={GradeDetailsView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
