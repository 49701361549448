import React, {useCallback, useMemo} from 'react';

import {useClientHook} from "reducers/client";
import {lessonDetails, updateLesson} from "reducers/client/requestTypes";

import {detailsTable} from "components/common/DetailsTable";
import {formatDate} from "constants/Date";
import {LinearProgress} from "@material-ui/core";

const InternalDetailsTable = detailsTable([
  {key: 'name', name: "Name"},
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
]);
InternalDetailsTable.displayName = "LessonDetailsTableInternal";

const lessonDetailsHook = lessonDetails();
const updateLessonHook = updateLesson();
export const LessonDetailsTable = ({lessonId}) => {
  const lessonDetails = useClientHook(lessonDetailsHook, lessonId);
  const updateLesson = useClientHook(updateLessonHook, lessonId);

  const handleSave = useCallback(state => {
    updateLesson.sendRequest({id: lessonId, ...state}).then(() => lessonDetails.sendRequest());
  }, [updateLesson, lessonDetails, lessonId]);

  const waiting = useMemo(
    () => lessonDetails.isLoading() || updateLesson.isLoading(),
    [lessonDetails, updateLesson]
  );

  return (
    <React.Fragment>
      {waiting && <LinearProgress />}
      <InternalDetailsTable
        initialState={lessonDetails.get()}
        onSave={handleSave}
        disabled={waiting}
      />
    </React.Fragment>
  )
};