import React from 'react';
import {connect} from 'react-redux';

import {Typography, Paper} from '@material-ui/core';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconHistory from '@material-ui/icons/HourglassEmpty';

import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";
import {GlossaryItemBreadcrumb} from 'components/common/Breadcrumb';
import {MergeGlossaryItems} from "components/glossaryItem/MergeGlossaryItems";
import {GlossaryItemBrowser} from "components/common/Browser";
import {MergeGlossaryItemsConfirmationDialog} from "components/Dialogs/Merge/MergeGlossaryItemsConfirmationDialog";

import {withClient} from "reducers/client";
import {glossaryItemDetails, glossaryItemElements} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {dialogOpen} from "reducers/dialog/actions";
import {MERGE_GLOSSARY_ITEMS_CONFIRMATION} from "reducers/dialog/dialogIds";

class MergeTabs extends React.Component {
  state = {
    tabToMerge: null,
  };

  showTabEditor = () => {
    this.props.history.push(`/app/glossaryItems/${this.props.glossaryItemDetails.getParams()}/editor`);
  };
  showHistory = () => {
    this.props.history.push(`/app/glossaryItems/${this.props.glossaryItemDetails.getParams()}/mergeHistory`);
  };

  handleTabSelected = selection => {
    if (selection && selection[0]) {
      this.setState({tabToMerge: selection[0]});
    }
  };
  handleCancel = () => {
    this.setState({tabToMerge: null});
  };
  handleConfirm = (selection) => {
    const {glossaryItemDetails, dispatch} = this.props;
    const {tabToMerge} = this.state;
    dispatch(dialogOpen(MERGE_GLOSSARY_ITEMS_CONFIRMATION, {
      glossaryItemIds: [glossaryItemDetails.getParams(), tabToMerge.id],
      selection,
      comment: "MANUAL_MERGE"
    }));
  };

  render() {
    const {
      glossaryItemDetails: tabRequest,
      glossaryItemElements,
    } = this.props;
    const {tabToMerge} = this.state;

    const glossaryItemId = tabRequest.getParams();
    if (!tabRequest.isLoaded() || glossaryItemId == null) {
      return <div />;
    }

    let glossaryElementType = glossaryItemElements.isLoaded() ? glossaryItemElements.get()[0].elementType : '';

    const tabDetails = tabRequest.get();
    return (
      <ViewportRoot>
        <MergeGlossaryItemsConfirmationDialog />
        <ButtonBox>
          <ButtonBoxButton title="Merge History" onClick={this.showHistory}>
            <IconHistory />
          </ButtonBoxButton>
          <ButtonBoxButton title="back" onClick={this.showTabEditor}>
            <IconArrowBack />
          </ButtonBoxButton>
        </ButtonBox>
        <Typography variant="h3" gutterBottom>Find duplicate for glossary item: "{tabDetails.title}"</Typography>
        {tabToMerge === null ? (
          <div>
            <GlossaryItemBreadcrumb glossaryItemId={glossaryItemId} />
            <Paper>
              <GlossaryItemBrowser onChange={this.handleTabSelected} blacklist={(row, table) => {
                if (table !== 'Glossary Item') return false;
                if (row.id === glossaryItemId) return true;
                if (!row.elements) return true;
                return row.elements[0].elementType !== glossaryElementType;
              }} />
            </Paper>
          </div>
        ) : (
          <div>
            <MergeGlossaryItems
              mainGlossaryItemId={glossaryItemId}
              mergeGlossaryItemId={tabToMerge.id}
              onCancel={this.handleCancel}
              onSubmit={this.handleConfirm}
            />
          </div>
        )}
      </ViewportRoot>
    );
  }
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const getNavGlossaryItem = createNavRouteSelector(navRoutes.GLOSSARY_ITEM);
export const GlossaryItemMergeView = withClient({
  hooks: {
    glossaryItemDetails: glossaryItemDetails(getNavGlossaryItem),
    glossaryItemElements: glossaryItemElements(getNavGlossaryItem),
  }
})(connect(
  mapStateToProps,
  mapDispatchToProps,
)(MergeTabs));
