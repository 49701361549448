import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {GlossaryItemEditorView} from "views/glossaryItem/GlossaryItemEditor";
import {GlossaryItemVersionsView} from "views/glossaryItem/GlossaryItemVersions";
import {GlossaryItemMediaView} from "views/glossaryItem/GlossaryItemMedia";

import {navRoutes, useNav} from "reducers/nav";
import {GlossaryItemMergeView} from "views/glossaryItem/GlossaryItemMerge";
import {GlossaryItemMergeHistoryView} from "views/glossaryItem/GlossaryItemMergeHistory";

export const GlossaryItemRoutes = ({match: {url, path, params: {glossaryItemId}}}) => {
  const selectedGlossaryItem = useNav(navRoutes.GLOSSARY_ITEM, glossaryItemId);
  if (!selectedGlossaryItem) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/editor`} />
      <Route path={`${path}/editor`} component={GlossaryItemEditorView}/>
      <Route path={`${path}/versions`} component={GlossaryItemVersionsView}/>
      <Route path={`${path}/media`} component={GlossaryItemMediaView}/>
      <Route path={`${path}/merge`} component={GlossaryItemMergeView}/>
      <Route path={`${path}/mergeHistory`} component={GlossaryItemMergeHistoryView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
