import {apiClient} from "constants/Clients";
import {DEFAULT_KEY} from "./util";

export const categories = {
  hookName: 'categories',
  request: () =>  apiClient.getCategories()
    .limit(100)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key: DEFAULT_KEY,
  auto: true,
};
export const categoryDetails = key => ({
  hookName: 'categoryDetails',
  request: categoryId =>  apiClient.getCategory(categoryId),
  key,
  auto: true,
});
export const createCategory = {
  hookName: 'createCategory',
  request: category => apiClient.createCategory(category)
};
export const updateCategory = key => ({
  hookName: 'updateCategory',
  request: (categoryId, category) => apiClient.updateCategory({id: categoryId, ...category}),
  key,
});
export const deleteCategory = key => ({
  hookName: 'deleteCategory',
  request: categoryId => apiClient.deleteCategory(categoryId),
  key,
});
export const categoryOrder = {
  hookName: 'categoryOrder',
  request: (categoryIds) => apiClient.setCategoryOrder(categoryIds),
};
export const categoryCreateBook = key => ({
  hookName: 'categoryCreateBook',
  request: (categoryId, book) => apiClient.createBook({...book, categoryId}),
  key,
});
export const categoryBooks = key => ({
  hookName: 'categoryBooks',
  request: categoryId => apiClient.getCategoryBooks(categoryId)
    .limit(100)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const categoryBookOrder = key => ({
  hookName: 'categoryBookOrder',
  request: (categoryId, bookIds) => apiClient.setCategoryBookOrder(categoryId, bookIds),
  key,
});
