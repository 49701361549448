import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {Button, TableCell} from "@material-ui/core";
import IconMerge from "@material-ui/icons/CallMerge";
import IconAdd from "@material-ui/icons/Add";
import IconRemove from "@material-ui/icons/Remove";

import {dialogOpen} from "reducers/dialog/actions";
import {dialogs} from "reducers/dialog";
import {getValidElementTypesForContentable} from "constants/ElementTypes";
import {ActionCreators} from "components/common/Table/reducer";

import LessonContainerElement from "./LessonContainerElement";

const elementTypes = getValidElementTypesForContentable('lessonContentContainer');

export const LessonContainersContent = props => {
  const {
    classes,
    state,
    dispatch,
    disabled,
    index,
    isNew,
  } = props;
  const {adding, editing, newRow, page} = state;
  const row = isNew ? newRow : page[index];
  const elements = (row && row.elements) || [];
  const editingRow = page[editing];

  const reduxDispatch = useDispatch();

  const handleRemove = useCallback((ev, element) => {
    dispatch({type: 'removeElement', payload: element})
  }, [dispatch]);
  const handleClickAddElement = useCallback(() => {
    reduxDispatch(dialogOpen(dialogs.LESSON_CONTENT_CONTAINER_ADD_ELEMENT, {containerId: row.id}));
  }, [reduxDispatch, row]);
  const handleMerge = useCallback((event, element) => {
    dispatch({type: 'mergeElement', rowIndex: index, payload: element});
  }, [dispatch, index]);
  const handleLink = useCallback(() => {
    if (row.linkedContainer) {
      dispatch(ActionCreators.update(index, 'linkedContainer', null));
    } else {
      reduxDispatch(dialogOpen(dialogs.LESSON_CONTENT_CONTAINER_LINK, {containerId: row.id}));
    }
  }, [dispatch, reduxDispatch, row, index]);

  let cellContent;
  if (isNew) {
    cellContent = (row.linkedContainer ? (
        <Button color="default" variant="outlined" onClick={handleLink}>
          <IconRemove />
          {row.linkedContainer.bookName} {row.linkedContainer.name}
        </Button>
      ) : (
        <Button color="default" variant="outlined" onClick={handleLink}>
          <IconMerge />
          Link
        </Button>
      ));
  } else if (editing === index) {
    cellContent = (
      <React.Fragment>
        {elements.map(e => (
          <LessonContainerElement
            key={e.id}
            containerId={row.id}
            element={e}
            mode="remove"
            onRemove={handleRemove}
            disabled={disabled}
          />
        ))}
        {elements.length < 1 && (
          <Button color="default" variant="outlined" onClick={handleLink} disabled={disabled}>
            <IconMerge /> Link
          </Button>
        )}
        {elements.length < elementTypes.length && (
          <Button color="secondary" variant="outlined" onClick={handleClickAddElement} disabled={disabled}>
            <IconAdd />
          </Button>
        )}
      </React.Fragment>
    );
  } else {
    cellContent = (
      <React.Fragment>
        {elements.map(e => {
          let elementDisabled = disabled;
          if (editing >= 0 && editingRow && editingRow.elements) {
            elementDisabled = disabled || !!editingRow.elements.find(other => other.elementType === e.elementType);
          }
          return (
            <LessonContainerElement
              key={e.id}
              containerId={row.id}
              element={e}
              mode={editing >= 0 ? "merge" : "link"}
              onMerge={handleMerge}
              disabled={elementDisabled}
            />
          );
        })}
      </React.Fragment>
    );
  }

  return (
    <TableCell padding="none">
      {cellContent}
    </TableCell>
  );
};
