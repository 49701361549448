import React from 'react';
import PropTypes from 'prop-types';

import {withClient} from "reducers/client";
import {contentVersionImages} from "reducers/client/requestTypes";

import {ElementMediasTable} from 'components/media/ElementMediasTable';

class ContentVersionMediasTable extends React.Component {
  static propTypes = {
    contentVersionImages: PropTypes.object.isRequired,

    contentVersionId: PropTypes.string.isRequired,
  };

  state = { medias: [] };

  componentDidMount() {
    const {contentVersionImages} = this.props;
    if (contentVersionImages.isLoaded()) {
      this.setState({medias: contentVersionImages.get()});
    }
  }
  componentDidUpdate(prevProps) {
    const {contentVersionImages} = this.props;
    const {contentVersionImages:prevMedias} = prevProps;
    if (contentVersionImages.isLoaded() && contentVersionImages.hasChanged(prevMedias)) {
      this.setState({medias: contentVersionImages.get()});
    }
  }

  handleChange = () => {
    const {contentVersionImages} = this.props;
    contentVersionImages.sendRequest();
  };

  render() {
    const {medias} = this.state;
    if (!medias) return false;
    const {contentVersionImages} = this.props;
    return (
      <ElementMediasTable
        medias={medias}
        waiting={contentVersionImages.isLoading()}
        onChange={this.handleChange}
      />
    );
  }
}

export default withClient({
  hooks: {
    contentVersionImages: contentVersionImages((state, props) => props.contentVersionId),
  },
})(ContentVersionMediasTable);
