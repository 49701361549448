import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import IconEdit from '@material-ui/icons/Edit';

import {withClient} from "reducers/client";
import {glossaryItemElements} from "reducers/client/requestTypes";

import {GlossaryItemBreadcrumb} from 'components/common/Breadcrumb';

import ContentVersionMediasTable from 'components/contentVersion/ContentVersionMediasTable';
import LinearProgress from "@material-ui/core/LinearProgress";
import {createNavRouteSelector, navRoutes} from "../../reducers/nav";
import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";

class GlossaryItemMedia extends React.Component {
  showGlossaryItemEditor = () => {
    const {
      history,
      glossaryItemElements
    } = this.props;
    history.push(`/app/glossaryItems/${glossaryItemElements.getParams()}/editor`);
  };

  render() {
    const {glossaryItemElements} = this.props;

    if (!glossaryItemElements.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading GlossaryItem media...</h2>
      </div>);
    }

    const glossaryItemElement = glossaryItemElements.get()[0];


    return (
      <ViewportRoot>
        <GlossaryItemBreadcrumb glossaryItemId={glossaryItemElements.getParams()} />
        <ButtonBox>
          <ButtonBoxButton title="GlossaryItem Editor" onClick={this.showGlossaryItemEditor}>
            <IconEdit />
          </ButtonBoxButton>
        </ButtonBox>

        <ContentVersionMediasTable contentVersionId={glossaryItemElement.publishedVersionId} />
      </ViewportRoot>
    );
  }
}

const getNavGlossaryItem = createNavRouteSelector(navRoutes.GLOSSARY_ITEM);
export const GlossaryItemMediaView = withClient({
  hooks: {
    glossaryItemElements: glossaryItemElements(getNavGlossaryItem),
  },
})(GlossaryItemMedia);
