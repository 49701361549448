import { inputRules } from 'prosemirror-inputrules';

import { makeBaseRules } from "./base";
import { makeListRules } from "./list";

export const rulesPlugin = ({schema}) => inputRules({
  rules: [
    ...makeBaseRules(schema),
    ...makeListRules(schema),
  ]
});
