import React from 'react';
import {useSelector} from "react-redux";

import IconArrowBack from '@material-ui/icons/ArrowBack';

import {LessonContentContainerBreadcrumb} from 'components/common/Breadcrumb'
import MergeHistoryTable from 'components/common/Merge/MergeHistoryTable';
import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";
import {createNavRouteSelector, navRoutes} from "reducers/nav";

const getNavLessonContentContainer = createNavRouteSelector(navRoutes.LESSON_CONTENT_CONTAINER);
export const LessonContentContainerMergeHistoryView = props => {
  const selectedLessonContentContainer = useSelector(getNavLessonContentContainer);
  const {history} = props;
  return (
    <ViewportRoot>
      <LessonContentContainerBreadcrumb containerId={selectedLessonContentContainer}/>
      <h1>Merge History</h1>
      <ButtonBox>
        <ButtonBoxButton title="Back" onClick={() => history.goBack()}>
          <IconArrowBack />
        </ButtonBoxButton>
      </ButtonBox>
      <MergeHistoryTable contentableId={selectedLessonContentContainer} />
    </ViewportRoot>
  )
};
