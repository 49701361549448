import React, {useCallback} from 'react';
import {connect, useSelector, useDispatch} from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconCheck from '@material-ui/icons/Check';
import IconError from '@material-ui/icons/Error';

import {
  hideSnackbar,
  SNACKBAR_HIDDEN,
  SNACKBAR_SAVING,
  SNACKBAR_SAVE_SUCCESS,
  SNACKBAR_SAVE_ERROR
} from "reducers/Prosemirror";

const ProsemirrorSnackbar = () => {
  const dispatch = useDispatch();
  const snackbarStatus = useSelector(state => state.prosemirror.snackbarStatus);
  const handleHideSnackbar = useCallback(
    (ev, reason) => reason !== 'clickaway' && dispatch(hideSnackbar())
  );

  let snackbarActions = null;
  let snackbarContent = null;
  let autoClose = 6000;

  if (snackbarStatus === SNACKBAR_SAVE_SUCCESS) {
    snackbarContent = <span>Successfully saved!</span>;
    snackbarActions = <IconCheck fontSize="small" />;
  } else if (snackbarStatus === SNACKBAR_SAVE_ERROR) {
    snackbarContent = <span>An error occurred while saving.</span>;
    snackbarActions = <IconError fontSize="small" />;
  } else if (snackbarStatus === SNACKBAR_SAVING) {
    snackbarContent = <span>Saving...</span>;
    snackbarActions = <CircularProgress size={20} />;
    autoClose = null;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={snackbarStatus !== SNACKBAR_HIDDEN}
      onClose={handleHideSnackbar}
      onClick={handleHideSnackbar}
      message={snackbarContent}
      action={snackbarActions}
      autoHideDuration={autoClose}
    />
  );
};

export {ProsemirrorSnackbar};
