import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';

import {Button, makeStyles} from '@material-ui/core';
import IconVisibility from '@material-ui/icons/Visibility';
import IconVisibilityOff from '@material-ui/icons/VisibilityOff';

import {MergeElementSwitch} from "./MergeElementSwitch";
import {getElementTypeByName} from "constants/ElementTypes";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `${1}px solid #dddddd`,
    '&:last-of-type': {
      borderBottom: `none`,
    }
  },
  flexLeft: {
    flex: 1,
    textAlign: 'left'
  },
  flexCenter: {
    flex: 1,
    textAlign: 'center'
  },
  flexRight: {
    flex: 1,
    textAlign: 'right'
  },
  tooltip: {
    maxWidth: `${200}px`
  }
}, {name: 'MergeElementsRow'});

const MergeElementsRow = props => {
  const {
    elements: {
      elementType,
      mainElement,
      mergeElement,
    },
    value,
    checked,
    onClick,
    onChange,
  } = props;

  const mainElementId = mainElement && mainElement.id;
  const mergeElementId = mergeElement && mergeElement.id;

  const classes = useStyles(props);
  const handleChange = useCallback(
    elementId => onChange(elementType, elementId),
    [onChange, elementType]
  );
  const handleClick = useCallback(
    () => onClick(elementType),
    [onClick,elementType]
  );

  const elementTypeName = useMemo(
    () => {
      const typeDef = getElementTypeByName(elementType);
      return typeDef ? (typeDef.displayName || typeDef.name) : elementType;
    },
    [elementType]
  );

  return (
    <div className={classes.root}>
      <div className={classes.flexLeft}>
        <MergeElementSwitch
          align="left"
          elementId={mainElementId}
          disabled={(mainElementId === mergeElementId) || !checked}
          checked={value === mainElementId}
          onChange={handleChange}
        />
      </div>
      <div className={classes.flexCenter}>
        <Button
          onClick={handleClick}
          color={checked ? 'primary' : 'default'}
        >
          {elementTypeName}
          {checked ? <IconVisibility /> : <IconVisibilityOff />}
        </Button>
      </div>
      <div className={classes.flexRight}>
        <MergeElementSwitch
          align="right"
          elementId={mergeElementId}
          disabled={(mainElementId === mergeElementId) || !checked}
          checked={value === mergeElementId}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
MergeElementsRow.propTypes = {
  elements: PropTypes.shape({
    elementType: PropTypes.string.isRequired,
    mainElement: PropTypes.shape({id: PropTypes.string.isRequired}),
    mergeElement: PropTypes.shape({id: PropTypes.string.isRequired})
  }).isRequired,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export {MergeElementsRow}
