import React, {useCallback} from "react";
import {useDispatch} from "react-redux";

import {Button, TableCell} from "@material-ui/core";
import IconMerge from "@material-ui/icons/CallMerge";
import IconRemove from "@material-ui/icons/Remove";

import {ActionCreators} from "components/common/Table/reducer";
import {dialogOpen} from "reducers/dialog/actions";
import {dialogs} from "reducers/dialog";

import AssessmentGroupProblemElement from "./AssessmentGroupProblemElement";

export const AssessmentGroupProblemContent = props => {
  const {
    classes,
    state,
    dispatch,
    disabled,
    index,
    isNew,
  } = props;
  const {adding, editing, newRow, page} = state;
  const row = isNew ? newRow : page[index];
  const elements = (row && row.elements) || [];

  const reduxDispatch = useDispatch();

  const handleLink = useCallback(() => {
    if (row.linkedProblem) {
      dispatch(ActionCreators.update(index, 'linkedProblem', null));
      dispatch(ActionCreators.update(index, 'replace', null));
      dispatch(ActionCreators.update(index, 'name', null));
      dispatch(ActionCreators.update(index, 'isTeam', null));
      dispatch(ActionCreators.update(index, 'difficultyLevel', null));
    } else {
      reduxDispatch(dialogOpen(dialogs.ASSESSMENT_PROBLEM_LINK, {containerId: row.id}));
    }
  }, [dispatch, reduxDispatch, row, index]);

  let cellContent;
  if (isNew) {
    cellContent = (row.linkedProblem ? (
      <Button color="default" variant="outlined" onClick={handleLink}>
        <IconRemove />
        {row.linkedProblem.bookName} {row.linkedProblem.name}
      </Button>
    ) : (
      <Button color="default" variant="outlined" onClick={handleLink}>
        <IconMerge />
        Link
      </Button>
    ));
  } else {
    cellContent = elements.map(e => (
      <AssessmentGroupProblemElement
        key={e.id}
        assessmentProblemId={row.id}
        element={e}
        mode="link"
        disabled={adding || editing >= 0 || disabled}
      />
    ));
  }

  return (
    <TableCell padding="none">
      {cellContent}
    </TableCell>
  );
};
