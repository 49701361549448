const borderOption = (id, top, middle, bottom, left, center, right) => ({
  id, lines: {top, middle, bottom, left, center, right}
});
export const BORDERS = {
  ALL: borderOption('ALL',true,true,true,true,true,true),
  INSIDE: borderOption('INSIDE',false,true,false,false,true,false),
  OUTSIDE: borderOption('OUTSIDE',true,false,true,true,false,true),
  TOP: borderOption('TOP',true,false,false,false,false,false),
  HORIZONTAL: borderOption('HORIZONTAL',false,true,false,false,false,false),
  BOTTOM: borderOption('BOTTOM',false,false,true,false,false,false),
  LEFT: borderOption('LEFT',false,false,false,true,false,false),
  VERTICAL: borderOption('VERTICAL',false,false,false,false,true,false),
  RIGHT: borderOption('RIGHT',false,false,false,false,false,true),
};
export const BORDER_WIDTHS = {
  THIN: 1,
  MEDIUM: 3,
  THICK: 5
};

export const DEFAULT_TABLE_WIDTH = 540;