import {apiClient} from "constants/Clients";

export const bookMenus = key => ({
  hookName: 'bookMenus',
  request: (bookId) => apiClient.getBookMenus(bookId),
  key,
  auto: true
});
export const createMenuForBook = key => ({
  hookName: 'createMenuForBook',
  request: (bookId,menuInfo) => apiClient.createBookMenu(bookId, menuInfo),
  key
});
export const menuDetails = key => ({
  hookName: 'menuDetails',
  request: (menuId) => apiClient.getMenu(menuId),
  key,
  auto: true
});
export const updateMenu = key => ({
  hookName: 'updateMenu',
  request: (menuId, menu) => apiClient.updateMenu({id: menuId, ...menu}),
  key,
});
export const deleteMenu = key => ({
  hookName: 'deleteMenu',
  request: (menuId) => apiClient.deleteMenu(menuId),
  key,
});
export const menuItemsForMenu = key => ({
  hookName: 'menuItemsForMenu',
  request: (menuId) => apiClient.getMenuItemsForMenu(menuId),
  key,
  auto: true
});
export const menuRender = key => ({
  hookName: 'menuRender',
  request: (menuId) => apiClient.renderMenu(menuId),
  key,
  auto: true
});
export const createMenuItem = key => ({
  hookName: 'createMenuItem',
  request: (menuId, menuItemInfo) => apiClient.createMenuItemForMenu(menuId, menuItemInfo),
  key
});
export const updateMenuItem = key => ({
  hookName: 'updateMenuItem',
  request: (menuItemInfo) => apiClient.updateMenuItem(menuItemInfo),
});
export const deleteMenuItem = key => ({
  hookName: 'deleteMenuItem',
  request: (menuItemId) => apiClient.deleteMenuItem(menuItemId),
});
