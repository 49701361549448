import {toggleMark} from "./base";

const canBeBookSpecific = node => node.type.attrs.bookId;
export const toggleBookSpecificContent = bookId => {
    const toggleBookSpecificMark = toggleMark('markBookSpecific', {bookId});

    return (state, dispatch) => {
        const {tr, selection: {node, $anchor}} = state;
        if (node && canBeBookSpecific(node)) {
            if (dispatch) {
                const {attrs} = node;
                tr.setNodeMarkup($anchor.pos, null, {...attrs, bookId});
                // if (state.selection.node) tr.setSelection(NodeSelection.create(tr.doc,$from.pos));
                dispatch(tr);
            }
            return true;
        }
        return toggleBookSpecificMark(state, dispatch);
    };
}
