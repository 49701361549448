import React, {useCallback} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';

import {Paper, Typography, Button} from '@material-ui/core';
import IconMedia from '@material-ui/icons/Image';

import {ViewportRoot, ViewportSection} from "components/layout";
import {LessonDetailsTable} from "components/lesson/LessonDetailsTable";
import {LessonBreadcrumb} from 'components/common/Breadcrumb';
import {LessonElementsTable} from 'components/lesson/LessonElementsTable';
import {LessonContainersTable} from 'components/lesson/LessonContainersTable';

import {createNavRouteSelector, navRoutes} from "reducers/nav";

const getNavLesson = createNavRouteSelector(navRoutes.LESSON);
const LessonDetails = () => {
  const history = useHistory();
  const lessonId = useSelector(getNavLesson);

  const goToLessonMedia = useCallback(() => {
    history.push(`/app/lessons/${lessonId}/media`);
  }, [history, lessonId]);

  return (
    <ViewportRoot>
      <ViewportSection>
        <LessonBreadcrumb lessonId={lessonId}/>
        <Typography variant="h3" gutterBottom>Lesson Container</Typography>
        <Paper>
          <LessonDetailsTable lessonId={lessonId} />
        </Paper>
      </ViewportSection>
      <ViewportSection>
        <Button variant="contained" color="primary" onClick={goToLessonMedia}>
          <IconMedia />
          Images
        </Button>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Pages</Typography>
        <Paper>
          <LessonElementsTable lessonId={lessonId} />
        </Paper>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Content Containers</Typography>
        <Paper>
          <LessonContainersTable lessonId={lessonId} />
        </Paper>
      </ViewportSection>
    </ViewportRoot>
  );
};

export {LessonDetails as LessonDetailsView}
