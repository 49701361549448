import config from "../constants/Config";
import {LogLevels, DEFAULT_LOG_LEVEL} from "./constants/log";

const {log} = config;

let LOG_LEVEL = DEFAULT_LOG_LEVEL;
let COLLAPSE = true;

if (log.prosemirror != null) {
  const {level, collapse} = log.prosemirror;
  if (level != null) LOG_LEVEL = level;
  if (collapse != null) COLLAPSE = collapse;
} else {
  LOG_LEVEL = LogLevels.WARN;
}

const logLevelName = level => {
  const entry = Object.entries(LogLevels).find(([key, value]) => level === value);
  if (entry) return entry[0];
  return '';
};
const logLevelColor = level => {
  switch(level) {
    case LogLevels.ERROR: return 'color: #ff0000; background-color: #401010';
    case LogLevels.WARN: return 'color: #f0d000';
    case LogLevels.INFO: return 'color: #00b0f0';
    case LogLevels.DEBUG: return 'color: #40a040';
    case LogLevels.SILL: return 'color: #802080';
    default: return 'color: #d0d0d0';
  }
};
const logAtLevel = (level, ...args) => {
  if (level === LogLevels.ERROR) return console.error(...args);
  if (level === LogLevels.WARN) return console.warn(...args);
  return console.log(...args);
};

export const logProsemirror = (title, attrs, level) => {
  if (!logProsemirrorStart(title, level)) return false;
  if (attrs != null) {
    Object.entries(attrs).forEach(
      ([key, value]) => logAtLevel(level, `%c${key}: %o`, 'color: #b0b0b0', value)
    );
  } else if (level <= LogLevels.WARN) {
    logAtLevel(level, '!');
  }
  console.groupEnd();
};
export const logProsemirrorStart = (title, level) => {
  if (typeof level === 'string') level = LogLevels[level];
  if (level == null) level = DEFAULT_LOG_LEVEL;
  if (level > LOG_LEVEL) return false;
  let groupOpen = COLLAPSE ? console.groupCollapsed : console.group;
  groupOpen(
    `%c[PROSEMIRROR] ${logLevelName(level)}%c ${title} %c_________________________`,
    logLevelColor(level),
    'color: #d8d4d0',
    'color: #606060'
  );
  return true;
};
export const logProsemirrorLine = (args, level) => {
  if (typeof level === 'string') level = LogLevels[level];
  if (level == null) level = DEFAULT_LOG_LEVEL;
  if (level > LOG_LEVEL) return false;
  return logAtLevel(level, ...args);
};
export const logProsemirrorKey = (key, value, level) => {
  if (typeof level === 'string') level = LogLevels[level];
  if (level == null) level = DEFAULT_LOG_LEVEL;
  if (level > LOG_LEVEL) return false;
  return logAtLevel(level, `%c${key}: %o`, 'color: #b0b0b0', value);
};
export const logProsemirrorEnd = level => {
  if (typeof level === 'string') level = LogLevels[level];
  if (level == null) level = DEFAULT_LOG_LEVEL;
  if (level > LOG_LEVEL) return false;
  return console.groupEnd();
};


export {LogLevels}