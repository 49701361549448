import React from 'react';
import {Redirect} from 'react-router-dom';

import LinearProgress from "@material-ui/core/LinearProgress";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconEdit from '@material-ui/icons/Edit';

import {withClient} from "reducers/client";
import {lessonDetails, lessonElements} from "reducers/client/requestTypes";

import {LessonBreadcrumb} from 'components/common/Breadcrumb';

import LessonElementMediasTable from 'components/lesson/LessonElementMediasTable';

import {getElementTypeByName} from "constants/ElementTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";

class LessonMedia extends React.Component {
  showElementEditor = () => {
    const {
      history,
      lessonElements,
      match: {
        url,
        params: {elementType}
      }
    } = this.props;
    let elementId = lessonElements.get().find(el => el.elementType === elementType).id;
    history.push(`/app/lessons/${lessonElements.getParams()}/editor/${elementId}/`);
  };
  handleChangeElementType = (ev, value) => {
    const { history, match: {path, url, params} } = this.props;
    let newPath = path.replace(':lessonId',params.lessonId).replace(':elementType', value);
    if (url !== newPath) history.push(newPath);
  };

  render() {
    const {
      lessonElements:elementsRequest,
      match: {
        url,
        params: {elementType}
      }
    } = this.props;

    if (!elementsRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Lesson media...</h2>
      </div>);
    }

    const lessonElements = elementsRequest.get().sort((a,b) => {
      const typeA = getElementTypeByName(a.elementType);
      const typeB = getElementTypeByName(b.elementType);
      return typeA.displayOrder - typeB.displayOrder;
    });
    if (!elementType) {
      return <Redirect to={`${url}/${lessonElements[0].elementType}`} />;
    }

    let elementId = lessonElements.find(el => el.elementType === elementType).id;

    return (
      <ViewportRoot>
        <LessonBreadcrumb lessonId={elementsRequest.getParams()} />
        <ButtonBox>
          <ButtonBoxButton title="Lesson Editor" onClick={this.showElementEditor}>
            <IconEdit />
          </ButtonBoxButton>
        </ButtonBox>
        <AppBar position="static">
          <Tabs value={elementType} onChange={this.handleChangeElementType}>
            {lessonElements.map(element => {
              const elementType = getElementTypeByName(element.elementType);
              return (
                <Tab
                  key={element.elementType}
                  value={element.elementType}
                  label={elementType ? elementType.displayName || elementType.name : element.elementType}
                />
              )
            })}
          </Tabs>
        </AppBar>
        <LessonElementMediasTable lessonId={elementsRequest.getParams()} elementId={elementId} />
      </ViewportRoot>
    );
  }
}

const getNavLesson = createNavRouteSelector(navRoutes.LESSON);
export const LessonMediaView = withClient({
  hooks: {
    lessonDetails: lessonDetails(getNavLesson),
    lessonElements: lessonElements(getNavLesson),
  },
})(LessonMedia);
