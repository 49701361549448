import React from 'react';
import PropTypes from "prop-types";

import {
  books,
  bookChapters,
  bookGlossaryItems,
  bookSections,
  chapterLessons,
  lessonContainers,
  sectionTabbedViews,
  tabbedViewTabs,
  classifications,
  classificationGrades,
  gradeStandards
} from "reducers/client/requestTypes";
import {makeBrowserList, browserListProps} from "./makeBrowserList";

import {LessonContentContainerElements} from "components/lessonContentContainer/LessonContentContainerElements";

export const BrowserBookList = makeBrowserList({
  name: 'Book',
  clientHook: books,
  getKey: book => book.id,
  render: book => [book.name]
});
BrowserBookList.propTypes = {
  ...browserListProps
};

export const BrowserChapterList = makeBrowserList({
  name: 'Chapter',
  clientHook: bookChapters((state, props) => props.bookId),
  getKey: chapter => chapter.id,
  render: chapter => [chapter.name]
});
BrowserChapterList.propTypes = {
  ...browserListProps,
  bookId: PropTypes.string.isRequired
};

export const BrowserLessonList = makeBrowserList({
  name: 'Lesson',
  clientHook: chapterLessons((state, props) => props.chapterId),
  getKey: lesson => lesson.id,
  render: lesson => [lesson.name]
});
BrowserLessonList.propTypes = {
  ...browserListProps,
  chapterId: PropTypes.string.isRequired
};

export const BrowserLessonContentContainerList = makeBrowserList({
  name: 'Lesson Content Container',
  clientHook: lessonContainers((state, props) => props.lessonId),
  getKey: lessonContentContainer => lessonContentContainer.id,
  render: (lessonContentContainer, props) => [
    lessonContentContainer.name,
    <LessonContentContainerElements elements={lessonContentContainer.elements} {...props}/>
  ]
});
BrowserLessonContentContainerList.propTypes = {
  ...browserListProps,
  lessonId: PropTypes.string.isRequired
};

export const BrowserSectionList = makeBrowserList({
  name: 'Section',
  clientHook: bookSections((state, props) => props.bookId),
  getKey: section => section.id,
  render: section => [section.type],
  tableProps: {
    initialState: {
      searchColumns: ['type']
    }
  }
});
BrowserSectionList.propTypes = {
  ...browserListProps,
  bookId: PropTypes.string.isRequired
};

export const BrowserTabbedViewList = makeBrowserList({
  name: 'Tabbed View',
  clientHook: sectionTabbedViews((state, props) => props.sectionId),
  getKey: tabbedView => tabbedView.id,
  render: tabbedView => [tabbedView.type],
  tableProps: {
    initialState: {
      searchColumns: ['type']
    }
  }
});
BrowserTabbedViewList.propTypes = {
  ...browserListProps,
  sectionId: PropTypes.string.isRequired
};

export const BrowserTabList = makeBrowserList({
  name: 'Tab',
  clientHook: tabbedViewTabs((state, props) => props.tabbedViewId),
  getKey: tab => tab.id,
  render: tab => [tab.name]
});
BrowserTabList.propTypes = {
  ...browserListProps,
  tabbedViewId: PropTypes.string.isRequired
};

export const BrowserGlossaryItemList = makeBrowserList({
  name: 'Glossary Item',
  clientHook: bookGlossaryItems((state, props) => props.bookId),
  getKey: glossaryItem => glossaryItem.id,
  render: glossaryItem => [glossaryItem.title],
  tableProps: {
    initialState: {
      searchColumns: ['title']
    }
  }
});
BrowserGlossaryItemList.propTypes = {
  ...browserListProps,
  bookId: PropTypes.string.isRequired
};

export const BrowserClassificationList = makeBrowserList({
  name: 'Classification',
  clientHook: classifications,
  getKey: classification => classification.id,
  render: classification => [classification.name]
});
BrowserClassificationList.propTypes = {
  ...browserListProps
};

export const BrowserGradeList = makeBrowserList({
  name: 'Grade',
  clientHook: classificationGrades((state, props) => props.classificationId),
  getKey: grade => grade.id,
  render: grade => [grade.title]
});
BrowserGradeList.propTypes = {
  ...browserListProps,
  classificationId: PropTypes.string.isRequired
};

export const BrowserStandardList = makeBrowserList({
  name: 'Standard',
  clientHook: gradeStandards((state, props) => props.gradeId),
  getKey: standard => standard.id,
  render: standard => [standard.title,standard.description],
  tableProps: {
    initialState: {
      searchColumns: ['title','description']
    }
  }
});
BrowserStandardList.propTypes = {
  ...browserListProps,
  gradeId: PropTypes.string.isRequired
};
