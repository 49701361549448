import React, {useRef, useState, useEffect, useMemo, useContext, forwardRef, useImperativeHandle} from 'react';
import {useStore} from 'react-redux';
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import {useTheme} from "@material-ui/core";

import {ProsemirrorInterface} from '../interface';

import {logProsemirror, LogLevels} from "../log";
import {debounce} from "util/debounce";

const ProsemirrorContext = React.createContext(null);

const ProsemirrorProviderBase = (props, ref) => {
  const theme = useTheme();
  const store = useStore();
  const history = useHistory();

  const [prosemirror, setProsemirror] = useState(null);
  const [state, setState] = useState(null);
  const pm = useRef(null);

  // ON MOUNT
  useEffect(() => {
    try {
      let pm = new ProsemirrorInterface(store, history, theme);
      pm.observe(debounce(state => setState(state)));
      setProsemirror(pm);
    } catch (ex) {
      setProsemirror({error: ex});
      logProsemirror('COULD NOT CONSTRUCT PROSEMIRROR', {
        error: ex
      }, LogLevels.ERROR);
    }
  }, []);

  // MEMOIZE CONTEXT VALUE
  const contextValue = useMemo(() => {
    return ({
      state,
      prosemirror,
    })
  }, [prosemirror, state]);

  useImperativeHandle(ref, () => prosemirror, [prosemirror]);

  if (prosemirror && !prosemirror.error) {
    return (
      <ProsemirrorContext.Provider value={contextValue}>
        {props.children}
      </ProsemirrorContext.Provider>
    );
  }
  return false;
};
ProsemirrorProviderBase.displayName = "ProsemirrorProvider";

const ProsemirrorProvider = forwardRef(ProsemirrorProviderBase);
ProsemirrorProvider.propTypes = {
  options: PropTypes.shape({
    schema: PropTypes.object,
    plugins: PropTypes.array,
  }),
};

const useProsemirror = () => {
  const pm = useContext(ProsemirrorContext);
  return pm && pm.state && pm.prosemirror;
};

const withProsemirror = BaseComponent => props => (<ProsemirrorContext.Consumer>
  {value => {
    if (!value ||!value.state) return false;
    return <BaseComponent prosemirror={value.prosemirror} {...props} />;
  }}
</ProsemirrorContext.Consumer>);

export {
  ProsemirrorProvider,
  useProsemirror,
  withProsemirror
}
