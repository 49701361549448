import React, {useEffect, useMemo, useRef} from 'react';
import classNames from 'classnames';
import PropTypes from "prop-types";

const HtmlPreview = ({html, postProcess, className, ...innerProps}) => {
  const contentRef = useRef();

  const runPostProcess = useMemo(() => {
    if (typeof postProcess === 'function') return postProcess;
    else if (Array.isArray(postProcess)) {
      return dom => postProcess.forEach(fn => fn(dom));
    }
    return dom => dom;
  }, [postProcess]);

  useEffect(() => {
    const dom = contentRef.current;
    dom.innerHTML = html;
    runPostProcess(dom);
  }, [html]);

  return <div className={classNames(className, 'content-preview')} {...innerProps} ref={contentRef}/>;
};
HtmlPreview.propTypes = {
  html: PropTypes.string,
  postProcess: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
};

export {HtmlPreview}