import {Plugin, PluginKey, NodeSelection} from 'prosemirror-state';

import {anyBrokenColumns} from "./fixColumns";
import {logProsemirror, LogLevels} from "../../log";

export const PLUGIN_COLUMNS = new PluginKey('columns');

export const columnPlugin = () => {
  return new Plugin({
    key: PLUGIN_COLUMNS,

    appendTransaction(transactions, oldState, state) {
      // return normalizeSelection(state, fixTables(state, oldState), allowTableNodeSelection)
      // return fixColumns(state, oldState);

      if (anyBrokenColumns(state, oldState)) {
        logProsemirror("ColumnSet in illegal state, reverting...", transactions, LogLevels.ERROR);
        const {tr} = state;
        for (let t = transactions.length - 1; t >= 0; --t) {
          const curr = transactions[t];
          for (let s = curr.steps.length - 1; s >= 0; --s) {
            tr.step(curr.steps[s].invert(curr.docs[s]));
          }
        }
        return tr;
      }
    }
  });
};
