import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faPencilAlt, faPlusCircle} from "@fortawesome/free-solid-svg-icons";

export const AddonUpRight = props => (<span className="fa-layers fa-fw">
  {React.cloneElement(props.children, {transform: 'left-2'})}
  <FontAwesomeIcon icon={props.icon} transform="shrink-6 up-8 right-8"/>
</span>);
export const AddonDownRight = props => (<span className="fa-layers fa-fw">
  {props.children}
  <FontAwesomeIcon icon={props.icon} transform="shrink-6 down-8 right-10"/>
</span>);
export const Dropdown = ({children}) => (<span className="fa-layers fa-fw">
  {children}
  <FontAwesomeIcon icon={faCaretDown} transform="shrink-6 down-12 right-10"/>
</span>);

export const withPlus = icon => <AddonUpRight icon={faPlusCircle}>{icon}</AddonUpRight>;
export const withPencil = icon => <AddonDownRight icon={faPencilAlt}>{icon}</AddonDownRight>;
export const text = text => <b style={{position: 'relative',top:'-0.2rem',fontSize:'120%'}}>{text}</b>;