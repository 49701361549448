import React from 'react';
import {useSelector} from "react-redux";

import {Typography, Paper} from "@material-ui/core";

import {ViewportRoot, ViewportSection} from "components/layout";
import { ChapterBreadcrumb } from 'components/common/Breadcrumb';
import {ChapterLessonsTable} from 'components/chapter/ChapterLessonsTable';
import {ChapterAssessmentGroupsTable} from 'components/chapter/ChapterAssessmentGroupsTable';
import {ChapterDetailsTable} from "components/chapter/ChapterDetailsTable";

import {createNavRouteSelector, navRoutes} from "reducers/nav";

const getNavChapter = createNavRouteSelector(navRoutes.CHAPTER);
const ChapterDetails = () => {
  const chapterId = useSelector(getNavChapter);

  return (
    <ViewportRoot>
      <ViewportSection>
        <ChapterBreadcrumb chapterId={chapterId} />
        <Typography variant="h3" gutterBottom>Chapter Information</Typography>
        <Paper><ChapterDetailsTable chapterId={chapterId} /></Paper>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Lessons</Typography>
        <Paper><ChapterLessonsTable chapterId={chapterId} /></Paper>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Assessment Groups</Typography>
        <Paper><ChapterAssessmentGroupsTable chapterId={chapterId} /></Paper>
      </ViewportSection>
    </ViewportRoot>
  );
};

export {ChapterDetails as ChapterDetailsView}
