import { InputRule, wrappingInputRule, textblockTypeInputRule, smartQuotes, emDash, ellipsis } from 'prosemirror-inputrules';

const hr = new InputRule(
  /^(--|—)-$/,
  (state, match, start, end) => {
    const {doc, tr, schema} = state;
    const $start = doc.resolve(start);
    return tr.replaceWith($start.before(), $start.after(), schema.nodes.horizontal_rule.create())
  }
);

export const makeBaseRules = schema => {
  const blockQuote = wrappingInputRule(
    /^\s*>\s$/,
    schema.nodes.blockquote
  );

  const codeBlock = textblockTypeInputRule(
    /^```$/,
    schema.nodes.code_block
  );

  return [
    ...smartQuotes,
    emDash,
    ellipsis,
    hr,
  ];
};