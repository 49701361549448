import React, {useMemo} from "react";
import PropTypes from "prop-types";

import {MergeElementsRow} from "./MergeElementsRow";

const getCombinedElements = (mainElements, mergeElements) => {
  let elements = [];
  mainElements.forEach(e => {
    elements.push({
      elementType: e.elementType,
      mainElement: e,
      mergeElement: null
    });
  });
  mergeElements.forEach(e => {
    let record = elements.find(el => el.elementType === e.elementType);
    if (record) {
      record.mergeElement = e;
    } else {
      record = {
        elementType: e.elementType,
        mainElement: null,
        mergeElement: e,
      };
      // Insert in order
      let index = elements.findIndex(({elementType}) => e.elementType < elementType);
      if (index === -1) elements.push(record);
      else elements.splice(index, 0, record);
    }
  });
  return elements;
};

const MergeElements = props => {
  const {mainElements, mergeElements, elementType, selection, onChangePreview, onChangeSelection} = props;

  const allElements = useMemo(
    () => getCombinedElements(mainElements, mergeElements),
    [mainElements, mergeElements]
  );

  return (<div>
    {allElements.map(elements => {
      return (<MergeElementsRow
        key={elements.elementType}
        elements={elements}
        checked={elementType === elements.elementType}
        value={selection[elements.elementType] || ''}
        onChange={onChangeSelection}
        onClick={onChangePreview}
      />);
    })}
  </div>);
};
const elementPropType = PropTypes.shape({
  elementType: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
});
MergeElements.propTypes = {
  mainElements: PropTypes.arrayOf(elementPropType).isRequired,
  mergeElements: PropTypes.arrayOf(elementPropType).isRequired,
  selection: PropTypes.objectOf(PropTypes.string).isRequired,
  elementType: PropTypes.string,
  onChangePreview: PropTypes.func,
  onChangeSelection: PropTypes.func,
};

export {MergeElements}
