import {
  setBlockType as pmSetBlockType,
  wrapIn as pmWrapIn
} from "prosemirror-commands";

import {insertNode} from "prosemirror/commands";
import {typeNameMiddleware} from "prosemirror/commands/helpers";

const setBlockType = typeNameMiddleware('nodes', pmSetBlockType);
const wrapIn = typeNameMiddleware('nodes',pmWrapIn);

export const blocksKeys = {
  'Mod-_': insertNode('horizontal_rule'),
  'Ctrl->': wrapIn('blockquote'),
  'Shift-Ctrl-0': setBlockType('paragraph'),
  'Shift-Ctrl-\\': setBlockType('code_block'),
  'Shift-Ctrl-1': setBlockType('heading', { level: 1 }),
  'Shift-Ctrl-2': setBlockType('heading', { level: 2 }),
  'Shift-Ctrl-3': setBlockType('heading', { level: 3 }),
  'Shift-Ctrl-4': setBlockType('heading', { level: 4 }),
  'Shift-Ctrl-5': setBlockType('heading', { level: 5 }),
  'Shift-Ctrl-6': setBlockType('heading', { level: 6 }),
};