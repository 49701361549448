import React, {useCallback, useMemo} from 'react';

import {useClientHook} from "reducers/client";
import {bookDetails, updateBook} from "reducers/client/requestTypes";

import {detailsTable} from "components/common/DetailsTable";
import {formatDate} from "constants/Date";
import {LinearProgress} from "@material-ui/core";

const InternalDetailsTable = detailsTable([
  {key: 'name', name: "Short Name"},
  {key: 'displayName', name: "Display Name"},
  {
    key: 'metadata.color',
    name: "Color",
    get: state => state.metadata && state.metadata.color,
    set: (state, value) => {
      state.metadata ? state.metadata.color = value : state.metadata = {color: value};
    }
  },
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
]);
InternalDetailsTable.displayName = "BookDetailsTableInternal";

const bookDetailsHook = bookDetails();
const updateBookHook = updateBook();
export const BookDetailsTable = ({bookId}) => {
  const bookDetails = useClientHook(bookDetailsHook, bookId);
  const updateBook = useClientHook(updateBookHook, bookId);

  const handleSave = useCallback(state => {
    updateBook.sendRequest({id: bookId, ...state}).then(() => bookDetails.sendRequest());
  }, [updateBook, bookDetails, bookId]);

  const waiting = useMemo(
    () => bookDetails.isLoading() || updateBook.isLoading(),
    [bookDetails, updateBook]
  );

  return (
    <React.Fragment>
      {waiting && <LinearProgress />}
      <InternalDetailsTable
        initialState={bookDetails.get()}
        onSave={handleSave}
        disabled={waiting}
      />
    </React.Fragment>
  )
};