import {apiClient} from "constants/Clients";

export const gradeDetails = key => ({
  hookName: 'gradeDetails',
  request: (gradeId) => apiClient.getGrade(gradeId),
  key,
  auto: true,
});
export const updateGrade = key => ({
  hookName: 'updateGrade',
  request: (gradeId, grade) => apiClient.updateGrade({id: gradeId, ...grade}),
  key,
});
export const deleteGrade = key => ({
  hookName: 'deleteGrade',
  request: (gradeId) => apiClient.deleteGrade(gradeId),
  key,
});
export const gradeStandards = key => ({
  hookName: 'gradeStandards',
  request: (gradeId) => apiClient.getClassificationStandardForGrade(gradeId),
  key,
  auto: true,
});
export const gradeCreateStandard = key => ({
  hookName: 'gradeCreateStandard',
  request: (gradeId, standard) => apiClient.createClassificationStandardForGrade(gradeId,standard),
  key,
});