import {Plugin, PluginKey, NodeSelection} from 'prosemirror-state';

import {matchTypes, findAncestor} from "prosemirror/commands/helpers";

export const PLUGIN_SELECTION = new PluginKey('selection');

const defaultTypes = [
  'mathNotes',
  'columnSet'
];
export const selectionPlugin = (blockSelectableTypes = defaultTypes) => {
  return new Plugin({
    key: PLUGIN_SELECTION,

    state: {
      init: ({schema}) => {
        let types = blockSelectableTypes.map(name => schema.nodes[name]);
        return {types, match: matchTypes(types)};
      },
      apply: (tr, value) => value,
      toJSON: value => value.types.map(type => type.name),
      fromJSON: ({schema}, value) => {
        let types = value.map(name => schema.nodes[name]);
        return {types, match: matchTypes(types)};
      }
    },

    props: {
      handleTripleClick(view, pos) {
        const {match} = PLUGIN_SELECTION.getState(view.state);
        const {doc, tr} = view.state;
        const $block = findAncestor(doc.resolve(pos), match);
        if (!$block) return false;
        view.dispatch(tr.setSelection(new NodeSelection($block)));
        return true;
      }
    }
  });
};
