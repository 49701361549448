import { combineReducers } from 'redux';
import {reducer as client} from './client';
import {reducer as table} from './table';
import {reducer as dialog} from './dialog';
import {reducer as nav} from './nav';
import form from './forms';
import settings from './settings';
import profile from './profile';
import books from './books';
import chapters from './chapters';
import lessons from './lessons';
import elements from './elements';
import classifications from './classifications';
import standards from './standards';
import dialogs from './LegacyDialogs';
import tables from './LegacyTables';
import search from './Search';
import prosemirror from './Prosemirror';
import menus from './menus';
import googleFonts from './googleFonts';

const reducers = {
  client,
  table,
  dialog,
  nav,
  form,
  settings,
  profile,
  books,
  chapters,
  lessons,
  elements,
  classifications,
  standards,
  dialogs,
  tables,
  search,
  prosemirror,
  menus,
  googleFonts,
};

export default router => combineReducers({
  router,
  ...reducers
});
