import React from 'react';
import { connect } from 'react-redux';

// Material
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";

// Icons
import IconHistory from '@material-ui/icons/History';
import IconImage from '@material-ui/icons/Image';
import IconCallMerge from '@material-ui/icons/CallMerge';
import IconHourglass from '@material-ui/icons/HourglassEmpty';

// Styles
import withStyles from '@material-ui/core/styles/withStyles';

// Components
import { GlossaryItemBreadcrumb } from 'components/common/Breadcrumb';
import {LinkedGlossaryItems} from "components/LinkList/LinkedGlossaryItems";

import {ElementEditor as Editor} from "components/editor/ElementEditor";
import PublishStatusIndicator from 'components/editor/PublishStatusIndicator';

// Constants
import config from "constants/Config";
const {color} = config;
import {displayModes} from "constants/Prosemirror";

// Actions
import {desmos, mathjax} from 'prosemirror/processors';

// Client
import {withClient, createLoadedSelector} from "reducers/client";
import {
  glossaryItemDetails,
  glossaryItemElements,
  elementDetails
} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {GlossaryItemMenu} from "prosemirror/menu";
import {setContext} from "../../reducers/Prosemirror";
import {ButtonBox, ButtonBoxButton, EditorViewport, FlexColumns} from "components/layout";

const styles = theme => ({
  title: {
    margin: `0 ${theme.spacing(1)}px`,
    color: theme.palette.primary.main,
  },
});

class GlossaryItemEditor extends React.Component {
  componentDidMount() {
    const {glossaryItemDetails, prosemirrorContextId, setProsemirrorContext} = this.props;
    if (glossaryItemDetails.isLoaded()) {
      const {id, bookId} = glossaryItemDetails.get();
      if (id !== prosemirrorContextId) {
        setProsemirrorContext(bookId, 'glossaryItem', id);
      }
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {glossaryItemDetails, prosemirrorContextId, setProsemirrorContext} = this.props;
    if (glossaryItemDetails.isLoaded()) {
      const {id, bookId} = glossaryItemDetails.get();
      if (id !== prosemirrorContextId) {
        setProsemirrorContext(bookId, 'glossaryItem', id);
      }
    }
  }

  showElementImages = () => {
    const {glossaryItemId} = this.props;
    this.props.history.push(`/app/glossaryItems/${glossaryItemId}/media`);
  };
  showElementVersions = () => {
    const {glossaryItemId} = this.props;
    this.props.history.push(`/app/glossaryItems/${glossaryItemId}/versions`);
  };
  showMerge = () => {
    const {glossaryItemId} = this.props;
    this.props.history.push(`/app/glossaryItems/${glossaryItemId}/merge`);
  };
  showMergeHistory = () => {
    const {glossaryItemId} = this.props;
    this.props.history.push(`/app/glossaryItems/${glossaryItemId}/mergeHistory`);
  };

  render() {
    const {
      classes,
      glossaryItemId,
      elementDetails,
    } = this.props;

    let selectedElementId = elementDetails.getParams() || '';
    let contentVersionId = elementDetails.isLoaded() ? elementDetails.get().currentVersionId : '';

    return (
      <EditorViewport>
        <ButtonBox>
          <PublishStatusIndicator contentVersionId={contentVersionId} />
          <ButtonBoxButton title="Images Overview" onClick={this.showElementImages}>
            <IconImage />
          </ButtonBoxButton>
          <ButtonBoxButton title="Version History" onClick={this.showElementVersions}>
            <IconHistory />
          </ButtonBoxButton>
          <ButtonBoxButton title="Share with another Glossary Item" onClick={this.showMerge}>
            <IconCallMerge />
          </ButtonBoxButton>
          <ButtonBoxButton title="Merge History" onClick={this.showMergeHistory}>
            <IconHourglass />
          </ButtonBoxButton>
        </ButtonBox>
        <FlexColumns noShrink>
          <Typography className={classes.title} variant="h6">Glossary Item Content Editor</Typography>
          <GlossaryItemBreadcrumb glossaryItemId={glossaryItemId} />
          <div className={classes.spacer} />
          <LinkedGlossaryItems glossaryItemId={glossaryItemId} />
        </FlexColumns>
        <Editor
          elementId={selectedElementId}
          menu={GlossaryItemMenu}
          previewProps={{ postProcess: [mathjax, desmos] }}
          displayMode={displayModes.DISPLAY_EBOOKS}
        />
      </EditorViewport>);
  }
}

const getNavGlossaryItem = createNavRouteSelector(navRoutes.GLOSSARY_ITEM);
const mapStateToProps = state => ({
  prosemirrorContextId: state.prosemirror.contextId,
  glossaryItemId: getNavGlossaryItem(state),
});
const mapDispatchToProps = dispatch => ({
  setProsemirrorContext: (...context) => dispatch(setContext(...context))
});

const getElementId = createLoadedSelector(
  'glossaryItemElements',
  getNavGlossaryItem,
    glossaryItemElements => glossaryItemElements[0].id
);
export const GlossaryItemEditorView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withClient({
  hooks: {
    glossaryItemDetails: glossaryItemDetails(getNavGlossaryItem),
    glossaryItemElements: glossaryItemElements(getNavGlossaryItem),
    elementDetails: elementDetails(getElementId)
  }
})(withStyles(styles)(GlossaryItemEditor)));
