import React, {useCallback, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconHistory from '@material-ui/icons/History';

import {LessonContentContainerBreadcrumb} from 'components/common/Breadcrumb';

import {LessonContentContainerBrowser} from 'components/common/Browser';
import {MergeContainersConfirmationDialog} from 'components/Dialogs/Merge/MergeContainersConfirmationDialog';
import {useClientHook} from "reducers/client";
import {lessonContentContainerDetails} from "reducers/client/requestTypes";

import {MergeLessonContentContainers} from "components/lessonContentContainer/MergeLessonContentContainers";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";
import {dialogOpen} from "reducers/dialog/actions";
import {MERGE_LESSON_CONTENT_CONTAINERS_CONFIRMATION} from "reducers/dialog/dialogIds";

const getNavLessonContentContainer = createNavRouteSelector(navRoutes.LESSON_CONTENT_CONTAINER);
const lessonContentContainerDetailsHook = lessonContentContainerDetails();
const LessonContentContainerMergeView = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [mergeId, setMergeId] = useState(null);

  const containerId = useSelector(getNavLessonContentContainer);
  const lessonContentContainerDetails = useClientHook(lessonContentContainerDetailsHook, containerId);

  const handleNavHistory = useCallback(
    () => history.push(`/app/lessonContentContainers/${containerId}/mergeHistory`),
    [history, containerId]
  );
  const handleNavEditor = useCallback(
    () => history.push(`/app/lessonContentContainers/${containerId}/editor`),
    [history, containerId]
  );
  const handleContainerSelected = useCallback(
    selection => {
      if (selection && selection[0]) setMergeId(selection[0].id);
    },
    [setMergeId]
  );
  const handleCancel = useCallback(
    () => setMergeId(null),
    [setMergeId],
  );
  const handleConfirm = useCallback(
    selection => {
      dispatch(dialogOpen(MERGE_LESSON_CONTENT_CONTAINERS_CONFIRMATION, {
        lessonContentContainerIds: [containerId, mergeId],
        selection,
        comment: "MANUAL_MERGE"
      }));
    },
    [dispatch, containerId, mergeId]
  );


  if (!lessonContentContainerDetails.isLoaded() || containerId == null) {
    return <div />;
  }
  return (
    <ViewportRoot>
      <MergeContainersConfirmationDialog />
      <ButtonBox>
        <ButtonBoxButton title="Merge History" onClick={handleNavHistory}>
          <IconHistory />
        </ButtonBoxButton>
        <ButtonBoxButton title="Back" onClick={handleNavEditor}>
          <IconArrowBack />
        </ButtonBoxButton>
      </ButtonBox>
      <h2 className="article-title">
        Find duplicate for lesson content container '{lessonContentContainerDetails.get().name}'
      </h2>
      {mergeId === null ? (
        <div>
          <LessonContentContainerBreadcrumb containerId={containerId} />
          <Paper>
            <LessonContentContainerBrowser onChange={handleContainerSelected} blacklist={[containerId]} />
          </Paper>
        </div>
      ) : (
        <div>
          <MergeLessonContentContainers
            mainContainerId={containerId}
            mergeContainerId={mergeId}
            onCancel={handleCancel}
            onSubmit={handleConfirm}
          />
        </div>
      )}
    </ViewportRoot>
  );
};

export {LessonContentContainerMergeView}
