import React, {useCallback, useState} from 'react';

import {
  Button,
  CircularProgress,
  Tooltip
} from '@material-ui/core';

import DownloadIcon from '@material-ui/icons/GetApp';

import {base} from '../../../constants/Clients';

export const getTokenFromCookie = () => {
  let cookObj = {};
  document.cookie.split(';').forEach(str => {
    let keyVal = str.split('=')
    cookObj[keyVal[0].replace(/^\s+|\s+$/g, "")]= keyVal[1]
  });
  return cookObj['cpm-sso-token']
};

const DownloadMergeReportSpreadsheetButton = ({bookMergeReportSpreadsheet, bookId, disabled, ...otherProps}) => {

  const [isLoading, setIsLoading] = useState(false);


  const handleClick = useCallback(ev => {
    setIsLoading(true);
    let jwt = getTokenFromCookie();
    fetch(`${base}/api/v1/books/${bookId}/mergereport`, {
      headers: { 'x-auth-token': jwt}
    })
      .then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `mergereport.xlsx`;
        document.body.appendChild(a); // Append the element to the dom (otherwise it will not work in firefox)
        a.click();
        a.remove(); // Afterwards remove the elements
        setIsLoading(false);
      })
  }, [bookId]);

  return (
    <Tooltip title="This may take several minutes to generate the Spreadsheet. Please be patient.">
      <span>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClick}
          disabled={isLoading || disabled}
          {...otherProps}>
          {isLoading ? (
            <CircularProgress color="secondary" size={20} />
          ) : (
            <DownloadIcon />
          )}
          Download XLSX
        </Button>
      </span>
    </Tooltip>
  )
};

export {
  DownloadMergeReportSpreadsheetButton
}
