import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {BookDetailsView} from "views/book/BookDetails";
import {BookCoverView} from "views/book/BookCover";
import {BookMenuRoutes} from './menu'
import {BookMenuListView} from "views/book/menu/BookMenuList";
import {BookStylesheetView} from "views/book/BookStylesheet";

import {useNav, navRoutes} from "reducers/nav";

export const BookRoutes = ({match: {url, path, params: {bookId}}}) => {
  const selectedBook = useNav(navRoutes.BOOK, bookId);
  if (!selectedBook) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={BookDetailsView}/>
      <Route path={`${path}/styles`} component={BookStylesheetView}/>
      <Route path={`${path}/menus/:menuID`} component={BookMenuRoutes}/>
      <Route path={`${path}/menus`} component={BookMenuListView}/>
      <Route path={`${path}/cover`} component={BookCoverView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
