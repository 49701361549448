import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {ClassificationDetailsView} from "views/classification/ClassificationDetails";

import {navRoutes, useNav} from "reducers/nav";

export const ClassificationRoutes = ({match: {url, path, params: {classificationId}}}) => {
  const selectedClassification = useNav(navRoutes.CLASSIFICATION, classificationId);
  if (!selectedClassification) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={ClassificationDetailsView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
