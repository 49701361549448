import {navRoutes, useNav} from "reducers/nav";
import {useClientHook} from "reducers/client";

export const useElementTypeNav = (containerElementsHook, containerId, elementType) => {
  const selectedElementType = useNav(navRoutes.ELEMENT_TYPE, elementType, true);
  const containerElements = useClientHook(containerElementsHook, containerId);

  let derivedElementId;
  if (containerElements.isLoaded()) {
    let selectedElement = containerElements.get().find(el => el.elementType === elementType);
    derivedElementId = selectedElement && selectedElement.id;
  }
  const selectedElementId = useNav(navRoutes.ELEMENT, derivedElementId, true);

  if (!derivedElementId) {
    if (containerElements.isLoaded()) {
      const availableType = containerElements.get()[0].elementType;
      return {redirect: availableType};
    }
    return {render: false};
  }
  if (selectedElementType && selectedElementId) return {render: true};
  return {render: false};
};