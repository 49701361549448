import {onDoubleClick} from "util/onDoubleClick";
import {promptImage} from "prosemirror/commands";
import {LogLevels, logProsemirror} from "prosemirror/log";

export class ImageMediaView {
  constructor(outerView, media) {
    this.outerView = outerView;
    this.media = media;

    logProsemirror(`CONSTRUCT CpmMediaView [${this.outerView.id}]::ImageMediaView`, {
      media: this.media
    }, LogLevels.INFO);

    this.dom = document.createElement('img');
    this.dom.setAttribute('src',media.publicUrl);
    this.outerView.dom.appendChild(this.dom);

    this.offDoubleClick = onDoubleClick(this.outerView.dom, () => {
      this.outerView.execute(promptImage);
    });
  }

  destroy() {
    logProsemirror(`DESTROY CpmMediaView [${this.outerView.id}]::ImageMediaView`, {
      media: this.media
    }, LogLevels.INFO);
    this.offDoubleClick();
    this.outerView.dom.removeChild(this.dom);
  }
}