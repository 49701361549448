import {apiClient} from "constants/Clients";

export const mediaDetails = key => ({
  hookName: 'mediaDetails',
  request: mediaId => apiClient.getMedia(mediaId),
  key,
  auto: true,
});
export const createMedia = {
  hookName: 'createMedia',
  request: mediaData => apiClient.createMediaForExistingAsset(mediaData),
};
export const createMediaFromImageUpload = {
  hookName: 'createMediaFromImageUpload',
  request: (file) => apiClient.createMedia({})
    .setQueryParams({type: 'IMAGE'})
    .attachFile(file.name, file),
};
export const updateMedia = key => ({
  hookName: 'updateMedia',
  request: (mediaId, media) => apiClient.updateMedia({id: mediaId, ...media}),
  key,
});

export const mediaAltTexts = key => ({
  hookName: 'mediaAltTexts',
  request: mediaId => apiClient.getAltTextForMedia(mediaId),
  key,
  auto: true,
});
export const createMediaAltText = key => ({
  hookName: 'createMediaAltText',
  request: (mediaId, mediaAltText) => apiClient.createAltTextForMedia(mediaId, mediaAltText),
  key,
});
export const updateMediaAltText = key => ({
  hookName: 'updateMediaAltText',
  request: (mediaId, mediaAltText) => apiClient.updateAltTextForMedia(mediaId, mediaAltText),
  key,
});
export const deleteMediaAltText = key => ({
  hookName: 'deleteMediaAltText',
  request: (mediaId, mediaAltTextId) => apiClient.deleteMediaAltText(mediaId, mediaAltTextId),
  key,
});

export const mediaTags = key => ({
  hookName: 'mediaTags',
  request: mediaId => apiClient.getTagsForMedia(mediaId),
  key,
  auto: true,
});
export const addMediaTags = key => ({
  hookName: 'addMediaTags',
  request: (mediaId, tags) => apiClient.addTagsToMedia(mediaId, tags),
  key,
});
export const removeMediaTags = key => ({
  hookName: 'removeMediaTags',
  request: (mediaId, tags) => apiClient.removeTagsFromMedia(mediaId, tags),
  key,
});
export const mediaByTags = key => ({
  hookName: 'mediaByTags',
  request: tags => apiClient.getMediaForTags(tags),
  key,
  auto: true,
});
export const mediaByTagsAndType = type => key => ({
  hookName: 'mediaByTagsAndType',
  request: tags => apiClient.getMediaForTags(tags,type),
  key,
  auto: true,
});
