import React from 'react';
import {useSelector} from "react-redux";

import {Typography, Paper} from "@material-ui/core";

import {ViewportRoot, ViewportSection} from "components/layout";
import {TabbedViewBreadcrumb} from "components/common/Breadcrumb";
import {TabbedViewTabsTable} from 'components/tabbedView/TabbedViewTabsTable';
import {TabbedViewDetailsTable} from "components/tabbedView/TabbedViewDetailsTable";

import {createNavRouteSelector, navRoutes} from "reducers/nav";

const getNavTabbedView = createNavRouteSelector(navRoutes.TABBED_VIEW);
const TabbedViewDetails = () => {
  const tabbedViewId = useSelector(getNavTabbedView);

  return (
    <ViewportRoot>
      <ViewportSection>
        <TabbedViewBreadcrumb tabbedViewId={tabbedViewId} />
        <Typography variant="h3" gutterBottom>TabbedView Information</Typography>
        <Paper><TabbedViewDetailsTable tabbedViewId={tabbedViewId} /></Paper>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Tabs</Typography>
        <Paper><TabbedViewTabsTable tabbedViewId={tabbedViewId} /></Paper>
      </ViewportSection>
    </ViewportRoot>
  );
};

export {TabbedViewDetails as TabbedViewDetailsView}
