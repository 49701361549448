import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

import AutocompleteGlossaryItem from 'components/Autocomplete/AutocompleteGlossaryItem';
import {useProsemirror} from "prosemirror/components/ProsemirrorProvider";

import {useDialog, dialogs} from "reducers/dialog";
import {toggleMark} from "prosemirror/commands";

const GlossaryLinkDialog = props => {
  const {isOpen, state, actions} = useDialog({
    dialog: dialogs.PROSEMIRROR_GLOSSARY_LINK,
    initialState: { glossaryItemId: null }
  });
  const bookId = useSelector(state => state.prosemirror.bookId);
  const prosemirror = useProsemirror();

  const handleSubmit = useCallback(() => {
    prosemirror.execute(toggleMark('glossaryLink', {id: state.glossaryItemId}));
    actions.close();
  }, [prosemirror, state.glossaryItemId]);

  // if (!state) return false;
  return (
    <Dialog open={isOpen} onClose={actions.close} disableRestoreFocus>
      <DialogTitle>Select a Glossary Item</DialogTitle>
      <DialogContent>
        <AutocompleteGlossaryItem
          fullWidth
          label="Glossary Item"
          suggestionsStatic
          bookId={bookId}
          onSuggestionSelected={(event, {suggestion}) => actions.setState({glossaryItemId: suggestion.id})}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export {GlossaryLinkDialog}
