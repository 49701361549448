import React from 'react';
import PropTypes from 'prop-types';

import ElementParents from './ElementParents';
import {useClientHook} from "reducers/client";
import {glossaryItemElements} from "reducers/client/requestTypes";

const glossaryItemElementsHook = glossaryItemElements();
const LinkedGlossaryItems = ({glossaryItemId}) => {
    const glossaryItemElements = useClientHook(glossaryItemElementsHook, glossaryItemId);
    if (!glossaryItemElements.isLoaded()) return null;
    const element = glossaryItemElements.get()[0];
    if (element) return <ElementParents elementId={element.id} />;
    return null;
};

LinkedGlossaryItems.propTypes = {
    glossaryItemId: PropTypes.string.isRequired,
};

export {LinkedGlossaryItems};
