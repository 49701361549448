import React from 'react';
import {useSelector} from "react-redux";

import {BookBreadcrumb} from "components/common/Breadcrumb";
import Menu from 'components/Menu/Menu';
import MenuSidePanel from "components/Menu/MenuSidePanel";

import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ViewportRoot, ViewportSection} from "components/layout";

const getNavBook = createNavRouteSelector(navRoutes.BOOK);
const BookMenuEditor = () => {
  const bookId = useSelector(getNavBook);

  return (
    <ViewportRoot>
      <ViewportSection>
        <BookBreadcrumb bookId={bookId} />
        <div className="row">
          <div className="col-sm-4">
            <MenuSidePanel />
          </div>
          <div className="col-sm-8">
            <Menu />
          </div>
        </div>
      </ViewportSection>
    </ViewportRoot>
  );
};

export {BookMenuEditor as BookMenuEditorView}
