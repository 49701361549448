import React from "react";

import {MenuGroup, MenuSelect} from 'prosemirror/components/menu';

import * as icons from 'prosemirror/icons';
import {selectionInside, getAttribute} from 'prosemirror/commands/readers';
import {setAttributes} from "prosemirror/commands/base";

const LIST_NODE_TYPES = ['ordered_list','bullet_list'];

const COLUMN_OPTIONS = [1,2,3,4].map(n => ({
  value: n,
  title: `${n} column${n > 1 ? 's' : ''}`,
  icon: icons.columns[n]
}));

export const columns = (<MenuSelect key="list-columns"
  command={columns => setAttributes(LIST_NODE_TYPES, {columns})}
  selected={getAttribute(LIST_NODE_TYPES, 'columns')}
  options={COLUMN_OPTIONS}
  title="List Columns"
/>);

export const ListMenuGroup = props => (
  <MenuGroup {...props} visible={selectionInside(LIST_NODE_TYPES)}>
    {props.children}
  </MenuGroup>
);
