import React from 'react';
import { hashHistory } from 'react-router';

const HeaderIconButtonStyle = {
  width: '60px',
  height: '60px'
};

const listItemStyle = {
  paddingLeft: '40px' // 36 + 16, algin with sub list
};

class NavLeftList extends React.Component {

  handleChange = (event, value) => {
    hashHistory.push(value);
  }


  render() {
    return (
      <ul className="list-unstyled list-inline">
        <li className="list-inline-item">
          <div style={{marginLeft: '20px', marginTop: '-10px'}}>
            {/* <ConsumerSelector /> */}
          </div>

        </li>

      </ul>
    );
  }
}

export default NavLeftList;
