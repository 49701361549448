
const replaceAround = (array, replacement, index, amount = 1) => {
  return array.slice(0,index).concat(replacement).concat(array.slice(index + amount));
};

export const ActionCreators = {
  reset: payload => ({type: 'reset', payload}),
  update: (rowIndex, columnIndex, payload) => ({type: 'update', rowIndex, columnIndex, payload}),
  selectToEdit: index => ({type:'selectToEdit', payload: index}),
  setAdding: bool => ({type:'setAdding', payload: bool}),
  setDeleting: bool => ({type:'setDeleting', payload: bool})
};

export const initialState = {
  adding: false,
  editing: -1,
  deleting: false,
  originalPage: [],
  page: [],
  newRow: {}
};

export const makeReducer = (columns, extension) => (state, action) => {
  state = extension(state, action);
  switch(action.type) {
    case 'reset':
      return {
        ...initialState,
        originalPage: action.payload,
        page: action.payload,
      };
    case 'update':
      const {rowIndex, columnIndex, payload} = action;
      const row = rowIndex != null ? state.page[rowIndex] : state.newRow;
      const column = columns.find(c => c.key === columnIndex);
      let nextRowState = {...row};
      column.set(nextRowState, payload);
      if (rowIndex != null) {
        return {
          ...state,
          page: replaceAround(state.page, nextRowState, rowIndex)
        };
      } else {
        return {
          ...state,
          newRow: nextRowState
        };
      }
    case 'selectToEdit':
      return { ...state, editing: action.payload };
    case 'setAdding':
      let newRow = {};
      columns.forEach(column => {
        if (column.hasOwnProperty('default')) newRow[column.key] = column.default;
      });
      return { ...state, adding: Boolean(action.payload), newRow };
    case 'setDeleting':
      return { ...state, deleting: Boolean(action.payload)};
    default: return state;
  }
};
