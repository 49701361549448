import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import Button from "@material-ui/core/Button";

import {LessonContentContainerBrowser} from 'components/common/Browser';
import {dialogs, reduxDialog} from "reducers/dialog";

class LinkLessonContentContainerDialogBase extends React.Component {
  static propTypes = {
    onConfirm: PropTypes.func,
  };

  handleSelectLessonContentContainer = ([selectedLessonContentContainer]) => {
    const {dialog: {actions}} = this.props;
    actions.setState({selectedLessonContentContainer});
  };
  handleConfirm = () => {
    const { dialog: {actions, state}, onConfirm } = this.props;
    actions.close();
    if (typeof onConfirm === 'function') onConfirm(state.selectedLessonContentContainer);
  };

  render() {
    const {dialog: {isOpen, actions, state: {selectedLessonContentContainer}, data: {containerId} = {}}}  = this.props;

    return (<Dialog open={isOpen} maxWidth={'md'} fullWidth onClose={actions.close}>
      <DialogTitle>Select Lesson Content Container to Link</DialogTitle>
      <div style={{overflowY: 'scroll'}}>
        <LessonContentContainerBrowser onChange={this.handleSelectLessonContentContainer} blacklist={[containerId]} />
      </div>
      <DialogActions>
        <Button onClick={actions.close} color="default">
          Cancel
        </Button>
        <Button onClick={this.handleConfirm} color="primary" disabled={!selectedLessonContentContainer}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>);
  }
}


const LinkLessonContentContainerDialog = reduxDialog({
  dialog: dialogs.LESSON_CONTENT_CONTAINER_LINK,
  initialState: {selectedLessonContentContainer: null}
})(LinkLessonContentContainerDialogBase);
LinkLessonContentContainerDialog.displayName = 'LinkLessonContentContainerDialog';

export {LinkLessonContentContainerDialog}
