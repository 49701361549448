import {Form, Field, reduxForm} from "redux-form";
import {renderField, renderCheckbox} from "../../../common/Forms/fields";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";

const EmbedDialogForm = () =>  (<form>
  <Field name="src" type="text" component={renderField} label="Embedded URL" fullWidth />
  <Field name="title" type="text" component={renderField} label="Title" fullWidth />
  <Field name="width" type="text" component={renderField} label="Width" />
  <Field name="height" type="text" component={renderField} label="Height" />
  <Field name="fullscreen" type="checkbox" component={renderCheckbox} label="Allow Fullscreen" />
</form>);

export default reduxForm({
  form: 'embedDialog'
})(EmbedDialogForm);