import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import * as TableActions from './actions';
import {createPaginationSelector} from "./selectors";
import {initialPaginationState} from "./reducer";

export const useTable = ({table, initialState}) => {
  const paginationSelector = useMemo(
    () => createPaginationSelector(table),
    [table]
  );
  const pagination = useSelector(paginationSelector);
  const dispatch = useDispatch();

  // Cache actions, they are stateless
  const actions = useMemo(() => ({
    setPage: pageNumber => dispatch(TableActions.tableSetPage(table, pageNumber)),
    setPageSize: pageSize => dispatch(TableActions.tableSetPageSize(table, pageSize)),
    setSearch: search => dispatch(TableActions.tableSetSearch(table, search)),
    setSearchColumns: columns => dispatch(TableActions.tableSetSearchColumns(table, columns)),
    setMetadata: metadata => dispatch(TableActions.tableSetMetadata(table, metadata)),
    toggleSort: column => dispatch(TableActions.tableToggleSort(table, column))
  }), [table]);

  // MOUNT / UNMOUNT
  useEffect(() => {
    dispatch(TableActions.registerTable(table, initialState));
    return () => dispatch(TableActions.unregisterTable(table));
  }, [table]);

  return useMemo(() => ({
    pagination: pagination || {
      ...initialPaginationState,
      ...initialState
    },
    actions
  }), [pagination, actions]);
};
