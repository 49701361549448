import {asArray} from "./util/map";

const LessonContentContainerElementTypeNames = ['problem','problemAnswer','lessonIntroduction','mathNote'];

const ElementTypes = {
  agenda: {
    name: 'agenda',
    ebooksTypeNumber: 33,
  },
  agendaNotes: {
    name: 'agendanotes',
    ebooksTypeNumber: 34
  },
  walkthrough: {
    name: 'walkthrough',
    ebooksTypeNumber: 35,
  },
  resources: {
    name: 'resources',
    ebooksTypeNumber: 36,
    validContentables: ['lesson'],
    displayOrder: 2,
  },
  slides: {
    name: 'slides',
    ebooksTypeNumber: 37,
  },
  planning: {
    name: 'planning',
    ebooksTypeNumber: 39,
    problemElementType: 'problem',
    validContentables: ['lesson'],
    validChildElementTypes: ['problem'],
    displayOrder: 1,
  },
  assessmentproblem: {
    name: 'assessmentproblem',
    ebooksTypeName: 'assessmentproblem',
    displayName: 'question',
  },
  assessmentproblemanswer: {
    name: 'assessmentproblemanswer',
    ebooksTypeName: 'assessmentProblemAnswer',
    displayName: 'answer',
  },
  lesson: {
    name: 'lesson',
    ebooksTypeName: 'lesson',
    ebooksTypeNumber: 0,
    problemElementType: 'problem',
    validContentables: ['lesson'],
    validChildElementTypes: LessonContentContainerElementTypeNames,
    displayOrder: 0,
  },
  notes: {
    name: 'notes',
    ebooksTypeName: 'notes',
    ebooksTypeNumber: 1,
    validContentables: ['lesson'],
    validChildElementTypes: LessonContentContainerElementTypeNames,
    displayOrder: 2,
  },
  answers: {
    name: 'answers',
    ebooksTypeName: 'answers',
    ebooksTypeNumber: 2,
    problemElementType: 'problemAnswer',
    validContentables: ['lesson'],
    validChildElementTypes: LessonContentContainerElementTypeNames,
    displayOrder: 1,
  },
  menu: {
    name: 'menu',
    ebooksTypeName: 'menu'
  },
  tcredits: {
    name: 'tcredits',
    ebooksTypeName: 'tcredits',
    ebooksTypeNumber: 8,
  },
  tpermissions: {
    name: 'tpermissions',
    ebooksTypeName: 'tpermissions',
    ebooksTypeNumber: 9,
  },
  tnote: {
    name: 'tnote',
    ebooksTypeName: 'tnote',
    ebooksTypeNumber: 10,
  },
  tlinks: {
    name: 'tlinks',
    ebooksTypeName: 'tlinks',
    ebooksTypeNumber: 12,
  },
  closure: {
    name: 'closure',
    ebooksTypeName: 'closure',
    ebooksTypeNumber: 15,
  },
  teachersupport: {
    name: 'teachersupport',
    ebooksTypeName: 'teachersupport',
    ebooksTypeNumber: 20,
  },
  sharing: {
    name: 'sharing',
    ebooksTypeName: 'sharing',
    ebooksTypeNumber: 26,
    validContentables: ['lesson'],
    displayOrder: 4,
  },
  hunt: {
    name: 'hunt',
    ebooksTypeName: 'hunt',
    ebooksTypeNumber: 27,
  },
  hunts: {
    name: 'hunts',
    ebooksTypeName: 'hunts',
    ebooksTypeNumber: 43,
  },
  mynotes: {
    name: 'mynotes',
    ebooksTypeName: 'mynotes',
    ebooksTypeNumber: 28,
    displayOrder: 3,
  },
  problem: {
    name: 'problem',
    ebooksTypeName: 'n/a',
    ebooksTypeNumber: 800,
    displayName: 'question',
    validContentables: ['lessonContentContainer'],
  },
  problemAnswer: {
    name: 'problemAnswer',
    ebooksTypeName: 'n/a',
    ebooksTypeNumber: 801,
    displayName: 'answer',
    validContentables: ['lessonContentContainer'],
  },
  lessonIntroduction: {
    name: 'lessonIntroduction',
    ebooksTypeName: 'n/a',
    ebooksTypeNumber: 802,
    displayName: 'lesson introduction',
    validContentables: ['lessonContentContainer'],
  },
  mathNote: {
    name: 'mathNote',
    ebooksTypeName: 'n/a',
    ebooksTypeNumber: 803,
    displayName: 'math note',
    validContentables: ['lessonContentContainer'],
  },
  tabbedContent: {
    name: 'tabbedContent',
    ebooksTypeName: 'n/a',
    ebooksTypeNumber: 5,
    validContentables: ['tab'],
  },
  oview: {
    name: 'oview',
    ebooksTypeName: 'oview',
    ebooksTypeNumber: 38,
    validContentables: ['lesson'],
    displayOrder: 0,
  },
  sectionoview: {
    name: 'sectionoview',
    ebooksTypeName: 'oview',
    ebooksTypeNumber: 44,
    validContentables: ['section'],
    displayOrder: 0,
  },
  sectionnotes: {
    name: 'sectionnotes',
    ebooksTypeName: 'oview',
    ebooksTypeNumber: 45,
    validContentables: ['section'],
    displayOrder: 0,
  },
  tcheckpoints: {
    name: 'tcheckpoints',
    ebooksTypeName: 'tcheckpoints',
    ebooksTypeNumber: 11,
  },
  misc: {
    name: 'misc'
  },
  lessonataglance: {
    name: 'lessonataglance',
    displayOrder: -1,
  },
  teacherlesson: {
    name: 'teacherlesson',
    displayOrder: 1,
  },
  // VV CDP TEACHER MATERIALS VV
  miscRoutinesAndSupports: {
    name: 'miscRoutinesAndSupports'
  },
  content: {
    name: 'content'
  },
  assessment: {
    name: 'assessment'
  },
  chapterClosure: {
    name: 'chapterClosure'
  },
  learningTargets: {
    name: 'learningTargets'
  },
  udlc: {
    name: 'udlc'
  },
  eightCompetencies: {
    name: 'eightCompetencies'
  },
  studyTeamAndStrats: {
    name: 'studyTeamAndStrats'
  },
  englishLearnersSuccessForumCoverage: {
    name: 'englishLearnersSuccessForumCoverage'
  },
  routines: {
    name: 'routines'
  },
  growthMindsetPractices: {
    name: 'growthMindsetPractices'
  },
  mathmaticalLanguageRoutines: {
    name: 'mathmaticalLanguageRoutines'
  },
  facilitaton: {
    name: 'facilitaton'
  },
  strategiesForDeeperThinking: {
    name: 'strategiesForDeeperThinking'
  },
  glossary: {
    name: 'glossary',
    ebooksTypeName: 'glossary',
    ebooksTypeNumber: 3,
    validContentables: ['glossaryItem'],
  },
  ccss: {
    name: 'ccss',
    ebooksTypeName: 'ccss',
    ebooksTypeNumber: 31,
    validContentables: ['glossaryItem'],
  },
  tstrategies: {
    name: 'tstrategies',
    ebooksTypeName: 'tstrategies',
    ebooksTypeNumber: 21,
    validContentables: ['glossaryItem'],
  }
};

export default ElementTypes;

const ElementTypesArray = asArray(ElementTypes);
export const getElementTypeByName = elementTypeName => {
  if (ElementTypes[elementTypeName]) return ElementTypes[elementTypeName];
  return ElementTypesArray.find(type => type.name === elementTypeName);
};
export const ElementTypeNames = ElementTypesArray.map(type => type.name);

const contentableElementTypes = {
  assessmentProblem: ['assessmentproblem', 'assessmentproblemanswer']
};
export const getValidElementTypesForContentable = contentableName => {
  if (contentableElementTypes[contentableName]) return contentableElementTypes[contentableName];
  let types = ElementTypesArray.filter(type => type.validContentables && type.validContentables.includes(contentableName));
  contentableElementTypes[contentableName] = types;
  return types;
};

export const getValidSiblingTypeNamesForElementTypeByName = elementTypeName => {
  let validParents = ElementTypesArray.filter(parentType =>
    parentType.validChildElementTypes ?
      parentType.validChildElementTypes.includes(elementTypeName) :
      false
  );
  let validSiblingNames = validParents.flatMap(parentType => parentType.validChildElementTypes);
  return Array.from(new Set(validSiblingNames));
};

export const defaultElementTypeForContentable = {
  lesson: ElementTypes.lesson,
  lessonContentContainer: ElementTypes.problem,
  assessmentProblem: ElementTypes.assessmentproblem,
  tab: ElementTypes.tabbedContent,
  section: ElementTypes.hunt
};