import React, {useCallback, useMemo} from 'react';

import {useClientHook} from "reducers/client";
import {classificationDetails, updateClassification} from "reducers/client/requestTypes";

import {detailsTable} from "components/common/DetailsTable";
import {formatDate} from "constants/Date";
import {LinearProgress} from "@material-ui/core";

const InternalDetailsTable = detailsTable([
  {key: 'name', name: "Classification"},
  {key: 'description', name: "Description"},
  {key: 'url', name: "URL"},
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
]);
InternalDetailsTable.displayName = "ClassificationDetailsTableInternal";

const classificationDetailsHook = classificationDetails();
const updateClassificationHook = updateClassification();
export const ClassificationDetailsTable = ({classificationId}) => {
  const classificationDetails = useClientHook(classificationDetailsHook, classificationId);
  const updateClassification = useClientHook(updateClassificationHook, classificationId);

  const handleSave = useCallback(state => {
    updateClassification.sendRequest({id: classificationId, ...state}).then(() => classificationDetails.sendRequest());
  }, [updateClassification, classificationDetails, classificationId]);

  const waiting = useMemo(
    () => classificationDetails.isLoading() || updateClassification.isLoading(),
    [classificationDetails, updateClassification]
  );

  return (
    <React.Fragment>
      {waiting && <LinearProgress />}
      <InternalDetailsTable
        initialState={classificationDetails.get()}
        onSave={handleSave}
        disabled={waiting}
      />
    </React.Fragment>
  )
};