import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {
  books,
  updateBook,
  deleteBook
} from "reducers/client/requestTypes";

import {pagedTable} from 'components/common/Table/PagedTable';
import {formatDate} from "constants/Date";
import {paging} from "reducers/table";

const InternalTable = pagedTable([
  {key: 'id', hidden: true},
  {key: 'name', sort: 'name', name: 'Short Name'},
  {key: 'displayName', sort: 'displayName', name: 'Display Name'},
  {key: 'createdAt', sort: 'createdAt,name', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', sort: 'updatedAt,name', name: "Last Updated", render: formatDate, readOnly: true},
], {
  table: 'BooksTable',
  initialState: {
    searchColumns: ['name','displayName']
  },
  readOnly: true,
  getRowKey: row => row.id,
  getRowName: row => row.name,
});

const booksHook = paging('BooksTable')(books);
const updateBookHook = updateBook();
const deleteBookHook = deleteBook();
const BooksTable = (props) => {
  const [totalCount, setTotalCount] = useState(0);
  const [tablePage, setTablePage] = useState([]);
  const history = useHistory();

  const clientKey = useCallback(selector => selector(props), [props]);
  const books = useClientHook(booksHook, clientKey);
  const updateBook = useClientHook(updateBookHook);
  const deleteBook = useClientHook(deleteBookHook);

  const handleSave = useCallback(book => {
    if (book.id) {
      return updateBook.sendRequest(book.id, book)
        .then(() => books.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [books, updateBook]);
  const handleDelete = useCallback(book => {
    if (book.id) {
      return deleteBook.sendRequest(book.id)
        .then(() => books.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [books, deleteBook]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/books/${tablePage[index].id}/details`);
  }, [history,tablePage]);

  const isWaiting = useMemo(
    () =>
      books.isLoading() ||
      updateBook.isLoading() ||
      deleteBook.isLoading(),
    [books, updateBook, deleteBook]
  );

  useEffect(() => {
    if (books.isLoaded()) {
      const {totalCount, results} = books.get();
      setTotalCount(totalCount);
      setTablePage(results);
    }
  }, [books, setTotalCount, setTablePage]);

  return (
    <InternalTable
      page={tablePage}
      totalCount={totalCount}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      waiting={isWaiting}
    />
  )
};

export {BooksTable}
