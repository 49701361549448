import {redo, undo} from "prosemirror-history";
import {
  chainCommands,
  exitCode,
  joinDown,
  joinUp,
  lift,
  selectParentNode
} from "prosemirror-commands";
import {undoInputRule} from "prosemirror-inputrules";

import {insertNode, deleteTableSelection} from "prosemirror/commands";

const insertBreak = insertNode('hard_break');

export const baseKeys = {
  'Mod-z': undo,
  'Shift-Mod-z': redo,
  Backspace: chainCommands(deleteTableSelection,undoInputRule),
  Delete: deleteTableSelection,
  'Mod-y': redo,
  'Alt-ArrowUp': joinUp,
  'Alt-ArrowDown': joinDown,
  'Mod-BracketLeft': lift,
  Escape: selectParentNode,
  'Mod-Enter': chainCommands(exitCode, insertBreak),
  'Shift-Enter': chainCommands(exitCode, insertBreak),
  'Ctrl-Enter': chainCommands(exitCode, insertBreak), // mac-only?
};