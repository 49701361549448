import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import {Button} from "@material-ui/core";
import IconCheck from "@material-ui/icons/Check";

import {paging} from "reducers/table";
import {useClientHook} from "reducers/client";
import {clearRequest} from "reducers/client/actions";
import {
  manual,
  assessmentProblemDetails,
  assessmentGroupProblems,
  assessmentGroupProblemOrder,
  assessmentGroupCreateProblem,
  updateAssessmentProblem,
  deleteAssessmentProblem,
  assessmentGroupAddProblem,
  assessmentGroupRemoveProblem
} from "reducers/client/requestTypes";

import {draggableTable} from 'components/common/Table/DraggableTable';
import {pagedTable} from "components/common/Table/PagedTable";
import {TableCheckbox} from "components/common/Table/TableControls";
import {ActionCreators} from "components/common/Table/reducer";
import {select} from "components/common/Table/TableControls";
import {AssessmentGroupLinkProblemDialog} from "components/assessmentGroup/AssessmentGroupLinkProblemDialog";

import {AssessmentGroupProblemContent} from "./AssessmentGroupProblemContent";

const columnSpec = [
  {key: 'id', hidden: true},
  {key: 'linkedProblem', hidden: true},
  {key: 'replace', hidden: true},
  {key: 'assessmentQuestionId', sort: 'assessmentQuestionId', name: 'ID', readOnly: true},
  {key: 'name', sort: 'name', name: 'Name', optional: true},
  {
    key: 'isTeam',
    sort: 'isTeam',
    default: false,
    name: 'Team',
    Editor: TableCheckbox,
    render: isTeam => isTeam && <IconCheck />,
    cellProps: {padding: 'checkbox'}
  },
  {
    key: 'difficultyLevel',
    sort: 'difficultyLevel',
    default: 0,
    name: 'Difficulty',
    Editor: select([{value:0},{value:1},{value:2}])
  },
  {
    key: 'elements',
    name: 'Elements',
    Component: AssessmentGroupProblemContent,
    memo: (prevProps, nextProps) => {
      if (prevProps.isNew !== nextProps.isNew) return false;
      const prevState = prevProps.state;
      const nextState = nextProps.state;
      if (!prevState) return !nextState;
      if (!nextState) return false;
      if (nextProps.isNew) {
        if (prevState.newRow.linkedProblem !== nextState.newRow.linkedProblem) return false;
      } else {
        const prevRow = prevState.page[prevProps.index];
        const nextRow = nextState.page[nextProps.index];
        if (!prevRow) return !nextRow;
        if (!nextRow) return false;
        if (prevRow.elements !== nextRow.elements) return false;
        const prevEditingRow = prevState.page[prevState.editing];
        const nextEditingRow = nextState.page[nextState.editing];
        if (!prevEditingRow) return !nextEditingRow;
        if (!nextEditingRow) return false;
        if (prevEditingRow.elements !== nextEditingRow.elements) return false;
        return true;
      }
      return true;
    },
    readOnly: true,
  },
];
const tableOptions = {
  table: 'AssessmentGroupProblemsTable',
  initialState: {
    sort: {
      by: 'assessmentQuestionId',
      asc: true
    },
    searchColumns: ['name']
  },
  getRowKey: row => row.id,
  getRowName: row => row.name,
  actions: {
    Component: ({state: {adding, editing, page, newRow}, index, isNew}) => {
      const history = useHistory();
      if (!isNew && !adding && editing < 0) {
        const row = isNew ? newRow : page[index];
        if (!row) return null;
        return (
          <Button
            color="primary"
            onClick={() => history.push(`/app/assessmentproblems/${row.id}/standards/assessmentproblem`)}>
            Standards
          </Button>
        );
      }
      return null;
    }
  }
};
// const InternalTable = draggableTable(columnSpec, tableOptions);
const InternalTable = pagedTable(columnSpec, tableOptions);

const assessmentProblemDetailsHook = manual(assessmentProblemDetails());
const assessmentProblemsHook = paging('AssessmentGroupProblemsTable')(
  assessmentGroupProblems((state, props) => props.assessmentGroupId)
);
const assessmentProblemOrderHook = assessmentGroupProblemOrder();
const createAssessmentProblemHook = assessmentGroupCreateProblem();
const updateAssessmentProblemHook = updateAssessmentProblem();
const deleteAssessmentProblemHook = deleteAssessmentProblem();
const assessmentGroupAddProblemHook = assessmentGroupAddProblem();
const assessmentGroupRemoveProblemHook = assessmentGroupRemoveProblem();

export const AssessmentGroupProblemsTable = ({assessmentGroupId}) => {
  const tableRef = useRef(null);

  const [totalCount, setTotalCount] = useState(0);
  const [tablePage, setTablePage] = useState([]);

  const reduxDispatch = useDispatch();

  const assessmentProblemDetails = useClientHook(assessmentProblemDetailsHook);
  const clientKey = useCallback(selector => selector({assessmentGroupId}), [assessmentGroupId]);
  const assessmentProblems = useClientHook(assessmentProblemsHook, clientKey);
  const assessmentProblemOrder = useClientHook(assessmentProblemOrderHook, assessmentGroupId);
  const createAssessmentProblem = useClientHook(createAssessmentProblemHook, assessmentGroupId);
  const assessmentGroupAddProblem = useClientHook(assessmentGroupAddProblemHook, assessmentGroupId);
  const assessmentGroupRemoveProblem = useClientHook(assessmentGroupRemoveProblemHook);
  const updateAssessmentProblem = useClientHook(updateAssessmentProblemHook);
  const deleteAssessmentProblem = useClientHook(deleteAssessmentProblemHook);

  const handleLink = useCallback((assessmentProblem, replace) => {
    tableRef.current.dispatch(ActionCreators.update(null, 'linkedProblem', assessmentProblem));
    tableRef.current.dispatch(ActionCreators.update(null, 'replace', replace));
    tableRef.current.dispatch(ActionCreators.update(null, 'name', assessmentProblem.name));
    tableRef.current.dispatch(ActionCreators.update(null, 'isTeam', assessmentProblem.isTeam));
    tableRef.current.dispatch(ActionCreators.update(null, 'difficultyLevel', assessmentProblem.difficultyLevel));
  }, []);
  const handleSave = useCallback(assessmentProblem => {
    if (assessmentProblem.id) {
      return updateAssessmentProblem.sendRequest(assessmentProblem.id, assessmentProblem)
        .then(() => assessmentProblems.sendRequest());
    } else if (assessmentProblem.linkedProblem) {
      let promise = Promise.resolve();

      if (assessmentProblem.replace) {
        // Remove links to previous groups
        promise = assessmentProblemDetails.sendRequest(assessmentProblem.linkedProblem.id).then(result => {
          return Promise.all(result.assessmentGroups.map(
            g => assessmentGroupRemoveProblem.sendRequest(g.id, assessmentProblem.linkedProblem.id)
              .then(() => reduxDispatch(clearRequest('assessmentGroupProblems', g.id)))
          ));
        });
      }
      // Add link to this group
      return promise
        .then(() => assessmentGroupAddProblem.sendRequest(assessmentProblem.linkedProblem.id))
        .then(() => assessmentProblems.sendRequest());
    } else {
      return createAssessmentProblem.sendRequest(assessmentProblem)
        .then(() => assessmentProblems.sendRequest());
    }
  }, [assessmentProblems, updateAssessmentProblem, createAssessmentProblem, assessmentGroupAddProblem, assessmentGroupRemoveProblem]);
  const handleDelete = useCallback(assessmentProblem => {
    if (assessmentProblem.id) {
      return deleteAssessmentProblem.sendRequest(assessmentProblem.id)
        .then(() => assessmentProblems.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [assessmentProblems, deleteAssessmentProblem]);
  // const handleOrder = useCallback(order => {
  //   const currentAssessmentProblems = assessmentProblems.get();
  //   assessmentProblemOrder.sendRequest(order.map(i => currentAssessmentProblems[i].id))
  //     .then(() => assessmentProblems.sendRequest());
  // }, [assessmentProblems, assessmentProblemOrder])

  const isWaiting = useMemo(
    () =>
      assessmentProblems.isLoading() ||
      createAssessmentProblem.isLoading() ||
      updateAssessmentProblem.isLoading() ||
      deleteAssessmentProblem.isLoading() ||
      assessmentProblemOrder.isLoading(),
    [assessmentProblems, createAssessmentProblem, updateAssessmentProblem, deleteAssessmentProblem, assessmentProblemOrder]
  );

  useEffect(() => {
    if (assessmentProblems.isLoaded()) {
      const {totalCount, results} = assessmentProblems.get();
      setTotalCount(totalCount);
      setTablePage(results);
    }
  }, [assessmentProblems, setTotalCount, setTablePage]);

  return (
    <React.Fragment>
      <AssessmentGroupLinkProblemDialog
        onConfirm={handleLink}
      />
      <InternalTable
        ref={tableRef}
        page={tablePage}
        totalCount={totalCount}
        onSave={handleSave}
        onDelete={handleDelete}
        // onOrder={handleOrder}
        waiting={isWaiting}
      />
    </React.Fragment>
  )
};
