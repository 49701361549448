import React, {useCallback, useMemo} from "react";
import PropTypes from "prop-types";

import {IconButton, makeStyles, Switch, Tooltip} from "@material-ui/core";
import IconLink from '@material-ui/icons/Link';

import {elementParents} from "reducers/client/requestTypes";
import {useClientHook} from "reducers/client";

import {mapParents} from "util/elementStructureRelations";

const useStyles = makeStyles({
  tooltip: {
    maxWidth: '200px'
  }
}, {name:MergeElementSwitch});

const elementParentsHook = elementParents();
const MergeElementSwitch = ({align, elementId, disabled, checked, onChange}) => {
  const classes = useStyles({});
  const parentsRequest = useClientHook(elementParentsHook, elementId);
  const parents = useMemo(
    () => parentsRequest.isLoaded() ? mapParents(parentsRequest.get().parents) : [],
    [parentsRequest]
  );
  const handleChange = useCallback(() => onChange(elementId), [onChange, elementId]);

  if (!elementId) return null;

  const theSwitch = (
    <Tooltip
      title="Link using this element. If unchecked, this element type will not be linked."
      classes={classes}
    >
      <Switch
        disabled={Boolean(disabled)}
        checked={Boolean(checked)}
        onChange={handleChange}
      />
    </Tooltip>
  );
  const linkIndicator = (parents.length > 1) ? (
    <Tooltip title={parents.map(p => `${p.bookName} ${p.name}`).join(', ')}>
      <IconButton>
        <IconLink />
      </IconButton>
    </Tooltip>
  ) : null;

  return (
    <React.Fragment>
      {align === 'right' && linkIndicator}
      {theSwitch}
      {align === 'left' && linkIndicator}
    </React.Fragment>
  );
};
MergeElementSwitch.propTypes = {
  align: PropTypes.oneOf(['left','right']).isRequired,
  elementId: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export {MergeElementSwitch}
