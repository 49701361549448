import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom'

import { Dialog, DialogTitle, DialogActions } from '@material-ui/core';

import Button from "@material-ui/core/Button";

import {useClientHook} from "reducers/client";
import {
  mergeTabs as mergeTabsHook,
  tabDetails,
  tabElements,
} from "reducers/client/requestTypes";

import {TabReference} from "components/ContentReference";
import {MergeInformationTable} from 'components/common/Merge/MergeInformationTable';
import {useDialog} from "reducers/dialog";
import {MERGE_TABS_CONFIRMATION} from "reducers/dialog/dialogIds";
import {clearRequest} from "reducers/client/actions";

const refreshElements = (containerElements, dispatch) => {
  return containerElements.sendRequest().then(result => {
    result.forEach(element => {
      dispatch(clearRequest('elementParents', element.id));
    });
  });
};

const tabDetailsHook = tabDetails();
const tabElementsHook = tabElements();
const MergeTabsConfirmationDialog = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {isOpen, data, actions} = useDialog({dialog: MERGE_TABS_CONFIRMATION});
  const [mainId, mergeId] = (data && data.tabIds) || [];

  const mainDetails = useClientHook(tabDetailsHook, mainId);
  const mergeDetails = useClientHook(tabDetailsHook, mergeId);
  const mainElements = useClientHook(tabElementsHook, mainId);
  const mergeElements = useClientHook(tabElementsHook, mergeId);
  const mergeTabs = useClientHook(mergeTabsHook);

  const handleConfirm = useCallback(
    () => {
      mergeTabs.sendRequest(data)
        .then(() => {
          // Refresh data that may be affected by the merge
          if (mainDetails.isLoaded()) dispatch(clearRequest('tabbedViewTabs', mainDetails.get().tabbedViewId));
          if (mergeDetails.isLoaded()) dispatch(clearRequest('tabbedViewTabs', mergeDetails.get().tabbedViewId));
          refreshElements(mainElements, dispatch);
          refreshElements(mergeElements, dispatch);
          dispatch(clearRequest('mergeHistory', mainId));
          dispatch(clearRequest('mergeHistory', mergeId));
          actions.close();
          history.push(`/app/tabs/${mainId}/editor`);
        });
    },
    [
      mergeTabs,
      mainDetails,
      mergeDetails,
      mainElements,
      mergeElements,
      mainId,
      mergeId,
      history,
      actions,
      dispatch
    ]
  );

  if (!data) return null;
  return (<Dialog open={isOpen} onClose={actions.close} {...props}>
    <DialogTitle>Confirm merge</DialogTitle>
    <MergeInformationTable
      mainId={mainId}
      mergeId={mergeId}
      mainElements={mainElements.isLoaded() ? mainElements.get() : []}
      mergeElements={mergeElements.isLoaded() ? mergeElements.get() : []}
      selection={data && data.selection}
      ReferenceComponent={({contentableId, ...props}) => <TabReference tabId={contentableId} {...props} />}
    />
    <DialogActions>
      <Button onClick={actions.close} color="default" disabled={mergeTabs.isLoading()}>
        Cancel
      </Button>
      <Button onClick={handleConfirm} color="primary" disabled={mergeTabs.isLoading()}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>);
};

export {MergeTabsConfirmationDialog}
