import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

import {bookDetails, updateBook} from "reducers/client/requestTypes";
import {useClientHook} from "reducers/client";
import {clearRequest} from "reducers/client/actions";
import {useDialog, dialogs} from "reducers/dialog";
import AutocompleteCategory from "components/Autocomplete/AutocompleteCategory";

const bookDetailsHook = bookDetails();
const updateBookHook = updateBook();

const CategoryTransferBookDialog = props => {
  const dispatch = useDispatch();
  const {isOpen, state, data, actions} = useDialog({
    dialog: dialogs.CATEGORY_TRANSFER_BOOK,
    initialState: { categoryId: null }
  });

  const bookDetails = useClientHook(bookDetailsHook, data && data.bookId);
  const updateBook = useClientHook(updateBookHook, data && data.bookId);

  const handleSubmit = useCallback(() => {
    const {id, categoryId: oldCategoryId} = bookDetails.get();
    const {categoryId} = state;
    updateBook.sendRequest({id, categoryId}).then(() => {
      dispatch(clearRequest('bookDetails', id));
      dispatch(clearRequest('categoryBooks', oldCategoryId));
      dispatch(clearRequest('categoryBooks', categoryId));
    });
    actions.close();
  }, [state.categoryId, bookDetails]);

  if (!data || !bookDetails.isLoaded()) return false;
  return (
    <Dialog open={isOpen} onClose={actions.close} disableRestoreFocus>
      <DialogTitle>Transfer {bookDetails.get().name} to new category</DialogTitle>
      <DialogContent>
        <AutocompleteCategory
          fullWidth
          label="Category"
          suggestionsStatic
          onSuggestionSelected={(event, {suggestion}) => actions.setState({categoryId: suggestion.id})}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleSubmit} disabled={!state.categoryId}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export {CategoryTransferBookDialog}
