import React from "react";

import {MenuGroup, MenuButton, Menu2AxisAlignment, MenuSelect, MenuExpand} from 'prosemirror/components/menu';

import {selectionInside} from 'prosemirror/commands/readers';
import {setColumnAlignment, getColumnAlignment, setColumnBreakpoint, getColumnBreakpoint, removeColumnSet} from "prosemirror/commands/column";
import * as icons from "prosemirror/icons";
import {setAttributes, setCellBorderWidth} from "../commands";
import {BORDER_WIDTHS} from "../constants/table";

export const alignment = (<Menu2AxisAlignment key="columns-alignment"
  command={alignment => setColumnAlignment(alignment)}
  selected={getColumnAlignment}
  title="Column Alignment"
/>);
export const breakpoint = (<MenuSelect key="columns-breakpoint"
  options={[
    {value: '', icon: icons.text('xs'), title: 'no break'},
    {value: 'sm', icon: icons.text('sm'), title: 'break below 576px'},
    {value: 'md', icon: icons.text('md'), title: 'break below 720px'},
    {value: 'lg', icon: icons.text('lg'), title: 'break below 960px'},
    {value: 'xl', icon: icons.text('xl'), title: 'break below 1140px'},
  ]}
  command={breakpoint => setColumnBreakpoint(breakpoint)}
  selected={getColumnBreakpoint}
  title="Column Stacking Breakpoint"
/>);
const borderOption = (width, title) => (<MenuButton
  key={width}
  className="ProseMirror-MenuLongButton"
  command={setAttributes('column', {borderWidth: width})}
  title={title}>
  <span className="ProseMirror-MenuTable-BorderWidthIcon" style={{borderLeftWidth: `${width}px`}}>
    {title}
  </span>
</MenuButton>);
export const borderWidth = (<MenuExpand
  key="columns-borderWidth"
  enabled={setAttributes('column', {borderWidth: 0})}
  icon={icons.grip_vertical}
  title="Set Column Border Width">
  {[
    (<MenuButton
      key={0}
      className="ProseMirror-MenuLongButton"
      command={setAttributes('column', {borderWidth: 0})}
      title="None">
      {icons.ban} None
    </MenuButton>),
    borderOption(BORDER_WIDTHS.THIN,"Thin"),
    borderOption(BORDER_WIDTHS.MEDIUM,"Medium"),
    borderOption(BORDER_WIDTHS.THICK,"Thick"),
  ]}
</MenuExpand>);

export const remove = (<MenuButton key="columns-remove"
  command={removeColumnSet}
  title="Convert to Single Column">
  {icons.ban}
</MenuButton>);


export const ColumnsMenuGroup = props => (
  <MenuGroup {...props} visible={selectionInside('column')}>
    {props.children}
  </MenuGroup>
);
