import {apiClient} from "constants/Clients";
import {DEFAULT_KEY} from "./util";

export const contentVersions = {
  hookName: 'contentVersions',
  request: () =>  apiClient.getContentVersions()
      .limit(10)
      .setSortFields(['createdAt'])
      .setOrderByDesc(),
  key: DEFAULT_KEY,
  auto: true,
};
export const contentVersionApprovalStatus = key => ({
  hookName: 'contentVersionApprovalStatus',
  request: contentVersionId => apiClient.getApprovalStatusForContentVersion(contentVersionId),
  key,
  auto: true,
});
export const contentVersionRender = key => ({
  hookName: 'contentVersionRender',
  request: contentVersionId => apiClient.renderContentVersion(contentVersionId),
  key,
  auto: true,
});
export const contentVersionRequirementStatus = key => ({
  hookName: 'contentVersionRequirementStatus',
  request: contentVersionId => apiClient.getControlRequirementStatusesForContentVersion(contentVersionId),
  key,
  auto: true,
});
export const validateContentVersion = key => ({
  hookName: 'validateContentVersion',
  request: contentVersionId => apiClient.validateContentVersion(contentVersionId),
  key,
});
export const validateHTML = {
  hookName: 'validateHTML',
  request: html => apiClient.validateHtmlContent(html),
};
export const contentVersionMedias = key => ({
  hookName: 'contentVersionMedias',
  request: contentVersionId => apiClient.getMediaForContentVersion(contentVersionId)
    .limit(100),
  //.setSortFields(['elementType'])
  //.setOrderByAsc(),
  key,
  auto: true,
});

export const contentVersionImages = key => ({
  hookName: 'contentVersionImages',
  request: contentVersionId => apiClient.getMediaForContentVersion(contentVersionId)
    .setQueryParams({type: 'IMAGE'})
    .limit(100),
  //.setSortFields(['elementType'])
  //.setOrderByAsc(),
  key,
  auto: true,
});
