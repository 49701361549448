import React from 'react';
import {RecentContentVersionsTable} from "components/contentVersion/RecentContentVersionsTable";
import {Paper} from "@material-ui/core";

export const LandingView = () => (
  <section className="container-fluid with-maxwidth ">
    <h2 className="article-title">Welcome!</h2>
    <div className="row">
      <div className="box box-default col-sm-12">
        <div className="box-body">
          <p>
              Please choose an item from the menu on the left to get started.
          </p>
        </div>
      </div>
    </div>
      <h2 className="article-title">Recent changes</h2>
      <Paper>
          <RecentContentVersionsTable />
      </Paper>
  </section>
);
