import React, {useCallback} from "react";
import {ActionCreators} from "components/common/Table/reducer";
import {TableCell, TextField} from "@material-ui/core";

export const BookChildName = props => {
  const {
    classes,
    state,
    dispatch,
    disabled,
    index,
    isNew
  } = props;

  const {adding, editing, newRow, page} = state;
  const row = isNew ? newRow : page[index];

  const handleChange = useCallback(ev => {
      const value = ev.target.value;
      dispatch(ActionCreators.update(index, 'displayName', value));
      if (row.contentable === 'section') {
        return dispatch(ActionCreators.update(index, 'type', value));
      } else if (row.contentable === 'chapter') {
        return dispatch(ActionCreators.update(index, 'name', value));
      } else {
        return dispatch(ActionCreators.update(index, 'name', value));
      }
    },
    [dispatch, index, row && row.contentable]
  );

  if (!row) return <TableCell />;

  const editMode = (isNew && adding) || editing === index;
  let value = row.name || '';
  if (row.contentable === 'section') value = row.type || value;

  if (editMode) {
    return (
      <TableCell>
        <TextField
          disabled={disabled}
          fullWidth
          value={value}
          onChange={handleChange}
          autoFocus
        />
      </TableCell>
    );
  } else {
    return (
      <TableCell>
        {value}
      </TableCell>
    );
  }
};
