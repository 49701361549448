import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';

export const save = <FontAwesomeIcon icon={icons.faSave} />;
export const cloud_upload = <FontAwesomeIcon icon={icons.faCloudUploadAlt} />;
export const em = <FontAwesomeIcon icon={icons.faItalic} />;
export const italic = em;
export const strong = <FontAwesomeIcon icon={icons.faBold} />;
export const bold = strong;
export const font = <FontAwesomeIcon icon={icons.faFont} />;
export const code = <FontAwesomeIcon icon={icons.faCode} />;
export const code_block = code;
export const subscript = <FontAwesomeIcon icon={icons.faSubscript} />;
export const superscript = <FontAwesomeIcon icon={icons.faSuperscript} />;
export const underline = <FontAwesomeIcon icon={icons.faUnderline} />;
export const strikethrough = <FontAwesomeIcon icon={icons.faStrikethrough} />;
export const link = <FontAwesomeIcon icon={icons.faLink} />;
export const paragraph = <FontAwesomeIcon icon={icons.faParagraph} />;
export const blockquote = <FontAwesomeIcon icon={icons.faQuoteLeft} />;
export const ordered_list = <FontAwesomeIcon icon={icons.faListOl} />;
export const bullet_list = <FontAwesomeIcon icon={icons.faListUl} />;
export const image = <FontAwesomeIcon icon={icons.faImage} />;
export const insert_table = <FontAwesomeIcon icon={icons.faTable} />;
export const footnote = <FontAwesomeIcon icon={icons.faAsterisk} />;
export const undo = <FontAwesomeIcon icon={icons.faUndo} />;
export const redo = <FontAwesomeIcon icon={icons.faRedo} />;
export const lift = <FontAwesomeIcon icon={icons.faOutdent} />;
export const join_up = <FontAwesomeIcon icon={icons.faAngleUp} />;
export const align_left = <FontAwesomeIcon icon={icons.faAlignLeft} />;
export const align_center = <FontAwesomeIcon icon={icons.faAlignCenter} />;
export const align_right = <FontAwesomeIcon icon={icons.faAlignRight} />;
export const sticky_note = <FontAwesomeIcon icon={icons.faStickyNote} />;
export const calculator = <FontAwesomeIcon icon={icons.faCalculator} />;
export const file_code = <FontAwesomeIcon icon={icons.faFileCode} />;
export const check_square = <FontAwesomeIcon icon={icons.faCheckSquare} />;
export const eye_slash = <FontAwesomeIcon icon={icons.faEyeSlash} />;
export const layer_group = <FontAwesomeIcon icon={icons.faLayerGroup} />;
export const object_group = <FontAwesomeIcon icon={icons.faObjectGroup} />;
export const search = <FontAwesomeIcon icon={icons.faSearch} />;
export const pencil_alt = <FontAwesomeIcon icon={icons.faPencilAlt} />;
export const caret_square_right = <FontAwesomeIcon icon={icons.faCaretSquareRight} />;
export const caret_square_down = <FontAwesomeIcon icon={icons.faCaretSquareDown} />;
export const compress = <FontAwesomeIcon icon={icons.faCompress} />;
export const h_square = <FontAwesomeIcon icon={icons.faHSquare} />;
export const magic = <FontAwesomeIcon icon={icons.faMagic} />;
export const book = <FontAwesomeIcon icon={icons.faBook} />;
export const pen_square = <FontAwesomeIcon icon={icons.faPenSquare} />;
export const plus = <FontAwesomeIcon icon={icons.faPlus} />;
export const plus_circle = <FontAwesomeIcon icon={icons.faPlusCircle} />;
export const arrows_alt = <FontAwesomeIcon icon={icons.faArrowsAlt} />;
export const pen_fancy = <FontAwesomeIcon icon={icons.faPenFancy} />;
export const cog = <FontAwesomeIcon icon={icons.faCog} />;
export const ban = <FontAwesomeIcon icon={icons.faBan} />;
export const bars = <FontAwesomeIcon icon={icons.faBars} />;
export const external_link = <FontAwesomeIcon icon={icons.faExternalLinkSquareAlt} />;
export const grip_horizontal = <FontAwesomeIcon icon={icons.faGripLines} />;
export const grip_vertical = <FontAwesomeIcon icon={icons.faGripLinesVertical} />;
export const anchor = <FontAwesomeIcon icon={icons.faAnchor} />;
export const newspaper = <FontAwesomeIcon icon={icons.faNewspaper} />;
export const question_circle = <FontAwesomeIcon icon={icons.faQuestionCircle} />;
export const comment_alt = <FontAwesomeIcon icon={icons.faCommentAlt} />;
export const glasses = <FontAwesomeIcon icon={icons.faGlasses} />;
export const square = <FontAwesomeIcon icon={icons.faSquare} />;
export const bolt = <FontAwesomeIcon icon={icons.faBolt} />;
