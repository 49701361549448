import {getRequestKey} from "../client/util";
import {createPaginationSelector} from "./selectors";
import {createSelector} from "reselect";

export const paging = tableId => hook => {
  const getOriginalKey = (state,props) => getRequestKey(hook, state, props);
  const getPagination = createPaginationSelector(tableId);
  const key = createSelector(
    [getOriginalKey, getPagination],
    (params, pagination) => params && pagination ? ({params, pagination}) : null
  );

  return {
    ...hook,
    key,
    request: ({params, pagination: {page, pageSize, sort, search, searchColumns}}, ...content) => {
      let action = hook.request(params, ...content);

      // Set page & page size
      if (page != null && pageSize) {
        action.offset(page * pageSize);
        action.limit(pageSize);
      }
      // Set sort & sort order
      if (sort && sort.by.length > 0) {
        action.setSortFields([sort.by]);
        if (sort.asc) action.setOrderByAsc();
        else action.setOrderByDesc();
      }
      // Set the search filter
      if (searchColumns && search && search.length > 1) {
        // Place searchFields in array if not already in array
        if (!Array.isArray(searchColumns)) {
          searchColumns = [searchColumns];
        }
        let searchFilter = searchColumns.map(field => {
          return `${field}='%${search}%'`;
        }).join(' OR ');
        action.setFilter(searchFilter);
      }

      return action;
    },
    transform: {
      response: (err, results, response) => ({ results, totalCount: response.body.total }),
    }
  };
};
