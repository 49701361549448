import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {
  elementStandards,
  elementRemoveStandardFromPublishedVersion
} from "reducers/client/requestTypes";

import {pagedTable} from 'components/common/Table/PagedTable';
import {paging} from "reducers/table";
import Button from "@material-ui/core/Button";
import ConfirmationDialog from "components/Dialogs/Confirmation";

const InternalTable = pagedTable([
  {key: 'id', hidden: true},
  {key: 'title', sort: 'title', name: 'Title'},
  {key: 'type', sort: 'type', name: 'Type'},
  {key: 'sourceUrl', sort: 'sourceUrl', name: 'Source URL'},
], {
  table: 'ElementStandardsTable',
  initialState: {
    searchColumns: ['title','type']
  },
  getRowKey: row => row.id,
  getRowName: row => row.title,
  readOnly: true,
  actions: {
    override: true,
    Component: ({dispatch, index, disabled}) => {
      const handleClick = () => dispatch({type:'selectToRemove',rowIndex: index});
      return <Button disabled={disabled} color="primary" onClick={handleClick}>Remove</Button>;
    }
  },
  reducer: (state, action) => {
    if (action.type === 'selectToRemove') return { ...state, removingIndex: action.rowIndex, };
    return state;
  }
});

const elementStandardsHook = paging('ElementStandardsTable')(elementStandards((state, props) => props.elementId));
const removeStandardHook = elementRemoveStandardFromPublishedVersion();
const ElementStandardsTable = (props) => {
  const [confirmationData, setConfirmationData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [tablePage, setTablePage] = useState([]);

  const clientKey = useCallback(selector => selector(props), [props]);
  const elementStandards = useClientHook(elementStandardsHook, clientKey);
  const removeStandard = useClientHook(removeStandardHook);

  const handleRemove = useCallback(() => {
    if (props.elementId && confirmationData && confirmationData.id) {
      setConfirmationData(null);
      return removeStandard.sendRequest(props.elementId, confirmationData.id)
        .then(() => elementStandards.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [props.elementId, confirmationData && confirmationData.id, elementStandards, removeStandard, setConfirmationData]);
  const handleStateChange = useCallback(state => {
    if (state.removingIndex >= 0) {
      const {id, title} = state.page[state.removingIndex];
      setConfirmationData({
        id,
        message: `Are you sure you want to remove the standard ${title} from this element?`
      });
    } else {
      setConfirmationData(null);
    }
  }, [setConfirmationData]);

  const isWaiting = useMemo(
    () =>
      elementStandards.isLoading() ||
      removeStandard.isLoading(),
    [elementStandards, removeStandard]
  );

  useEffect(() => {
    if (elementStandards.isLoaded()) {
      const {totalCount, results} = elementStandards.get();
      setTotalCount(totalCount);
      setTablePage(results);
    }
  }, [elementStandards, setTotalCount, setTablePage]);

  return (
    <React.Fragment>
      <ConfirmationDialog
        title="Remove Standard"
        message={confirmationData && confirmationData.message}
        isOpen={Boolean(confirmationData)}
        onClose={() => setConfirmationData(null)}
        onConfirm={handleRemove}
      />
      <InternalTable
        page={tablePage}
        totalCount={totalCount}
        onStateChange={handleStateChange}
        waiting={isWaiting}
      />
    </React.Fragment>
  )
};
ElementStandardsTable.propTypes = {
  elementId: PropTypes.string.isRequired,
}

export {ElementStandardsTable}
