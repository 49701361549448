import React, {useCallback, useMemo, useState} from "react";
import classNames from 'classnames';
import PropTypes from 'prop-types';

import * as icons from "prosemirror/icons";
import {useProsemirror} from "prosemirror/components/ProsemirrorProvider";
import {insertTable} from "prosemirror/commands/table";

import {MenuExpand} from "./MenuExpand";

const MenuInsertTable = ({columns, rows, ...props}) => {
  const prosemirror = useProsemirror();
  const [hoverOption, setHoverOption] = useState(-1);

  const includedInSelection = useCallback(
    i => i < hoverOption && i % columns <= hoverOption % columns,
    [hoverOption]
  );
  const menuOptions = useMemo(() => Array(columns*rows).fill(), [columns, rows]);
  const infoStyle = useMemo(() => ({gridColumn: `1 / span ${columns}`}), [columns]);

  const handleOptionClick = useCallback((ev, value) => {
    ev.preventDefault();
    prosemirror.execute(insertTable(value % columns + 1, Math.floor(value / columns) +  1));
  }, [prosemirror, columns]);

  return (
    <MenuExpand
      {...props}
      icon={icons.insert_table}
      columns={columns}
    >
      {menuOptions.map((_, i) => (
        <button
          key={i}
          className={classNames({
            "ProseMirror-MenuButton": true,
            "ProseMirror-MenuTable-Selected": includedInSelection(i),
          })}
          onMouseDown={ev => handleOptionClick(ev,i)}
          onPointerEnter={() => setHoverOption(i)}
        >
          <i className="ProseMirror-MenuTable-BoxIcon" />
        </button>
      ))}
      <div className="ProseMirror-MenuTable-Info" style={infoStyle}>
        {(
          hoverOption >= 0
            ? `${hoverOption % columns + 1} \u00d7 ${Math.floor(hoverOption / columns) + 1}`
            : '...'
        )}
      </div>
    </MenuExpand>
  );
};
MenuInsertTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.number,
  rows: PropTypes.number,
};
MenuInsertTable.defaultProps = {
  columns: 6,
  rows: 6,
};

export {MenuInsertTable}
