import {TextSelection} from 'prosemirror-state';
import {
  deleteColumn,
  deleteRow,
  deleteTable,
  isInTable,
  selectionCell,
  CellSelection,
  TableMap,
  selectedRect,
  addColumn,
} from 'prosemirror-tables';
import {BORDERS, DEFAULT_TABLE_WIDTH} from "../constants/table";
import {ReplaceStep} from "prosemirror-transform";
import {findAncestor} from "./helpers";

export const selectionTable = state => {
  if (isInTable(state)) {
    const $cell = selectionCell(state);
    return state.doc.resolve($cell.before($cell.depth - 1));
  }
};

export const insertTable = (columns, rows) => (state, dispatch) => {
  const {tr, schema} = state;
  const cellNodes = [schema.nodes.table_cell.createAndFill({
    colwidth: [DEFAULT_TABLE_WIDTH / columns]
  })];
  for (let i = 1; i < columns; ++i) cellNodes.push(cellNodes[0]);
  const rowNodes = [schema.nodes.table_row.createAndFill(null, cellNodes)];
  for (let i = 1; i < rows; ++i) rowNodes.push(rowNodes[0]);
  const table = schema.nodes.table.createAndFill(null, rowNodes);

  tr.replaceSelectionWith(table);
  tr.setSelection(TextSelection.create(tr.doc, state.selection.from + 3)); // Step inside table, table row, table cell
  dispatch(tr);
};

export const deleteTableSelection = (state, dispatch) => {
  const {selection} = state;
  if (!selection.$anchorCell) return false;
  if (selection.isRowSelection()) {
    if (selection.isColSelection()) {
      return deleteTable(state, dispatch);
    } else {
      return deleteRow(state, dispatch);
    }
  } else if (selection.isColSelection()) {
    return deleteColumn(state, dispatch);
  }
  return false;
};

const outside = (pos, dir, $table) => {
  const tableMap = TableMap.get($table.parent);
  let cellPos, border;
  switch (dir) {
    case 0: cellPos = tableMap.nextCell(pos - $table.pos, 'horiz', -1); border = 2; break; // LEFT
    case 1: cellPos = tableMap.nextCell(pos - $table.pos, 'vert', -1); border = 3; break; // TOP
    case 2: cellPos = tableMap.nextCell(pos - $table.pos, 'horiz', 1); border = 0; break; // RIGHT
    case 3: cellPos = tableMap.nextCell(pos - $table.pos, 'vert', 1); border = 1; break; // BOTTOM
  }
  if (cellPos) {
    const $cell = $table.parent.resolve(cellPos);
    const {attrs} = $cell.nodeAfter;
    let borders = attrs.borders.slice();
    borders[border] = '?';
    return {
      pos: cellPos + $table.pos,
      attrs,
      borders,
      anyInactive: attrs.borders[border] === 0,
    };
  }
  return null;
};

const cell = ($pos, $table, options, selectionRect) => {
  const tableMap = TableMap.get($table.parent);
  const pos = $pos.pos;
  const node = $pos.nodeAfter;
  const affectedCells = [];
  let anyInactive = false;
  let borders = node.attrs.borders.slice();

  // Check whether the current cell is on the edges of the selection
  let cellRect = null;
  if (selectionRect) cellRect = tableMap.findCell(pos - $table.pos);
  const left = !cellRect || (cellRect.left === selectionRect.left);
  const right = !cellRect || (cellRect.right === selectionRect.right);
  const top = !cellRect || (cellRect.top === selectionRect.top);
  const bottom = !cellRect || (cellRect.bottom === selectionRect.bottom);

  // Mark borders for change depending on the cell's position
  // and the selected border pattern
  if (options.lines.left && left) {
    anyInactive = anyInactive || borders[0] === 0;
    borders[0] = '?';
    affectedCells.push(outside(pos, 0, $table));
  } else if (options.lines.center && !left) {
    anyInactive = anyInactive || borders[0] === 0;
    borders[0] = '?';
  }
  if (options.lines.right && right) {
    anyInactive = anyInactive || borders[2] === 0;
    borders[2] = '?';
    affectedCells.push(outside(pos, 2, $table));
  } else if (options.lines.center && !right) {
    anyInactive = anyInactive || borders[2] === 0;
    borders[2] = '?';
  }
  if (options.lines.top && top) {
    anyInactive = anyInactive || borders[1] === 0;
    borders[1] = '?';
    affectedCells.push(outside(pos, 1, $table));
  } else if (options.lines.middle && !top) {
    anyInactive = anyInactive || borders[1] === 0;
    borders[1] = '?';
  }
  if (options.lines.bottom && bottom) {
    anyInactive = anyInactive || borders[3] === 0;
    borders[3] = '?';
    affectedCells.push(outside(pos, 3, $table));
  } else if (options.lines.middle && !bottom) {
    anyInactive = anyInactive || borders[3] === 0;
    borders[3] = '?';
  }

  // If this cell is affected by the operation, add it to the list
  if (borders.includes('?')) {
    return affectedCells.filter(el => el != null).concat({
      pos: $pos.pos,
      attrs: node.attrs,
      borders,
      anyInactive,
    });
  }
  return [];
};

// Get cell borders (marked with '?') affected by the given border pattern
const getAffectedCells = options => state => {
  if (!isInTable(state)) return [];
  const {doc,selection} = state;
  let $cell = selectionCell(state);
  let $table = doc.resolve($cell.start(-1));
  let affectedCells;

  if (selection instanceof CellSelection) {
    const tableMap = TableMap.get($table.parent);
    const selectionRect =
      tableMap.rectBetween(selection.$anchorCell.pos - $table.pos, selection.$headCell.pos - $table.pos);
    affectedCells = [];
    selection.forEachCell((node, pos) => {
      affectedCells = affectedCells.concat(cell(doc.resolve(pos), $table, options, selectionRect));
    });
  } else {
    affectedCells = cell($cell, $table, options);
  }
  return affectedCells;
};

export const toggleCellBorders = options => {
  const getTheCells = getAffectedCells(options);
  return (state, dispatch) => {
    let affectedCells = getTheCells(state);
    if (affectedCells.length < 1) return false;
    const {tr} = state;

    if (dispatch) {
      let active = affectedCells.some(cell => cell.anyInactive) ? 1 : 0;
      affectedCells.forEach(cell => {
        tr.setNodeMarkup(cell.pos, null, {
          ...cell.attrs,
          borders: cell.borders.map(b => b === '?' ? active : b),
        });
      });
      dispatch(tr);
    }
    return true;
  };
};

const getAllCellBorders = getAffectedCells(BORDERS.ALL);
export const setCellBorderWidth = width => (state, dispatch) => {
  let affectedCells = getAllCellBorders(state)
    .filter(cell => cell.borders.some((b,i) => b === '?' && cell.attrs.borders[i] !== 0));
  if (affectedCells.length < 1) return false;
  const {tr} = state;

  if (dispatch) {
    affectedCells.forEach(cell => {
      tr.setNodeMarkup(cell.pos, null, {
        ...cell.attrs,
        borders: cell.attrs.borders.map((b,i) => b !== 0 && cell.borders[i] === '?' ? width : b),
      });
    });
    dispatch(tr);
  }
  return true;
};

export const getColumnWidth = state => {
  const {selection} = state;

  if (selection instanceof CellSelection) {
    let matchingWidth = 0;
    selection.forEachCell((node, pos) => {
      if (matchingWidth === -1) return false;
      let widths = node.attrs.colwidth;
      if (widths && widths.length === 1) {
        if (matchingWidth === 0) matchingWidth = widths[0];
        else if (matchingWidth !== widths[0]) {
          matchingWidth = -1;
          return false;
        }
      } else {
        matchingWidth = -1
      }
    });
    if (matchingWidth > 0) return matchingWidth;
  } else {
    let widths = selectionCell(state).nodeAfter.attrs.colwidth;
    if (widths && widths.length === 1) return widths[0];
  }

  return '';
};

const strictColumnSelection = state => {
  let {$anchorCell, $headCell} = state.selection;
  if (!$anchorCell) $anchorCell = $headCell = selectionCell(state);

  const map = TableMap.get($anchorCell.node(-1));
  const start = $anchorCell.start(-1);
  const anchorRect = map.findCell($anchorCell.pos - start);
  const headRect = map.findCell($headCell.pos - start);
  const minCol = Math.min(anchorRect.left, headRect.left);
  const maxCol = Math.max(anchorRect.right - 1, headRect.right - 1);

  let updates = [], repeats = [];
  for (let j = 0; j < map.height; ++j) {
    for (let i = minCol; i <= maxCol; ++i) {
      // Get document-relative position of cell
      let pos = start + map.map[j*map.width + i];
      // Get bounds of cell
      let cellRect = map.findCell(pos - start);
      // If we've seen this cell before, skip it
      if (repeats[i] === cellRect.bottom) continue;
      // If we are not at the bottom of the cell, mark it as a repeat so we can skip it later
      if (cellRect.bottom !== j + 1) repeats[i] = cellRect.bottom;
      // Record the intra-cell index of the current column
      if (!updates[pos]) updates[pos] = [i - cellRect.left];
      else updates[pos].push(i - cellRect.left);
    }
  }
  return updates;
};
export const setColumnWidth = width => {
  if (typeof width === 'string') width = parseInt(width);
  if (isNaN(width)) return (state, dispatch) => false;
  return (state, dispatch) => {
    if (!isInTable(state)) return false;
    const {doc, tr} = state;

    const columnSelection = strictColumnSelection(state);

    if (dispatch) {
      columnSelection.forEach((indices, pos) => {
        const $cell = doc.resolve(pos);
        let colWidth = Array.isArray($cell.nodeAfter.attrs.colwidth) ? [...$cell.nodeAfter.attrs.colwidth] : [];
        indices.forEach(i => colWidth[i] = width);
        tr.setNodeMarkup($cell.pos, null, {
          ...$cell.nodeAfter.attrs,
          colwidth: colWidth
        });
      })
      dispatch(tr);
    }
    return true;
  };
};

export const setAllColumnWidths = width => {
  if (typeof width === 'string') width = parseInt(width);
  if (isNaN(width)) return (state, dispatch) => false;
  return (state, dispatch) => {
    if (!isInTable(state)) return false;
    const {tr} = state;

    if (dispatch) {
      const $table = selectionTable(state);
      const offset = $table.start($table.depth+1);
      $table.nodeAfter.descendants((node, pos) => {
        if (node.type.spec.tableRole === 'cell' || node.type.spec.tableRole === 'header_cell') {
          let colwidth = new Array(node.attrs.colspan || 1).fill(width);
          tr.setNodeMarkup(pos + offset, null, {
            ...node.attrs,
            colwidth
          });
          return false;
        }
      });
      dispatch(tr);
    }
    return true;
  };
};

export const getCellAlignment = state => {
  const $cell = selectionCell(state);
  if ($cell) {
    const {alignment, vAlign} = $cell.nodeAfter.attrs;
    return `${alignment}/${vAlign}`;
  }
  return '';
};

export const setTableAlignment = options => {
  const [alignment, vAlign] = options.split('/');
  return (state, dispatch) => {
    if (!isInTable(state)) return false;
    const {tr,selection} = state;
    if (dispatch) {
      if (selection instanceof CellSelection) {
        selection.forEachCell((node, pos) => {
          tr.setNodeMarkup(pos, null, {
            ...node.attrs,
            alignment,
            vAlign,
          });
        });
      } else {
        const $cell = selectionCell(state);
        tr.setNodeMarkup($cell.pos, null, {
          ...$cell.nodeAfter.attrs,
          alignment,
          vAlign,
        })
      }
      dispatch(tr);
    }
    return true;
  };
};

const addCellAttrsAfterInsert = (tr, colwidth) => {
  const $base = selectionCell(tr);
  const originalSteps = tr.steps.slice();
  originalSteps.forEach((step, i) => {
    if (!(step instanceof ReplaceStep)) return;
    const $cell = tr.doc.resolve(step.from);
    tr.setNodeMarkup($cell.pos, null, {
      ...$cell.nodeAfter.attrs,
      ...$base.nodeAfter.attrs,
      colspan: $cell.nodeAfter.attrs.colspan,
      colwidth
    })
  });
  return tr;
}
export const addColumnAfter = (state, dispatch) => {
  if (!isInTable(state)) return false;
  if (dispatch) {
    const $base = selectionCell(state);
    let newColWidth = $base.nodeAfter.attrs.colwidth;
    if (Array.isArray(newColWidth) && newColWidth.length > 1) newColWidth = [newColWidth[newColWidth.length - 1]];

    const rect = selectedRect(state);
    const tr = addColumn(state.tr, rect, rect.right);
    addCellAttrsAfterInsert(tr, newColWidth);
    dispatch(tr);
  }
  return true;
};
export const addColumnBefore = (state, dispatch) => {
  if (!isInTable(state)) return false;
  if (dispatch) {
    const $base = selectionCell(state);
    let newColWidth = $base.nodeAfter.attrs.colwidth;
    if (Array.isArray(newColWidth) && newColWidth.length > 1) newColWidth = [newColWidth[0]];

    const rect = selectedRect(state);
    const tr = addColumn(state.tr, rect, rect.left);
    addCellAttrsAfterInsert(tr, newColWidth);
    dispatch(tr);
  }
  return true;
};