import React from 'react';
import {useSelector} from "react-redux";

import {Typography, Paper} from "@material-ui/core";

import {ViewportRoot, ViewportSection} from "components/layout";
import {ClassificationDetailsTable} from "components/classification/ClassificationDetailsTable";
import {ClassificationGradesTable} from 'components/classification/ClassificationGradesTable';
import {ClassificationBreadcrumb} from "components/common/Breadcrumb";

import {createNavRouteSelector, navRoutes} from "reducers/nav";

const getNavClassification = createNavRouteSelector(navRoutes.CLASSIFICATION);
const ClassificationDetails = () => {
  const classificationId = useSelector(getNavClassification);

  return (
    <ViewportRoot>
      <ViewportSection>
        <ClassificationBreadcrumb classificationId={classificationId} />
        <Typography variant="h3" gutterBottom>Classification Information</Typography>
        <Paper><ClassificationDetailsTable classificationId={classificationId} /></Paper>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Grades</Typography>
        <Paper><ClassificationGradesTable classificationId={classificationId} /></Paper>
      </ViewportSection>
    </ViewportRoot>
  );
};

export {ClassificationDetails as ClassificationDetailsView}
