import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {StandardDetailsView} from "views/standard/StandardDetails";

import {navRoutes, useNav} from "reducers/nav";

export const StandardRoutes = ({match: {url, path, params: {standardId}}}) => {
  const selectedStandard = useNav(navRoutes.STANDARD, standardId);
  if (!selectedStandard) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={StandardDetailsView} />
      <Redirect to={"/404"} />
    </Switch>
  );
};
