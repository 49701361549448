// Create a compound key that will always return the same object given the same inputs
export const createCompoundKeyGenerator = keyNames => {
  const cache = {};
  return key => {
    let cacheIndex = cache;
    for (let i = 0; i < keyNames.length; ++i) {
      const index = key[keyNames[i]];
      if (index == null) return null;
      if (i < keyNames.length - 1) {
        // If before the last key, create a new cache line
        if (!cacheIndex[index]) cacheIndex[index] = {};
      } else {
        // If at the last key, store the newly created key in the cache
        if (!cacheIndex[index]) cacheIndex[index] = key;
      }
      cacheIndex = cacheIndex[index];
    }
    return cacheIndex;
  }
};
