import React from 'react';
import {useSelector} from "react-redux";

import {isInTable} from 'prosemirror-tables';

import * as icons from 'prosemirror/icons';
import {MenuButton, MenuSelect} from 'prosemirror/components/menu';
import {toggleBlock} from "prosemirror/commands/base";
import {getAttribute, selectionInside, selectionContains, or, not} from "prosemirror/commands/readers";
import {setTextBlockType, getTextBlockType, setAlignment} from "prosemirror/commands/textBlock";
import {listActive, listOperationEnabled, getListType, setListType} from "prosemirror/commands/list";

import {
  promptSave,
  quickSave as quickSaveCommand,
  promptContentContainerReference,
  insertNode
} from 'prosemirror/commands';

import {getContentVersionChanged} from "reducers/Prosemirror";

// ==================== PUBLISH CURRENT VERSION ====================
const PublishButton = () => {
  const pendingChanges = useSelector(state => getContentVersionChanged(state.prosemirror));
  return (<MenuButton key="blocks-publish"
                      command={promptSave}
                      title="Publish">
    {(prosemirror) => pendingChanges ? icons.cloud_changes : icons.cloud_upload}
  </MenuButton>);
};

export const publish = <PublishButton key="blocks-publish" />;

// export const publish = (<MenuButton key="blocks-publish"
//   command={promptSave}
//   title="Publish">
//   {(prosemirror) => {
//     console.log('publish touched');
//     if (getContentVersionChanged(prosemirror.getReduxState().prosemirror)) {
//       return icons.cloud_changes;
//     }
//     return icons.cloud_upload;
//   }}
// </MenuButton>);
// ==================== QUICK SAVE ====================
export const quickSave = (<MenuButton key="blocks-quickSave"
  command={quickSaveCommand}
  title="Quick Save">
  {icons.save}
</MenuButton>);
// ==================== SET BLOCK TYPE ====================
const paragraphBlock = {
  value: 'paragraph',
  title: 'Paragraph',
  icon: icons.paragraph
};
const h = level => ({
  value: `h${level}`,
  title: `Heading ${level}`,
  icon: icons.heading[level]
});
const codeBlock = {
  value: 'code_block',
  title: 'Code Block',
  icon: icons.code
};
export const textBlock = (<MenuSelect key="blocks-textBlock"
  active={selectionInside(['paragraph','heading','code_block'])}
  command={blockType => setTextBlockType(blockType)}
  selected={getTextBlockType}
  options={[paragraphBlock,h(1),h(2),h(3),h(4),h(5),h(6),codeBlock]}
  title="Change Block Type" />);
// ==================== SET ALIGNMENT ====================
const alignLeft = {
  value: 'left',
  title: 'Align Left',
  icon: icons.align_left
};
const alignCenter = {
  value: 'center',
  title: 'Align Center',
  icon: icons.align_center
};
const alignRight = {
  value: 'right',
  title: 'Align Right',
  icon: icons.align_right
};
export const align = (<MenuSelect key="blocks-align"
  enabled={or(isInTable,selectionInside(['paragraph','heading']))}
  command={alignment => setAlignment(alignment)}
  selected={getAttribute(['paragraph','heading'], 'alignment')}
  options={[alignLeft,alignCenter,alignRight]}
  title="Change Text Alignment" />);
// ==================== TOGGLE MATH NOTES ====================
export const mathNotes = (<MenuButton key="blocks-mathNotes"
  active={selectionInside('mathNotes')}
  enabled={toggleBlock('mathNotes')}
  command={toggleBlock('mathNotes')}
  title="Change to Math Notes">
  {icons.search}
</MenuButton>);
// ==================== INSERT CONTENT CONTAINER REFERENCE ====================
export const contentContainerReference = (<MenuButton key="blocks-contentContainerReference"
  enabled={not(selectionContains('contentContainerReference'))}
  command={promptContentContainerReference}
  title="Insert Content Container Reference">
  {icons.magic}
</MenuButton>);
// ==================== LIST TYPE ====================
export const makeListSelect = listTypes => (<MenuSelect key="blocks-list"
  enabled={listOperationEnabled}
  active={listActive}
  command={listType => setListType(listType)}
  selected={getListType}
  options={listTypes}
  title="Insert List" />);
// ==================== INSERT LESSON TITLE ====================
export const lessonTitle = (<MenuButton key="blocks-lessonTitle"
  enabled={not(selectionContains('lessonTitle'))}
  command={insertNode('lessonTitle')}
  title="Insert Lesson Title Block">
  {icons.newspaper}
</MenuButton>);
// ==================== INSERT METADATA ====================
export const metadata = (<MenuButton key="blocks-metadata"
                                     enabled={toggleBlock('importerMetadata')}
                                     command={toggleBlock('importerMetadata', () => ({contentType: prompt("Content Type").toUpperCase()}))}
                                     title="Insert Importer Metadata">
  {icons.bolt}
</MenuButton>);
