import React, {useMemo} from 'react';
import {useHistory, Link} from 'react-router-dom';

import {LinearProgress, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";

import {useClientHook, withClient} from "reducers/client";
import {contentVersions as contentVersionsHook, elementParents} from "reducers/client/requestTypes";

import {formatTime} from "constants/Date";
import {getElementTypeByName} from "constants/ElementTypes";

const elementParentsHook = elementParents();
const RecentContentVersionsTableRow = ({contentVersion}) => {
    const elementParents = useClientHook(elementParentsHook, contentVersion.elementId);

    let ref = useMemo(() => {
        if (!elementParents.isLoaded()) return null;
        const {elementType, parents} = elementParents.get();
        const type = getElementTypeByName(elementType);
        const typeName = type ? type.displayName || type.name : elementType;
        const firstParent = parents.find(p => p.type !== 'book');
        if (firstParent) {
            const parentBook = parents.find(p => p.id === firstParent.bookId);
            const linkTitle = `${parentBook ? parentBook.name : ''} : ${firstParent.name} (${typeName})`;

            switch(firstParent.type) {
                case 'lesson':
                    return (<Link to={`/app/lessons/${firstParent.id}/editor/${contentVersion.elementId}`}>
                        {linkTitle}
                    </Link>);
                case 'lessonContentContainer':
                    return (<Link to={`/app/lessonContentContainers/${firstParent.id}/editor/${elementType}`}>
                        {linkTitle}
                    </Link>);
                case 'glossaryItem':
                    return (<Link to={`/app/glossaryItems/${firstParent.id}/editor`}>
                        {linkTitle}
                    </Link>);
                case 'section':
                    return (<Link to={`/app/sections/${firstParent.id}/editor/${elementType}`}>
                        {linkTitle}
                    </Link>);
                case 'tab':
                    return (<Link to={`/app/tabs/${firstParent.id}/editor`}>
                        {linkTitle}
                    </Link>);
                default:
                    return linkTitle;
            }
        }
        return `Orphaned ${typeName} element`;
    }, [elementParents]);

    return (
        <TableRow>
            <TableCell>{formatTime(contentVersion.createdAt)}</TableCell>
            <TableCell>{contentVersion.author}</TableCell>
            <TableCell>{ref}</TableCell>
        </TableRow>
    );
};

export const RecentContentVersionsTable = () => {
    const contentVersions = useClientHook(contentVersionsHook, contentVersionsHook.key);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Time</TableCell>
                    <TableCell>Author</TableCell>
                    <TableCell>Item</TableCell>
                </TableRow>
                {contentVersions.isLoading() && (
                    <TableRow>
                        <TableCell padding="none" colSpan={3}>
                            <LinearProgress />
                        </TableCell>
                    </TableRow>
                )}
            </TableHead>
            <TableBody>
                {contentVersions.isLoaded() && contentVersions.get().map(cv => (
                    <RecentContentVersionsTableRow key={cv.id} contentVersion={cv} />
                ))}
            </TableBody>
        </Table>
    )
};
