import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import {useClientHook} from "reducers/client";
import {
  categories as categoriesHook,
  categoryOrder as categoryOrderHook,
  createCategory as createCategoryHook,
  updateCategory,
  deleteCategory
} from "reducers/client/requestTypes";

import {draggableTable} from 'components/common/Table/DraggableTable';
import {formatDate} from "constants/Date";

const InternalTable = draggableTable([
  {key: 'id', hidden: true},
  {key: 'shortName', name: 'Short Name'},
  {key: 'displayName', name: 'Display Name'},
  {key: 'description', name: 'Description', optional: true},
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
], {
  draggable: true,
  getRowKey: row => row.id,
  getRowName: row => row.displayName,
});

const updateCategoryHook = updateCategory();
const deleteCategoryHook = deleteCategory();
export const CategoriesTable = () => {
  const history = useHistory();

  const categories = useClientHook(categoriesHook);
  const updateCategory = useClientHook(updateCategoryHook);
  const createCategory = useClientHook(createCategoryHook);
  const deleteCategory = useClientHook(deleteCategoryHook);
  const categoryOrder = useClientHook(categoryOrderHook);

  const handleSave = useCallback(category => {
    if (category.id) {
      return updateCategory.sendRequest(category.id, category)
        .then(() => categories.sendRequest());
    } else {
      return createCategory.sendRequest(category)
        .then(() => categories.sendRequest());
    }
  }, [categories, updateCategory]);
  const handleDelete = useCallback(category => {
    if (category.id) {
      return deleteCategory.sendRequest(category.id)
        .then(() => categories.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [categories, deleteCategory]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/categories/${categories.get()[index].id}/details`);
  }, [history,categories]);
  const handleOrder = useCallback(order => {
    const currentCategories = categories.get();
    categoryOrder.sendRequest(order.map(i => currentCategories[i].id))
      .then(() => categories.sendRequest());
  }, [categories, categoryOrder])

  const isWaiting = useMemo(
    () =>
      categories.isLoading() ||
      createCategory.isLoading() ||
      updateCategory.isLoading() ||
      deleteCategory.isLoading() ||
      categoryOrder.isLoading(),
    [categories, createCategory, updateCategory, deleteCategory, categoryOrder]
  );

  return (
    <InternalTable
      initialState={categories.get()}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      onOrder={handleOrder}
      waiting={isWaiting}
    />
  )
};
