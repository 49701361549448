import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {RootRef, withStyles} from '@material-ui/core';

const styles = theme => ({
  item: {transition: `transform ${0.2}s`},
  dragging: {transition: 'none'},
});

// Check whether two arrays contain the same values in the same order
const arrayEqual = (a,b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

// Calculate drag offsets from the currently dragged element that will result
// in dropping that element into new slots.
const getDropSlots = (list, activeElement) => {
  const slots = [];
  for (let i = list.length; i; --i) {
    const el = list[i-1];
    slots.unshift(el.offsetTop - activeElement.offsetTop + el.offsetHeight / 2 -
      (el.offsetTop > activeElement.offsetTop ? activeElement.offsetHeight : 0));
  }
  return slots;
};

// Stub Component for rendering draggable items. This allows grafting styles,
// classes, events, etc onto rendered components without sacrificing performance.
const DraggableItem = ({children, ...props}) => React.cloneElement(children, props);

// Draggable List Component
class DraggableList extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    component: PropTypes.any.isRequired,
    itemComponent: PropTypes.any.isRequired,
    itemProps: PropTypes.object,
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    disabled: PropTypes.bool,
  };

  state = {
    items: [],
    activeId: -1,
    generation: 0,
  };

  constructor(props) {
    super(props);

    this.listRoot = React.createRef();
    this.dragState = {
      draggedItem: null,
      slots: [],
      siblings: [],
      pointerId: -1,
      activeId: -1,
      currentSlot: -1,
      dragStartX: 0,
      dragStartY: 0,
      pointerX: 0,
      pointerY: 0,
      offsetY: 0,
      offsetAmount: 0,
      lastAnimationFrame: -1
    };
  }

  initList() {
    let generation = this.state.generation + 1;
    this.setState({
      items: this.props.items.map((item, key) => ({item, key, generation})),
      generation
    });
  }
  componentDidMount() {
    // Cache the list of draggable items
    this.initList();
  }
  componentDidUpdate(prevProps) {
    // Only update the cached list if it was changed from the outside
    if (prevProps.items !== this.props.items) {
      this.initList();
    }
  }

  // Commit a move from one slot to another
  moveItem = (index, target) => {
    if (index === target) return false;
    const {items} = this.state;
    console.group(`DraggableList.moveItem(${index},${target})`);
    console.log('Active Item:', items[index]);
    // Create a new list to commit the change, don't modify the existing list
    let updatedList = items;
    if (target < index) {
      const preSwap = items.slice(0,target);
      const midSwap = items.slice(target, index);
      const postSwap = items.slice(index + 1);
      updatedList = preSwap.concat(items[index]).concat(midSwap).concat(postSwap);
    } else {
      const preSwap = items.slice(0,index);
      const midSwap = items.slice(index + 1, target + 1);
      const postSwap = items.slice(target + 1);
      updatedList = preSwap.concat(midSwap).concat(items[index]).concat(postSwap);
    }
    // Only update the state and call the event handler if anything actually changed
    if (!arrayEqual(items, updatedList)) {
      console.log('Updated list:', updatedList);
      console.groupEnd();
      this.setState({items: updatedList});
      if (typeof this.props.onChange === 'function') {
        // props.items is an array of React elements, so we want to send out an
        // updated array of indices, e.g. [0,3,1,2,4]
        this.props.onChange(updatedList.map(i => i.key));
      }
    } else {
      console.groupEnd();
    }
  };
  // Handle a click on a list item
  handlePointerDown = (ev, index) => {
    if (this.props.disabled) return false;
    let handlerState = undefined;
    // onDragStart is exposed so that outer components can cancel drags based on their own criteria
    if (typeof this.props.onDragStart === 'function') handlerState = this.props.onDragStart(ev, index);
    if (handlerState === false || ev.defaultPrevented) return false;

    // Record current state before the drag starts
    const draggedItem = ev.currentTarget;
    const siblings = Array.from(this.listRoot.current.children).filter(el => el !== draggedItem);
    this.dragState = {
      draggedItem,
      slots: getDropSlots(siblings, draggedItem),
      siblings,
      currentSlot: index,
      pointerId: ev.pointerId,
      activeId: index,
      dragStartX: ev.clientX,
      dragStartY: ev.clientY,
      pointerX: ev.clientX,
      pointerY: ev.clientY,
      offsetY: draggedItem.offsetTop,
      offsetAmount: draggedItem.offsetHeight,
    };
    this.setState({activeId: index});

    // Start listening for pointer events on the dragged element
    draggedItem.setPointerCapture(ev.pointerId);
    draggedItem.addEventListener('pointermove', this.onDragMove);
    draggedItem.addEventListener('pointerup', this.onDragRelease);
    this.dragState.lastAnimationFrame = window.requestAnimationFrame(this.onAnimationFrame);
  };
  onAnimationFrame = () => {
    // Position the element under the mouse
    const {draggedItem, dragStartX, dragStartY, pointerX, pointerY} = this.dragState;
    let tx = pointerX - dragStartX;
    let ty = pointerY - dragStartY;
    draggedItem.style.transform = `translate(${tx}px,${ty}px)`;
    this.dragState.lastAnimationFrame = window.requestAnimationFrame(this.onAnimationFrame);
  };
  onDragMove = ev => {
    // Record the new mouse position
    this.dragState.pointerX = ev.clientX;
    this.dragState.pointerY = ev.clientY;
    let deltaY = this.dragState.pointerY - this.dragState.dragStartY;
    let {slots, activeId, currentSlot, offsetAmount, siblings} = this.dragState;
    // Find the position in the list that we are hovering over
    // Move other items out of the way to show the "gap"
    while (deltaY < slots[currentSlot-1]) {
      --currentSlot;
      if (currentSlot < activeId) {
        siblings[currentSlot].style.transform = `translateY(${offsetAmount}px)`;
      } else {
        siblings[currentSlot].style.transform = '';
      }
    }
    while (deltaY > slots[currentSlot]) {
      ++currentSlot;
      if (currentSlot <= activeId) {
        siblings[currentSlot-1].style.transform = '';
      } else {
        siblings[currentSlot-1].style.transform = `translateY(-${offsetAmount}px)`;
      }
    }
    this.dragState.currentSlot = currentSlot;
  };
  onDragRelease = () => {
    // Clear event listeners and animation frames
    const {draggedItem, pointerId, activeId, currentSlot, siblings} = this.dragState;
    draggedItem.releasePointerCapture(pointerId);
    draggedItem.removeEventListener('pointerup', this.onDragRelease);
    draggedItem.removeEventListener('pointermove', this.onDragMove);
    if (this.dragState.lastAnimationFrame) {
      window.cancelAnimationFrame(this.dragState.lastAnimationFrame);
      this.dragState.lastAnimationFrame = null;
    }
    // Clear all css transforms
    draggedItem.style.transform = '';
    for (let i = 0; i < siblings.length; ++i) {
      siblings[i].style.transform = '';
    }
    // Update the state
    this.moveItem(activeId, currentSlot);
    this.setState({activeId: -1});
    this.dragState.draggedItem = null;
    this.dragState.pointerId = -1;
    this.dragState.activeId = -1;
    this.dragState.currentSlot = -1;
  };

  render() {
    const {
      classes,
      component:Component,
      itemComponent:ItemComponent,
      itemProps,
      items:propItems,
      onChange,
      onDragStart,
      disabled,
      ...other
    } = this.props;
    const {items, activeId} = this.state;
    if (!items || items.length < 1) return false;
    return (
      <RootRef rootRef={this.listRoot}>
        <Component {...other}>
          {items.map((item, index) => {
            return <ItemComponent
              key={`${item.generation}-${item.key}`}
              index={item.key}
              item={item.item}
              className={classnames({
                [classes.item]:true,
                [classes.dragging]:index === activeId
              })}
              onPointerDown={ev => this.handlePointerDown(ev, index)}
              {...itemProps}
            />
          })}
        </Component>
      </RootRef>
    );
  }
}

export default withStyles(styles)(DraggableList);