import React from 'react';
import {connect} from 'react-redux';

// Material
import Typography from "@material-ui/core/Typography";

// Icons
import IconHistory from '@material-ui/icons/History';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconImage from '@material-ui/icons/Image';

// Styles
import withStyles from '@material-ui/core/styles/withStyles';

// Components
import { LessonElementBreadcrumb } from 'components/common/Breadcrumb';
import {LessonMenu} from 'prosemirror/menu';

import {ElementEditor} from "components/editor/ElementEditor";

// Constants
import config from "constants/Config";
const {color} = config;
import {displayModes} from "constants/Prosemirror";

// Actions
import {desmos, mathjax} from 'prosemirror/processors';

// Client
import {withClient} from "reducers/client";
import {elementDetails, lessonDetails} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {setContext} from "reducers/Prosemirror";
import {ButtonBox, ButtonBoxButton, EditorViewport, FlexColumns} from "components/layout";

const styles = theme => ({
  title: {
    margin: `0 ${theme.spacing(1)}px`,
    color: theme.palette.primary.main,
  },
});

class LessonEditor extends React.Component {
  componentDidMount() {
    const {lessonDetails, prosemirrorContextId, setProsemirrorContext} = this.props;
    if (lessonDetails.isLoaded()) {
      const {id, bookId} = lessonDetails.get();
      if (id !== prosemirrorContextId) {
        setProsemirrorContext(bookId, 'lesson', id);
      }
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {lessonDetails, prosemirrorContextId, setProsemirrorContext} = this.props;
    if (lessonDetails.isLoaded()) {
      const {id, bookId} = lessonDetails.get();
      if (id !== prosemirrorContextId) {
        setProsemirrorContext(bookId, 'lesson', id);
      }
    }
  }

  showImages = () => {
    const {elementDetails, lessonId} = this.props;
    if (elementDetails.isLoaded()) {
      this.props.history.push(`/app/lessons/${lessonId}/media/${elementDetails.get().elementType}/`);
    }
  };

  showVersions = () => {
    const {elementDetails, lessonId} = this.props;
    this.props.history.push(`/app/lessons/${lessonId}/versions/${elementDetails.getParams()}`);
  };

  showElementAccessibility = () => {
    const {elementDetails} = this.props;
    this.props.history.push(`/app/elements/${elementDetails.getParams()}/accessibility`);
  };

  render() {
    const {
      classes,
      elementDetails,
      lessonId,
    } = this.props;

    let selectedElementId = elementDetails.getParams();

    return (
      <EditorViewport>
        <ButtonBox>
          <ButtonBoxButton title="Images Overview" onClick={this.showImages}>
            <IconImage />
          </ButtonBoxButton>
          <ButtonBoxButton title="Version History" onClick={this.showVersions}>
            <IconHistory />
          </ButtonBoxButton>
          <ButtonBoxButton title="Show Accessibility Compliance" onClick={this.showElementAccessibility}>
            <IconCheckCircle />
          </ButtonBoxButton>
        </ButtonBox>
        <FlexColumns noShrink>
          <Typography className={classes.title} variant="h6">Lesson Content Editor</Typography>
          <LessonElementBreadcrumb elementId={selectedElementId} lessonId={lessonId} />
        </FlexColumns>
        <ElementEditor
          elementId={selectedElementId}
          menu={LessonMenu}
          previewProps={{ postProcess: [mathjax, desmos] }}
          displayMode={displayModes.DISPLAY_EBOOKS}
        />
      </EditorViewport>
    );
  }
}

const getNavLesson = createNavRouteSelector(navRoutes.LESSON);
const getElementId = (state, props) => props.match.params.elementId;
export const LessonEditorView = withClient({
  hooks: {
    lessonDetails: lessonDetails(getNavLesson),
    elementDetails: elementDetails(getElementId),
  }
})(connect(
  state => ({
    lessonId: getNavLesson(state),
    prosemirrorContextId: state.prosemirror.contextId
  }),
  dispatch => ({
    setProsemirrorContext: (...context) => dispatch(setContext(...context))
  })
)(
  withStyles(styles)(LessonEditor)
));
