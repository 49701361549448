import React, {useState, useCallback} from 'react';

import {useClientHook} from "reducers/client";
import {books as booksRequest} from "../../../reducers/client/requestTypes";

import {
  Typography,
  Select,
  MenuItem,
  makeStyles,
  createStyles
} from '@material-ui/core';

import {DownloadBookAltTextSpreadsheetButton} from "./DownloadBookAltTextSpreadsheetButton";

const useStyles = makeStyles(theme => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  flexShrink: {
    flex: 0,
    whiteSpace: 'noWrap',
    margin: theme.spacing(1)
  },
  flexGrow: {
    flex: 1,
    margin: theme.spacing(1)
  }
}));

const BookAltTextSpreadsheet = props => {
  const myBooks = useClientHook(booksRequest);
  let books = [];
  if (myBooks.isLoaded()) { books = myBooks.get(); }
  const [bookId, setBookId] = useState();
  const [bookName, setBookName] = useState();
  const classes = useStyles();

  const handleChange = useCallback(ev => {
    const [id, name] = ev.target.value.split('-*-');
    setBookId(id);
    setBookName(name);
  });


  return (
    <div className={classes.root}>
      <div className={classes.flexShrink}>
        <Typography variant="h6">Download Book Alt Text Spreadsheet</Typography>
      </div>
      <div className={classes.flexGrow}>
        <Select
          value={`${bookId}-*-${bookName}`  || ''}
          onChange={handleChange}
        >
          {books.map(b => <MenuItem key={`menu-item-${b.name}`} value={`${b.id}-*-${b.name}`}>{b.name}</MenuItem> )}
        </Select>
      </div>
      <div className={classes.flexShrink}>
        <DownloadBookAltTextSpreadsheetButton bookId={bookId} bookName={bookName} disabled={!bookId} />
      </div>
    </div>
  )
};

export {
  BookAltTextSpreadsheet
}