import * as ACTION_TYPES from './actions';

const initialState = {};
export const initialPaginationState = {
  page: 0,
  pageSize: 10,
  sort: {
    by: '',
    asc: true
  },
  search: '',
  searchColumns: ['name'],
};

const updateTable = (state, action, values) => {
  let table = state[action.metadata.tableId];
  if (!table) return state;
  return {
    ...state,
    [action.metadata.tableId]: {
      ...table,
      ...values
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.REGISTER:
      if (state[action.payload]) return state;
      return {
        ...state,
        [action.payload]: {
          ...initialPaginationState,
          ...action.initialState,
        },
      };
    case ACTION_TYPES.UNREGISTER:
      if (!state[action.payload]) return state;
      let unregisteredState = {...state};
      delete unregisteredState[action.payload];
      return unregisteredState;
    case ACTION_TYPES.SET_PAGE:
      return updateTable(state, action, {page: action.payload});
    case ACTION_TYPES.SET_PAGE_SIZE:
      return updateTable(state, action, {pageSize: action.payload});
    case ACTION_TYPES.SET_SORT:
      return updateTable(state, action, {sort: action.payload});
    case ACTION_TYPES.SET_SEARCH:
      return updateTable(state, action, {search: action.payload});
    case ACTION_TYPES.SET_SEARCH_COLUMNS:
      let searchColumns = Array.isArray(action.payload) ? action.payload : [action.payload];
      return updateTable(state, action, {searchColumns});
    case ACTION_TYPES.SET_METADATA:
      return updateTable(state, action, {metadata: action.payload});
    case ACTION_TYPES.TOGGLE_SORT:
      // "TOGGLE" cycles between 3 modes, starting with ascending, then descending, then no-sort
      let table = state[action.metadata.tableId];
      if (!table) return state;
      let sort = {...table.sort};
      if (action.payload === sort.by) {
        if (sort.asc) sort.asc = false;
        else sort = initialPaginationState.sort;
      } else {
        sort = {
          by: action.payload,
          asc: true,
        }
      }
      return updateTable(state, action, {sort});
    default: return state;
  }
};
