import { combineReducers } from 'redux';

import { apiClient } from '../constants/Clients';
import MultiRequest, { makeRequestSelector } from './util/multiRequest';

// Action Types
export const STANDARD_DETAILS = 'STANDARD_DETAILS';
export const STANDARD_SAVE = 'STANDARD_SAVE';

// Child Reducers
const details = MultiRequest(STANDARD_DETAILS);

// Action Creators
export function requestStandardDetails(standardId) {
  return details.sendRequest(apiClient.getClassificationStandard(standardId), { key: standardId });
}

// Selectors
const getStandardDetailRequests = state => state.standards.details;
export const makeStandardSelector = standardIdResolver =>
  makeRequestSelector(standardIdResolver, getStandardDetailRequests);

export default combineReducers({
  details,
});