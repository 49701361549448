import {Form, Field, reduxForm} from "redux-form";
import {renderSelect, renderField, renderCheckbox} from "../../../common/Forms/fields";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";

const TableDialogForm = ({onSubmit}) =>  (<form onSubmit={ev=> {ev.preventDefault(); onSubmit(ev);}}>
  <Field name="alignment" type="text" component={renderSelect} label="Table Alignment">
    <MenuItem value="left">Left</MenuItem>
    <MenuItem value="center">Center</MenuItem>
    <MenuItem value="right">Right</MenuItem>
  </Field>
  <Field name="columnWidth" type="number" component={renderField} label="Column Width" />
  <Field name="squareCells" component={renderCheckbox} label="Square Cells" />
  <Field name="presentationOnly" component={renderCheckbox} label="Presentation Only" />
  <Field name="fixedWidth" component={renderCheckbox} label="Fixed Width" />
</form>);

export default reduxForm({
  form: 'tableDialog'
})(TableDialogForm);