import React from "react";
import PropTypes from 'prop-types';
import {LinearProgress, TableCell, TableHead, TablePagination, TableRow, TextField} from "@material-ui/core";
import TableSortHeading from "components/common/Table/TableSortHeading";

export const makeTableHeader = options => {
  const {visibleColumns, visibleActions, draggable, paged, paginationColSpan} = options;
  // (extra columns for drag handle and actions)
  const totalColumns = visibleColumns.length + (draggable ? 1 : 0) + (visibleActions ? 1 : 0);

  const TableColumnTitlesComponent = React.memo(({pagination, actions}) => (
    <TableRow>
      {draggable && <TableCell padding="none"/>}
      {visibleColumns.map(column => {
        const columnTitle = column.name == null ? column.key : column.name;
        if (paged && column.sort) {
          return (
            <TableSortHeading
              key={column.key}
              sortId={column.sort}
              onClick={() => { actions.toggleSort(column.sort); }}
              pagination={pagination}>
              {columnTitle}
            </TableSortHeading>
          );
        }
        return (
          <TableCell key={column.key}>{columnTitle}</TableCell>
        )
      })}
      <TableCell padding="none"/>
    </TableRow>
  ));

  const TableHeadComponent = props => {
    const {classes, waiting, pagination, actions, totalCount} = props;
    return (
      <TableHead>
        {paged && (
          <TableRow>
            <TableCell colSpan={totalColumns - paginationColSpan}>
              <TextField
                fullWidth
                label="Filter"
                value={pagination.search}
                onChange={ev => actions.setSearch(ev.target.value)}
              />
            </TableCell>
            <TablePagination
              className={classes.paginationRoot}
              padding="none"
              colSpan={paginationColSpan}
              count={totalCount}
              page={pagination.page}
              rowsPerPage={pagination.pageSize}
              rowsPerPageOptions={[5,10,25,50,100]}
              onChangePage={(ev, page) => actions.setPage(page)}
              onChangeRowsPerPage={ev => actions.setPageSize(ev.target.value)}
            />
          </TableRow>
        )}
        <TableColumnTitlesComponent pagination={pagination} actions={actions} />
        {waiting && (
          <TableRow>
            <TableCell colSpan={totalColumns} padding="none">
              <LinearProgress />
            </TableCell>
          </TableRow>
        )}
      </TableHead>
    );
  };
  TableHeadComponent.propTypes = {
    waiting: PropTypes.bool.isRequired
  };
  if (paged) {
    TableHeadComponent.propTypes = {
      ...TableHeadComponent.propTypes,
      classes: PropTypes.shape({
        paginationRoot: PropTypes.string.isRequired
      }).isRequired,
      pagination: PropTypes.shape({
        search: PropTypes.string.isRequired,
        page: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        sort: PropTypes.shape({
          by: PropTypes.string.isRequired,
          asc: PropTypes.bool.isRequired,
        }).isRequired,
      }).isRequired,
      actions: PropTypes.shape({
        setSearch: PropTypes.func.isRequired,
        setPage: PropTypes.func.isRequired,
        setPageSize: PropTypes.func.isRequired,
        toggleSort: PropTypes.func.isRequired,
      }).isRequired,
      totalCount: PropTypes.number.isRequired
    }
  }

  return React.memo(TableHeadComponent);
};
