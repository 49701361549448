import React from 'react';
import { connect } from 'react-redux';

import {Paper, withStyles} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {withClient} from "reducers/client";
import {
  elementDetails,
  assessmentProblemDetails,
  assessmentProblemElements,
  updateAssessmentProblem
} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";

import {ElementVersionsTable} from 'components/element/ElementVersionsTable';
import {AssessmentProblemBreadcrumb} from "components/common/Breadcrumb";
import {ContentPreview} from "components/editor/previews";
import {desmos, mathjax} from "prosemirror/processors";
import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";

const gutter = 30;

const styles = theme => ({
  versionsContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  previewPane: {
    minHeight: `${180}px`,
    marginRight: `${gutter}px`,
    padding: `${10}px`,
    flex: 1,
    border: `${1}px solid #dddddd`,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    backgroundColor: '#ffffff'
  },
  listSpacer: {
    flex: `0 ${600}px`
  }
});

class  AssessmentProblemVersions extends React.Component {
  state = {
    selected: null
  }

  showAssessmentProblemEditor = () => {
    const {assessmentProblemDetails, history, elementType} = this.props;
    history.push(`/app/assessmentProblems/${assessmentProblemDetails.getParams()}/editor/${elementType}`);
  };

  getSelectedVersionId() {
    const {selected} = this.state;
    return selected && selected.id;
  }

  handleSelectionChange = selected => {
    this.setState({selected});
  };

  render() {
    const {elementDetails: elementRequest, assessmentProblemDetails: assessmentProblemRequest, classes} = this.props;

    if (!assessmentProblemRequest.isLoaded() || !elementRequest.isLoaded()) {
      return <div />;
    }

    const assessmentProblemDetails = assessmentProblemRequest.get();
    return (
      <ViewportRoot>
        <ButtonBox style={{width:600}}>
          <ButtonBoxButton title="Back" onClick={this.showAssessmentProblemEditor}>
            <ArrowBack />
          </ButtonBoxButton>
          <Paper>
            <ElementVersionsTable
              elementId={elementRequest.getParams()}
              onSelectionChange={this.handleSelectionChange}
            />
          </Paper>
        </ButtonBox>
        <h2 className="article-title">Assessment Problem '{assessmentProblemDetails.name}' versions</h2>
        <div>
          <AssessmentProblemBreadcrumb assessmentProblemId={assessmentProblemRequest.getParams()} />
        </div>
        <div className={classes.versionsContainer}>
          <div className={classes.previewPane}>
            <ContentPreview contentVersionId={this.getSelectedVersionId()} postProcess={[mathjax, desmos]} />
          </div>
          <div className={classes.listSpacer} />
        </div>
      </ViewportRoot>
    );
  }
}

const getNavAssessmentProblem = createNavRouteSelector(navRoutes.ASSESSMENT_PROBLEM);
const getNavElementType = createNavRouteSelector(navRoutes.ELEMENT_TYPE);
const getNavElement = createNavRouteSelector(navRoutes.ELEMENT);
const mapStateToProps = (state, props) => ({
  elementType: getNavElementType(state),
});

export const AssessmentProblemVersionsView = withClient({
  hooks: {
    assessmentProblemDetails: assessmentProblemDetails(getNavAssessmentProblem),
    assessmentProblemElements: assessmentProblemElements(getNavAssessmentProblem),
    elementDetails: elementDetails(getNavElement),

    updateAssessmentProblem: updateAssessmentProblem(getNavAssessmentProblem),
  }
})(connect(
  mapStateToProps
)(withStyles(styles)(AssessmentProblemVersions)));
