import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useProsemirror} from "prosemirror/components/ProsemirrorProvider";

const MenuButton = ({className, title, active, enabled, command, children, visible}) => {
  const prosemirror = useProsemirror();
  return (prosemirror && (!visible || visible(prosemirror.state))) ? (
    <button
      className={classNames({
        'ProseMirror-MenuButton': true,
        'ProseMirror-MenuButton-Active': active && active(prosemirror.state),
        [className]: Boolean(className)
      })}
      title={title}
      disabled={enabled && !enabled(prosemirror.state)}
      onMouseDown={(e) => {
        e.preventDefault();
        prosemirror.execute(command);
      }}
    >{typeof children === 'function' ? children(prosemirror) : children}</button>
  ) : null;
};
MenuButton.propTypes = {
  title: PropTypes.string,
  active: PropTypes.func,
  enabled: PropTypes.func,
  command: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  visible: PropTypes.func
};

export {MenuButton}
