import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Radio, TableCell} from "@material-ui/core";
import {CloudCircle, CheckCircle} from "@material-ui/icons";

import {useClientHook} from "reducers/client";
import {
  elementDetails,
  elementVersions,
  elementSetCurrentVersion,
  elementSetPublishedVersion
} from "reducers/client/requestTypes";

import {pagedTable} from 'components/common/Table/PagedTable';
import {paging} from "reducers/table";
import {formatTime} from "constants/Date";
import {types as ApprovalStatuses} from 'constants/ApprovalStatuses';

const replaceAround = (array, replacement, index, amount = 1) => {
  return array.slice(0,index).concat(replacement).concat(array.slice(index + amount));
};
const updateSelection = (array, index, key) => {
  let next = array;
  let prevSelectedIndex = array.findIndex(row => row[key]);
  if (prevSelectedIndex >= 0) {
    next = replaceAround(next, {...array[prevSelectedIndex], [key]: false}, prevSelectedIndex);
  }
  let selectedRow = array[index];
  if (selectedRow) {
    next = replaceAround(next, {...selectedRow, [key]: true}, index);
  }
  return next;
};

const useFlagStyles = makeStyles(theme => ({
  inactive: {
    cursor: 'pointer',
    '& > *': {
      display: 'none',
    },
    '&:hover > *': {
      display: 'unset',
      opacity: 0.33
    }
  }
}), {name: 'Flag'});
const flag = ({key, type, Icon, color}) => ({state, index, dispatch}) => {
  const classes = useFlagStyles({});
  const handleClick = useCallback(ev => {
    dispatch({type, payload: index});
    ev.preventDefault();
    ev.stopPropagation();
    return false;
  }, []);
  if (state.page[index][key]) {
    return (
      <TableCell padding="checkbox" align="center">
        <Icon fontSize="large" color={color} />
      </TableCell>
    )
  }
  return (
    <TableCell className={classes.inactive} onClick={handleClick} padding="checkbox" align="center">
      <Icon fontSize="large" color="action"/>
    </TableCell>
  );
};

const InternalTable = pagedTable([
  {key: 'id', hidden: true},
  {
    key: 'selected',
    name: "",
    render: selected => <Radio checked={Boolean(selected)} />,
    readOnly: true,
    cellProps: {padding: 'checkbox'}
  },
  {key: 'author', sort: 'author', name: 'Author'},
  {key: 'createdAt', sort: 'createdAt', name: "Created", render: formatTime, readOnly: true},
  {
    key: 'editing',
    name: "Editing",
    Component: flag({key: 'editing', type: 'setCurrentVersion', Icon: CheckCircle, color: 'secondary'}),
    readOnly: true,
  },
  {
    key: 'published',
    name: "Published",
    Component: flag({key: 'published', type: 'setPublishedVersion', Icon: CloudCircle, color: 'primary'}),
    readOnly: true,
  },
], {
  table: 'ElementVersionsTable',
  initialState: {
    pageSize: 5,
    searchColumns: ['author'],
    sort: {
      by: 'createdAt',
      asc: false
    },
  },
  paginationColSpan: 3,
  getRowKey: row => row.id,
  getRowName: row => row.title,
  readOnly: true,
  actions: { hidden: true },
  reducer: (state, action) => {
    switch(action.type) {
      case 'select':
        let nextPage = updateSelection(state.page,action.rowIndex, 'selected');
        return nextPage === state.page ? state : { ...state, page: nextPage };
      case 'setCurrentVersion':
        return {...state, currentVersion: action.payload}
      case 'setPublishedVersion':
        return {...state, publishedVersion: action.payload}
      default:
        return state;
    }
  }
});

const elementDetailsHook = elementDetails();
const elementVersionsHook = paging('ElementVersionsTable')(elementVersions((state, props) => props.elementId));
const setCurrentVersionHook = elementSetCurrentVersion();
const setPublishedVersionHook = elementSetPublishedVersion();
const ElementVersionsTable = (props) => {
  const tableRef = useRef(null);
  const [totalCount, setTotalCount] = useState(0);
  const [tablePage, setTablePage] = useState([]);

  const clientKey = useCallback(selector => selector(props), [props]);
  const elementVersions = useClientHook(elementVersionsHook, clientKey);
  const elementDetails = useClientHook(elementDetailsHook, props.elementId);
  const setCurrentVersion = useClientHook(setCurrentVersionHook, props.elementId);
  const setPublishedVersion = useClientHook(setPublishedVersionHook, props.elementId);

  const handleClick = useCallback((ev, index) => {
    const {onSelectionChange} = props;
    tableRef.current.dispatch({type:'select', rowIndex: index});
    if (typeof onSelectionChange === 'function') onSelectionChange(elementVersions.get().results[index]);
  }, [elementVersions, props.onSelectionChange]);
  const handleStateChange = useCallback(state => {
    if (state.currentVersion >= 0) {
      setCurrentVersion.sendRequest(state.page[state.currentVersion].id)
        .then(() => elementDetails.sendRequest());
    } else if (state.publishedVersion >= 0) {
      const publishedVersionId = state.page[state.publishedVersion].id;
      setPublishedVersion.sendRequest(publishedVersionId, {
        approvalStatus: ApprovalStatuses.APPROVED,
        contentVersionId: publishedVersionId,
        comment: "Published by Version History Table",
        submitterComment: "N/A",
      })
        .then(() => elementDetails.sendRequest());
    }
  }, [setCurrentVersion, setPublishedVersion, elementDetails]);

  const isWaiting = useMemo(
    () =>
      elementVersions.isLoading() ||
      elementDetails.isLoading() ||
      setCurrentVersion.isLoading() ||
      setPublishedVersion.isLoading(),
    [elementVersions, elementDetails, setCurrentVersion, setPublishedVersion]
  );

  useEffect(() => {
    if (elementDetails.isLoaded() && elementVersions.isLoaded()) {
      const {totalCount, results} = elementVersions.get();
      const {currentVersionId, publishedVersionId} = elementDetails.get();
      let cv = results.findIndex(version => version.id === currentVersionId);
      let initialPage = updateSelection(results, cv, 'editing');
      initialPage = updateSelection(initialPage, cv, 'selected');
      if (cv >= 0 && typeof props.onSelectionChange === 'function') props.onSelectionChange(results[cv]);
      let pv = results.findIndex(version => version.id === publishedVersionId);
      initialPage = updateSelection(initialPage, pv, 'published');
      setTotalCount(totalCount);
      setTablePage(initialPage);
    }
  }, [elementDetails, elementVersions, setTotalCount, setTablePage, props.onSelectionChange]);

  return (
    <React.Fragment>
      <InternalTable
        ref={tableRef}
        page={tablePage}
        totalCount={totalCount}
        onClick={handleClick}
        onStateChange={handleStateChange}
        waiting={isWaiting}
      />
    </React.Fragment>
  )
};
ElementVersionsTable.propTypes = {
  elementId: PropTypes.string.isRequired,
}

export {ElementVersionsTable}
