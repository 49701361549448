import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createDialogSelector} from "./selectors";
import {dialogOpen, dialogClose, dialogSetState, registerDialog, unregisterDialog} from "./actions";

export const useDialog = ({dialog, initialState}) => {
  const dialogSelector = useMemo(
    () => createDialogSelector(dialog),
    [dialog]
  );
  const dialogState = useSelector(dialogSelector);
  const dispatch = useDispatch();

  // MOUNT / UNMOUNT
  useEffect(() => {
    dispatch(registerDialog(dialog, initialState));
    return () => dispatch(unregisterDialog(dialog));
  }, [dialog]);

  return useMemo(() => ({
    ...(dialogState || {
      isOpen: false,
      state: initialState,
    }),
    actions: {
      open: data => dispatch(dialogOpen(dialog, data)),
      close: () => dispatch(dialogClose(dialog)),
      setState: state => dispatch(dialogSetState(dialog, state)),
    }
  }), [dialogState, dialog]);
};
