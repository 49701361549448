import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {TableCell, TextField} from "@material-ui/core";
import {ActionCreators} from "./reducer";

const DefaultEditor = ({onChange, ...props}) => (
  <TextField
    fullWidth
    {...props}
    onChange={ev => onChange(ev.target.value)}
  />
)

export const makeTableCell = options => {
  const {
    key: columnKey,
    name,
    Editor = DefaultEditor,
    render = a => a,
    Component,
    hidden,
    optional,
    createOnly,
    readOnly,
    autoFocus,
    cellProps,
    inputProps,
  } = options;
  if (hidden) return () => null;
  if (Component) return Component;

  const TableCellBase = props => {
    const {rowKey, isNew, value, editMode, dispatch, disabled} = props;
    const isReadOnly = readOnly || (!isNew && createOnly);
    const handleChange = useCallback(
      value => dispatch(ActionCreators.update(rowKey, columnKey, value)),
      [dispatch, rowKey]
    );
    return (editMode ? (
      <TableCell {...cellProps}>
        <Editor
          disabled={disabled || isReadOnly}
          value={isReadOnly ? render(value) : (value == null ? '' : value)}
          onChange={handleChange}
          autoFocus={autoFocus}
          {...inputProps}
        />
      </TableCell>
    ) : (<TableCell {...cellProps}>{render(value)}</TableCell>));
  };
  TableCellBase.propTypes = {
    rowKey: PropTypes.number,
    value: PropTypes.any,
    editMode: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  return React.memo(TableCellBase);
};