import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';

import ImageUploadButton from "components/media/ImageUploadButton";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  spacer: {
    flex: '1 1 100%',
  },
  item: {
    flex: '0 0 auto',
  }
});

const ImageToolbar = ({title, allowUpload = false, onUploadImage = () => {}}) => {
  const classes = useStyles();

  if (allowUpload) {
    return (<div className={classes.root}>
      <div className={classes.item}>{title}</div>
      <div className={classes.spacer} />
      <ImageUploadButton className={classes.item} onComplete={onUploadImage} />
    </div>);
  } else {
    return (<div className={classes.root}>
      <div className={classes.item}>{title}</div>
    </div>);
  }
};
ImageToolbar.propTypes = {
  title: PropTypes.string,
  allowUpload: PropTypes.bool,
  onUploadImage: PropTypes.func,
};

export default ImageToolbar;
