import React, {useCallback, useMemo} from 'react';

import {useClientHook} from "reducers/client";
import {tabbedViewDetails, updateTabbedView} from "reducers/client/requestTypes";

import {detailsTable} from "components/common/DetailsTable";
import {formatDate} from "constants/Date";
import {LinearProgress} from "@material-ui/core";

const InternalDetailsTable = detailsTable([
  {key: 'type', name: "Type"},
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
]);
InternalDetailsTable.displayName = "TabbedViewDetailsTableInternal";

const tabbedViewDetailsHook = tabbedViewDetails();
const updateTabbedViewHook = updateTabbedView();
export const TabbedViewDetailsTable = ({tabbedViewId}) => {
  const tabbedViewDetails = useClientHook(tabbedViewDetailsHook, tabbedViewId);
  const updateTabbedView = useClientHook(updateTabbedViewHook, tabbedViewId);

  const handleSave = useCallback(state => {
    updateTabbedView.sendRequest({id: tabbedViewId, ...state}).then(() => tabbedViewDetails.sendRequest());
  }, [updateTabbedView, tabbedViewDetails, tabbedViewId]);

  const waiting = useMemo(
    () => tabbedViewDetails.isLoading() || updateTabbedView.isLoading(),
    [tabbedViewDetails, updateTabbedView]
  );

  return (
    <React.Fragment>
      {waiting && <LinearProgress />}
      <InternalDetailsTable
        initialState={tabbedViewDetails.get()}
        onSave={handleSave}
        disabled={waiting}
      />
    </React.Fragment>
  )
};