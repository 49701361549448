import React from 'react';
import {Redirect} from 'react-router-dom';

// Material
import Tooltip from "@material-ui/core/Tooltip";
import Fab from '@material-ui/core/Fab';

// Icons
import IconHistory from '@material-ui/icons/History';
import IconHourglass from '@material-ui/icons/HourglassEmpty';
import IconCallMerge from '@material-ui/icons/CallMerge';
import IconCheckCircle from '@material-ui/icons/CheckCircle'
import IconImage from '@material-ui/icons/Image';

// Styles
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

// Constants
import {defaultElementTypeForContentable} from 'constants/ElementTypes';

// Components
import {ElementSelector} from 'components/Navigation/ElementSelector';
import {desmos, mathjax} from "prosemirror/processors";
import {LinkedSections} from 'components/LinkList';

// Client
import {withClient, createSelector} from "reducers/client";
import {sectionDetails, sectionElements, elementDetails} from "reducers/client/requestTypes";
import PublishStatusIndicator from "components/editor/PublishStatusIndicator";
import {SectionBreadcrumb} from "components/common/Breadcrumb";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ElementEditor} from "components/editor/ElementEditor";
import {ContainerMenu} from "prosemirror/menu";
import {setBookId, setContext} from "../../reducers/Prosemirror";
import {connect} from "react-redux";
import {ButtonBox, ButtonBoxButton, EditorViewport, FlexColumns} from "components/layout";

const defaultElementType = defaultElementTypeForContentable.section;

const sideNavWidth = 250;
const collapsedWidth = 64;
const headerHeight = 60;
const footerHeight = 43;
const gutter = 30;

const styles = theme => ({
  spacer: {
    width: theme.spacing(1),
  },
});

class SectionEditor extends React.Component {
  componentDidMount() {
    const {sectionDetails, prosemirrorContextId, setProsemirrorContext} = this.props;
    if (sectionDetails.isLoaded()) {
      const {id, bookId} = sectionDetails.get();
      if (id !== prosemirrorContextId) {
        setProsemirrorContext(bookId, 'section', id);
      }
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {sectionDetails, prosemirrorContextId, setProsemirrorContext} = this.props;
    if (sectionDetails.isLoaded()) {
      const {id, bookId} = sectionDetails.get();
      if (id !== prosemirrorContextId) {
        setProsemirrorContext(bookId, 'section', id);
      }
    }
  }

  navigateTo(path) {
    if (path.startsWith('/')) {
      this.props.history.push(path);
    } else {
      const {sectionDetails} = this.props;
      let containerId = sectionDetails.getParams();
      this.props.history.push(`/app/sections/${containerId}/${path}`);
    }
  }

  handleSelectElement = elementType => {
    const {sectionDetails} = this.props;
    const sectionId = sectionDetails.getParams();
    this.props.history.push(`/app/sections/${sectionId}/editor/${elementType}`);
  };

  render() {

    const {
      classes,
      sectionDetails,
      sectionElements,
      elementDetails,
    } = this.props;

    const elementType = this.props.match.params.elementType || defaultElementType.name;
    let containerId = sectionDetails.getParams();
    let contentVersionId = '';
    if (elementDetails.isLoaded()) {
      contentVersionId = elementDetails.get().currentVersionId;
    } else if (!sectionElements.isLoading() && !elementDetails.isLoading()) {
      let {elementType} = this.props.match.params;
      if (elementType && sectionElements.isLoaded()) {
        let elements = sectionElements.get();
        if (elements.length > 0 && !elements.find(e => e.elementType === elementType)) {
          return (<Redirect to={`/app/sections/${containerId}/editor/${elements[0].elementType}`} />);
        }
      }
    }

    return (
      <EditorViewport>
        <ButtonBox>
          <PublishStatusIndicator contentVersionId={contentVersionId} />
          <ButtonBoxButton title="Images Overview" onClick={() => this.navigateTo(`media/${elementType}`)}>
            <IconImage />
          </ButtonBoxButton>
          <ButtonBoxButton title="Version History" onClick={() => this.navigateTo(`versions/${elementType}`)}>
            <IconHistory />
          </ButtonBoxButton>
          <ButtonBoxButton title="Share with another Section" onClick={() => this.navigateTo(`merge`)}>
            <IconCallMerge />
          </ButtonBoxButton>
          <ButtonBoxButton title="Merge History" onClick={() => this.navigateTo(`mergeHistory`)}>
            <IconHourglass />
          </ButtonBoxButton>
          <ButtonBoxButton title="Show Accessibility Compliance" onClick={() => this.navigateTo(`accessibility`)}>
            <IconCheckCircle />
          </ButtonBoxButton>
        </ButtonBox>
        <FlexColumns noShrink>
          <SectionBreadcrumb sectionId={sectionDetails.getParams()} />
          { sectionElements.isLoaded() &&
            <ElementSelector
              className={classnames(classes.navButton,classes.menuButton)}
              elements={sectionElements.get()}
              value={elementType}
              onClick={value => this.handleSelectElement(value)}
            />
          }
          <div className={classes.spacer} />
          <LinkedSections containerId={containerId} elementType={elementType} />
        </FlexColumns>
        <ElementEditor
          elementId={elementDetails.getParams()}
          menu={ContainerMenu}
          previewProps={{ postProcess: [mathjax, desmos] }}
        />
      </EditorViewport>);
  }
}

const mapStateToProps = (state, props) => ({
  prosemirrorContextId: state.prosemirror.contextId,
});
const mapDispatchToProps = dispatch => ({
  setProsemirrorContext: (...context) => dispatch(setContext(...context))
});

const getNavSection = createNavRouteSelector(navRoutes.SECTION);
const getElementType = (state, props) => props.match.params.elementType || defaultElementType.name;
const getSectionElements = createSelector('sectionElements',getNavSection);
export const SectionEditorView = withClient({
  hooks: {
    sectionDetails: sectionDetails(getNavSection),
    sectionElements: sectionElements(getNavSection),
    elementDetails: elementDetails((state, props) => {
      const sectionElements = getSectionElements(state, props);
      if (sectionElements && sectionElements.isLoaded()) {
        const elementType = getElementType(state, props);
        const sectionElement = sectionElements.get().find(e => e.elementType === elementType);
        if (sectionElement) return sectionElement.id;
      }
      return null;
    }),
  }
})(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(
  SectionEditor
)));
