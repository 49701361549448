import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withStyles} from '@material-ui/core/styles';

import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconPublish from '@material-ui/icons/Publish';
import IconLibraryBooks from '@material-ui/icons/LibraryBooks';

import {types} from "constants/ApprovalStatuses";

import {withClient} from "reducers/client";
import {contentVersionApprovalStatus} from "reducers/client/requestTypes";
import config from "constants/Config";
const {color} = config;

const styles = theme => ({
  statusButton: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: color.gray,
    },
  },
  draftButton: {
    backgroundColor: color.infoAlt,
  },
  awaitingReviewButton: {
    backgroundColor: color.warning,
  },
  disapprovedButton: {
    backgroundColor: color.danger,
  },
  approvedButton: {
    backgroundColor: color.warning,
  },
  publishedButton: {
    backgroundColor: color.success,
  },
});

class PublishStatusIndicator extends React.Component {
  static propTypes = {
    contentVersionId: PropTypes.string.isRequired,
  };

  render () {
    const { classes, contentVersionApprovalStatus } = this.props;

    if (!contentVersionApprovalStatus.isLoaded()) {
      return null;
    }

    const { DRAFT, AWAITING_REVIEW, APPROVED, DISAPPROVED } = types;
    const { approvalStatus } = contentVersionApprovalStatus.get();
    switch(approvalStatus) {
      case DRAFT:
        return (
          <Tooltip title="Submit this version for review and approval to publish.">
            <Fab
              className={classnames(classes.draftButton, classes.statusButton)}
              color="inherit"
              variant="extended"
              onClick={this.handleTogglePublishDialog}
            >
              <IconPublish />
              Draft
            </Fab>
          </Tooltip>
        );
      case AWAITING_REVIEW:
        return (
          <Tooltip title="This content version is awaiting review.">
            <Fab
              className={classnames(classes.awaitingReviewButton, classes.statusButton)}
              color="inherit"
              variant="extended"
              onClick={this.showElementAccessibility}
            >
              <IconPublish />
              Awaiting Review
            </Fab>
          </Tooltip>
        );
      case APPROVED:
        // const { currentVersionId, publishedVersionId } = elementDetails.get();
        // if (currentVersionId !== publishedVersionId) {
        //   return (
        //     <Tooltip title="This content version has been approved. Click to publish.">
        //       <Fab
        //         className={classnames(classes.approvedButton, classes.statusButton)}
        //         color="secondary"
        //         variant="extended"
        //         onClick={this.handlePublishApprovedContentVersion}
        //       >
        //         <IconLibraryBooks />
        //         Approved
        //       </Fab>
        //     </Tooltip>
        //   );
        // }
        return (
          <Tooltip title="This is the most recently published content version.">
            <Fab variant="extended" color="secondary" className={classnames(classes.publishedButton, classes.statusButton)}>
              <IconLibraryBooks />
              Published
            </Fab>
          </Tooltip>
        );
      case DISAPPROVED:
        return (
          <Tooltip title="This content version has been rejected. Please edit it and try again.">
            <Fab variant="extended" color="secondary" className={classnames(classes.disapprovedButton, classes.statusButton)}>
              <IconLibraryBooks />
              Rejected
            </Fab>
          </Tooltip>
        );
      default:
        return false;
    }
  }
}

export default withClient({
  hooks: {
    contentVersionApprovalStatus: contentVersionApprovalStatus((state, props) => props.contentVersionId),
  }
})(withStyles(
  styles
)(PublishStatusIndicator));