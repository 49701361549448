import React from 'react';
import {makeStyles} from "@material-ui/core";
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderBottom: `${1}px solid ${theme.palette.primary.dark}`,
    flexShrink: 0,
  }
}), {name: 'ProsemirrorMenu'});

export const ProsemirrorMenu = ({children}) => {
  const classes = useStyles();
  return <div className={classNames('ProseMirror-Menu',classes.root)}>{children}</div>;
};
