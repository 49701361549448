import React from "react";
import PropTypes from 'prop-types';
import {TableCell, TableRow} from "@material-ui/core";
import IconLink from "@material-ui/icons/Link";
import IconUnlink from "@material-ui/icons/LinkOff";
import {ElementParentsReference} from "components/ContentReference";

const MergeInformationTableRow = props => {
  const {
    ReferenceComponent,
    elementTypeName,
    referenceClass,
    mainId,
    mainElement,
    mergeId,
    mergeElement,
    selected
  } = props;

  const mainSelected = mainElement && selected === mainElement.id;
  const mergeSelected = mergeElement && selected === mergeElement.id;

  return (
    <TableRow>
      <TableCell>{elementTypeName}</TableCell>
      <TableCell>
        {mainElement ? (
          <ElementParentsReference
            classes={{reference:referenceClass}}
            elementId={mainElement.id}
          />
        ):(
          <ReferenceComponent classes={{root:referenceClass}} contentableId={mainId} />
        )}
      </TableCell>
      <TableCell padding="none">
        {selected ? (
          <IconLink />
        ):(
          <IconUnlink />
        )}
      </TableCell>
      <TableCell>
        {mergeElement ? (
          <ElementParentsReference
            classes={{reference:referenceClass}}
            elementId={mergeElement.id}
          />
        ):(
          <ReferenceComponent classes={{root:referenceClass}} contentableId={mergeId} />
        )}
      </TableCell>
      <TableCell>
        {mainSelected && <ReferenceComponent contentableId={mainId} />}
        {mergeSelected && <ReferenceComponent contentableId={mergeId} />}
      </TableCell>
    </TableRow>
  );
};

let ElementPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  elementType: PropTypes.string.isRequired
});

MergeInformationTableRow.propTypes = {
  ReferenceComponent: PropTypes.elementType.isRequired,
  referenceClass: PropTypes.string,
  mainId: PropTypes.string.isRequired,
  mergeId: PropTypes.string.isRequired,
  mainElement: ElementPropType,
  mergeElement: ElementPropType,
  selected: PropTypes.string,
  elementTypeName: PropTypes.string.isRequired,
}

export {MergeInformationTableRow}
