import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";

import {Paper} from '@material-ui/core';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconHistory from '@material-ui/icons/History';

import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";
import {TabBreadcrumb} from 'components/common/Breadcrumb';
import {TabBrowser} from 'components/common/Browser';
import {MergeTabsConfirmationDialog} from 'components/Dialogs/Merge/MergeTabsConfirmationDialog';
import {MergeTabs} from "components/tab/MergeTabs";

import {useClientHook} from "reducers/client";
import {tabDetails} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {dialogOpen} from "reducers/dialog/actions";
import {MERGE_TABS_CONFIRMATION} from "reducers/dialog/dialogIds";

const getNavTab = createNavRouteSelector(navRoutes.TAB);
const tabDetailsHook = tabDetails();
const TabMergeView = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [mergeId, setMergeId] = useState(null);

  const tabId = useSelector(getNavTab);
  const tabDetails = useClientHook(tabDetailsHook, tabId);

  const handleNavHistory = useCallback(
    () => history.push(`/app/tabs/${tabId}/mergeHistory`),
    [history, tabId]
  );
  const handleNavEditor = useCallback(
    () => history.push(`/app/tabs/${tabId}/editor`),
    [history, tabId]
  );
  const handleTabSelected = useCallback(
    selection => {
      if (selection && selection[0]) setMergeId(selection[0].id);
    },
    [setMergeId]
  );
  const handleCancel = useCallback(() => setMergeId(null), [setMergeId]);
  const handleConfirm = useCallback(
    selection => {
      dispatch(dialogOpen(MERGE_TABS_CONFIRMATION, {
        tabIds: [tabId, mergeId],
        selection,
        comment: "MANUAL_MERGE"
      }));
    },
    [dispatch, tabId, mergeId]
  );


  if (!tabDetails.isLoaded() || tabId == null) {
    return <div />;
  }
  return (
    <ViewportRoot>
      <MergeTabsConfirmationDialog />
      <ButtonBox>
        <ButtonBoxButton title="Merge History" onClick={handleNavHistory}>
          <IconHistory />
        </ButtonBoxButton>
        <ButtonBoxButton title="Back" onClick={handleNavEditor}>
          <IconArrowBack />
        </ButtonBoxButton>
      </ButtonBox>
      <h2 className="article-title">
        Find duplicate for tab '{tabDetails.get().name}'
      </h2>
      {mergeId === null ? (
        <div>
          <TabBreadcrumb tabId={tabId} />
          <Paper>
            <TabBrowser onChange={handleTabSelected} blacklist={[tabId]} />
          </Paper>
        </div>
      ) : (
        <div>
          <MergeTabs
            mainTabId={tabId}
            mergeTabId={mergeId}
            onCancel={handleCancel}
            onSubmit={handleConfirm}
          />
        </div>
      )}
    </ViewportRoot>
  );
};

export {TabMergeView}
