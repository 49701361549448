import React, {useMemo} from "react";
import {Checkbox, IconButton, MenuItem, Select, Tooltip} from "@material-ui/core";
import IconLink from "@material-ui/icons/Link";

import {elementParents} from "reducers/client/requestTypes";
import {useClientHook} from "reducers/client";
import {mapParents} from "util/elementStructureRelations";

export const TableCheckbox = ({value, onChange, ...props}) => (
  <Checkbox
    {...props}
    checked={Boolean(value)}
    onChange={ev => onChange(ev.target.checked)}
  />
);

export const select = options => {
  const children = options.map(({key, value, name}, i) => (
    <MenuItem
      key={key || value || name || i}
      value={value || name || key || i}>
      {name || value || key || i}
    </MenuItem>
  ));
  return ({onChange, ...props}) => (
    <Select {...props} onChange={ev => onChange(ev.target.value)} children={children} />
  );
};

const elementParentsHook = elementParents();
export const ElementLinks = ({elementId}) => {
  const elementParents = useClientHook(elementParentsHook, elementId);
  const parents = useMemo(() => {
    if (!elementParents.isLoaded()) return [];
    return mapParents(elementParents.get().parents);
  }, [elementParents]);
  if (parents.length <= 1) return null;
  return (
    <Tooltip title={parents.map(p => `${p.bookName} ${p.name}`).join(', ')}>
      <IconButton>
        <IconLink fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
