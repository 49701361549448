import React from "react";

import {MenuGroup, MenuButton, MenuExpand} from 'prosemirror/components/menu';

import * as icons from "prosemirror/icons";
import {selectionIs} from 'prosemirror/commands/readers';
import {setAttributes} from "prosemirror/commands";
import {BORDER_WIDTHS} from "prosemirror/constants/table";

const thicknessOption = (width, title) => (<MenuButton
  key={width}
  className="ProseMirror-MenuLongButton"
  command={setAttributes('horizontal_rule', {thickness: width})}
  title={title}>
  <span className="ProseMirror-MenuTable-BorderWidthIcon" style={{borderLeftWidth: `${width}px`}}>
    {title}
  </span>
</MenuButton>);

export const thickness = (<MenuExpand
  key="table-borderWidth"
  enabled={setAttributes('horizontal_rule', {thickness: 1})}
  icon={icons.grip_vertical}
  title="Set Horizontal Line Thickness">
  {[
    thicknessOption(BORDER_WIDTHS.THIN,"Thin"),
    thicknessOption(BORDER_WIDTHS.MEDIUM,"Medium"),
    thicknessOption(BORDER_WIDTHS.THICK,"Thick"),
  ]}
</MenuExpand>);


export const HrMenuGroup = props => (
  <MenuGroup {...props} visible={selectionIs('horizontal_rule')}>
    {props.children}
  </MenuGroup>
);
