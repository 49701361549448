import React from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";

import {getElementTypeByName} from "constants/ElementTypes";
import {AssessmentProblemBreadcrumb} from "components/common/Breadcrumb";
import {ElementSelector} from "components/Navigation/ElementSelector";

import {withClient} from "reducers/client";
import {assessmentProblemDetails, assessmentProblemElements} from "reducers/client/requestTypes";

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: `0 ${theme.spacing(1)}px`,
    color: theme.palette.primary.main,
  },
  navButton: {
    width: `${90}px`,
  },
  menuButton: {
    marginLeft: theme.spacing(1)
  }
});

class AssessmentProblemNavigation extends React.Component {
  static propTypes = {
    assessmentProblemId: PropTypes.string.isRequired,
    elementType: PropTypes.string.isRequired,
    assessmentProblemDetails: PropTypes.object,
    assessmentProblemElements: PropTypes.object,
    assessmentGroupProblems: PropTypes.object,
    classes: PropTypes.objectOf(PropTypes.string)
  };

  state = {
    menuAnchor: null
  };

  handleSelectElement = elementType => {
    this.props.history.push(`/app/assessmentProblems/${this.props.assessmentProblemId}/editor/${elementType}`);
  };

  render() {
    const {
      assessmentProblemId,
      elementType,
      assessmentProblemDetails,
      assessmentProblemElements,
      classes
    } = this.props;
    if (!assessmentProblemDetails.isLoaded()) return false;

    let elementTypeDefinition = getElementTypeByName(elementType);
    let elementTypeName = (elementTypeDefinition && elementTypeDefinition.displayName) || elementType;

    return (<div className={classes.root}>
      <Typography className={classes.title} variant="h6">{elementTypeName}</Typography>
      <AssessmentProblemBreadcrumb assessmentProblemId={assessmentProblemId} />
      {assessmentProblemElements.isLoaded() && (
        <ElementSelector
          className={classnames(classes.navButton,classes.menuButton)}
          elements={assessmentProblemElements.get()}
          value={elementType}
          onClick={elementTypeName => this.handleSelectElement(elementTypeName)}
        />
      )}
    </div>);
  }
}

const getProblemId = (state, props) => props.assessmentProblemId;
export default withClient({
  hooks: {
    assessmentProblemDetails: assessmentProblemDetails(getProblemId),
    assessmentProblemElements: assessmentProblemElements(getProblemId),
  }
})(withRouter(withStyles(styles)(AssessmentProblemNavigation)));
