import React from "react";

import {MenuButton, MenuInsertTable} from 'prosemirror/components/menu';
import {and, canInsert, exists} from 'prosemirror/commands/readers';
import {
  insertNode,
  insertHomeworkHint,
  promptColumns,
  promptDesmos,
  promptEmbed,
  promptHomework,
  promptImage,
  promptMathquill,
} from 'prosemirror/commands';
import * as icons from 'prosemirror/icons';

// ==================== INSERT ANCHOR ====================
export const anchor = (<MenuButton key="insert-anchor"
  enabled={canInsert('anchor')}
  command={insertNode('anchor', {name:''})}
  title="Insert Anchor">
  {icons.anchor}
</MenuButton>);
// ==================== INSERT HR ====================
export const hr = (<MenuButton key="insert-hr"
  enabled={canInsert('horizontal_rule')}
  command={insertNode('horizontal_rule')}
  title="Insert Horizontal Line">
  {icons.grip_horizontal}
</MenuButton>);
// ==================== INSERT IMAGE ====================
export const image = (<MenuButton key="insert-image"
  enabled={canInsert('media')}
  command={promptImage()}
  title="Insert image">
  {icons.image}
</MenuButton>);
// ==================== INSERT MATHQUILL ====================
export const mathQuill = (<MenuButton key="insert-mathQuill"
  enabled={canInsert('media')}
  command={promptMathquill()}
  title="Insert MathQuill">
  {icons.pen_fancy}
</MenuButton>);
// ==================== INSERT DESMOS ====================
export const desmos = (<MenuButton key="insert-desmos"
  enabled={canInsert('desmos')}
  command={promptDesmos()}
  title="Insert Desmos Graph">
  {icons.arrows_alt}
</MenuButton>);
// ==================== INSERT EMBED ====================
export const embed = (<MenuButton key="insert-embed"
  enabled={canInsert('embed')}
  command={promptEmbed()}
  title="Insert Embedded URL">
  {icons.external_link}
</MenuButton>);
// ==================== INSERT HOMEWORK LINK ====================
export const homeworkLink = (<MenuButton key="insert-homeworkLink"
  enabled={canInsert('homeworkLink')}
  command={insertNode('homeworkLink')}
  title="Insert Homework Link">
  {icons.withPencil(icons.link)}
</MenuButton>);
// ==================== INSERT HOMEWORK HINT ====================
export const homeworkHint = (<MenuButton key="insert-homeworkHint"
  enabled={canInsert('homeworkHint')}
  command={insertHomeworkHint}
  title="Insert Homework Hint">
  {icons.check_square}
</MenuButton>);
// ==================== INSERT HOMEWORK HINT TARGET ====================
export const homeworkHintTarget = (<MenuButton key="insert-homeworkHintTarget"
  enabled={and(canInsert('homeworkHintTarget'), exists('homeworkHint'))}
  command={promptHomework}
  title="Insert Homework Hint Toggle">
  {icons.question_circle}
</MenuButton>);
// ==================== INSERT SEQUENCE CONTAINER ====================
export const sequenceContainer = (<MenuButton key="insert-sequenceContainer"
  enabled={canInsert('homeworkSequenceContainer')}
  command={insertNode('homeworkSequenceContainer')}
  title="Insert Sequence Container">
  {icons.object_group}
</MenuButton>);
// ==================== INSERT TABLE ====================
export const table = <MenuInsertTable key="insert-table" />;
// ==================== INSERT COLUMNS ====================
export const columns = (<MenuButton key="insert-columns"
  enabled={canInsert('columnSet')}
  command={promptColumns()}
  title="Insert columns">
  {icons.columns[0]}
</MenuButton>);



