import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import {useClientHook} from "reducers/client";
import {
  bookChapters,
  bookChapterOrder,
  bookCreateChapter,
  updateChapter,
  deleteChapter
} from "reducers/client/requestTypes";

import {draggableTable} from 'components/common/Table/DraggableTable';
import {formatDate} from "constants/Date";

const InternalTable = draggableTable([
  {key: 'id', hidden: true},
  {key: 'name', name: 'Name'},
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
], {
  draggable: true,
  getRowKey: row => row.id,
  getRowName: row => row.name,
});

const chaptersHook = bookChapters();
const chapterOrderHook = bookChapterOrder();
const createChapterHook = bookCreateChapter();
const updateChapterHook = updateChapter();
const deleteChapterHook = deleteChapter();
export const BookChaptersTable = ({bookId}) => {
  const history = useHistory();

  const chapters = useClientHook(chaptersHook, bookId);
  const chapterOrder = useClientHook(chapterOrderHook, bookId);
  const createChapter = useClientHook(createChapterHook, bookId);
  const updateChapter = useClientHook(updateChapterHook);
  const deleteChapter = useClientHook(deleteChapterHook);

  const handleSave = useCallback(chapter => {
    if (chapter.id) {
      return updateChapter.sendRequest(chapter.id, chapter)
        .then(() => chapters.sendRequest());
    } else {
      return createChapter.sendRequest(chapter)
        .then(() => chapters.sendRequest());
    }
  }, [chapters, updateChapter, createChapter]);
  const handleDelete = useCallback(chapter => {
    if (chapter.id) {
      return deleteChapter.sendRequest(chapter.id)
        .then(() => chapters.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [chapters, deleteChapter]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/chapters/${chapters.get()[index].id}/details`);
  }, [history,chapters]);
  const handleOrder = useCallback(order => {
    const currentChapters = chapters.get();
    chapterOrder.sendRequest(order.map(i => currentChapters[i].id))
      .then(() => chapters.sendRequest());
  }, [chapters, chapterOrder])

  const isWaiting = useMemo(
    () =>
      chapters.isLoading() ||
      createChapter.isLoading() ||
      updateChapter.isLoading() ||
      deleteChapter.isLoading() ||
      chapterOrder.isLoading(),
    [chapters, createChapter, updateChapter, deleteChapter, chapterOrder]
  );

  return (
    <InternalTable
      initialState={chapters.get()}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      onOrder={handleOrder}
      waiting={isWaiting}
    />
  )
};
