import {apiClient} from "constants/Clients";

export const mergeHistory = key => ({
  hookName: 'mergeHistory',
  request: (contentableId) => apiClient.getMergeHistoriesForContent(contentableId),
  key,
  auto: true
});
export const undoMerge = {
  hookName: 'undoMerge',
  request: (contentableId) => apiClient.undoLastMergeForContent(contentableId, {comment: "UNDO"}),
};