import React, {useCallback, useMemo, useRef, useState} from 'react';

import {useClientHook} from "reducers/client";
import {
  lessonContainers,
  lessonContainerOrder,
  lessonCreateLessonContentContainer,
  updateLessonContentContainer,
  deleteLessonContentContainer,
  lessonContentContainerRemoveElement,
  lessonContentContainerAddElement
} from "reducers/client/requestTypes";

import {draggableTable} from 'components/common/Table/DraggableTable';
import {CreateLessonContentContainerElementDialog} from "components/Dialogs/LessonContentContainers/CreateLessonContentContainerElement";
import ConfirmationDialog from "components/Dialogs/Confirmation";

import {getElementTypeByName} from "constants/ElementTypes";
import {LinkLessonContentContainerDialog} from "components/Dialogs/LessonContentContainers/LinkLessonContentContainer";
import {ActionCreators} from "components/common/Table/reducer";

import {LessonContainersContent} from "./LessonContainersContent";

const InternalTable = draggableTable([
  {key: 'id', hidden: true},
  {key: 'elements', hidden: true},
  {key: 'linkedContainer', hidden: true},
  {key: 'name', name: 'Name'},
  {key: 'number', name: 'Number'},
  {
    key: 'content',
    name: 'Content',
    Component: LessonContainersContent,
    memo: (prevProps, nextProps) => {
      if (prevProps.isNew !== nextProps.isNew) return false;
      const prevState = prevProps.state;
      const nextState = nextProps.state;
      if (!prevState) return !nextState;
      if (!nextState) return false;
      if (nextProps.isNew) {
        if (prevState.newPage.linkedContainer !== nextState.newPage.linkedContainer) return false;
      } else {
        const prevRow = prevState.page[prevProps.index];
        const nextRow = nextState.page[nextProps.index];
        if (!prevRow) return !nextRow;
        if (!nextRow) return false;
        if (prevRow.elements !== nextRow.elements) return false;
        const prevEditingRow = prevState.page[prevState.editing];
        const nextEditingRow = nextState.page[nextState.editing];
        if (!prevEditingRow) return !nextEditingRow;
        if (!nextEditingRow) return false;
        if (prevEditingRow.elements !== nextEditingRow.elements) return false;
        return true;
      }
    },
    readOnly: true
  },
], {
  draggable: true,
  getRowKey: row => row.id,
  getRowName: row => row.name,
  reducer: (state, action) => {
    switch(action.type) {
      case 'removeElement':
        return {
          ...state,
          removingElement: action.payload,
        };
      case 'mergeElement':
        return {
          ...state,
          mergeElement: {
            element: action.payload,
            rowIndex: action.rowIndex,
          }
        }
      default: return state;
    }
  }
});

const containersHook = lessonContainers();
const containerOrderHook = lessonContainerOrder();
const createContainerHook = lessonCreateLessonContentContainer();
const updateContainerHook = updateLessonContentContainer();
const deleteContainerHook = deleteLessonContentContainer();
const containerRemoveElementHook = lessonContentContainerRemoveElement();
const containerAddElementHook = lessonContentContainerAddElement();
export const LessonContainersTable = ({lessonId}) => {
  const tableRef = useRef(null);

  const [confirmationData, setConfirmationData] = useState(null);
  let confirmationElementType = '';
  if (confirmationData) {
    const type = getElementTypeByName(confirmationData.element.elementType);
    confirmationElementType = type.displayName || type.name;
  }
  const [editing, setEditing] = useState(-1);

  const confirmationContainerName = confirmationData ? confirmationData.container.name : '';

  const containers = useClientHook(containersHook, lessonId);
  const containerOrder = useClientHook(containerOrderHook, lessonId);
  const createContainer = useClientHook(createContainerHook, lessonId);
  const updateContainer = useClientHook(updateContainerHook);
  const deleteContainer = useClientHook(deleteContainerHook);
  const containerRemoveElement = useClientHook(containerRemoveElementHook);
  const containerAddElement = useClientHook(containerAddElementHook);

  const handleSave = useCallback(container => {
    if (container.id) {
      return updateContainer.sendRequest(container.id, container)
        .then(() => containers.sendRequest());
    } else {
      const payload = {...container};
      if (container.linkedContainer) {
        payload.elementIds = container.linkedContainer.elements.map(e => e.id);
        delete payload.linkedContainer;
      }
      return createContainer.sendRequest(payload)
        .then(() => containers.sendRequest());
    }
  }, [containers, updateContainer, createContainer]);
  const handleDelete = useCallback(container => {
    if (container.id) {
      return deleteContainer.sendRequest(container.id)
        .then(() => containers.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [containers, deleteContainer]);
  const handleOrder = useCallback(order => {
    const currentContainers = containers.get();
    containerOrder.sendRequest(order.map(i => currentContainers[i].id))
      .then(() => containers.sendRequest());
  }, [containers, containerOrder]);
  const handleCreateElement = useCallback(() => {
    containers.sendRequest();
  }, [containers]);
  const handleCloseConfirm = useCallback(() => setConfirmationData(null), [setConfirmationData]);
  const handleConfirm = useCallback(() => {
    setConfirmationData(null);
    containerRemoveElement.sendRequest(confirmationData.container.id, confirmationData.element.id)
      .then(() => containers.sendRequest());
  }, [setConfirmationData, containerRemoveElement, confirmationData]);
  const handleLink = useCallback(lessonContentContainer => {
    if (editing >= 0) {
      const containerId = containers.get()[editing].id;
      let promises = lessonContentContainer.elements.map(
        element => containerAddElement.sendRequest(containerId, element.id)
      );
      Promise.all(promises).then(() => containers.sendRequest());
    } else {
      tableRef.current.dispatch(ActionCreators.update(null, 'linkedContainer', lessonContentContainer));
    }
  }, [editing, containerAddElement, containers]);
  const handleMerge = useCallback((element, rowIndex) => {
    if (containers.isLoaded()) {
      const containerId = containers.get()[editing].id;
      const sourceRow = containers.get()[rowIndex];
      console.log('handleMerge', containers, element, rowIndex, sourceRow);
      let promises = [];
      promises.push(containerAddElement.sendRequest(containerId, element.id));
      if (sourceRow.elements.length > 1) promises.push(containerRemoveElement.sendRequest(sourceRow.id, element.id));
      else promises.push(deleteContainer.sendRequest(sourceRow.id));
      Promise.all(promises).then(() => containers.sendRequest());
    }
  }, [editing, containers, containerAddElement, containerRemoveElement, deleteContainer]);
  const handleStateChange = useCallback(state => {
    if (state.mergeElement) {
      handleMerge(state.mergeElement.element, state.mergeElement.rowIndex);
    }
    if (state.removingElement) {
      setConfirmationData({
        element: state.removingElement,
        container: state.page[state.editing]
      });
    } else {
      setConfirmationData(null);
    }
    setEditing(state.editing);
  }, [setConfirmationData, setEditing, handleMerge]);

  const isWaiting = useMemo(
    () =>
      containers.isLoading() ||
      createContainer.isLoading() ||
      updateContainer.isLoading() ||
      deleteContainer.isLoading() ||
      containerOrder.isLoading() ||
      containerRemoveElement.isLoading() ||
      containerAddElement.isLoading(),
    [containers, createContainer, updateContainer, deleteContainer, containerOrder, containerRemoveElement, containerAddElement]
  );

  return (
    <React.Fragment>
      <ConfirmationDialog
        title="Unlink Lesson Content Container"
        message={`Are you sure you want to unlink the ${confirmationElementType} content from Lesson Content Container ${confirmationContainerName}?`}
        isOpen={Boolean(confirmationData)}
        onClose={handleCloseConfirm}
        onConfirm={handleConfirm}
      />
      <CreateLessonContentContainerElementDialog onSubmit={handleCreateElement} />
      <LinkLessonContentContainerDialog onConfirm={handleLink} />
      <InternalTable
        ref={tableRef}
        initialState={containers.get()}
        onSave={handleSave}
        onDelete={handleDelete}
        onOrder={handleOrder}
        onStateChange={handleStateChange}
        waiting={isWaiting}
      />
    </React.Fragment>
  )
};
