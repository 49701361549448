import React from 'react';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {glossaryItemElements} from "reducers/client/requestTypes";

import {ContentPreview} from "./ContentPreview";

const glossaryItemElementsHook = glossaryItemElements();
const GlossaryItemPreview = ({ glossaryItemId, ...innerProps }) => {
  // Get glossary item Elements and return the first one (should be the only one)
  const glossaryItemElements = useClientHook(glossaryItemElementsHook, glossaryItemId);
  if (!glossaryItemElements.isLoaded()) return null;
  return <ContentPreview contentVersionId={glossaryItemElements.get()[0].publishedVersionId} {...innerProps} />;
};
GlossaryItemPreview.propTypes = {
  glossaryItemId: PropTypes.string.isRequired,
};

export {GlossaryItemPreview}
