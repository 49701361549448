import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import {useClientHook} from "reducers/client";
import {
  chapterLessons,
  chapterLessonOrder,
  chapterCreateLesson,
  updateLesson,
  deleteLesson
} from "reducers/client/requestTypes";

import {draggableTable} from 'components/common/Table/DraggableTable';
import {formatDate} from "constants/Date";

const InternalTable = draggableTable([
  {key: 'id', hidden: true},
  {key: 'name', name: 'Name'},
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
], {
  draggable: true,
  getRowKey: row => row.id,
  getRowName: row => row.name,
});

const lessonsHook = chapterLessons();
const lessonOrderHook = chapterLessonOrder();
const createLessonHook = chapterCreateLesson();
const updateLessonHook = updateLesson();
const deleteLessonHook = deleteLesson();
export const ChapterLessonsTable = ({chapterId}) => {
  const history = useHistory();

  const lessons = useClientHook(lessonsHook, chapterId);
  const lessonOrder = useClientHook(lessonOrderHook, chapterId);
  const createLesson = useClientHook(createLessonHook, chapterId);
  const updateLesson = useClientHook(updateLessonHook);
  const deleteLesson = useClientHook(deleteLessonHook);

  const handleSave = useCallback(lesson => {
    if (lesson.id) {
      return updateLesson.sendRequest(lesson.id, lesson)
        .then(() => lessons.sendRequest());
    } else {
      return createLesson.sendRequest(lesson)
        .then(() => lessons.sendRequest());
    }
  }, [lessons, updateLesson, createLesson]);
  const handleDelete = useCallback(lesson => {
    if (lesson.id) {
      return deleteLesson.sendRequest(lesson.id)
        .then(() => lessons.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [lessons, deleteLesson]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/lessons/${lessons.get()[index].id}/details`);
  }, [history,lessons]);
  const handleOrder = useCallback(order => {
    const currentLessons = lessons.get();
    lessonOrder.sendRequest(order.map(i => currentLessons[i].id))
      .then(() => lessons.sendRequest());
  }, [lessons, lessonOrder])

  const isWaiting = useMemo(
    () =>
      lessons.isLoading() ||
      createLesson.isLoading() ||
      updateLesson.isLoading() ||
      deleteLesson.isLoading() ||
      lessonOrder.isLoading(),
    [lessons, createLesson, updateLesson, deleteLesson, lessonOrder]
  );

  return (
    <InternalTable
      initialState={lessons.get()}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      onOrder={handleOrder}
      waiting={isWaiting}
    />
  )
};
