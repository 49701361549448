import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  CircularProgress,
  withStyles
} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

import {withClient} from "reducers/client";
import {createMedia} from "reducers/client/requestTypes";
import {reduxDialog, dialogs} from "reducers/dialog";
import {withProsemirror} from "prosemirror/components/ProsemirrorProvider";
import {updateNodeAttrs, insertNode} from "prosemirror/commands";

import MediaTypes from 'constants/MediaTypes';

import MathJax from 'components/common/Math/MathJax';
import {ToggleButton} from "components/Button/ToggleButton";

import * as MathQuill from 'lib/mathquill/mathquill.js';
import mathQuillStyle from 'lib/mathquill/mathquill.css';
const MQ = MathQuill.getInterface(2);

const MODE_MATHQUILL = 'MODE_MATHQUILL';
const MODE_LATEX = 'MODE_LATEX';

const styles = theme => ({
  root: {},
  latexInput: {
    fontFamily: 'monospace',
  },
  mathquill: {
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
    margin: `${theme.spacing(1)}px 0`,
  }
});

class MathquillDialog extends React.Component {
  componentDidMount() {
    const { dialog: {data} } = this.props;
    if (data) this.initializeState();
  }
  componentDidUpdate(prevProps, prevState) {
    const { dialog: {data} } = this.props;
    const { dialog: {data: prevData} } = prevProps;
    if (data && data !== prevData) this.initializeState();
  }

  initializeState() {
    const { dialog: {data, actions: {setState}} } = this.props;
    if (data.latex != null) {
      setState({latex: data.latex});
    } else if (data.html != null) {
      setState({latex: this.convertHtmlToLatex(data.html), html: data.html});
    } else {
      setState({latex: ''});
    }
  }

  handleClose = () => {
    const {dialog: {actions}} = this.props;
    actions.close();
  };
  handleSubmit = () => {
    const {createMedia, prosemirror, dialog: {data, state: {latex}}} = this.props;

    if (!data || latex !== data.latex) {
      createMedia.sendRequest({
        type: MediaTypes.FORMULA.name,
        latex,
      }).then(media => {
        const nodeAttrs = {'media-id': media.id};
        if (data && data.pos) {
          prosemirror.execute(updateNodeAttrs(nodeAttrs, data.pos));
        } else {
          prosemirror.execute(insertNode('media', nodeAttrs));
        }
        this.handleClose();
      });
    } else {
      this.handleClose();
    }
  };

  createMathquill = dom => {
    const {dialog: {state: {latex}, actions: {setState}}} = this.props;
    if (dom) {
      if (!this.innerView) {
        dom.innerText = latex;
        // MathQuill
        this.innerView = MQ.MathField(dom, {
          handlers: {
            edit: () => {
              const latex = this.innerView.latex();
              setState({latex});
            },
            enter: () => {
              const {dialog: {state: {latex}}} = this.props;
              if (latex) {
                this.handleSubmit();
              }
            },
          }
        });
        window.requestAnimationFrame(() => this.innerView.focus());
        if (this.innerView.latex() === '' && latex !== '') {
          setState({error: true});
        } else {
          setState({error: false});
        }
      }
    } else if (this.innerView) {
      this.innerView.revert();
      this.innerView = null;
    }
  };

  convertHtmlToLatex = html => {
    let latex = html;
    latex = latex.replace(/&nbsp;/gi,'\ ');
    latex = latex.replace(/\(/gi,'\\left(');
    latex = latex.replace(/\)/gi,'\\right)');
    latex = latex.replace(/\|(.*?)\|/gi,(match, ...captures) => `\\left|${captures[0]}\\right|`);
    latex = latex.replace(/<sup>(.*?)<\/sup>/gi,(match, ...captures) => `^{${captures[0]}}`);
    latex = latex.replace(/<sub>(.*?)<\/sub>/gi,(match, ...captures) => `_{${captures[0]}}`);
    latex = latex.replace(/<.*?>/gi,'');
    console.log({html}, '>>>', {latex});
    return latex;
  };

  render() {
    const { classes, dialog: {isOpen, actions: {setState}, data, state: {latex, error, mode}}, createMedia  } = this.props;

    let title = "Insert Formula";
    let allowSubmit = !!latex;
    let showLatex = mode === MODE_LATEX;

    if (data !== null) {
      title = "Edit Formula";
    }

    return (<Dialog open={isOpen} maxWidth={'md'} fullWidth disableRestoreFocus>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {createMedia.isLoading() ? (
          <CircularProgress />
        ) : (
          <div>
            <ToggleButton checked={!showLatex} onClick={() => setState({mode:MODE_MATHQUILL})}>Mathquill</ToggleButton>
            <ToggleButton checked={showLatex} onClick={() => setState({mode:MODE_LATEX})}>Raw LaTeX</ToggleButton>
            <div className="row" style={{margin: `${10}px 0`}}>
              <div className="col-sm-6" style={{textAlign:'center'}}>
                {showLatex ?
                  <TextField
                    onChange={ev => setState({latex: ev.target.value})}
                    InputProps={{classes:{input: classes.latexInput}}}
                    value={latex}
                    multiline
                    fullWidth
                  /> :
                  <div>
                    <div className={classes.mathquill} ref={this.createMathquill} />
                  </div>
                }
                {!showLatex && error &&
                  <div className={classes.error}>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    MathQuill error: Please use the raw LaTeX editor.
                  </div>
                }
              </div>
              <div className="col-sm-6" style={{textAlign:'center'}}>
                <MathJax type="math/tex" text={latex || ''} />
              </div>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleClose}>Cancel</Button>
        <Button color="primary" onClick={this.handleSubmit} disabled={!allowSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>);
  }
}

export default withClient({
  hooks: {
    createMedia
  }
})(reduxDialog({
  dialog: dialogs.PROSEMIRROR_MATHQUILL,
  initialState: {
    latex: '',
    error: false,
    mode: MODE_MATHQUILL
  }
})(withProsemirror(withStyles(styles)(MathquillDialog))));
