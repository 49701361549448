import PropTypes from "prop-types";

const isRequired = validator => (props, propName, componentName, location, propFullName, secret) => {
  const propValue = props[propName];
  if (propValue == null) {
    let errType = propValue === null ? 'null' : 'undefined';
    return new Error(
      `The ${location} \`${propFullName}\` is marked as required in \`${componentName}\`, but its value is \`${errType}\`.`
    );
  } else {
    return validator(props, propName, componentName, location, propFullName, secret);
  }
};

export const arrayOfLength = (elementValidator, length) => {
  const baseValidator = PropTypes.arrayOf(elementValidator);
  const arrayValidator = (props, propName, componentName, location, propFullName, secret) => {
    let baseResult = baseValidator(props, propName, componentName, location, propFullName, secret);
    if (baseResult instanceof Error) return baseResult;
    const propValue = props[propName];
    if (propValue.length !== length) {
      return new Error(
        `Invalid ${location} \`${propFullName || propName}\` of length \`${propValue.length}\` supplied to \`${componentName}\`, expected an array of length \`${length}\`.`
      );
    }
  };
  arrayValidator.isRequired = isRequired(arrayValidator);
  return arrayValidator;
};