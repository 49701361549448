import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from "react-router-dom";

import {
  AppBar,
  Tabs,
  Tab,
  LinearProgress
} from '@material-ui/core';
import IconEdit from '@material-ui/icons/Edit';

import {useClientHook} from "reducers/client";
import {assessmentProblemElements} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";

import {AssessmentProblemBreadcrumb} from 'components/common/Breadcrumb';
import ContentVersionMediasTable from 'components/contentVersion/ContentVersionMediasTable';
import {getElementTypeByName} from "constants/ElementTypes";
import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";

const getNavAssessmentProblem = createNavRouteSelector(navRoutes.ASSESSMENT_PROBLEM);
const getNavElementType = createNavRouteSelector(navRoutes.ELEMENT_TYPE);
const assessmentProblemRequest = assessmentProblemElements();

const AssessmentProblemMedia = () => {
  const history = useHistory();
  const location = useLocation();
  const assessmentProblemId = useSelector(getNavAssessmentProblem);
  const elementType = useSelector(getNavElementType);
  const elementsRequest = useClientHook(assessmentProblemRequest, assessmentProblemId);

  const showAssessmentProblemEditor = useCallback(() => {
    history.push(`/app/assessmentProblems/${assessmentProblemId}/editor/${elementType}`);
  }, [history, assessmentProblemId, elementType]);
  const handleChangeElementType = useCallback((ev, value) => {
    let newPath = `/app/assessmentProblems/${assessmentProblemId}/media/${value}`;
    if (location.pathname !== newPath) history.push(newPath);
  }, [history, assessmentProblemId, elementType]);

  if (!elementsRequest.isLoaded()) {
    return (<div>
      <LinearProgress />
      <h2 className="article-title">Loading Assessment Problem media...</h2>
    </div>);
  }

  const selectedElement = elementsRequest.get().find(el => el.elementType === elementType);

  return (
    <ViewportRoot>
      <AssessmentProblemBreadcrumb assessmentProblemId={elementsRequest.getParams()} />
      <ButtonBox>
        <ButtonBoxButton title="Tab Editor" onClick={showAssessmentProblemEditor}>
          <IconEdit />
        </ButtonBoxButton>
      </ButtonBox>
      <AppBar position="static">
        <Tabs value={elementType} onChange={handleChangeElementType}>
          {elementsRequest.get().map(element => {
            const elementType = getElementTypeByName(element.elementType);
            return (
              <Tab
                key={element.elementType}
                value={element.elementType}
                label={elementType ? elementType.displayName || elementType.name : element.elementType}
              />
            )
          })}
        </Tabs>
      </AppBar>
      <ContentVersionMediasTable contentVersionId={selectedElement.publishedVersionId} />
    </ViewportRoot>
  );
};

export {AssessmentProblemMedia as AssessmentProblemMediaView}
