import {apiClient} from "constants/Clients";

export const assessmentGroupDetails = key => ({
  hookName: 'assessmentGroupDetails',
  request: assessmentGroupId => apiClient.getAssessmentGroup(assessmentGroupId),
  key,
  auto: true,
});
export const updateAssessmentGroup = key => ({
  hookName: 'updateAssessmentGroup',
  request: (assessmentGroupId, assessmentGroupInfo) => apiClient.updateAssessmentGroup({...assessmentGroupInfo, id: assessmentGroupId}),
  key,
});
export const deleteAssessmentGroup = key => ({
  hookName: 'deleteAssessmentGroup',
  request: assessmentGroupId => apiClient.deleteAssessmentGroup(assessmentGroupId),
  key,
});
export const assessmentGroupProblems = key => ({
  hookName: 'assessmentGroupProblems',
  request: groupId => apiClient.getAssessmentProblemsForAssessmentGroup(groupId),
  key,
  auto: true,
});
export const assessmentGroupProblemOrder = key => ({
  hookName: 'assessmentGroupProblemOrder',
  request: (groupId, assessmentProblemIdArray) => apiClient.setAssesssmentProblemOrderForAssessmentGroup(groupId, assessmentProblemIdArray),
  key
});
export const assessmentGroupCreateProblem = key => ({
  hookName: 'assessmentGroupCreateProblem',
  request: (groupId, problemInfo) => apiClient.createAssessmentProblemForAssessmentGroup(groupId, problemInfo),
  key
});
export const assessmentGroupAddProblem = key => ({
  hookName: 'assessmentGroupAddProblem',
  request: (groupId, problemId) => apiClient.addAssessmentProblemToAssessmentGroup(groupId, problemId),
  key
});
export const assessmentGroupRemoveProblem = key => ({
  hookName: 'assessmentGroupRemoveProblem',
  request: (groupId, problemId) => apiClient.removeAssessmentProblemFromAssessmentGroup(groupId, problemId),
  key
});
