import React from 'react';
import PropTypes from 'prop-types';

import {withClient} from "reducers/client";
import {createLessonElementKey, lessonElementMediasByType} from "reducers/client/requestTypes";

import {ElementMediasTable} from 'components/media/ElementMediasTable';

class LessonElementMediasTable extends React.Component {
  static propTypes = {
    lessonElementMedias: PropTypes.object.isRequired,

    lessonId: PropTypes.string.isRequired,
    elementId: PropTypes.string.isRequired,
  };

  state = { medias: [] };

  componentDidMount() {
    const {lessonElementMedias} = this.props;
    lessonElementMedias.sendRequest();
  }
  componentDidUpdate(prevProps) {
    const {lessonElementMedias} = this.props;
    const {lessonElementMedias:prevMedias} = prevProps;
    if (lessonElementMedias.isLoaded() && lessonElementMedias.hasChanged(prevMedias)) {
      this.setState({medias: lessonElementMedias.get()});
    }
  }

  handleChange = () => {
    const {lessonElementMedias} = this.props;
    lessonElementMedias.sendRequest();
  };

  render() {
    const {medias} = this.state;
    if (!medias) return false;
    const {lessonElementMedias} = this.props;
    return (
      <ElementMediasTable
        medias={medias}
        waiting={lessonElementMedias.isLoading()}
        onChange={this.handleChange}
      />
    );
  }
}

const getMediaKey = (state, props) => createLessonElementKey({
  lessonId: props.lessonId,
  elementId: props.elementId
});

export default withClient({
  hooks: {
    lessonElementMedias: lessonElementMediasByType('IMAGE')(getMediaKey),
  },
})(LessonElementMediasTable);
