import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faColumns} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const makeColumnIcon = number => (<span className="fa-layers fa-fw">
  <FontAwesomeIcon icon={faColumns} transform="left-2 up-2" />
  <span className="fa-layers-text" style={{
    fontWeight:900,
    transform: "translate(0.35em, -1.0em) scale(0.75)"
  }}>{number}</span>
</span>);
export const columns = [<FontAwesomeIcon icon={faColumns} />].concat([1,2,3,4].map(makeColumnIcon));
