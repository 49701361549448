import { Plugin } from 'prosemirror-state';
import {AnchorView} from "./anchor";
import {ContentContainerReferenceView} from "./contentContainerReference";
import {DesmosView} from "./desmos";
import {CpmEmbedView} from "./embed";
import {LessonTitleView} from './lessonTitle';
import {CpmMediaView} from "./media";

const construct = ClassDef => (node, view, getPos) => new ClassDef(node, view, getPos);

export const nodeViewsPlugin = () => new Plugin({
  props: {
    nodeViews: {
      anchor: construct(AnchorView),
      contentContainerReference: construct(ContentContainerReferenceView),
      desmos: construct(DesmosView),
      embed: construct(CpmEmbedView),
      lessonTitle: construct(LessonTitleView),
      media: construct(CpmMediaView),
    }
  }
});