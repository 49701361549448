import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {ElementEditorView} from "views/element/ElementEditor";
import {ElementMediaView} from "views/element/ElementMedia";
import {ElementVersionsView} from "views/element/ElementVersions";
import {ElementAccessibilityView} from "views/element/ElementAccessibility";

import {navRoutes, useNav} from "reducers/nav";

export const ElementRoutes = ({match: {url, path, params: {elementId}}}) => {
  const selectedElement = useNav(navRoutes.ELEMENT, elementId);
  if (!selectedElement) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/editor`} />
      <Route path={`${path}/editor`} component={ElementEditorView}/>
      <Route path={`${path}/media`} component={ElementMediaView}/>
      <Route path={`${path}/versions`} component={ElementVersionsView}/>
      <Route path={`${path}/accessibility`} component={ElementAccessibilityView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
