import React, {useCallback} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';

import {Typography, Button, Paper} from "@material-ui/core";

import {ViewportRoot, ViewportSection} from "components/layout";
import {BookDetailsTable} from "components/book/BookDetailsTable";
import {BookBreadcrumb} from "components/common/Breadcrumb";
import {BookTableOfContents} from 'components/book/BookTableOfContents';
import {BookChaptersTable} from 'components/book/BookChaptersTable';
import {BookSectionsTable} from 'components/book/BookSectionsTable';
import {BookGlossaryItemsTable} from 'components/book/BookGlossaryItemsTable';

import {createNavRouteSelector, navRoutes} from "reducers/nav";

const getNavBook = createNavRouteSelector(navRoutes.BOOK);
const BookDetails = () => {
  const history = useHistory();
  const bookId = useSelector(getNavBook);

  const showEditMenus = useCallback(() => history.push(`/app/books/${bookId}/menus`), [bookId]);
  const showEditStylesheet = useCallback(() => history.push(`/app/books/${bookId}/styles`), [bookId]);
  const openImageEditor = useCallback(() => history.push(`/app/books/${bookId}/cover`), [bookId]);
  const openTitlePageEditor = useCallback(() => history.push(`/app/books/${bookId}/titlePageEditor`), [bookId]);

  return (
    <ViewportRoot>
      <ViewportSection>
        <BookBreadcrumb bookId={bookId} />
        <Typography variant="h3" gutterBottom>Book Information</Typography>
        <Paper><BookDetailsTable bookId={bookId} /></Paper>
        <br />
        <Button variant="contained" color="primary" onClick={showEditMenus}>Menus</Button>
        <Button variant="contained" color="primary" onClick={showEditStylesheet}>Custom Styles</Button>
        <Button variant="contained" color="primary" onClick={openImageEditor}>Cover Media</Button>
        <Button variant="contained" color="primary" onClick={openTitlePageEditor}>Title Page</Button>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Table of Contents</Typography>
        <Paper><BookTableOfContents bookId={bookId} /></Paper>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Glossary Items</Typography>
        <Paper><BookGlossaryItemsTable bookId={bookId} /></Paper>
      </ViewportSection>
    </ViewportRoot>
  );
};

export {BookDetails as BookDetailsView}
