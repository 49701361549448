import React from "react";
import {connect} from 'react-redux';

import MenuItem from "@material-ui/core/MenuItem";

import {requestGoogleFonts} from "reducers/googleFonts";
import {GoogleFont} from "components/GoogleFonts/Cache";
import Autocomplete from '.';

import config from "constants/Config";
const {inputDebounce} = config;

const MAX_LENGTH = 40;

const getSuggestionValue = suggestion => suggestion.family;

const renderSuggestion = ({family}, {query, isHighlighted}) => {
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        <GoogleFont font={family} text={family}>
          {family}
        </GoogleFont>
      </div>
    </MenuItem>
  );
};

class AutocompleteGoogleFont extends React.Component {
  state = {
    value: '',
    suggestions: []
  };

  componentDidMount() {
    const {googleFonts, requestGoogleFonts} = this.props;
    if (!googleFonts.completed && !googleFonts.pending && !googleFonts.error) {
      requestGoogleFonts();
    }
  }

  getSuggestions(value) {
    const {googleFonts} = this.props;
    if (!googleFonts.fonts) return [];
    const fonts = googleFonts.fonts.items;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : fonts.filter(font =>
      font.family.toLowerCase().slice(0, inputLength) === inputValue
    ).slice(0,5);
  }
  onSuggestionsFetchRequested = ({value}) => {
    this.setState({suggestions: this.getSuggestions(value)});
  };

  onSuggestionsClearRequested = () => {
    this.setState({suggestions: []});
  };

  onChange = (event, {newValue}) => {
    this.setState({ value: newValue });
  };

  render() {
    const {value, suggestions} = this.state;

    if (this.props.googleFonts.fonts && this.props.googleFonts.fonts.items) {
      let variants = [];
      this.props.googleFonts.fonts.items.forEach(f => {
        f.variants.forEach(v => {
          if (!variants.includes(v)) variants.push(v);
        });
      });
      console.log(variants);
    }

    return (<Autocomplete
      value={value}
      suggestions={suggestions}
      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
      onChange={this.onChange}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      {...this.props}
    />);
  }
}

const mapStateToProps = state => ({
  googleFonts: state.googleFonts,
});
const mapDispatchToProps = dispatch => ({
  requestGoogleFonts: () => dispatch(requestGoogleFonts())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AutocompleteGoogleFont);
