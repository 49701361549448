import {apiClient} from "constants/Clients";
import {DEFAULT_KEY} from "./util";

export const assessmentProblems = {
  hookName: 'assessmentProblems',
  request: () =>  apiClient.getAssessmentProblems()
    .limit(10)
    .setSortFields(['id'])
    .setOrderByAsc(),
  key: DEFAULT_KEY,
  auto: true,
};
export const assessmentProblemDetails = key => ({
  hookName: 'assessmentProblemDetails',
  request: assessmentProblemId => apiClient.getAssessmentProblem(assessmentProblemId),
  key,
  auto: true,
});
export const assessmentProblemRender = key => ({
  hookName: 'assessmentProblemRender',
  request: assessmentProblemId => apiClient.assessmentsGetAssessmentProblem(assessmentProblemId),
  key,
  auto: true,
});
export const assessmentProblemElements = key => ({
  hookName: 'assessmentProblemElements',
  request: assessmentProblemId => apiClient.getAssessmentProblemElements(assessmentProblemId)
    .limit(100)
    .setSortFields(['elementType'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const assessmentProblemStandards = key => ({
  hookName: 'assessmentProblemStandards',
  request: assessmentProblemId => apiClient.getStandardsForAssessmentProblem(assessmentProblemId),
  key,
  auto: true,
});
export const assessmentProblemAddElement = key => ({
  hookName: 'assessmentProblemAddElement',
  request: (containerId, elementId) => apiClient.addAssessmentProblemElement(containerId, elementId),
  key,
});
export const assessmentProblemRemoveElement = key => ({
  hookName: 'assessmentProblemRemoveElement',
  request: (containerId, elementId) => apiClient.removeAssessmentProblemElement(containerId, elementId),
  key,
});
export const updateAssessmentProblem = key => ({
  hookName: 'updateAssessmentProblem',
  request: (assessmentProblemId, assessmentProblemInfo) => apiClient.updateAssessmentProblem({...assessmentProblemInfo, id: assessmentProblemId}),
  key,
});
export const deleteAssessmentProblem = key => ({
  hookName: 'deleteAssessmentProblem',
  request: assessmentProblemId => apiClient.deleteAssessmentProblem(assessmentProblemId),
  key,
});
