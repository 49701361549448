import React from 'react';

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import {ClassificationsTable} from 'components/classification/ClassificationsTable';
import {ViewportRoot, ViewportSection} from "components/layout";

class ClassificationList extends React.Component {
  render() {
    return (<ViewportRoot>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>CPM Classifications</Typography>
        <Paper>
          <ClassificationsTable />
        </Paper>
      </ViewportSection>
    </ViewportRoot>);
  }
}

export {ClassificationList as ClassificationListView};
