import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import * as icons from "prosemirror/icons";
import {useProsemirror} from "prosemirror/components/ProsemirrorProvider";
import {toggleMark, toggleBookSpecificContent} from "prosemirror/commands";
import {markActive} from "prosemirror/commands/readers";
import {useClientHook} from "reducers/client";
import {elementParents} from "reducers/client/requestTypes";

import {MenuExpand} from "./MenuExpand";
import {MenuOption} from "./MenuOption";

const elementParentsHook = elementParents();
const MenuBookSpecific = ({...props}) => {
  const prosemirror = useProsemirror();
  const elementId = useSelector(state => state.prosemirror.elementId);
  const elementParents = useClientHook(elementParentsHook, elementId);

  const handleOptionClick = (ev, value) => {
    ev.preventDefault();
    // prosemirror.execute(toggleMark('markBookSpecific', {bookId: value}));
    prosemirror.execute(toggleBookSpecificContent(value));
  };

  const options = useMemo(() => {
    if (elementParents.isLoaded()) {
      return elementParents.get().parents
        .filter(parent => parent.type === 'book')
        .map(book => ({title: book.name, value: book.id}));
    }
    return [];
  }, [elementParents]);

  if (options.length <= 1) return null;

  return (
    <MenuExpand
      {...props}
      columns={1}
    >
      {options.map(option => (
        <MenuOption
          key={option.value}
          option={option}
          className="ProseMirror-MenuLongButton"
          onClick={handleOptionClick}
        >
          {icons.book}&nbsp;{option.title}
        </MenuOption>
      ))}
    </MenuExpand>
  );
};
MenuBookSpecific.propTypes = {
  ...MenuExpand.propTypes
};
MenuBookSpecific.defaultProps = {
  active: markActive('markBookSpecific'),
  icon: icons.glasses,
  title: "Toggle eBook specific content"
};

export {MenuBookSpecific}
