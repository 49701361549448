import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {TabEditorView} from "views/tab/TabEditor";
import {TabVersionsView} from "views/tab/TabVersions";
import {TabMediaView} from "views/tab/TabMedia";
import {TabMergeView} from "views/tab/TabMerge";
import {TabMergeHistoryView} from "views/tab/TabMergeHistory";

import {navRoutes, useNav} from "reducers/nav";

export const TabRoutes = ({match: {url, path, params: {tabId}}}) => {
  const selectedTab = useNav(navRoutes.TAB, tabId);
  if (!selectedTab) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/editor`} component={TabEditorView}/>
      <Route path={`${path}/versions`} component={TabVersionsView}/>
      <Route path={`${path}/media`} component={TabMediaView}/>
      <Route path={`${path}/merge`} component={TabMergeView}/>
      <Route path={`${path}/mergeHistory`} component={TabMergeHistoryView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
