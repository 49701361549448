import React from 'react';
import {connect} from 'react-redux';
import {getFormValues, getFormSyncErrors} from 'redux-form';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";

import DesmosDialogForm from "./form";
import {withProsemirror} from "prosemirror/components/ProsemirrorProvider";
import {updateNodeAttrs, insertNode} from "prosemirror/commands";
import {reduxDialog, dialogs} from "reducers/dialog";
import {hasAnyProperty} from "util/form";

const formValues = getFormValues('desmosDialog');
const formErrors = getFormSyncErrors('desmosDialog');

class DesmosDialog extends React.Component {
  handleClose = () => {
    const {dialog: {actions}} = this.props;
    actions.close();
  };

  handleSubmit = () => {
    const { prosemirror, formData, dialog: {data} } = this.props;
    const nodeAttrs = {
      ...formData,
      degreeMode: formData.degreeMode && formData.degreeMode === 'true',
    };

    if (formData.type === 'ap') {
      const regex = /(https?:\/\/)?(teacher.desmos.com\/activitybuilder\/(export|custom)\/)?([0-9a-f]+)/i;
      const result = regex.exec(formData.src);
      const activityId = result[4];
      nodeAttrs.src = `https://teacher.desmos.com/activitybuilder/export/${activityId}`;
    }

    if (data && data.pos) {
      prosemirror.execute(updateNodeAttrs(nodeAttrs, data.pos));
    } else {
      prosemirror.execute(insertNode('desmos', nodeAttrs));
    }

    this.handleClose();
  };

  render() {
    const { dialog: {isOpen, data}, formData, formErrors } = this.props;
    return (<Dialog open={isOpen} maxWidth="sm" fullWidth disableRestoreFocus>
      <DialogTitle>Desmos Calculator Properties</DialogTitle>
      <DialogContent>
        <DesmosDialogForm
          initialValues={data && data.attrs}
          nodeData={data}
          formData={formData}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={e => this.handleClose(e)} color="primary">
          Cancel
        </Button>
        <Button onClick={e => this.handleSubmit(e)} color="primary" disabled={hasAnyProperty(formErrors)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

const mapStateToProps = (state) => ({
  formData: formValues(state),
  formErrors: formErrors(state),
});

export default connect(
  mapStateToProps
)(reduxDialog({
  dialog: dialogs.PROSEMIRROR_DESMOS
})(withProsemirror(DesmosDialog)));
