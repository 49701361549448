import React from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, CircularProgress } from '@material-ui/core';
import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';

import {getElementTypeByName} from 'constants/ElementTypes';

import {withProsemirror} from "prosemirror/components/ProsemirrorProvider";
import {insertContentContainerReference} from "prosemirror/commands";

import LessonContentContainerDialogCreateTab from './createTab';
import LessonContentContainerDialogInsertTab from './insertTab';
import {ToggleButton} from "components/Button/ToggleButton";

import {withClient, createLoadedSelector} from "reducers/client";
import {clearRequest} from "reducers/client/actions";
import {
  lessonContainers,
  elementDetails,
  lessonContentContainerCreateElement,
  elementCreateCurrentVersion,
  saveLessonContentContainerWithContent
} from "reducers/client/requestTypes";
import {createDialogDataSelector, reduxDialog, dialogs} from "reducers/dialog";

const MODE_CREATE = 'MODE_CREATE';
const MODE_INSERT = 'MODE_INSERT';

class LessonContentContainerDialog extends React.Component {

  handleClose() {
    const {dialog: {actions}} = this.props;
    actions.close();
  }
  handleSubmit() {
    const {
      dialog: {
        state: {mode}
      },
      insertFormData,
      createFormData,
      lessonContainers,
      lessonContentContainerCreateElement,
      elementCreateCurrentVersion,
      saveLessonContentContainerWithContent,
      clearRequest
    } = this.props;
    if (mode === MODE_INSERT) {
      const {selectedContainer, elementType} = insertFormData;
      this.insertContentReferenceNode(selectedContainer.id, elementType);
      this.handleClose();
    } else {
      if (createFormData.containerId) {
        const {element: {contentVersion, ...element}} = this.getContainerData();
        let selectedContainer = lessonContainers.get().find(p => p.id === createFormData.containerId);
        let conflictingElement = selectedContainer.elements.find(e => e.elementType === element.elementType);

        let prepare;
        if (conflictingElement) prepare = Promise.resolve({id: conflictingElement.id});
        else prepare = lessonContentContainerCreateElement.sendRequest(element);

        prepare
          .then(result => elementCreateCurrentVersion.sendRequest(result.id, contentVersion))
          .then(() => {
            if (conflictingElement) clearRequest('elementDetails',conflictingElement.id);
            lessonContainers.sendRequest();
            this.insertContentReferenceNode(selectedContainer.id, element.elementType);
            this.handleClose();
          });
      } else {
        const container = this.getContainerData();

        return saveLessonContentContainerWithContent.sendRequest(container).then(results => {
          lessonContainers.sendRequest();
          this.insertContentReferenceNode(results.id, results.element.elementType);
          this.handleClose();
        });
      }
    }
  }

  getContainerData() {
    const {
      elementDetails,
      createFormData: {
        name,
        elementType
      },
      dialog: {data}
    } = this.props;
    let html, markup;
    if (data) {
      html = data.html;
      markup = data.markup;
    } else {
      html = '';
    }

    let parentLesson = elementDetails.get().parents.find(p => p.type === 'lesson');

    return {
      name,
      number: name,
      lessonId: parentLesson.id,
      bookId: parentLesson.bookId,
      element: {
        elementType,
        language: 'English',
        contentVersion: {
          name,
          markup: markup && JSON.stringify(markup),
          html: html
        },
      }
    };
  }

  insertContentReferenceNode(id, elementType) {
    const { prosemirror } = this.props;
    prosemirror.execute(insertContentContainerReference('lessonContentContainer', id, elementType));
  }

  render() {
    const {
      dialog: {
        isOpen,
        data: dialogData,
        actions: {setState},
        state: {mode}
      },
      insertFormData,
      elementDetails: elementRequest,
      lessonContainers: lessonContainersRequest,
      saveLessonContentContainerWithContent
    } = this.props;
    let title = "Insert Lesson Content Container Reference";
    if (dialogData && dialogData.html) {
      title = "Convert Text to Reusable Container";
    }

    if (saveLessonContentContainerWithContent.isLoading()) {
      return (<Dialog open={isOpen} maxWidth={'md'} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          Saving, Please Wait...
          <CircularProgress />
        </DialogContent>
      </Dialog>);
    }

    if (!elementRequest.isLoaded() || !lessonContainersRequest.isLoaded()) {
      return (<Dialog open={isOpen} maxWidth={'md'} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          Please Wait...
          <CircularProgress />
        </DialogContent>
      </Dialog>);
    }

    const submitDisabled = mode === MODE_INSERT && !(insertFormData && insertFormData.selectedContainer);
    const parentElementType = getElementTypeByName(elementRequest.get().elementType);

    return (<Dialog open={isOpen} maxWidth={'xl'} fullWidth disableRestoreFocus>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ToggleButton checked={mode === MODE_CREATE} onClick={() => setState({mode:MODE_CREATE})}>
          Create New Lesson Content Container
        </ToggleButton>
        <ToggleButton
          checked={mode === MODE_INSERT}
          onClick={() => setState({mode:MODE_INSERT})}
          disabled={dialogData && !!dialogData.html}
        >
          Insert Existing Lesson Content Container
        </ToggleButton>
        {mode === MODE_CREATE ? (
          <LessonContentContainerDialogCreateTab
            parentElementType={parentElementType}
            lessonId={lessonContainersRequest.getParams()}
            htmlContent={dialogData && dialogData.html}
          />
        ) : (
          <div>
            <LessonContentContainerDialogInsertTab
              parentElementType={parentElementType}
              lessonId={lessonContainersRequest.getParams()}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={e => this.handleClose(e)} color="primary">
          Cancel
        </Button>
        <Button disabled={submitDisabled} onClick={e => this.handleSubmit(e)} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

const createFormValues = getFormValues('lessonContentContainerDialogCreate');
const insertFormValues = getFormValues('lessonContentContainerDialogInsert');
const mapStateToProps = () => {
  return (state, props) => ({
    createFormData: createFormValues(state),
    insertFormData: insertFormValues(state)
  });
};

const mapDispatchToProps = dispatch => ({
  clearRequest: (name, params) => dispatch(clearRequest(name, params)),
});

const getDialogData = createDialogDataSelector(dialogs.PROSEMIRROR_LESSON_CONTENT_CONTAINER);
const getElementId = state => state.prosemirror.elementId;
const getLessonId = createLoadedSelector('elementDetails', getElementId, (element => {
  let parentLesson = element.parents && element.parents.find(p => p.type === 'lesson');
  if (parentLesson) return parentLesson.id;
}));
const getContainerId = state => {
  let createFormData = createFormValues(state);
  return createFormData && createFormData.containerId;
};
export default withClient({
  hooks: {
    elementDetails: elementDetails(getElementId),
    lessonContainers: lessonContainers(getLessonId),
    lessonContentContainerCreateElement: lessonContentContainerCreateElement(getContainerId),
    elementCreateCurrentVersion: elementCreateCurrentVersion(),
    saveLessonContentContainerWithContent
  }
})(connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxDialog({
  dialog: dialogs.PROSEMIRROR_LESSON_CONTENT_CONTAINER,
  initialState: {
    mode: MODE_CREATE
  }
})(withProsemirror(LessonContentContainerDialog))));
