import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {LessonDetailsView} from "views/lesson/LessonDetails";
import {LessonEditorView} from "views/lesson/LessonEditor";
import {LessonVersionsView} from "views/lesson/LessonVersions";
import {LessonMediaView} from "views/lesson/LessonMedia";

import {navRoutes, useNav} from "reducers/nav";

export const LessonRoutes = ({match: {url, path, params: {lessonId}}}) => {
  const selectedLesson = useNav(navRoutes.LESSON, lessonId);
  if (!selectedLesson) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={LessonDetailsView}/>
      <Route path={`${path}/editor/:elementId`} component={LessonEditorView}/>
      <Route path={`${path}/versions/:elementId`} component={LessonVersionsView}/>
      <Route path={`${path}/media/:elementType`} component={LessonMediaView}/>
      <Route path={`${path}/media`} component={LessonMediaView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
