import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBan,
  faBook,
  faCircle,
  faCloudUploadAlt,
  faCut,
  faPencilAlt,
  faSave
} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const split_ebooks_homework = (<span className="fa-layers fa-fw">
  <FontAwesomeIcon icon={faCut} transform="up-2"/>
  <FontAwesomeIcon icon={faBook} transform="shrink-6 down-8 left-6"/>
  <FontAwesomeIcon icon={faPencilAlt} transform="shrink-6 down-8 right-6"/>
</span>);
export const save_changes = (<span className="fa-layers fa-fw">
  <FontAwesomeIcon icon={faSave} />
  <FontAwesomeIcon icon={faCircle} style={{color:'tomato'}} transform="shrink-6 up-8 right-8"/>
</span>);
export const cloud_changes = (<span className="fa-layers fa-fw">
  <FontAwesomeIcon icon={faCloudUploadAlt} />
  <FontAwesomeIcon icon={faCircle} style={{color:'tomato'}} transform="shrink-6 up-8 right-8"/>
</span>);
export const remove_font = (<span className="fa-layers fa-fw">
  <span className="fa-layers-text" style={{
    fontFamily:'serif',
    fontWeight:900,
  }}>
    F
  </span>
  <FontAwesomeIcon icon={faBan} transform="shrink-6 down-8 right-10"/>
</span>);