import React from 'react';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {tabElements} from "reducers/client/requestTypes";

import {ContentPreview} from "./ContentPreview";

const tabElementsHook = tabElements();
const TabPreview = ({ tabId, ...innerProps }) => {
  // Get tab Elements and return the first one (should be the only one)
  const tabElements = useClientHook(tabElementsHook, tabId);
  if (!tabElements.isLoaded()) return null;
  return <ContentPreview contentVersionId={tabElements.get()[0].publishedVersionId} {...innerProps} />;
};
TabPreview.propTypes = {
  tabId: PropTypes.string.isRequired,
};

export {TabPreview}
