import classNames from 'classnames';
import { Plugin, PluginKey } from 'prosemirror-state';

import {icon} from '@fortawesome/fontawesome-svg-core';
import {faWrench} from '@fortawesome/free-solid-svg-icons';

import {getMarkedRange} from "prosemirror/commands/helpers";
import {dialog as dialogAction} from 'prosemirror/connectors/actions';
import {dialogs} from 'reducers/dialog';
import {PLUGIN_INTERFACE} from 'prosemirror/plugins/interface';

import './linkEditor.css';

export const PLUGIN_LINK_EDITOR = new PluginKey('link-editor');

const initialState = {
  start: null,
  end: null,
  mark: null,
};

class LinkEditor {
  constructor(view) {
    let pm = PLUGIN_INTERFACE.get(view.state).interface;
    const themeColor = pm.theme.palette.primary.main;
    this.outerView = view;

    this.active = {...initialState};

    this.container = document.createElement('div');
    this.container.setAttribute('class',"ProseMirror-LinkEditor-Container");
    this.container.style.borderColor = themeColor;
    this.container.style.display = 'none';

    this.inputWrapper = document.createElement('div');
    this.inputWrapper.setAttribute('class',"ProseMirror-LinkEditor-Wrapper");

    this.inputSpacer = document.createElement('span');
    this.inputSpacer.setAttribute('class',"ProseMirror-LinkEditor-Spacer");
    this.inputSpacer.innerText = '';

    this.hrefInput = document.createElement('input');
    this.hrefInput.setAttribute('class',"ProseMirror-LinkEditor-Input");
    this.hrefInput.value = '';
    this.hrefInput.addEventListener('input', ev => this.onInput(ev));
    this.hrefInput.addEventListener('keydown', ev => this.onKeyDown(ev));

    this.advancedButton = document.createElement('button');
    this.advancedButton.setAttribute('class',"ProseMirror-LinkEditor-Button");
    this.advancedButton.style.borderLeftColor = themeColor;
    this.advancedButton.style.backgroundColor = themeColor;
    this.advancedButton.addEventListener('click', ev => this.onClickButton(ev));

    this.icon = icon(faWrench).node[0];

    this.container.appendChild(this.inputWrapper);
    this.inputWrapper.appendChild(this.hrefInput);
    this.inputWrapper.appendChild(this.inputSpacer);
    this.container.appendChild(this.advancedButton);
    this.advancedButton.appendChild(this.icon);
    this.outerView.root.body.appendChild(this.container);
    this.outerView.dom.addEventListener('click', this.onClickLink, false);
  }

  select(view, mark, start, end) {
    if (mark !== this.active.mark) { this.active.mark = mark; }
    if (end !== this.active.end) { this.active.end = end; }
    if (start !== this.active.start) {
      this.active.start = start;
      this.showEditor(view, mark.attrs.href);
    }
  }

  focus() {
    this.hrefInput.focus();
  }

  showEditor(view, href) {
    // Update the editor value
    if (href != null && this.hrefInput.value !== href) {
      // Update the editor's text value
      this.hrefInput.value = href;
      this.inputSpacer.innerText = href;
    }
    // Position editor box next to the active link
    let {left, bottom} = view.coordsAtPos(this.active.start);
    this.container.style.display = '';
    const width = this.container.offsetWidth;
    const viewWidth = this.outerView.root.body.offsetWidth;
    if (left + width > viewWidth) {
      this.container.style.left = `${viewWidth - width}px`;
    } else {
      this.container.style.left = `${left}px`;
    }
    this.container.style.top = `${bottom}px`;
  }

  hideEditor() {
    if (this.container.style.display !== 'none') {
      // Hide the editor if no link is selected
      this.container.style.display = 'none';
      this.active = {...initialState};
    }
  }

  onClickLink(event) {
    if (event.target.tagName.toLowerCase() === 'a') event.preventDefault();
  }

  onClickButton(event) {
    this.outerView.dispatch(dialogAction(dialogs.PROSEMIRROR_LINK, {
      attrs: this.active.mark.attrs,
      type: this.active.mark.type.name,
      start: this.active.start,
      end: this.active.end,
    }));
  }

  onKeyDown(event) {
    // Override ctrl-a functionality since it interferes with Prosemirror
    if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
      this.hrefInput.select();
      event.preventDefault();
      return false;
    }
  }

  onInput(event) {
    const href = this.hrefInput.value;
    this.inputSpacer.innerText = href;

    const {start, end, mark} = this.active;
    const {tr, schema} = this.outerView.state;

    if (mark) {
      let isAbsolute = /^(?:[a-z]+:)?\/\//i.test(href);
      tr.removeMark(start, end, mark);
      tr.addMark(start, end, schema.marks.link.create({
        href,
        target: isAbsolute ? '_blank' : '_self'
      }));
      this.outerView.dispatch(tr);
    }

    this.showEditor(this.outerView);
  }

  update(view) {
    const {schema, doc, selection: {node, anchor, from, to}} = view.state;
    if (node) {
      const nodeMark = node.marks.find(m => m.type === schema.marks.link);
      if (nodeMark) {
        // Select a marked range around a node selection
        const {start, end} = getMarkedRange(doc, nodeMark, anchor);
        this.select(view, nodeMark, start, end);
      } else {
        this.hideEditor();
      }
    } else {
      let toMark = doc.resolve(to).marks().find(m => m.type === schema.marks.link);
      let fromMark = toMark;
      if (from !== to) fromMark = doc.resolve(from+1).marks().find(m => m.type === schema.marks.link);
      if (fromMark && fromMark === toMark) {
        // Select a marked range around the cursor
        const {start, end} = getMarkedRange(doc, fromMark, to);
        this.select(view, fromMark, start, end);
      } else {
        this.hideEditor();
      }
    }
  }

  destroy() {
    PLUGIN_LINK_EDITOR.get(this.outerView.state).destroyView(this);
    this.outerView.dom.removeEventListener('click', this.onClickLink, false);
    this.container.parentNode.removeChild(this.container);
  }
}

export const linkEditorPlugin = () => {
  const activeViews = [];

  let plugin = new Plugin({
    key: PLUGIN_LINK_EDITOR,
    view: editorView => {
      let pluginView = new LinkEditor(editorView, this);
      activeViews.push(pluginView);
      return pluginView;
    }
  });

  plugin.getView = editorView => editorView.pluginViews.find(v => activeViews.includes(v));
  plugin.destroyView = pluginView => activeViews.splice(activeViews.indexOf(pluginView), 1);

  return plugin;
};
