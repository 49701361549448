import React, {Component} from 'react';
import {withClient} from "reducers/client";
import {menuRender} from "reducers/client/requestTypes";
import {withStyles} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from "@material-ui/core/Paper";
import {createNavRouteSelector, navRoutes} from "../../reducers/nav";

class MenuPreview extends Component {

  render(){
    const {menuRender:renderRequest} = this.props;

    if (!renderRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Book details...</h2>
      </div>);
    }
    return (
      <Paper>
        <div dangerouslySetInnerHTML={{__html: renderRequest.get().value.renderedHtml}}/>
      </Paper>
    )
  }
}

const getNavBookMenu = createNavRouteSelector(navRoutes.BOOK_MENU);
export default withClient({
  hooks: {
    menuRender: menuRender(getNavBookMenu),
  },
})(MenuPreview);
