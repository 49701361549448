import React, {Component} from 'react';
import {AppBar, Tab, Tabs, Paper, Snackbar} from "@material-ui/core";

import {connect} from "react-redux";
import {withClient} from "reducers/client";
import {elementAddStandardToPublishedVersion} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";

import {ElementStandardsTable} from 'components/element/ElementStandardsTable';
import {AssessmentProblemBreadcrumb} from "components/common/Breadcrumb";
import {ClassificationStandardBrowser} from 'components/common/Browser';
import {ViewportRoot, ViewportSection} from "components/layout";

class AssessmentProblemStandards extends Component {

  state = {
    tabValue: 'list',
    snackbarMessage: '',
  };

  handleChangeTab = (e, tabValue) => {
    this.setState({tabValue})
  };
  handleAddStandard = (standards) => {
    const {elementAddStandardToPublishedVersion} = this.props;
    const standardTitles = standards.map(s => s.title).join(', ');
    this.setState({snackbarMessage: `Added ${standardTitles}`});
    Promise.all(standards.map(standard => elementAddStandardToPublishedVersion.sendRequest(standard.id)));
  };
  handleSnackbarClose = (ev, reason) => {
    if (reason === 'timeout') {
      this.setState({snackbarMessage: ''});
    }
  };

  renderTabContent = () => {
    const {elementId, elementAddStandardToPublishedVersion} = this.props;
    if(this.state.tabValue === 'list'){
      return <ElementStandardsTable elementId={elementId}/>
    } else {
      return <ClassificationStandardBrowser
        disabled={elementAddStandardToPublishedVersion.isLoading()}
        onChange={this.handleAddStandard}
      />
    }
  };

  render(){
    const {assessmentProblemId} = this.props;

    return (
      <ViewportRoot>
        <AssessmentProblemBreadcrumb assessmentProblemId={assessmentProblemId} />
        <ViewportSection>
          <Paper>
            <AppBar position={'static'}>
              <Tabs value={this.state.tabValue} onChange={this.handleChangeTab}>
                <Tab label="List" value={"list"} />
                <Tab label="Add" value={"add"} />
              </Tabs>
            </AppBar>
            {this.renderTabContent()}
          </Paper>
        </ViewportSection>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(this.state.snackbarMessage)}
          onClose={this.handleSnackbarClose}
          key={this.state.snackbarMessage}
          message={this.state.snackbarMessage}
          autoHideDuration={5000}
        />
      </ViewportRoot>
    )
  }
}

const getNavAssessmentProblem = createNavRouteSelector(navRoutes.ASSESSMENT_PROBLEM);
const getNavElement = createNavRouteSelector(navRoutes.ELEMENT);
const mapStateToProps = (state, props) => ({
  assessmentProblemId: getNavAssessmentProblem(state),
  elementId: getNavElement(state)
});

export const AssessmentProblemStandardsView = connect(
  mapStateToProps
)(withClient({
  hooks: {
    elementAddStandardToPublishedVersion: elementAddStandardToPublishedVersion(getNavElement)
  }
})(AssessmentProblemStandards));

