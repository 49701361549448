import {onDoubleClick} from "util/onDoubleClick";
import MathJax from 'MathJax';
import {promptMathpix} from "prosemirror/commands";
import {LogLevels, logProsemirror} from "prosemirror/log";

export class MathpixMediaView {
  constructor(outerView, media) {
    this.outerView = outerView;
    this.media = media;

    logProsemirror(`CONSTRUCT CpmMediaView [${this.outerView.id}]::MathpixMediaView`, {
      media
    }, LogLevels.INFO);

    if (media.mathPixFoundLatex) {
      this.hasJax = true;
      this.dom = document.createElement('script');
      this.dom.setAttribute('type','math/tex');
      this.dom.innerText = media.latex;

      window.requestAnimationFrame(() => MathJax.Hub.Queue(["Typeset",MathJax.Hub,this.dom]));
    } else {
      this.dom = document.createElement('img');
      this.dom.setAttribute('src',media.publicUrl);
    }
    this.outerView.dom.appendChild(this.dom);

    this.badge = document.createElement('div');
    this.badge.setAttribute('class',"ProseMirror-Media-Badge ProseMirror-MathPix-Badge");
    this.badge.innerText = "MathPix";
    this.outerView.dom.appendChild(this.badge);

    this.offDoubleClick = onDoubleClick(this.outerView.dom, () => {
      this.outerView.execute(promptMathpix);
    });
  }

  destroy() {
    logProsemirror(`DESTROY CpmMediaView [${this.outerView.id}]::MathpixMediaView`, {
      media: this.media
    }, LogLevels.INFO);
    if (this.hasJax) {
      const math = MathJax.Hub.getAllJax(this.outerView.dom);
      math.forEach(jax => MathJax.Hub.Queue(["Remove",jax]));
    }

    this.offDoubleClick();
    this.outerView.dom.removeChild(this.dom);
    this.outerView.dom.removeChild(this.badge);
  }
}