import {liftListItem, wrapInList as pmWrapInList} from "prosemirror-schema-list";

import {
  typeNameMiddleware,
  commonAncestor,
  findAncestor,
  findNodesInRange,
  matchTypes
} from "prosemirror/commands/helpers";
import {selectionInside} from "prosemirror/commands/readers";
import {asArray} from "constants/util/map";
import * as icons from "prosemirror/icons";
import {NodeSelection} from "prosemirror-state";

const insideOl = selectionInside('ordered_list');
const insideUl = selectionInside('bullet_list');
const lift = typeNameMiddleware('nodes',liftListItem)('list_item');

const getSelectedList = state => {
  const { schema, selection: {$from, $to} } = state;
  const $common = commonAncestor($from, $to);
  if (!$common) return null;
  const $ol = findAncestor($from, matchTypes(schema.nodes.ordered_list));
  const $ul = findAncestor($from, matchTypes(schema.nodes.bullet_list));
  if ($ol && $ol.depth < $common.depth && (!$ul || $ol.depth > $ul.depth)) return $ol;
  if ($ul && $ul.depth < $common.depth && (!$ol || $ul.depth > $ol.depth)) return $ul;
  return null;
};

export const replaceList = typeNameMiddleware('nodes', (nodeType, attrs) => (state, dispatch) => {
  const $list = getSelectedList(state);
  if (!$list) return false;
  if (dispatch) dispatch(state.tr.setNodeMarkup($list.pos, nodeType, attrs));
  return true;
});
export const wrapInList = typeNameMiddleware('nodes', pmWrapInList);

export const ListTypes = {
  NUMERIC: {
    value: 'decimal',
    title: 'Numeric List',
    icon: icons.list.ordered_numeric,
    replace: replaceList('ordered_list', {listStyle: 'decimal'}),
    wrap: wrapInList('ordered_list', {listStyle: 'decimal'}),
  },
  LOWER_ALPHA: {
    value: 'lower-alpha',
    title: 'Alphabetic List (lowercase)',
    icon: icons.list.ordered_lower_alpha,
    replace: replaceList('ordered_list', {listStyle: 'lower-alpha'}),
    wrap: wrapInList('ordered_list', {listStyle: 'lower-alpha'}),
  },
  UPPER_ALPHA: {
    value: 'upper-alpha',
    title: 'Alphabetic List (uppercase)',
    icon: icons.list.ordered_upper_alpha,
    replace: replaceList('ordered_list', {listStyle: 'upper-alpha'}),
    wrap: wrapInList('ordered_list', {listStyle: 'upper-alpha'}),
  },
  LOWER_ROMAN: {
    value: 'lower-roman',
    title: 'Roman Numerals (lowercase)',
    icon: icons.list.ordered_lower_roman,
    replace: replaceList('ordered_list', {listStyle: 'lower-roman'}),
    wrap: wrapInList('ordered_list', {listStyle: 'lower-roman'}),
  },
  UPPER_ROMAN: {
    value: 'upper-roman',
    title: 'Roman Numerals (uppercase)',
    icon: icons.list.ordered_upper_roman,
    replace: replaceList('ordered_list', {listStyle: 'upper-roman'}),
    wrap: wrapInList('ordered_list', {listStyle: 'upper-roman'}),
  },
  BULLET: {
    value: 'bullet',
    title: 'Bullet List',
    icon: icons.list.bullet,
    replace: replaceList('bullet_list', {listStyle: 'bullet'}),
    wrap: wrapInList('bullet_list', {listStyle: 'bullet'}),
  },
  PLAIN: {
    value: 'plain',
    title: 'Plain List',
    icon: icons.bars,
    replace: replaceList('bullet_list', {listStyle: 'plain'}),
    wrap: wrapInList('bullet_list', {listStyle: 'plain'}),
  },
  NONE: {
    value: 'none',
    title: 'Remove List',
    icon: icons.ban
  }
};
const listTypesArray = asArray(ListTypes);

export const listOperationEnabled = state => {
  return insideOl(state) || insideUl(state) || ListTypes.NUMERIC.wrap(state);
};
export const listActive = state => {
  return insideOl(state) || insideUl(state);
};

export const getListType = state => {
  const $node = getSelectedList(state);
  const {schema} = state;
  if ($node && $node.nodeAfter.type === schema.nodes.ordered_list) {
    if ($node.nodeAfter.attrs.listStyle) return $node.nodeAfter.attrs.listStyle;
    return ListTypes.NUMERIC.value;
  }
  if ($node && $node.nodeAfter.type === schema.nodes.bullet_list) {
    if ($node.nodeAfter.attrs.listStyle) return $node.nodeAfter.attrs.listStyle;
    return ListTypes.BULLET.value;
  }
  return null;
};
export const setListType = listTypeName => (state, dispatch) => {
  if (listTypeName === ListTypes.NONE.value) {
    return lift(state, dispatch);
  }
  const listType = listTypesArray.find(t => t.value === listTypeName);
  const list = getSelectedList(state);
  if (list) {
    return listType.replace(state,dispatch);
  } else {
    return listType.wrap(state,dispatch);
  }
};

export const rotateList = amount => (state, dispatch) => {
  const {tr, schema, selection: {$from, $to, node}} = state;
  const isOrderedList = matchTypes(schema.nodes.ordered_list);
  let selectedListNodes;
  if (node && isOrderedList(node)) {
    selectedListNodes = [$from];
  } else {
    selectedListNodes = findNodesInRange($from, $to, isOrderedList);
  }
  if (selectedListNodes.length === 0) return false;
  if (dispatch) {
    selectedListNodes.forEach($node => {
      const {attrs} = $node.nodeAfter;
      tr.setNodeMarkup($node.pos, null, {...attrs, order: Math.max(1, attrs.order + amount)});
    });
    if (node) tr.setSelection(NodeSelection.create(tr.doc,$from.pos));
    dispatch(tr);
  }
  return true;
};

export const indentList = amount => (state, dispatch) => {
  const $list = getSelectedList(state);
  if (!$list) return false;
  if ($list.nodeAfter.attrs.indent + amount < 0) return false;
  if (dispatch) {
    const {tr} = state;
    const {attrs} = $list.nodeAfter;
    tr.setNodeMarkup($list.pos, null, {...attrs, indent: attrs.indent + amount});
    dispatch(tr);
  }
  return true;
};