import {combineReducers} from 'redux';

import imageBrowser from './imageBrowser';
import standardLessons from './standardLessons';
import standardLessonContentContainers from './standardLessonContentContainers';
import contentVersionStandards from './contentVersionStandards';

export default combineReducers({
  imageBrowser,
  standardLessons,
  standardLessonContentContainers,
  contentVersionStandards
});
