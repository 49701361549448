import {showSnackbarSaveError, showSnackbarSaveSuccess, showSnackbarSaving, getContentVersionProperties} from "reducers/Prosemirror";
import {sendRequest} from "reducers/client/actions";
import {defaults} from "reducers/client/util";
import {elementCreateCurrentVersion, elementDetails} from "reducers/client/requestTypes";
import {getDocumentHTML} from "prosemirror/commands/readers";

const elementDetailsHook = elementDetails();
const elementCreateCurrentVersionHook = elementCreateCurrentVersion();
export const quickSave = (tr, prosemirror) => {
  const state = prosemirror.getReduxState();
  const elementId = state.prosemirror.elementId;
  const properties = getContentVersionProperties(state.prosemirror);

  const contentVersion = {
    ...properties,
    elementId,
    markup: prosemirror.state.doc.toJSON(),
    html: getDocumentHTML(prosemirror.state)
  };

  const request = elementCreateCurrentVersionHook.request(elementId, contentVersion, true);
  const action = sendRequest(elementCreateCurrentVersionHook.hookName, defaults.transform, request, elementId);

  prosemirror.dispatch.toRedux(showSnackbarSaving());
  return prosemirror.dispatch.toRedux(action)
    .then(() => {
      prosemirror.dispatch.toRedux(showSnackbarSaveSuccess());
      const request = elementDetailsHook.request(elementId);
      const action = sendRequest(elementDetailsHook.hookName, defaults.transform, request, elementId);
      return prosemirror.dispatch.toRedux(action);
    })
    .catch(() => {
      prosemirror.dispatch.toRedux(showSnackbarSaveError());
    });
};
