import * as BrowserLists from "./BrowserLists";
import {makeBrowser} from "components/common/Browser/makeBrowser";

const stepTypes = {
  BOOK: {
    id: 'BOOK',
    Component: BrowserLists.BrowserBookList,
    getKey: book => book.id,
    getName: book => book.name,
    getProps: () => undefined,
  },
  CHAPTER: {
    id: 'CHAPTER',
    Component: BrowserLists.BrowserChapterList,
    getKey: chapter => chapter.id,
    getName: chapter => chapter.name,
    getProps: book => ({bookId: book.id}),
  },
  LESSON: {
    id: 'LESSON',
    Component: BrowserLists.BrowserLessonList,
    getKey: lesson => lesson.id,
    getName: lesson => lesson.name,
    getProps: chapter => ({chapterId: chapter.id}),
  },
  LESSON_CONTENT_CONTAINER: {
    id: 'LESSON_CONTENT_CONTAINER',
    Component: BrowserLists.BrowserLessonContentContainerList,
    getKey: lessonContentContainer => lessonContentContainer.id,
    getName: lessonContentContainer => lessonContentContainer.name,
    getProps: lesson => ({lessonId: lesson.id}),
  },
  SECTION: {
    id: 'SECTION',
    Component: BrowserLists.BrowserSectionList,
    getKey: section => section.id,
    getName: section => section.type,
    getProps: book => ({bookId: book.id}),
  },
  TABBED_VIEW: {
    id: 'TABBED_VIEW',
    Component: BrowserLists.BrowserTabbedViewList,
    getKey: tabbedView => tabbedView.id,
    getName: tabbedView => tabbedView.type,
    getProps: section => ({sectionId: section.id}),
  },
  TAB: {
    id: 'TAB',
    Component: BrowserLists.BrowserTabList,
    getKey: tab => tab.id,
    getName: tab => tab.name,
    getProps: tabbedView => ({tabbedViewId: tabbedView.id}),
  },
  GLOSSARY_ITEM: {
    id: 'GLOSSARY_ITEM',
    Component: BrowserLists.BrowserGlossaryItemList,
    getKey: glossaryItem => glossaryItem.id,
    getName: glossaryItem => glossaryItem.title,
    getProps: book => ({bookId: book.id}),
  },
  CLASSIFICATION: {
    id: 'CLASSIFICATION',
    Component: BrowserLists.BrowserClassificationList,
    getKey: classification => classification.id,
    getName: classification => classification.name,
    getProps: () => undefined,
  },
  GRADE: {
    id: 'GRADE',
    Component: BrowserLists.BrowserGradeList,
    getKey: grade => grade.id,
    getName: grade => grade.title,
    getProps: classification => ({classificationId: classification.id}),
  },
  STANDARD: {
    id: 'STANDARD',
    Component: BrowserLists.BrowserStandardList,
    getKey: standard => standard.id,
    getName: standard => standard.title,
    getProps: grade => ({gradeId: grade.id}),
  }
};

export const LessonContentContainerBrowser = makeBrowser([
  stepTypes.BOOK,
  stepTypes.CHAPTER,
  stepTypes.LESSON,
  stepTypes.LESSON_CONTENT_CONTAINER
]);
export const SectionBrowser = makeBrowser([
  stepTypes.BOOK,
  stepTypes.SECTION
]);
export const TabBrowser = makeBrowser([
  stepTypes.BOOK,
  stepTypes.SECTION,
  stepTypes.TABBED_VIEW,
  stepTypes.TAB
]);
export const GlossaryItemBrowser = makeBrowser([
  stepTypes.BOOK,
  stepTypes.GLOSSARY_ITEM
]);
export const ClassificationStandardBrowser = makeBrowser([
  stepTypes.CLASSIFICATION,
  stepTypes.GRADE,
  stepTypes.STANDARD
]);
