import React from 'react';
import classNames from 'classnames';
import {makeStyles, Tooltip} from "@material-ui/core";
import IconLink from '@material-ui/icons/Link';
import {getElementTypeByName} from "constants/ElementTypes";

const useStyles = makeStyles(theme => ({
  containerElement: {
    ...theme.typography.button,
    display: 'inline-flex',
    padding: '6px 8px',
    color: theme.palette.primary.main,
  }
}),{name: 'LessonContentContainerElements'});

const expandLinks = links => links.map(link => `${link.bookName} ${link.name}`).join(', ');

export const LessonContentContainerElements = ({elements, className}) => {
  const classes = useStyles({});

  return elements.map(element => {
    const elementType = getElementTypeByName(element.elementType);
    if (element.links.length > 0) {
      return (
        <Tooltip key={element.id} title={expandLinks(element.links)}>
          <div className={classNames(classes.containerElement, className)}>
            {elementType.displayName || elementType.name}
            <IconLink />
          </div>
        </Tooltip>
      );
    }
    return (
      <div key={element.id} className={classNames(classes.containerElement, className)}>
        {elementType.displayName || elementType.name}
      </div>
    );
  })
};
