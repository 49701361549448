import React, {useCallback} from 'react';
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import {useDialog, dialogs} from "reducers/dialog";
import AutocompleteAssessmentProblem from "components/Autocomplete/AutocompleteAssessmentProblem";
import {AssessmentPreview} from "components/editor/previews";
import ElementTypes from "constants/ElementTypes";

const AssessmentGroupLinkProblemDialog = ({onConfirm}) => {
  const {isOpen, state, actions} = useDialog({
    dialog: dialogs.ASSESSMENT_PROBLEM_LINK,
    initialState: { selectedAssessmentProblem: null, replace: false }
  });

  const handleSubmit = useCallback(() => {
    actions.close();
    if (typeof onConfirm === 'function') onConfirm(state.selectedAssessmentProblem, state.replace);
  }, [onConfirm, state.selectedAssessmentProblem, state.replace]);

  // if (!state) return false;
  return (
    <Dialog open={isOpen} onClose={actions.close} disableRestoreFocus>
      <DialogTitle>Select an Assessment Problem</DialogTitle>
      <DialogContent>
        <AutocompleteAssessmentProblem
          fullWidth
          label="Assessment Problem"
          suggestionsStatic
          onSuggestionSelected={(event, {suggestion}) => actions.setState({selectedAssessmentProblem: suggestion})}
        />
        {state.selectedAssessmentProblem && (
          <React.Fragment>
            <h2 className="article-title">Preview</h2>
            <AssessmentPreview
              assessmentProblemId={state.selectedAssessmentProblem.id}
              elementType={ElementTypes.assessmentproblem.name}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.replace}
                  onChange={() => actions.setState({replace: !state.replace})}
                  value="replace"
                />
              }
              label="Unlink from original group"
            />
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
AssessmentGroupLinkProblemDialog.propTypes = {
  onConfirm: PropTypes.func,
};

export {AssessmentGroupLinkProblemDialog}
