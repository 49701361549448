import {inputDebounce} from "../constants/Config";

// Limit state updates to once every {inputDebounce} ms (default: 2 per second)
export const debounce = (f, interval = inputDebounce) => {
  let lock = null;
  let cache = null;
  const setLock = () => {
    if (cache) {
      f(...cache);
      cache = null;
      lock = window.setTimeout(setLock, interval);
    } else {
      lock = null;
    }
  };
  return (...args) => {
    if (!lock) {
      f(...args);
      lock = window.setTimeout(setLock, interval);
    } else {
      cache = args;
    }
  }
};
