import React from 'react';
import { Link, hashHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import 'jquery-slimscroll/jquery.slimscroll.min';

import roles from "constants/Roles";
import Restrict from 'components/Restrict';

export default props => (
  <ul className="nav">
    <li className="nav-header"><span /></li>
    <li>
      <Button href="#/app/landing"><i className="nav-icon material-icons">favorite_border</i><span className="nav-text">Welcome</span></Button>
    </li>

    <Restrict role={[roles.ebookEditor]}>
      <li className="nav-header"><span>Administration</span></li>
      <li>
        <Button href="#/app/categories/list">
          <i className="nav-icon material-icons">list</i><span className="nav-text">Categories</span>
        </Button>
      </li>
      <li>
        <Button href="#/app/books/list">
          <i className="nav-icon material-icons">book</i><span className="nav-text">List All Books</span>
        </Button>
      </li>
      <li>
        <Button href="#/app/classifications/list">
          <i className="nav-icon material-icons">school</i><span className="nav-text">Classifications</span>
        </Button>
      </li>
      <li>
        <Button href="#/app/lessonContentContainers/search">
          <i className="nav-icon material-icons">search</i><span className="nav-text">Search Problems</span>
        </Button>
      </li>
      <li>
        <Button href="#/app/medias/search">
          <i className="nav-icon material-icons">image</i><span className="nav-text">Search Images</span>
        </Button>
      </li>
      <li>
        <Button href="#/app/assessmentproblems/search">
          <i className="nav-icon material-icons">search</i><span className="nav-text">Search Assessment Problems</span>
        </Button>
      </li>
      <li>
        <Button href="#/app/reports">
          <i className="nav-icon material-icons">insert_chart</i><span className="nav-text">Reports</span>
        </Button>
      </li>
      <li>
        <Button href="#/app/utilities">
          <i className="nav-icon material-icons">settings</i><span className="nav-text">Utilites</span>
        </Button>
      </li>
    </Restrict>
  </ul>
);