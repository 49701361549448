import { history } from 'prosemirror-history';
import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';
import { tableEditing } from 'prosemirror-tables';

import {interfacePlugin} from './interface';
import {rulesPlugin} from "./rules";
import {keysPlugin} from "./keys";
import {columnPlugin} from "./column";
import {selectionPlugin} from './selection';
import {nodeViewsPlugin} from "./nodeViews";
import {homeworkPlugin} from "./homework";
import {linkEditorPlugin} from './linkEditor';
import {bookSpecificPlugin} from "./bookSpecific";
import {integrityPlugin} from "./integrity";
import {defaultSchema} from "../schema";

import 'prosemirror-tables/style/tables.css';
import 'prosemirror-gapcursor/style/gapcursor.css';

// for tables
document.execCommand('enableObjectResizing', false, false);
document.execCommand('enableInlineTableEditing', false, false);

const defaultPlugins = [
  rulesPlugin({schema: defaultSchema}),
  keysPlugin(),
  dropCursor(),
  gapCursor(),
  history(),
  tableEditing(),
  columnPlugin(),
  selectionPlugin(),
  nodeViewsPlugin(),
  homeworkPlugin(),
  linkEditorPlugin(),
  bookSpecificPlugin(),
  integrityPlugin(),
];

export {
  interfacePlugin,
  rulesPlugin,
  keysPlugin,
  dropCursor,
  gapCursor,
  history,
  tableEditing,
  columnPlugin,
  selectionPlugin,
  nodeViewsPlugin,
  homeworkPlugin,
  linkEditorPlugin,
  bookSpecificPlugin,
  integrityPlugin,
  defaultPlugins
}
