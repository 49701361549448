import React, {useState} from "react";

import {
  Dialog,
  DialogContent,
  Grow,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import {formatDate} from "constants/Date";

import {useCachedClientRequest} from "hooks/useCachedClientRequest";
import {useClientHook} from "reducers/client";
import {mediaDetails} from "reducers/client/requestTypes";

const useStyles = makeStyles({
  image: {
    maxWidth: '300px',
    maxHeight: '72px',
    cursor: 'zoom-in',
  },
  minimize: {
    cursor: 'zoom-out',
  },
});

const mediaDetailsHook = mediaDetails();
export const MediaDetailsTable = ({mediaId}) => {
  const [maximized, setMaximized] = useState(false);
  const classes = useStyles({});
  const cachedMediaDetails = useCachedClientRequest(useClientHook(mediaDetailsHook, mediaId));
  if (!cachedMediaDetails) return false;

  return (
    <React.Fragment>
      <Dialog
        open={maximized}
        onClose={() => setMaximized(false)}
        classes={{scrollPaper: classes.minimize}}
        TransitionComponent={Grow}
      >
        <DialogContent>
          <img
            className={classes.minimize}
            onClick={() => setMaximized(false)}
            src={cachedMediaDetails.publicUrl}
            alt=""
          />
        </DialogContent>
      </Dialog>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>File Name</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <img
                className={classes.image}
                onClick={() => setMaximized(true)}
                src={cachedMediaDetails.publicUrl}
                alt=""
              />
            </TableCell>
            <TableCell>{cachedMediaDetails.fileName}</TableCell>
            <TableCell>{formatDate(cachedMediaDetails.createdAt)}</TableCell>
            <TableCell>{formatDate(cachedMediaDetails.updatedAt)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
};
