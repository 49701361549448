import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Select, MenuItem, makeStyles} from '@material-ui/core';

import {setEditorDisplayMode} from 'reducers/Prosemirror/index';
import {displayModes} from "constants/Prosemirror";
import {asArray} from "constants/util/map";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    fontSize: '14pt',
  },
  select: {
    marginLeft: theme.spacing(1),
    minWidth: 160,
  }
}), {name: "ProsemirrorDisplayModeToggle"});

const ProsemirrorDisplayModeToggle = innerProps => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const displayMode = useSelector(state => state.prosemirror.displayMode);

  const handleChange = useCallback(
    event => dispatch(setEditorDisplayMode(event.target.value)),
    [dispatch]
  );

  return (
    <div className={classes.root} {...innerProps}>
      View:
      <Select
        className={classes.select}
        value={displayMode}
        onChange={handleChange}
      >
        {asArray(displayModes).map(m => {
          const IconComponent = m.icon;
          return (
            <MenuItem
              key={m.id}
              value={m.id}
            >
              <IconComponent /> {m.name}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export {ProsemirrorDisplayModeToggle}
