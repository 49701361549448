import {apiClient} from "constants/Clients";

export const tabbedViewDetails = key => ({
  hookName: 'tabbedViewDetails',
  request: tabbedViewId => apiClient.getTabbedView(tabbedViewId),
  key,
  auto: true,
});
export const updateTabbedView = key => ({
  hookName: 'updateTabbedView',
  request: (tabbedViewId, tabbedView) => apiClient.updateTabbedView({id: tabbedViewId, ...tabbedView}),
  key,
});
export const deleteTabbedView = key => ({
  hookName: 'deleteTabbedView',
  request: tabbedViewId => apiClient.deleteTabbedView(tabbedViewId),
  key,
});
export const tabbedViewCreateTab = key => ({
  hookName: 'tabbedViewCreateTab',
  request: (tabbedViewId, tab) => apiClient.createTabbedViewTab(tabbedViewId, tab),
  key,
});
export const tabbedViewTabs = key => ({
  hookName: 'tabbedViewTabs',
  request: tabbedViewId => apiClient.getTabbedViewTabs(tabbedViewId)
    .limit(100)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const tabbedViewTabOrder = key => ({
  hookName: 'tabbedViewTabOrder',
  request: (tabbedViewId, tabIds) => apiClient.setTabbedViewTabOrder(tabbedViewId, tabIds),
  key,
});
