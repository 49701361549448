import React, {useCallback} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';

import {
  Typography,
  Paper,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import IconBack from '@material-ui/icons/ArrowBack'

import {useClientHook} from "reducers/client";
import {mediaDetails, updateMedia} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";

import {ButtonBox, ButtonBoxButton, ViewportRoot, ViewportSection} from "components/layout";
import {MediaDetailsTable} from "components/media/MediaDetailsTable";
import {MediaAltTextsTable} from "components/Tables/MediaAltTextsTable";
import {MediaTagEditor} from "components/media/MediaTagEditor";

import {useCachedClientRequest} from "hooks/useCachedClientRequest";

const getNavMedia = createNavRouteSelector(navRoutes.MEDIA);
const mediaDetailsHook = mediaDetails();
const updateMediaHook = updateMedia();
const MediaDetails = () => {
  const history = useHistory();
  const mediaId = useSelector(getNavMedia);
  const cachedMediaDetails = useCachedClientRequest(useClientHook(mediaDetailsHook, mediaId));
  const updateMedia = useClientHook(updateMediaHook, mediaId);

  const handleChangePresentationOnly = useCallback(ev => {
    return updateMedia.sendRequest({
      ...mediaDetails.get(),
      presentationOnly: ev.target.checked
    }).then(() => mediaDetails.sendRequest());
  });
  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  if (!cachedMediaDetails) return false;

  return (
    <ViewportRoot>
      <ButtonBox>
        <ButtonBoxButton title="Go Back" onClick={handleGoBack}>
          <IconBack />
        </ButtonBoxButton>
      </ButtonBox>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Media Information</Typography>
        <Paper><MediaDetailsTable mediaId={mediaId} /></Paper>
      </ViewportSection>
      <ViewportSection>
        <div>
          <Typography variant="h3" gutterBottom>Alt Texts</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={cachedMediaDetails.presentationOnly}
                onChange={handleChangePresentationOnly}
                disabled={updateMedia.isLoading()}
              />
            }
            label="Presentation Only"
          />
        </div>
        <Paper>
          <MediaAltTextsTable mediaId={mediaId}/>
        </Paper>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Tags</Typography>
        <Paper>
          <MediaTagEditor mediaId={mediaId}/>
        </Paper>
      </ViewportSection>
    </ViewportRoot>
  );
};

export {MediaDetails as MediaDetailsView}
