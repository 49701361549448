export const processColumns = (columns, options) => {
  const {draggable} = options;
  const columnsWithDefaults = columns.map(column => {
    const mapped = {...column};
    if (!column.get) mapped.get = row => row[column.key];
    if (!column.set) mapped.set = (row, value) => row[column.key] = value;
    return mapped;
  });

  const visibleColumns = columnsWithDefaults.filter(c => !c.hidden);
  const totalColumns = visibleColumns.length + (draggable ? 1 : 0) + 1; // (extra columns for drag handle and buttons)

  const getRowPayload = (row, includeReadOnly) => {
    const rowState = {};
    columnsWithDefaults.forEach(({get, set, readOnly}) => {
      if (includeReadOnly || !readOnly) {
        set(rowState, get(row));
      }
    });
    return rowState;
  };

  const getPagePayload = (rows, includeReadOnly) => {
    if (!Array.isArray(rows)) return [];
    return rows.map(row => getRowPayload(row, includeReadOnly));
  };

  return {
    columns: columnsWithDefaults,
    visibleColumns,
    totalColumns,
    getRowPayload,
    getPagePayload
  };
};

export const makePendingChangesSelector = columns => state => {
  const {adding, editing, originalPage, page} = state;
  if (adding) {
    return columns.every(column => {
      if (column.readOnly || column.optional) return true;
      let value = column.get(state.newRow);
      if (typeof value === 'string') return value.length > 0;
      return value != null;
    });
  } else if (editing < 0 || !Array.isArray(originalPage) || editing >= originalPage.length) {
    return false;
  } else {
    return columns.some(column => {
      return column.get(originalPage[editing]) !== column.get(page[editing]);
    });
  }
};
