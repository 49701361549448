import {Plugin, PluginKey, NodeSelection} from 'prosemirror-state';

export const PLUGIN_INTEGRITY = new PluginKey('integrity');

export const integrityPlugin = () => {
    return new Plugin({
        key: PLUGIN_INTEGRITY,

        appendTransaction(transactions, oldState, state) {
            const {tr, doc} = state;
            let fixRequired = false;

            // fix nodes with empty content
            const whitespace = /^\s*$/;
            doc.descendants((node, pos) => {
                if (node.type.name === 'text' && whitespace.test(node.text)) {
                    const $pos = doc.resolve(pos);
                    const parent = $pos.parent;
                    if (parent.firstChild === parent.lastChild && parent.lastChild === node) {
                        fixRequired = true;
                        const mappedStart = tr.mapping.map($pos.before());
                        tr.replaceWith(mappedStart, mappedStart + parent.nodeSize - 1, parent.copy());
                    }
                }
            });

            if (fixRequired) return tr;
        }
    });
};
