import React from 'react';

import {BooksTable} from 'components/book/BooksTable';
import {ViewportRoot, ViewportSection} from "components/layout";
import {Paper, Typography} from "@material-ui/core";

export const BookListView = props => (
  <ViewportRoot>
    <ViewportSection>
      <Typography variant="h3" gutterBottom>Books</Typography>
      <Paper><BooksTable /></Paper>
    </ViewportSection>
  </ViewportRoot>
);
