import {PLUGIN_INTERFACE} from "prosemirror/plugins/interface";

import {ImageMediaView} from "./image";
import {FormulaMediaView} from "./formula";
import {MathpixMediaView} from "./mathpix";
import {MathMLMediaView} from "./mathml";

import MediaTypes from 'constants/MediaTypes';
import {LogLevels, logProsemirror} from "prosemirror/log";

import {createObserver} from "reducers/client";
import {mediaDetails} from "reducers/client/requestTypes";

import './media.css';

let count = 0;

const createMediaObserver = (props, store, handler) => createObserver(
  {
    hooks: {
      mediaDetails: mediaDetails((state, props) => props.mediaId)
    },
    props
  },
  store,
  handler
);

export class CpmMediaView {
  constructor(node, view, getPos) {
    this.interface = PLUGIN_INTERFACE.get(view.state).interface;

    this.id = count++;
    logProsemirror(`CONSTRUCT CpmMediaView [${this.id}]`, {node}, LogLevels.INFO);

    this.node = node;
    this.outerView = view;
    this.getPos = getPos;

    this.mediaId = node.attrs['media-id'];
    this.dom = document.createElement('div');
    this.dom.setAttribute('class', 'c3po-media');
    this.dom.setAttribute('data-media-id', this.mediaId);
    this.setStyleAttributes();

    if (this.mediaId) {
      this.clientObserver = createMediaObserver(
        {
          mediaId: this.mediaId,
        },
        this.interface.getReduxStore(),
        clientState => this.onMediaUpdated(clientState)
      );
    }
  }

  execute(command, ...args) {
    this.interface.execute(command({
      node: this.node,
      view: this.outerView,
      getPos: this.getPos,
    }, ...args));
  }

  setStyleAttributes() {
    let {align, vAlign, border, width, height, horizontalPadding, verticalPadding, color} = this.node.attrs;

    if (align != null) this.dom.style.cssFloat = align;
    else this.dom.removeAttribute('align');
    if (vAlign != null)  this.dom.style.verticalAlign = vAlign;
    else this.dom.style.verticalAlign = '';

    if (border) { this.dom.style.border = `${border}px solid black`; } else { this.dom.style.border = ''; }

    if (width != null) { this.dom.style.width = `${width}px`; } else { this.dom.style.width = ''; }
    if (height != null) { this.dom.style.height = `${height}px`; } else { this.dom.style.height = ''; }

    if (horizontalPadding || verticalPadding) {
      this.dom.style.padding = `${verticalPadding || 0}px ${horizontalPadding || 0}px`;
    } else {
      this.dom.style.padding = '';
    }

    const nodeColor = /color-(\w+)/.exec(this.dom.getAttribute('class'));
    if (nodeColor && nodeColor[1] !== color) this.dom.classList.remove(nodeColor[0]);
    if (color) this.dom.classList.add(`color-${color}`);
  }

  onMediaUpdated({mediaDetails}) {
    if (mediaDetails.getParams() !== this.mediaId) return;
    if (mediaDetails.isLoaded()) {
      let media = mediaDetails.get();
      logProsemirror(`CpmMediaView [${this.id}] :: onMediaUpdated()`, {media}, LogLevels.DEBUG);

      if (this.innerView) this.innerView.destroy();
      this.innerView = null;
      this.dom.innerHTML = '';

      switch(media.type) {
        case MediaTypes.IMAGE.name:
          this.dom.setAttribute('class', 'c3po-media c3po-media-image');
          this.innerView = new ImageMediaView(this, media);
          break;
        case MediaTypes.MATHPIX.name:
          this.dom.setAttribute('class', 'c3po-media c3po-media-mathpix');
          this.innerView = new MathpixMediaView(this, media);
          break;
        case MediaTypes.FORMULA.name:
          this.dom.setAttribute('class', 'c3po-media c3po-media-formula');
          this.innerView = new FormulaMediaView(this, media);
          break;
        case MediaTypes.MATHML.name:
          this.dom.setAttribute('class', 'c3po-media c3po-media-mathml');
          this.innerView = new MathMLMediaView(this, media);
          break;
        default:
          this.dom.setAttribute('class', 'c3po-media c3po-media-unknown');
          this.dom.innerHTML = `Unknown media type "${media.type}" for media <b>${media.id}</b>`;
          this.innerView = null;
          break;
      }
      this.setStyleAttributes();
    } else if (mediaDetails.isError()) {
      logProsemirror(`CpmMediaView [${this.id}] : Redux error`, {
        error: mediaDetails.get()
      }, LogLevels.ERROR);
      this.dom.setAttribute('class', 'c3po-media error');
      this.dom.innerHTML = `Error fetching media <b>${mediaDetails.getParams()}</b>`;
      this.innerView = null;
    }
  }

  selectNode() {
    this.dom.classList.add('ProseMirror-selectednode');
  }
  deselectNode() {
    this.dom.classList.remove('ProseMirror-selectednode');
  }

  update(node) {
    if (node.type.name !== 'media') return false;
    logProsemirror(`UPDATE CpmMediaView [${this.id}]`, {node}, LogLevels.DEBUG);

    const nextId = node.attrs['media-id'];
    if (this.mediaId !== nextId) {
      if (this.innerView) this.innerView.destroy();
      this.innerView = null;

      this.mediaId = nextId;
      this.clientObserver.setProps({mediaId: nextId});
    }

    this.node = node;
    this.setStyleAttributes();
    return true;
  }

  destroy() {
    logProsemirror(`DESTROY CpmMediaView [${this.id}]`, null, LogLevels.INFO);
    if (this.innerView) this.innerView.destroy();
    this.innerView = null;
    if (this.clientObserver) {
      this.clientObserver.unsubscribe();
      delete this.clientObserver;
    }
  }

  stopEvent() { return false; }
  ignoreMutation() { return true; }
}
