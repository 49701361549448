import React from 'react';
import {useSelector} from "react-redux";

import {Typography, Paper} from "@material-ui/core";

import {ViewportRoot, ViewportSection} from "components/layout";
import {SectionDetailsTable} from "components/section/SectionDetailsTable";
import {SectionBreadcrumb} from "components/common/Breadcrumb";
import {SectionElementsTable} from 'components/section/SectionElementsTable';
import {SectionTabbedViewsTable} from 'components/section/SectionTabbedViewsTable';

import {createNavRouteSelector, navRoutes} from "reducers/nav";

const getNavSection = createNavRouteSelector(navRoutes.SECTION);
const SectionDetails = () => {
  const sectionId = useSelector(getNavSection);

  return (
    <ViewportRoot>
      <ViewportSection>
        <SectionBreadcrumb sectionId={sectionId} />
        <Typography variant="h3" gutterBottom>Section Information</Typography>
        <Paper><SectionDetailsTable sectionId={sectionId} /></Paper>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Elements</Typography>
        <Paper><SectionElementsTable sectionId={sectionId} /></Paper>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Tabbed Views</Typography>
        <Paper><SectionTabbedViewsTable sectionId={sectionId} /></Paper>
      </ViewportSection>
    </ViewportRoot>
  );
};

export {SectionDetails as SectionDetailsView}
