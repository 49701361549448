import {chainCommands, setBlockType} from "prosemirror-commands";
import {CellSelection, isInTable, selectionCell} from "prosemirror-tables";
import {NodeSelection} from "prosemirror-state";

import {commonAncestor, findNodesInRange, matchTypes} from "./helpers";
import {indentList} from "./list";
import {setAttributes} from "./base";
import {getAttribute, getMarkAttribute} from "./readers";

export const getTextBlockType = state => {
  const $ancestor = commonAncestor(state.selection.$from,state.selection.$to);
  if ($ancestor && $ancestor.nodeAfter.isTextblock) {
    if ($ancestor.nodeAfter.type.name === 'heading') {
      return 'h' + $ancestor.nodeAfter.attrs.level;
    }
    return $ancestor.nodeAfter.type.name;
  }
  return null;
};

export const setTextBlockType = type => {
  let fn;
  return (state, dispatch) => {
    if (!fn) {
      const {nodes} = state.schema;
      switch (type) {
        case 'h1': fn = setBlockType(nodes.heading,{level:1}); break;
        case 'h2': fn = setBlockType(nodes.heading,{level:2}); break;
        case 'h3': fn = setBlockType(nodes.heading,{level:3}); break;
        case 'h4': fn = setBlockType(nodes.heading,{level:4}); break;
        case 'h5': fn = setBlockType(nodes.heading,{level:5}); break;
        case 'h6': fn = setBlockType(nodes.heading,{level:6}); break;
        default: fn = setBlockType(nodes[type]);
      }
    }
    return fn(state, dispatch);
  };
};

const setCellAlignment = (tr, node, pos, alignment) => {
  tr.setNodeMarkup(pos, null, {...node.attrs, alignment});
  const $from = tr.doc.resolve(pos);
  const $to = tr.doc.resolve(pos + node.nodeSize);
  const paragraphs = findNodesInRange($from, $to, isParagraphOrHeading);
  paragraphs.forEach($node => {
    tr.setNodeMarkup($node.pos, null, {...$node.nodeAfter.attrs, alignment})
  });
};
export const setAlignment = alignment => {
  let isParagraphOrHeading;
  return (state, dispatch) => {
    if (!isParagraphOrHeading) {
      const {nodes} = state.schema;
      isParagraphOrHeading = matchTypes([nodes.paragraph,nodes.heading]);
    }
    if (isInTable(state)) {
      let $cell = selectionCell(state);
      if (dispatch) {
        const {tr} = state;
        if (state.selection instanceof CellSelection)
          state.selection.forEachCell((node, pos) => setCellAlignment(tr, node, pos, alignment));
        else {
          setCellAlignment(tr, $cell.nodeAfter, $cell.pos, alignment);
        }
        dispatch(tr);
      }
      return true;
    } else {
      const {$from, $to} = state.selection;
      const paragraphs = findNodesInRange($from, $to, isParagraphOrHeading);
      if (dispatch) {
        const {tr} = state;
        paragraphs.forEach($node => {
          tr.setNodeMarkup($node.pos, null, {...$node.nodeAfter.attrs, alignment})
        });
        dispatch(tr);
      }
      return paragraphs.length > 0;
    }
  };
};

const canIndent = node => node.type.attrs.indent;
export const indent = amount => (state, dispatch) => {
  const {$from, $to} = state.selection;
  if (indentList(amount)(state,dispatch)) return true;

  let nodes;
  if (state.selection.node && canIndent(state.selection.node)) {
    nodes = [state.selection.$from];
  } else {
    nodes = findNodesInRange($from, $to, canIndent);
  }
  if (nodes.length === 0) return false;
  const topLevel = nodes.reduce((min, next) => Math.min(min, next.depth), nodes[0].depth);
  nodes = nodes.filter(n => n.depth === topLevel);
  if (nodes.some($node => $node.nodeAfter.attrs.indent + amount < 0)) return false;
  if (dispatch) {
    const {tr} = state;
    nodes.forEach($node => {
      const {attrs} = $node.nodeAfter;
      tr.setNodeMarkup($node.pos, null, {...attrs, indent: attrs.indent + amount});
    });
    if (state.selection.node) tr.setSelection(NodeSelection.create(tr.doc,$from.pos));
    dispatch(tr);
  }
  return true;
};

const HIGHLIGHT_NODES = ['paragraph', 'homeworkHint', 'media'];
export const getHighlight = (() => {
  const getMarkHighlight = getMarkAttribute('highlight', 'color');
  const getNodeHighlight = getAttribute(HIGHLIGHT_NODES, 'color');
  return (state, dispatch) => getMarkHighlight(state, dispatch) || getNodeHighlight(state, dispatch);
})();

export const setHighlight = color => {
  const setNodeHighlight = setAttributes(HIGHLIGHT_NODES, {color});
  return (state, dispatch) => {
    const {schema, tr, selection: {node, $cursor, from, to}} = state;
    if (node || $cursor) return setNodeHighlight(state, dispatch);
    if (dispatch) {
      tr.removeMark(from, to, schema.marks.highlight);
      if (color) tr.addMark(from,to, schema.marks.highlight.create({color}));
      dispatch(tr);
    }
    return true;
  };
}
