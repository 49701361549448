import { apiClient } from 'constants/Clients';
import PagedTable, { invalidateState } from "../../util/pagedTable";
import {createNavRouteSelector, navRoutes} from "../../nav";

const getNavStandard = createNavRouteSelector(navRoutes.STANDARD);
const StandardLessonsTable = PagedTable(
  'standardLessons',
  state => apiClient.getClassificationStandardsLessons(getNavStandard(state)),
  (state, action) => {
    return state;
  }
);

export default StandardLessonsTable;