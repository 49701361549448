import React from "react";
import {IconButton} from "@material-ui/core";

import IconSave from "@material-ui/icons/Save";
import IconEdit from "@material-ui/icons/Edit";
import IconCancel from '@material-ui/icons/Cancel';

export const EditModeToggle = React.memo(({editMode, pendingChanges, onClick, disabled, ...innerProps}) => (
  <IconButton onClick={onClick} disabled={disabled} {...innerProps}>{
    !editMode ? <IconEdit fontSize="small"/> :
      pendingChanges ? <IconSave fontSize="small"/> :
        <IconCancel fontSize="small"/>
  }</IconButton>
));