import React, {useCallback, useMemo} from 'react';

import {useClientHook} from "reducers/client";
import {chapterDetails, updateChapter} from "reducers/client/requestTypes";

import {detailsTable} from "components/common/DetailsTable";
import {formatDate} from "constants/Date";
import {LinearProgress} from "@material-ui/core";

const InternalDetailsTable = detailsTable([
  {key: 'name', name: "Name"},
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
]);
InternalDetailsTable.displayName = "ChapterDetailsTableInternal";

const chapterDetailsHook = chapterDetails();
const updateChapterHook = updateChapter();
export const ChapterDetailsTable = ({chapterId}) => {
  const chapterDetails = useClientHook(chapterDetailsHook, chapterId);
  const updateChapter = useClientHook(updateChapterHook, chapterId);

  const handleSave = useCallback(state => {
    updateChapter.sendRequest({id: chapterId, ...state}).then(() => chapterDetails.sendRequest());
  }, [updateChapter, chapterDetails, chapterId]);

  const waiting = useMemo(
    () => chapterDetails.isLoading() || updateChapter.isLoading(),
    [chapterDetails, updateChapter]
  );

  return (
    <React.Fragment>
      {waiting && <LinearProgress />}
      <InternalDetailsTable
        initialState={chapterDetails.get()}
        onSave={handleSave}
        disabled={waiting}
      />
    </React.Fragment>
  )
};