import React, {useCallback} from "react";
import classNames from 'classnames';

import {useProsemirror} from "prosemirror/components/ProsemirrorProvider";
import {toggleCellBorders} from "prosemirror/commands/table";
import {BORDERS} from "prosemirror/constants/table";
import {MenuExpand} from "./MenuExpand";
import {MenuOption} from "./MenuOption";

const borderOptions = [
  BORDERS.ALL,
  BORDERS.INSIDE,
  BORDERS.OUTSIDE,
  BORDERS.TOP,
  BORDERS.HORIZONTAL,
  BORDERS.BOTTOM,
  BORDERS.LEFT,
  BORDERS.VERTICAL,
  BORDERS.RIGHT
];

const BorderIcon = ({className, lines, ...props}) => (
  <div className={classNames("ProseMirror-MenuTable-BorderIcon", className)} {...props}>
    <div className={classNames("ProseMirror-MenuTable-BorderBox", {
      "ProseMirror-MenuTable-BorderBox-Left": lines.left,
      "ProseMirror-MenuTable-BorderBox-Top": lines.top,
    })} />
    <div className={classNames("ProseMirror-MenuTable-BorderBox", {
      "ProseMirror-MenuTable-BorderBox-Left": lines.center,
      "ProseMirror-MenuTable-BorderBox-Right": lines.right,
      "ProseMirror-MenuTable-BorderBox-Top": lines.top,
    })} />
    <div className={classNames("ProseMirror-MenuTable-BorderBox", {
      "ProseMirror-MenuTable-BorderBox-Left": lines.left,
      "ProseMirror-MenuTable-BorderBox-Top": lines.middle,
      "ProseMirror-MenuTable-BorderBox-Bottom": lines.bottom,
    })} />
    <div className={classNames("ProseMirror-MenuTable-BorderBox", {
      "ProseMirror-MenuTable-BorderBox-Left": lines.center,
      "ProseMirror-MenuTable-BorderBox-Right": lines.right,
      "ProseMirror-MenuTable-BorderBox-Top": lines.middle,
      "ProseMirror-MenuTable-BorderBox-Bottom": lines.bottom,
    })} />
  </div>
);
const allBordersIcon = <BorderIcon className="ProseMirror-Menu-Dropdown-Fix" lines={BORDERS.ALL.lines} />;

export const MenuTableBorder = props => {
  const prosemirror = useProsemirror();

  const handleOptionClick = useCallback((ev, value) => {
    ev.preventDefault();
    prosemirror.execute(toggleCellBorders(BORDERS[value]));
  }, [prosemirror]);

  return (
    <MenuExpand {...props} icon={allBordersIcon} columns={3}>
      {borderOptions.map(o => (
        <MenuOption key={o.id} onClick={handleOptionClick} option={{value:o.id}}>
          <BorderIcon lines={o.lines} />
        </MenuOption>
      ))}
    </MenuExpand>
  );
};
