import React, {useCallback, useEffect, useMemo, useRef} from "react";
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from "react-redux";
import classNames from "classnames";
import {LinearProgress, makeStyles} from "@material-ui/core";

import {ProsemirrorProvider} from "prosemirror/components/ProsemirrorProvider";
import {ProsemirrorMenu} from "prosemirror/components/ProsemirrorMenu";
import {ProsemirrorView} from "prosemirror/components/ProsemirrorView";
import {handlers} from 'prosemirror/connectors';

import {displayModes, sidebarModes} from "constants/Prosemirror";

import {
  ProsemirrorEditModeToggle,
  ProsemirrorSidebarMenu,
  ProsemirrorSnackbar,
  ProsemirrorDisplayModeToggle
} from "components/editor/controls";

import {FlexColumns, Flex} from "components/layout";
import {MarkupPreview, ProsemirrorPreview} from "components/editor/previews";
import WcagReport from "components/editor/sidebars/WcagReport";
import {ContentVersionProperties} from "components/editor/sidebars/ContentVersionProperties";
import * as dialogs from 'components/Dialogs/Prosemirror';

import {useClientHook} from "reducers/client";
import {elementDetails} from "reducers/client/requestTypes";
import {setContentVersionProperties, setElementId} from "reducers/Prosemirror";
import {Prompt} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  pane: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: `0 ${4}px`,
    overflow: 'hidden',
    transition: 'width 0.3s cubic-bezier(0, 0, 0.2, 1)',
    border: `${1}px solid #dddddd`,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    backgroundColor: '#ffffff',
  },
  paneTitle: {
    flex: `0 0 ${48}px`,
    lineHeight: `${48}px`,
    textAlign: 'center',
    fontSize: '18pt',
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    backgroundColor: '#f8f8f8',
    boxShadow: `0 ${2}px ${4}px 0 rgba(0,0,0,0.2)`,
    zIndex: 1,
    verticalAlign: 'middle',
  },
  previewBox: {
    padding: `${16}px`,
    overflowY: 'scroll',
  },
}), {name: "ElementEditor"});

const getOptionsFromElement = element => ({
  html: element.currentVersion.html,
  markup: element.currentVersion.markup,
  handlers
});

const elementDetailsHook = elementDetails();
const ElementEditor = ({elementId, menu, previewProps, displayMode: ownDisplayMode, dialogs}) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const {
    docChanged,
    editMode,
    displayMode,
    sidebarMode,
    elementId: prosemirrorElementId,
  } = useSelector(state => state.prosemirror);
  const elementDetails = useClientHook(elementDetailsHook, elementId);

  const initPm = useRef();
  const pm = useRef();

  initPm.current = useCallback(() => {
    if (pm.current && !pm.current.error && elementDetails.isLoaded()) {
      pm.current.init(getOptionsFromElement(elementDetails.get()));
      dispatch(setElementId(elementId));
      dispatch(setContentVersionProperties(elementDetails.get().currentVersion));
    }
  }, [elementDetails, elementId]);

  let ref = useCallback((prosemirror) => {
    if (!pm.current || !pm.current.error) {
      pm.current = prosemirror;
      if (prosemirror) initPm.current();
    }
  }, []);

  useEffect(() => {
    if (pm.current && !pm.current.error && elementDetails.isLoaded()) {
      if (prosemirrorElementId !== elementId) {
        pm.current.init(getOptionsFromElement(elementDetails.get()));
        dispatch(setElementId(elementId));
        dispatch(setContentVersionProperties(elementDetails.get().currentVersion));
      } else {
        pm.current.backgroundInit(getOptionsFromElement(elementDetails.get()));
        dispatch(setContentVersionProperties(elementDetails.get().currentVersion));
      }
    }
  }, [elementDetails]);

  const displayClass = (ownDisplayMode && ownDisplayMode.className) || displayModes[displayMode].className;

  if (pm.current && pm.current.error) {
    return (
      <div>
        An error occurred while loading the editor:
        <div>
          <span>{pm.current.error.name}</span> :: <span>{pm.current.error.message}</span>
        </div>
        Please try loading a previous version.
      </div>
    )
  } else {
    return (<ProsemirrorProvider ref={ref}>
      <Prompt
        when={docChanged}
        message="You have unsaved changes on this page. Are you sure you want to leave? Your changes will not be saved."
      />
      <div className={classes.root}>
        <div className={classes.pane}>
          <FlexColumns className={classes.paneTitle}>
            <Flex align="left">
              <ProsemirrorEditModeToggle />
              {!ownDisplayMode && <ProsemirrorDisplayModeToggle />}
            </Flex>
            <Flex align="right">
              <ProsemirrorSidebarMenu />
            </Flex>
          </FlexColumns>
          {editMode ? (
            <React.Fragment>
              <ProsemirrorMenu>{menu}</ProsemirrorMenu>
              {elementDetails.isLoading() && <LinearProgress />}
              <ProsemirrorView contentClassName={displayClass} disabled={elementDetails.isLoading()} />
            </React.Fragment>
          ) : (
            <ProsemirrorPreview
              className={classNames(classes.previewBox, displayClass)}
              {...previewProps}
            />
          )}
        </div>
        {sidebarMode === sidebarModes.PROPERTIES.id && <div className={classes.pane}>
          <div className={classes.paneTitle}>Properties</div>
          <ContentVersionProperties/>
        </div>}
        {sidebarMode === sidebarModes.MARKUP.id && <div className={classes.pane}>
          <div className={classes.paneTitle}>Markup</div>
          <div className={classNames(classes.previewBox)}>
            <MarkupPreview/>
          </div>
        </div>}
        {sidebarMode === sidebarModes.WCAG.id && <div className={classes.pane}>
          <div className={classes.paneTitle}>WCAG 2.0 Compliance Status</div>
          <WcagReport />
        </div>}
        {/*{sidebarMode === sidebarModes.HISTORY.id && <div>*/}
        {/*  /!*<WithFetch request={contentRequest}>{this.renderHistory}</WithFetch>*!/*/}
        {/*  <h6>This view is not yet implemented.</h6>*/}
        {/*</div>}*/}
        {/*{sidebarMode === sidebarModes.COMPLIANCE.id && <div>*/}
        {/*  <h6>This view is not yet implemented.</h6>*/}
        {/*</div>}*/}
        {dialogs}
        <ProsemirrorSnackbar />
      </div>
    </ProsemirrorProvider>);
  }
};
ElementEditor.propTypes = {
  elementId: PropTypes.string,
  menu: PropTypes.node,
  previewProps: PropTypes.shape(ProsemirrorPreview.propTypes),
  displayMode: PropTypes.oneOf(Object.values(displayModes)),
  dialogs: PropTypes.node
};
ElementEditor.defaultProps = {
  dialogs: Object.entries(dialogs).map(([key, Component]) => <Component key={key}/>),
};

export {ElementEditor}
