import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import {useClientHook} from "reducers/client";
import {
  classificationGrades,
  classificationGradeOrder,
  classificationCreateGrade,
  updateGrade,
  deleteGrade
} from "reducers/client/requestTypes";

import {draggableTable} from 'components/common/Table/DraggableTable';
import {formatDate} from "constants/Date";

const InternalTable = draggableTable([
  {key: 'id', hidden: true},
  {key: 'title', name: 'Grade'},
  {key: 'url', name: 'URL'},
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
], {
  draggable: true,
  getRowKey: row => row.id,
  getRowName: row => row.displayName,
});

const gradesHook = classificationGrades();
const gradeOrderHook = classificationGradeOrder();
const createGradeHook = classificationCreateGrade();
const updateGradeHook = updateGrade();
const deleteGradeHook = deleteGrade();
export const ClassificationGradesTable = ({classificationId}) => {
  const history = useHistory();

  const grades = useClientHook(gradesHook, classificationId);
  const gradeOrder = useClientHook(gradeOrderHook, classificationId);
  const createGrade = useClientHook(createGradeHook, classificationId);
  const updateGrade = useClientHook(updateGradeHook);
  const deleteGrade = useClientHook(deleteGradeHook);

  const handleSave = useCallback(grade => {
    if (grade.id) {
      return updateGrade.sendRequest(grade.id, grade)
        .then(() => grades.sendRequest());
    } else {
      return createGrade.sendRequest(grade)
        .then(() => grades.sendRequest());
    }
  }, [grades, updateGrade, createGrade]);
  const handleDelete = useCallback(grade => {
    if (grade.id) {
      return deleteGrade.sendRequest(grade.id)
        .then(() => grades.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [grades, deleteGrade]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/grades/${grades.get()[index].id}/details`);
  }, [history,grades]);
  const handleOrder = useCallback(order => {
    const currentGrades = grades.get();
    gradeOrder.sendRequest(order.map(i => currentGrades[i].id))
      .then(() => grades.sendRequest());
  }, [grades, gradeOrder])

  const isWaiting = useMemo(
    () =>
      grades.isLoading() ||
      createGrade.isLoading() ||
      updateGrade.isLoading() ||
      deleteGrade.isLoading() ||
      gradeOrder.isLoading(),
    [grades, createGrade, updateGrade, deleteGrade, gradeOrder]
  );

  return (
    <InternalTable
      initialState={grades.get()}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      onOrder={handleOrder}
      waiting={isWaiting}
    />
  )
};
