import React from 'react';
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";

import {LessonContentContainerEditorView} from "views/lessonContentContainer/LessonContentContainerEditor";
import {LessonContentContainerVersionsView} from "views/lessonContentContainer/LessonContentContainerVersions";

import {lessonContentContainerElements} from "reducers/client/requestTypes";
import {navRoutes, createNavRouteSelector} from "reducers/nav";
import {useElementTypeNav} from "hooks/useElementTypeNav";

const getNavLessonContentContainer = createNavRouteSelector(navRoutes.LESSON_CONTENT_CONTAINER);
const lessonContentContainerElementsHook = lessonContentContainerElements();
export const LessonContentContainerElementTypeRoutes = (props) => {
  const {match: {params: {subRoute, lessonContentContainerId, elementType}}} = props;
  const {redirect, render} = useElementTypeNav(
    lessonContentContainerElementsHook,
    lessonContentContainerId,
    elementType
  );
  if (redirect) {
    return <Redirect to={`/app/lessonContentContainers/${lessonContentContainerId}/${subRoute}/${redirect}`} />;
  }

  switch (subRoute) {
    case 'editor': return <LessonContentContainerEditorView {...props} />;
    case 'versions': return <LessonContentContainerVersionsView {...props} />;
    default: return <Redirect to="/404" />;
  }
};
