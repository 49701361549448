import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from '@material-ui/core';

export default ({link, active, children, ...props}) => {
  let button;
  if (active) {
    button = <Button variant="contained" color="primary" {...props}>{children}</Button>;
  } else {
    button = <Button {...props}>{children}</Button>;
  }

  if (link) {
    return <Link to={link}>{button}</Link>;
  }
  return button;
};