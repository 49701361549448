import {TableCell, TableFooter, TableRow} from "@material-ui/core";
import IconAdd from "@material-ui/icons/Add";
import IconDots from "@material-ui/icons/MoreHoriz";
import React, {useCallback, useMemo} from "react";
import {ActionCreators} from "components/common/Table/reducer";

export const makeTableFooter = options => {
  const {visibleColumns, visibleActions, draggable, TableRowBase} = options;
  // (extra columns for drag handle and actions)
  const totalColumns = visibleColumns.length + (draggable ? 1 : 0) + (visibleActions ? 1 : 0);

  return props => {
    const {classes, state, dispatch, waiting, onSave} = props;
    const {adding, editing} = state;

    const handleClickAdd = useCallback(() => {
      if (adding || editing >= 0 || waiting) return false;
      return dispatch(ActionCreators.setAdding(true));
    }, [dispatch, adding, editing, waiting]);

    return (
      <TableFooter>
        {adding ? (
          <TableRowBase
            isNew
            classes={classes}
            state={state}
            dispatch={dispatch}
            row={state.newRow}
            disabled={waiting}
            onSave={onSave}
          />
        ) : (
          <TableRow hover={editing === -1} onClick={handleClickAdd}>
            <TableCell colSpan={totalColumns} align="center">
              {editing === -1 ? (
                <IconAdd />
              ):(
                <IconDots />
              )}
            </TableCell>
          </TableRow>
        )}
      </TableFooter>
    );
  };
};
