import * as ACTION_TYPES from './actions';

const initialState = {
  active: null
};

export default (state = initialState, {type, payload, metadata: {route} = {}} = {}) => {
  switch (type) {
    case ACTION_TYPES.NAV_UPDATE:
      if (state[route] === payload) return state;
      return {
        ...state,
        [route]: payload,
      };
    case ACTION_TYPES.NAV_SELECT:
      if (state.active === route && state[route] === payload) return state;
      return {
        ...state,
        active: route,
        [route]: payload,
      };
    default: return state;
  }
};