import React, {useCallback, useMemo} from 'react';

import {useClientHook} from "reducers/client";
import {gradeDetails, updateGrade} from "reducers/client/requestTypes";

import {detailsTable} from "components/common/DetailsTable";
import {formatDate} from "constants/Date";
import {LinearProgress} from "@material-ui/core";

const InternalDetailsTable = detailsTable([
  {key: 'title', name: "Grade"},
  {key: 'url', name: "URL"},
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
]);
InternalDetailsTable.displayName = "GradeDetailsTableInternal";

const gradeDetailsHook = gradeDetails();
const updateGradeHook = updateGrade();
export const GradeDetailsTable = ({gradeId}) => {
  const gradeDetails = useClientHook(gradeDetailsHook, gradeId);
  const updateGrade = useClientHook(updateGradeHook, gradeId);

  const handleSave = useCallback(state => {
    updateGrade.sendRequest({id: gradeId, ...state}).then(() => gradeDetails.sendRequest());
  }, [updateGrade, gradeDetails, gradeId]);

  const waiting = useMemo(
    () => gradeDetails.isLoading() || updateGrade.isLoading(),
    [gradeDetails, updateGrade]
  );

  return (
    <React.Fragment>
      {waiting && <LinearProgress />}
      <InternalDetailsTable
        initialState={gradeDetails.get()}
        onSave={handleSave}
        disabled={waiting}
      />
    </React.Fragment>
  )
};