import React from 'react';

import {Paper, withStyles} from "@material-ui/core";
import ArrowBack from '@material-ui/icons/ArrowBack';

import {LessonContentContainerBreadcrumb} from 'components/common/Breadcrumb';
import {ElementVersionsTable} from 'components/element/ElementVersionsTable';
import {ContentPreview} from 'components/editor/previews/ContentPreview';
import {desmos, homework, mathjax} from "prosemirror/processors";

import {defaultElementTypeForContentable} from "constants/ElementTypes";
import {createSelector, withClient} from "reducers/client";
import {
  elementDetails,
  lessonContentContainerDetails,
  lessonContentContainerElements,
} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";

const defaultElementType = defaultElementTypeForContentable.lessonContentContainer;

const gutter = 30;

const styles = theme => ({
  versionsContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  previewPane: {
    minHeight: `${180}px`,
    marginRight: `${gutter}px`,
    padding: `${10}px`,
    flex: 1,
    border: `${1}px solid #dddddd`,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    backgroundColor: '#ffffff'
  },
  listSpacer: {
    flex: `0 ${600}px`
  }
});

class BaseComponent extends React.Component {
  state = {
    selected: null
  }

  showEditor = () => {
    this.props.history.push(`/app/lessonContentContainers/${this.props.lessonContentContainerDetails.getParams()}/editor`);
  };

  getSelectedVersionId() {
    const {selected} = this.state;
    return selected && selected.id;
  }

  handleSelectionChange = selected => {
    this.setState({selected});
  };

  render() {
    const {elementDetails: elementRequest, lessonContentContainerDetails: containerRequest, match, classes} = this.props;

    if (!containerRequest.isLoaded()) {
      return <div />;
    }

    const lessonContentContainerDetails = containerRequest.get();
    return (
      <ViewportRoot>
        <ButtonBox style={{width:600}}>
          <ButtonBoxButton title="back" onClick={this.showEditor}>
            <ArrowBack />
          </ButtonBoxButton>
          <Paper>
            <ElementVersionsTable
              elementId={elementRequest.getParams()}
              onSelectionChange={this.handleSelectionChange}
            />
          </Paper>
        </ButtonBox>
        <h2 className="article-title">{match.params.elementType} '{lessonContentContainerDetails.name}' versions</h2>
        <div>
          <LessonContentContainerBreadcrumb containerId={containerRequest.getParams()} />
        </div>
        <div className={classes.versionsContainer}>
          <div className={classes.previewPane}>
            <ContentPreview contentVersionId={this.getSelectedVersionId()} postProcess={[mathjax, homework, desmos]} />
          </div>
          <div className={classes.listSpacer} />
        </div>
      </ViewportRoot>
    );
  }
}

const StyledComponent = withStyles(styles)(BaseComponent);

const getNavLessonContentContainer = createNavRouteSelector(navRoutes.LESSON_CONTENT_CONTAINER);
const getElementType = (state, props) => props.match.params.elementType || defaultElementType.name;
const getContainerElements = createSelector('lessonContentContainerElements',getNavLessonContentContainer);
export const LessonContentContainerVersionsView = withClient({
  hooks: {
    lessonContentContainerDetails: lessonContentContainerDetails(getNavLessonContentContainer),
    lessonContentContainerElements: lessonContentContainerElements(getNavLessonContentContainer),
    elementDetails: elementDetails((state, props) => {
      const containerElements = getContainerElements(state, props);
      if (containerElements && containerElements.isLoaded()) {
        const elementType = getElementType(state, props);
        const containerElement = containerElements.get().find(e => e.elementType === elementType);
        if (containerElement) return containerElement.id;
      }
      return null;
    }),
  }
})(StyledComponent);
