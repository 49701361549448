import {styled} from "@material-ui/core";

export const ButtonBox = styled('div')(
  ({theme}) => ({
    position: 'fixed',
    top: `${65}px`,
    right: theme.spacing(3),
    zIndex: 5,
    textAlign: "right"
  }),
  {name: 'ButtonBox'}
);
