import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faGripLines,
  faGripLinesVertical,
  faPlus,
  faSquare
} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const table = {
  columnBefore: (<span className="fa-layers fa-fw">
    <FontAwesomeIcon icon={faGripLinesVertical} transform="grow-4 right-8" />
    <FontAwesomeIcon icon={faPlus} transform="shrink-4 left-4" />
  </span>),
  columnAfter: (<span className="fa-layers fa-fw">
    <FontAwesomeIcon icon={faGripLinesVertical} transform="grow-4 left-8" />
    <FontAwesomeIcon icon={faPlus} transform="shrink-4 right-4" />
  </span>),
  rowBefore: (<span className="fa-layers fa-fw">
    <FontAwesomeIcon icon={faGripLines} transform="grow-4 down-8" />
    <FontAwesomeIcon icon={faPlus} transform="shrink-4 up-4" />
  </span>),
  rowAfter: (<span className="fa-layers fa-fw">
    <FontAwesomeIcon icon={faGripLines} transform="grow-4 up-8" />
    <FontAwesomeIcon icon={faPlus} transform="shrink-4 down-4" />
  </span>),
  align: {
    leftTop: (<FontAwesomeIcon icon={faAlignLeft} transform="shrink-7 left-2 up-2" mask={faSquare} />),
    centerTop: (<FontAwesomeIcon icon={faAlignCenter} transform="shrink-7 up-2" mask={faSquare} />),
    rightTop: (<FontAwesomeIcon icon={faAlignRight} transform="shrink-7 right-2 up-2" mask={faSquare} />),
    leftMiddle: (<FontAwesomeIcon icon={faAlignLeft} transform="shrink-7 left-2" mask={faSquare} />),
    centerMiddle: (<FontAwesomeIcon icon={faAlignCenter} transform="shrink-7" mask={faSquare} />),
    rightMiddle: (<FontAwesomeIcon icon={faAlignRight} transform="shrink-7 right-2" mask={faSquare} />),
    leftBottom: (<FontAwesomeIcon icon={faAlignLeft} transform="shrink-7 left-2 down-2" mask={faSquare} />),
    centerBottom: (<FontAwesomeIcon icon={faAlignCenter} transform="shrink-7 down-2" mask={faSquare} />),
    rightBottom: (<FontAwesomeIcon icon={faAlignRight} transform="shrink-7 right-2 down-2" mask={faSquare} />),
  }
};
