import React from 'react';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {contentVersionRender} from "reducers/client/requestTypes";
import {HtmlPreview} from "./HtmlPreview";

const contentVersionRenderHook = contentVersionRender();
const ContentPreview = ({contentVersionId, ...innerProps}) => {
  const contentVersionRender = useClientHook(contentVersionRenderHook, contentVersionId);

  if (contentVersionRender.isLoaded()) return <HtmlPreview html={contentVersionRender.get().renderedHtml} {...innerProps} />;
  return null;
};
ContentPreview.propTypes = {
  ...HtmlPreview.propTypes,
  contentVersionId: PropTypes.string,
};

export {ContentPreview}
