import React from 'react';
import {useSelector} from 'react-redux';

import {ViewportRoot, ViewportSection} from "components/layout";
import {BookMenusTable} from 'components/book/BookMenusTable';
import {Paper, Typography} from "@material-ui/core";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {BookBreadcrumb} from "components/common/Breadcrumb";

const getNavBook = createNavRouteSelector(navRoutes.BOOK);
const BookMenuList = () => {
  const bookId = useSelector(getNavBook);
  return (
    <ViewportRoot>
      <ViewportSection>
        <BookBreadcrumb bookId={bookId} />
        <Typography variant="h3" gutterBottom>Book Menus</Typography>
        <Paper><BookMenusTable bookId={bookId} /></Paper>
      </ViewportSection>
    </ViewportRoot>
  );
};

export {BookMenuList as BookMenuListView}