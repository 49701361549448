import React, {useCallback, useRef, useState} from "react";

import MenuItem from "@material-ui/core/MenuItem";

import {useClientHook} from "reducers/client";
import {assessmentProblems, manual} from "reducers/client/requestTypes";

import Autocomplete from '.';

import config from "constants/Config";
const {inputDebounce} = config;


const getSuggestionValue = suggestion => suggestion.assessmentQuestionId.toString();

const renderSuggestion = ({assessmentQuestionId}, {query, isHighlighted}) => (
  <MenuItem selected={isHighlighted} component="div">
    <div>
      {assessmentQuestionId}
    </div>
  </MenuItem>
);

const assessmentProblemsHook = manual(assessmentProblems);
const {request} = assessmentProblemsHook;
assessmentProblemsHook.request = (key, value) => {
  let req = request(key);
  req.limit(5);
  req.setFilter(`assessmentQuestionId::varchar='%${value}%'`);
  req.setSortFields(['assessmentQuestionId']);
  req.setOrderByAsc();
  return req;
};
const AutocompleteAssessmentProblem = ({bookId, ...props}) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const assessmentProblems = useClientHook(assessmentProblemsHook,bookId);

  const pending = useRef(null);

  const handleFetchSuggestions = useCallback(({value}) => {
    if (pending.current) window.clearTimeout(pending.current);
    pending.current = window.setTimeout(() => {
      assessmentProblems.sendRequest(value).then(results => {
        setSuggestions(results);
      });
    }, inputDebounce);
  }, []);
  const handleClearSuggestions = () => {
    if (pending.current) {
      window.clearTimeout(pending.current);
      pending.current = null;
    }
    assessmentProblems.clear();
    setSuggestions([]);
  };
  const handleChange = useCallback(
    (ev, {newValue}) => setValue(newValue),
    [setValue]
  );

  return (<Autocomplete
    value={value}
    waiting={assessmentProblems.isLoading()}
    suggestions={suggestions}
    onSuggestionsFetchRequested={handleFetchSuggestions}
    onSuggestionsClearRequested={handleClearSuggestions}
    onChange={handleChange}
    getSuggestionValue={getSuggestionValue}
    renderSuggestion={renderSuggestion}
    {...props}
  />);
};

export default AutocompleteAssessmentProblem;