import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {
  chapterAssessmentGroups,
  chapterCreateAssessmentGroup,
  updateAssessmentGroup,
  deleteAssessmentGroup
} from "reducers/client/requestTypes";

import {pagedTable} from 'components/common/Table/PagedTable';
import {formatDate} from "constants/Date";
import {getElementTypeByName, getValidElementTypesForContentable} from "constants/ElementTypes";
import {paging} from "reducers/table";

const InternalTable = pagedTable([
  {key: 'id', hidden: true},
  {key: 'name', sort: 'name', name: 'Name'},
  // {key: 'createdAt', sort: 'createdAt,title', name: "Created", render: formatDate, readOnly: true},
  // {key: 'updatedAt', sort: 'updatedAt,title', name: "Last Updated", render: formatDate, readOnly: true},
], {
  table: 'ChapterAssessmentGroupsTable',
  initialState: {
    searchColumns: ['name']
  },
  getRowKey: row => row.id,
  getRowName: row => row.name,
});

const chapterAssessmentGroupsHook = paging('ChapterAssessmentGroupsTable')(chapterAssessmentGroups((state, props) => props.chapterId));
const chapterCreateAssessmentGroupHook = chapterCreateAssessmentGroup();
const updateAssessmentGroupHook = updateAssessmentGroup();
const deleteAssessmentGroupHook = deleteAssessmentGroup();
const ChapterAssessmentGroupsTable = (props) => {
  const [totalCount, setTotalCount] = useState(0);
  const [tablePage, setTablePage] = useState([]);
  const history = useHistory();

  const clientKey = useCallback(selector => selector(props), [props]);
  const chapterAssessmentGroups = useClientHook(chapterAssessmentGroupsHook, clientKey);
  const chapterCreateAssessmentGroup = useClientHook(chapterCreateAssessmentGroupHook, props.chapterId);
  const updateAssessmentGroup = useClientHook(updateAssessmentGroupHook);
  const deleteAssessmentGroup = useClientHook(deleteAssessmentGroupHook);

  const handleSave = useCallback(assessmentGroup => {
    if (assessmentGroup.id) {
      return updateAssessmentGroup.sendRequest(assessmentGroup.id, assessmentGroup)
        .then(() => chapterAssessmentGroups.sendRequest());
    } else {
      return chapterCreateAssessmentGroup.sendRequest(assessmentGroup)
        .then(() => chapterAssessmentGroups.sendRequest());
    }
  }, [chapterAssessmentGroups, updateAssessmentGroup, chapterCreateAssessmentGroup]);
  const handleDelete = useCallback(assessmentGroup => {
    if (assessmentGroup.id) {
      return deleteAssessmentGroup.sendRequest(assessmentGroup.id)
        .then(() => chapterAssessmentGroups.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [chapterAssessmentGroups, deleteAssessmentGroup]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/assessmentGroups/${tablePage[index].id}/details`);
  }, [history,tablePage]);

  const isWaiting = useMemo(
    () =>
      chapterAssessmentGroups.isLoading() ||
      chapterCreateAssessmentGroup.isLoading() ||
      updateAssessmentGroup.isLoading() ||
      deleteAssessmentGroup.isLoading(),
    [chapterAssessmentGroups, chapterCreateAssessmentGroup, updateAssessmentGroup, deleteAssessmentGroup]
  );

  useEffect(() => {
    if (chapterAssessmentGroups.isLoaded()) {
      const {totalCount, results} = chapterAssessmentGroups.get();
      setTotalCount(totalCount);
      setTablePage(results);
    }
  }, [chapterAssessmentGroups, setTotalCount, setTablePage]);

  return (
    <InternalTable
      page={tablePage}
      totalCount={totalCount}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      waiting={isWaiting}
    />
  )
};
ChapterAssessmentGroupsTable.propTypes = {
  chapterId: PropTypes.string.isRequired,
}

export {ChapterAssessmentGroupsTable}
