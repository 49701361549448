import {createSelector} from "reselect";
import {combineReducers} from 'redux';

import {apiClient} from '../constants/Clients';
import ClientRequest, {RequestState} from "./util/clientRequest";

// ACTION TYPES
export const BOOK_LIST = 'BOOK_LIST';

// CHILD REDUCERS
const list = ClientRequest(BOOK_LIST);

// ACTION CREATORS
export function requestBookList() {
  const req = apiClient.getBooks();
  req.limit(9999);
  return list.sendRequest(req);
}

// SELECTORS
const getBookListRequest = state => state.books.list;
export const getBookList = createSelector([getBookListRequest], books => new RequestState(1, books));

export default combineReducers({
  list,
});
