import React from 'react';

// Material
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";

// Icons
import IconHistory from '@material-ui/icons/History';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconImage from '@material-ui/icons/Image';

// Styles
import withStyles from '@material-ui/core/styles/withStyles';

// Components
import { ElementBreadcrumb } from 'components/common/Breadcrumb';
import PublishStatusIndicator from 'components/editor/PublishStatusIndicator';

// Constants
import config from "constants/Config";
const {color} = config;
import {displayModes} from "constants/Prosemirror";

// Actions
import {desmos, mathjax} from 'prosemirror/processors';
// Client
import {withClient} from "reducers/client";
import {elementDetails} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ElementEditor as Editor} from "components/editor/ElementEditor";
import {LessonMenu} from "prosemirror/menu";

const sideNavWidth = 75;
const collapsedWidth = 64;
const headerHeight = 60;
const footerHeight = 43;
const gutter = 30;

const styles = theme => ({
  root: {
    position: 'absolute',
    left: `${sideNavWidth}px`,
    top: `${headerHeight}px`,
    right: `${0}px`,
    bottom: `${footerHeight}px`,
    paddingLeft: `${gutter}px`,
    paddingRight: `${gutter}px`,
    paddingBottom: `${20}px`,
    display: 'flex',
    flexDirection: 'column',
    transition: 'left 0.3s cubic-bezier(0, 0, 0.2, 1)',
    '.nav-collapsed &': {
      left: `${collapsedWidth}px`,
    }
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    marginTop: `${30}px`,
    flexShrink: 0,
  },
  title: {
    margin: `0 ${theme.spacing(1)}px`,
    color: theme.palette.primary.main,
  },
  buttonBox: {
    position: 'fixed',
    top: `${65}px`,
    right: `${20}px`
  },
  button: {
    margin: theme.spacing(1),
  }
});

class ElementEditor extends React.Component {
  showElementImages = () => {
    const {elementDetails} = this.props;
    this.props.history.push(`/app/elements/${elementDetails.getParams()}/media`);
  };

  showElementVersions = () => {
    const {elementDetails} = this.props;
    if (elementDetails.isLoaded()) {
      this.props.history.push(`/app/elements/${elementDetails.get().id}/versions`);
    }
  };

  showElementAccessibility = () => {
    const {elementDetails} = this.props;
    if (elementDetails.isLoaded()) {
      this.props.history.push(`/app/elements/${elementDetails.get().id}/accessibility`);
    }
  };

  render() {
    const {
      classes,
      elementDetails,
    } = this.props;

    let selectedElementId = elementDetails.getParams();
    let contentVersionId = elementDetails.isLoaded() ? elementDetails.get().currentVersionId : '';

    return (
      <div className={classes.root}>
        <div className={classes.buttonBox}>
          <PublishStatusIndicator contentVersionId={contentVersionId} />
          <Tooltip title="Images Overview">
            <Fab color="primary" className={classes.button}>
              <IconImage onClick={this.showElementImages} />
            </Fab>
          </Tooltip>
          <Tooltip title="Version History">
            <Fab color="primary" className={classes.button}>
              <IconHistory onClick={this.showElementVersions} />
            </Fab>
          </Tooltip>
          <Tooltip title="Show Accessibility Compliance">
            <Fab color="primary" className={classes.button}>
              <IconCheckCircle onClick={this.showElementAccessibility} />
            </Fab>
          </Tooltip>
        </div>
        <div className={classes.navigation}>
          <Typography className={classes.title} variant="h6">Element Editor</Typography>
          <ElementBreadcrumb elementId={selectedElementId} />
        </div>
        <Editor
          elementId={selectedElementId}
          menu={LessonMenu}
          previewProps={{ postProcess: [mathjax, desmos] }}
          displayMode={displayModes.DISPLAY_EBOOKS}
        />
      </div>);
  }
}

const getNavElement = createNavRouteSelector(navRoutes.ELEMENT);
export const ElementEditorView = withClient({
  hooks: {
    elementDetails: elementDetails(getNavElement),
  }
})(
  withStyles(styles)(ElementEditor)
);
