import {selectionInside} from './readers';
import {findNodesInRange, matchTypes} from "./helpers";
import {TextSelection} from "prosemirror-state";
import {Slice} from 'prosemirror-model';

export const selectionColumn = state => {
  const {selection: {$anchor, $head}, schema} = state;
  const [$column] = findNodesInRange($anchor, $head, matchTypes(schema.nodes.column));
  return $column;
};
export const selectionColumnSet = state => {
  const $column = selectionColumn(state);
  if ($column) return state.doc.resolve($column.before($column.depth));
};

export const getColumnAlignment = state => {
  const $column = selectionColumn(state);
  if ($column) {
    const {alignment, vAlign} = $column.nodeAfter.attrs;
    return `${alignment}/${vAlign}`;
  }
  return '';
};

export const setColumnAlignment = options => {
  const [alignment, vAlign] = options.split('/');
  return (state, dispatch) => {
    const {tr,selection,schema} = state;
    if (!selectionInside(schema.nodes.columnSet)) return false;
    if (dispatch) {
      const columns = findNodesInRange(selection.$anchor, selection.$head, matchTypes(schema.nodes.column));
      columns.forEach($column => {
        tr.setNodeMarkup($column.pos, null, {
          ...$column.nodeAfter.attrs,
          alignment,
          vAlign,
        });
      });
      dispatch(tr);
    }
    return true;
  };
};

export const getColumnBreakpoint = state => {
  const $column = selectionColumn(state);
  if ($column) {
    return $column.nodeAfter.attrs.breakpoint;
  }
  return '';
};

export const setColumnBreakpoint = breakpoint => (state, dispatch) => {
  const {tr} = state;
  const $set = selectionColumnSet(state);
  if (!$set) return false;
  if (dispatch) {
    const offset = $set.start($set.depth+1);
    $set.nodeAfter.forEach((node, pos) => {
      tr.setNodeMarkup(offset + pos, null, {
        ...node.attrs,
        breakpoint,
      });
    });
    dispatch(tr);
  }
  return true;
};

export const insertColumnSet = columnWidths => (state, dispatch) => {
  const {tr, schema: {nodes}} = state;

  const columns = columnWidths.map(width => nodes.column.createAndFill({width}));
  const columnSet = nodes.columnSet.createAndFill(null, columns);

  if (dispatch) {
    dispatch(tr.replaceSelectionWith(columnSet));
  }
  return true;
};

export const updateColumnSet = (columnWidths, pos) => (state, dispatch) => false;

export const removeColumnSet = (state, dispatch) => {
  const {tr} = state;
  const $set = selectionColumnSet(state);
  if ($set) {
    let {anchor, head} = state.selection;
    --anchor; --head; // Offset selection for the Column Set itself
    let contents;
    $set.nodeAfter.forEach((node, offset, index) => {
      // Grab contents from inside column
      if (contents) contents = contents.append(node.content);
      else contents = node.content;
      // Offset selection so that it is preserved
      if ($set.pos + offset < anchor) --anchor;
      if ($set.pos + offset < head) -- head;
    });
    const finalContents = new Slice(contents,0,0);
    const above = $set.parent;
    const index = $set.index();
    if (!above.canReplace(index,index,finalContents)) return false;
    const from = $set.pos;
    const to = $set.pos + $set.nodeAfter.nodeSize;
    if (dispatch) {
      tr.replace(from, to, finalContents);
      tr.setSelection(TextSelection.create(tr.doc, anchor, head));
      dispatch(tr.scrollIntoView());
    }
    return true;
  }
  return false;
};
