import React from 'react';

import {
  Paper,
  Typography,
  Button,
  Fab,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Zoom,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';

import IconAdd from '@material-ui/icons/Add';
import IconSave from "@material-ui/icons/Save";
import IconCancel from "@material-ui/icons/Cancel";
import IconUndo from "@material-ui/icons/Undo";

import {BookBreadcrumb} from "components/common/Breadcrumb";
import StylesheetEntry from 'components/Stylesheet/StylesheetEntry';
import {GoogleFontCache, withFont} from "components/GoogleFonts/Cache";
import {withClient} from "reducers/client";
import {bookDetails, bookUpdateStyleSheet, bookRevertStyleSheet} from "reducers/client/requestTypes";

import {StyleSelectorTypes} from 'constants/StyleTypes';
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ViewportRoot, ViewportSection} from "components/layout";

const styles = theme => ({
  formControl: {
    minWidth: `${200}px`,
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  floatingButton: {
    position: 'fixed',
    right: theme.spacing(1),
    bottom: theme.spacing(7),
  },
  bottomMargin: {
    height: theme.spacing(2),
  }
});

const FontTest = withFont('ZCOOL KuaiLe',{text: ' Thissometext'})(props => (
  <span style={{fontFamily:'"ZCOOL KuaiLe"'}}>This is some text</span>
));

class BookStylesheet extends React.Component {
  state = {
    styles: [],
    expanded: -1,
    newSelector: null,
    pendingChanges: false,
  };

  componentDidMount() {
    const {bookDetails:bookRequest} = this.props;
    if (bookRequest.isLoaded()) {
      if (bookRequest.get().styleSheet) {
        this.initStyleSheet(bookRequest.get().styleSheet.new);
      }
    }
  }
  componentDidUpdate(prevProps) {
    const {bookDetails:bookRequest} = this.props;
    const {bookDetails:prevRequest} = prevProps;

    if (bookRequest.isLoaded() && bookRequest.hasChanged(prevRequest)) {
      if (bookRequest.get().styleSheet) {
        this.initStyleSheet(bookRequest.get().styleSheet.new);
      }
    }
  }
  initStyleSheet(styleSheet) {
    const styles = [];
    for (let selector in styleSheet) {
      if (styleSheet.hasOwnProperty(selector)) styles.push({selector, declaration: styleSheet[selector]});
    }
    this.setState({styles});
  }
  commitStyleSheet(styles) {
    const styleSheet = {};
    styles.forEach(s => {
      styleSheet[s.selector] = s.declaration;
    });
    return styleSheet;
  }

  handleExpand(index) {
    const {expanded} = this.state;
    if (expanded === index) this.setState({expanded: -1});
    else this.setState({expanded: index});
  }
  handleChange(styleData) {
    const {styles} = this.state;
    const index = styles.findIndex(s => s.selector === styleData.selector);
    console.log('handleChange()',styleData);
    this.setState({
      styles: styles.slice(0,index).concat(styleData, styles.slice(index + 1)),
      pendingChanges: true,
    });
  }
  handleDelete(index) {
    const {styles} = this.state;
    this.setState({
      expanded: -1,
      styles: styles.slice(0,index).concat(styles.slice(index + 1)),
      pendingChanges: true,
    });
  }
  handleAddSelector() {
    this.setState({newSelector: ''});
  }
  handleChangeSelectorType(type) {
    this.setState({newSelector: type});
  }
  handleConfirmAddSelector() {
    const {newSelector} = this.state;
    if (newSelector !== '') {
      this.setState({
        styles: this.state.styles.concat({selector: newSelector, declaration: {}}),
        newSelector: null,
        pendingChanges: true,
      });
    } else {
      this.setState({newSelector: null});
    }
  }
  handleSave() {
    const {bookDetails, bookUpdateStyleSheet} = this.props;
    const {styles} = this.state;

    bookUpdateStyleSheet.sendRequest(this.commitStyleSheet(styles))
      .then(() => {
        this.setState({pendingChanges: false});
        return bookDetails.sendRequest();
      });
  }
  handleRevert() {
    const {bookDetails, bookRevertStyleSheet} = this.props;

    bookRevertStyleSheet.sendRequest()
      .then(() => {
        this.setState({pendingChanges: false});
        return bookDetails.sendRequest();
      });
  }

  render() {
    const {classes, bookDetails:bookRequest, bookUpdateStyleSheet, bookRevertStyleSheet} = this.props;
    const {styles, newSelector, expanded, pendingChanges} = this.state;

    if (!bookRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Book details...</h2>
      </div>);
    }

    let unusedSelectors = StyleSelectorTypes.filter(t => !styles.some(s => s.selector === t.selector));

    return (
      <ViewportRoot>
        <GoogleFontCache>
          <ViewportSection>
            <BookBreadcrumb bookId={bookRequest.get().id} />
            <Typography variant="h3">
              Stylesheet Editor
              {bookRequest.get().styleSheet && bookRequest.get().styleSheet.old && (
                <IconButton onClick={() => this.handleRevert()}>
                  {bookRevertStyleSheet.isLoading() ? <CircularProgress size={24} /> : <IconUndo />}
                </IconButton>
              )}
            </Typography>
            <Typography variant="subtitle1">
              {bookRequest.get().displayName}
            </Typography>
          </ViewportSection>
          <ViewportSection>
            {styles.map((s,i) => (
              <StylesheetEntry
                key={s.selector}
                data={s}
                expanded={expanded === i}
                onExpand={() => this.handleExpand(i)}
                onChange={(data) => this.handleChange(data)}
                onDelete={() => this.handleDelete(i)}
              />
            ))}
          </ViewportSection>
          <ViewportSection>
            {unusedSelectors.length > 0 && (<Paper>
              {newSelector !== null ? (
                <div className="row">
                  <div className="col-sm-6">
                    <FormControl className={classes.formControl}>
                      <InputLabel>Select Style Type</InputLabel>
                      <Select
                        value={newSelector}
                        onChange={ev => this.handleChangeSelectorType(ev.target.value)}
                      >
                        <MenuItem value="">---</MenuItem>
                        {unusedSelectors.map(type => (
                          <MenuItem key={type.selector} value={type.selector}>{type.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-sm-6" style={{textAlign:'right'}}>
                    <IconButton className={classes.button} onClick={() => this.handleConfirmAddSelector()}>
                      {newSelector !== '' ? (
                        <IconSave fontSize="small"/>
                      ):(
                        <IconCancel fontSize="small"/>
                      )}
                    </IconButton>
                  </div>
                </div>
              ) : (
                <Button fullWidth onClick={() => this.handleAddSelector()}><IconAdd /></Button>
              )}
            </Paper>)}
          </ViewportSection>
          <div className={classes.bottomMargin} />
          <Zoom in={pendingChanges} unmountOnExit>
            <Fab className={classes.floatingButton} color="primary" onClick={() => this.handleSave()}>
              {bookUpdateStyleSheet.isLoading() ? <CircularProgress color="secondary" /> : <IconSave />}
            </Fab>
          </Zoom>
        </GoogleFontCache>
      </ViewportRoot>
    );
  }
}

const getNavBook = createNavRouteSelector(navRoutes.BOOK);
export const BookStylesheetView = withClient({
  hooks: {
    bookDetails: bookDetails(getNavBook),
    bookUpdateStyleSheet: bookUpdateStyleSheet(getNavBook),
    bookRevertStyleSheet: bookRevertStyleSheet(getNavBook),
  },
})(withStyles(styles)(BookStylesheet));
