import React from 'react';
import classNames from 'classnames';
import {useProsemirror} from "prosemirror/components/ProsemirrorProvider";

export const MarkupPreview = (className, ...innerProps) => {
  const prosemirror = useProsemirror();
  return (
    <div className={classNames(className, 'content-preview')} style={{fontFamily:'monospace'}} {...innerProps}>
      {JSON.stringify(prosemirror.state.doc)}
    </div>
  );
};
