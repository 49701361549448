import React from 'react';
import {createStyles, makeStyles} from "@material-ui/core";

const sideNavWidth = 250;
const collapsedWidth = 64;
const headerHeight = 60;
const footerHeight = 43;

const useStyles = makeStyles(theme => createStyles({
  root: {
    position: 'absolute',
    left: `${sideNavWidth}px`,
    top: `${headerHeight}px`,
    right: `${0}px`,
    bottom: `${footerHeight}px`,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    transition: 'left 0.3s cubic-bezier(0, 0, 0.2, 1)',
    '.nav-collapsed &': {
      left: `${collapsedWidth}px`,
    }
  }
}), {name: "EditorViewport"});

export const EditorViewport = props => {
  const classes = useStyles();
  return <div {...props} className={classes.root} />;
};
