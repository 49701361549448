import React from "react";
import PropTypes from "prop-types";
import {useProsemirror} from "prosemirror/components/ProsemirrorProvider";

const MenuGroup = ({visible, children}) => {
  const prosemirror = useProsemirror();
  return (prosemirror && (!visible || visible(prosemirror.state))) && (
    <span className="ProseMirror-MenuGroup">{children}</span>
  );
};
MenuGroup.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.func
};

export {MenuGroup}
