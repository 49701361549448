import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {
  lessonElements,
  lessonCreateElement,
  updateElement,
  deleteElement
} from "reducers/client/requestTypes";

import {pagedTable} from 'components/common/Table/PagedTable';
import {select} from "components/common/Table/TableControls";
import {formatDate} from "constants/Date";
import {getElementTypeByName, getValidElementTypesForContentable} from "constants/ElementTypes";
import {paging} from "reducers/table";

const elementTypes = getValidElementTypesForContentable('lesson');

const renderElementType = elementTypeName => {
  const elementType = getElementTypeByName(elementTypeName);
  return (elementType && elementType.displayName) || elementTypeName;
};

const InternalTable = pagedTable([
  {key: 'id', hidden: true},
  {
    key: 'elementType',
    sort: 'elementType',
    name: 'Type',
    Editor: select(elementTypes.map(type => ({value:type.name, name: type.displayName || type.name}))),
    render: renderElementType
  },
  {key: 'title', sort: 'title', name: 'Title'},
  {key: 'description', sort: 'description', name: 'Description', optional: true},
  {key: 'createdAt', sort: 'createdAt,title', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', sort: 'updatedAt,title', name: "Last Updated", render: formatDate, readOnly: true},
], {
  table: 'LessonElementsTable',
  initialState: {
    searchColumns: ['title']
  },
  getRowKey: row => row.id,
  getRowName: row => renderElementType(row.elementType),
});

const lessonElementsHook = paging('LessonElementsTable')(lessonElements((state, props) => props.lessonId));
const lessonCreateElementHook = lessonCreateElement();
const updateElementHook = updateElement();
const deleteElementHook = deleteElement();
const LessonElementsTable = (props) => {
  const [totalCount, setTotalCount] = useState(0);
  const [tablePage, setTablePage] = useState([]);
  const history = useHistory();

  const clientKey = useCallback(selector => selector(props), [props]);
  const lessonElements = useClientHook(lessonElementsHook, clientKey);
  const lessonCreateElement = useClientHook(lessonCreateElementHook, props.lessonId);
  const updateElement = useClientHook(updateElementHook);
  const deleteElement = useClientHook(deleteElementHook);

  const handleSave = useCallback(element => {
    if (element.id) {
      return updateElement.sendRequest(element.id, element)
        .then(() => lessonElements.sendRequest());
    } else {
      return lessonCreateElement.sendRequest(element)
        .then(() => lessonElements.sendRequest());
    }
  }, [lessonElements, updateElement, lessonCreateElement]);
  const handleDelete = useCallback(element => {
    if (element.id) {
      return deleteElement.sendRequest(element.id)
        .then(() => lessonElements.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [lessonElements, deleteElement]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/lessons/${props.lessonId}/editor/${tablePage[index].id}`);
  }, [history,tablePage]);

  const isWaiting = useMemo(
    () =>
      lessonElements.isLoading() ||
      lessonCreateElement.isLoading() ||
      updateElement.isLoading() ||
      deleteElement.isLoading(),
    [lessonElements, lessonCreateElement, updateElement, deleteElement]
  );

  useEffect(() => {
    if (lessonElements.isLoaded()) {
      const {totalCount, results} = lessonElements.get();
      setTotalCount(totalCount);
      setTablePage(results);
    }
  }, [lessonElements, setTotalCount, setTablePage]);

  return (
    <InternalTable
      page={tablePage}
      totalCount={totalCount}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      waiting={isWaiting}
    />
  )
};
LessonElementsTable.propTypes = {
  lessonId: PropTypes.string.isRequired,
}

export {LessonElementsTable}
