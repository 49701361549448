import {Form, Field, reduxForm} from "redux-form";
import {renderSelect} from "../../../common/Forms/fields";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";

const LinkDialogForm = () =>  (<form>
  <Field name="target" type="text" component={renderSelect} label="Target">
    <MenuItem value="_self">Self</MenuItem>
    <MenuItem value="_blank">Blank (new tab/window)</MenuItem>
    {/*<MenuItem value="_parent">Parent</MenuItem>*/}
    {/*<MenuItem value="_top">Top</MenuItem>*/}
  </Field>
</form>);

export default reduxForm({
  form: 'linkDialog'
})(LinkDialogForm);
