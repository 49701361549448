import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {containerRender, createContainerKey} from "reducers/client/requestTypes";
import {HtmlPreview} from "./HtmlPreview";

import {getElementTypeByName} from "constants/ElementTypes";

const containerRenderHook = containerRender();
const ContainerPreview = ({containerId, elementType, ...innerProps}) => {
  const ebooksTypeNumber = useMemo(
    () => getElementTypeByName(elementType).ebooksTypeNumber,
    [elementType]
  );
  const key = createContainerKey({id: containerId, ebooksTypeNumber});
  const contentVersionRender = useClientHook(containerRenderHook, key);

  if (contentVersionRender.isLoaded()) {
    return <HtmlPreview html={contentVersionRender.get().renderedHtml} {...innerProps} />;
  }
  return null;
};
ContainerPreview.propTypes = {
  ...HtmlPreview.propTypes,
  containerId: PropTypes.string,
  elementType: PropTypes.string,
};

export {ContainerPreview}
