import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {
  sectionElements,
  sectionCreateElement,
  updateElement,
  deleteElement
} from "reducers/client/requestTypes";

import {pagedTable} from 'components/common/Table/PagedTable';
import {ElementLinks, select} from "components/common/Table/TableControls";
import {formatDate} from "constants/Date";
import ElementTypes, {getElementTypeByName} from "constants/ElementTypes";
import {asArray} from "constants/util/map";
import {paging} from "reducers/table";

const elementTypes = asArray(ElementTypes);

const renderElementType = elementTypeName => {
  const elementType = getElementTypeByName(elementTypeName);
  return (elementType && elementType.displayName) || elementTypeName;
};

const InternalTable = pagedTable([
  {key: 'id', hidden: true},
  {
    key: 'elementType',
    sort: 'elementType',
    name: 'Type',
    Editor: select(elementTypes.map(type => ({value:type.name, name: type.displayName || type.name}))),
    render: renderElementType
  },
  {key: 'title', sort: 'title', name: 'Title'},
  {key: 'description', sort: 'description', name: 'Description', optional: true},
  {key: 'createdAt', sort: 'createdAt,title', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', sort: 'updatedAt,title', name: "Last Updated", render: formatDate, readOnly: true},
  {
    key: 'links',
    name: '',
    get: element => element.id,
    Editor: ({value}) => value,
    render: elementId => <ElementLinks elementId={elementId} />,
    cellProps: {
      padding: 'none'
    },
    readOnly: true
  }
], {
  table: 'SectionElementsTable',
  initialState: {
    searchColumns: ['title']
  },
  getRowKey: row => row.id,
  getRowName: row => row.title,
});

const sectionElementsHook = paging('SectionElementsTable')(sectionElements((state, props) => props.sectionId));
const sectionCreateElementHook = sectionCreateElement();
const updateElementHook = updateElement();
const deleteElementHook = deleteElement();
const SectionElementsTable = (props) => {
  const [totalCount, setTotalCount] = useState(0);
  const [tablePage, setTablePage] = useState([]);
  const history = useHistory();

  const clientKey = useCallback(selector => selector(props), [props]);
  const sectionElements = useClientHook(sectionElementsHook, clientKey);
  const sectionCreateElement = useClientHook(sectionCreateElementHook, props.sectionId);
  const updateElement = useClientHook(updateElementHook);
  const deleteElement = useClientHook(deleteElementHook);

  const handleSave = useCallback(element => {
    if (element.id) {
      return updateElement.sendRequest(element.id, element)
        .then(() => sectionElements.sendRequest());
    } else {
      return sectionCreateElement.sendRequest(element)
        .then(() => sectionElements.sendRequest());
    }
  }, [sectionElements, updateElement, sectionCreateElement]);
  const handleDelete = useCallback(element => {
    if (element.id) {
      return deleteElement.sendRequest(element.id)
        .then(() => sectionElements.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [sectionElements, deleteElement]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/sections/${props.sectionId}/editor/${tablePage[index].elementType}`);
  }, [history,tablePage]);

  const isWaiting = useMemo(
    () =>
      sectionElements.isLoading() ||
      sectionCreateElement.isLoading() ||
      updateElement.isLoading() ||
      deleteElement.isLoading(),
    [sectionElements, sectionCreateElement, updateElement, deleteElement]
  );

  useEffect(() => {
    if (sectionElements.isLoaded()) {
      const {totalCount, results} = sectionElements.get();
      setTotalCount(totalCount);
      setTablePage(results);
    }
  }, [sectionElements, setTotalCount, setTablePage]);

  return (
    <InternalTable
      page={tablePage}
      totalCount={totalCount}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      waiting={isWaiting}
    />
  )
};
SectionElementsTable.propTypes = {
  sectionId: PropTypes.string.isRequired,
}

export {SectionElementsTable}
