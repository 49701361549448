import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {styled, withStyles} from "@material-ui/core";

const FlexColumns = styled(({noShrink, ...props}) => <div {...props} />)(
  {
    display: 'flex',
    alignItems: 'center',
    flexShrink: props => props.noShrink ? 0 : undefined
  },
  {name: "FlexColumns"}
);
FlexColumns.propTypes = {
  noShrink: PropTypes.bool,
};

const flexStyles = {
  left: {
    display: 'flex',
    flex: 1,
    textAlign: 'left',
  },
  center: {
    flex: 1,
    textAlign: 'center',
  },
  right: {
    flex: 1,
    textAlign: 'right',
  },
  noShrink: {
    flexShrink: 0,
  },
};
const Flex = withStyles(flexStyles, {name:"Flex"})(({Component, classes, className, align, noShrink, ...props}) => {
  const aggregateClass = classNames(className, {
    [classes.left]: align === 'left',
    [classes.center]: align === 'center',
    [classes.right]: align === 'right',
    [classes.noShrink]: noShrink,
  })

  return <Component className={aggregateClass} {...props} />
});
Flex.propTypes = {
  Component: PropTypes.elementType,
  align: PropTypes.oneOf(['left','center','right']),
  noShrink: PropTypes.bool,
};
Flex.defaultProps = {
  Component: 'div'
};

export {
  Flex,
  FlexColumns
}