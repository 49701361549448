import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Select,
  MenuItem
} from "@material-ui/core";

import {updateContentVersionProperties} from "reducers/Prosemirror";

import {AllLanguages} from "constants/Language";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  },
  progress: {
    flexShrink: 0,
  },
  container: {
    flexGrow: 1,
    overflowY: 'scroll',
    padding: theme.spacing(1),
  }
}), {name: "ContentVersionProperties"});

const ContentVersionProperties = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const {
    language
  } = useSelector(state => state.prosemirror.contentVersionProperties);

  const handleChangeLanguage = ev => {
    dispatch(updateContentVersionProperties({language: ev.target.value}))
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <List>
          <ListItem>
            <ListItemText>
              Language
            </ListItemText>
            <ListItemSecondaryAction>
              <Select
                value={language || ''}
                onChange={handleChangeLanguage}
              >
                {AllLanguages.map(lang => (<MenuItem key={lang.value} value={lang.value}>
                  {lang.name}
                </MenuItem>))}
              </Select>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </div>
    </div>
  );

}

export {ContentVersionProperties}