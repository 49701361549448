import React from 'react';
import {useSelector} from "react-redux";

import {Typography, Paper} from "@material-ui/core";

import {ViewportRoot, ViewportSection} from "components/layout";
import {CategoryDetailsTable} from "components/category/CategoryDetailsTable";
import {CategoryBreadcrumb} from "components/common/Breadcrumb";
import {CategoryBooksTable} from 'components/category/CategoryBooksTable';

import {createNavRouteSelector, navRoutes} from "reducers/nav";

const getNavCategory = createNavRouteSelector(navRoutes.CATEGORY);
const CategoryDetails = () => {
  const categoryId = useSelector(getNavCategory);

  return (
    <ViewportRoot>
      <ViewportSection>
        <CategoryBreadcrumb categoryId={categoryId} />
        <Typography variant="h3" gutterBottom>Category Information</Typography>
        <Paper><CategoryDetailsTable categoryId={categoryId} /></Paper>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Books</Typography>
        <Paper><CategoryBooksTable categoryId={categoryId} /></Paper>
      </ViewportSection>
    </ViewportRoot>
  );
};

export {CategoryDetails as CategoryDetailsView}
