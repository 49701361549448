import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';

import {getElementTypeByName} from "constants/ElementTypes";
import {MergeInformationTableRow} from "./MergeInformationTableRow";

const useStyles = makeStyles(theme => ({
  parentReference: {
    display: 'block',
    marginTop: '8px',
    padding: '6px 16px',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    borderRadius: '4px',
    backgroundColor: '#e0e0e0',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    '&:first-child': {
      marginTop: 0
    }
  },
  parentReferenceDisabled: {
    color: '#b0b0b0',
    display: 'block',
    marginTop: '8px',
    padding: '6px 16px',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.07), 0px 3px 1px -2px rgba(0,0,0,0.06)',
    borderRadius: '4px',
    backgroundColor: '#f0f0f0',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    '&:first-child': {
      marginTop: 0
    }
  }
}), {name: "MergeInformationTable"});

const MergeInformationTable = props => {
  const {
    selection,
    mainId,
    mergeId,
    mainElements,
    mergeElements,
    ReferenceComponent
  } = props;
  const classes = useStyles(props);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Element Type</TableCell>
          <TableCell>Group 1</TableCell>
          <TableCell padding="none" />
          <TableCell>Group 2</TableCell>
          <TableCell>Using</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(selection).map(k => {
          const elementType = getElementTypeByName(k);
          const mainElement = mainElements.find(e => e.elementType === k);
          const mergeElement = mergeElements.find(e => e.elementType === k);
          const className = selection[k] ? classes.parentReference : classes.parentReferenceDisabled;

          return (
            <MergeInformationTableRow
              key={k}
              ReferenceComponent={ReferenceComponent}
              referenceClass={className}
              mainId={mainId}
              mergeId={mergeId}
              mainElement={mainElement}
              mergeElement={mergeElement}
              elementTypeName={elementType ? elementType.displayName || elementType.name : k}
              selected={selection[k]}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

let ElementPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  elementType: PropTypes.string.isRequired
});

MergeInformationTable.propTypes = {
  mainId: PropTypes.string.isRequired,
  mergeId: PropTypes.string.isRequired,
  mainElements: PropTypes.arrayOf(ElementPropType).isRequired,
  mergeElements: PropTypes.arrayOf(ElementPropType).isRequired,
  selection: PropTypes.objectOf(PropTypes.string).isRequired,
  ReferenceComponent: PropTypes.elementType.isRequired,
};

export {MergeInformationTable}
