import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {LessonContentContainerElementTypeRoutes} from "./elementType";
import {LessonContentContainerEditorView} from "views/lessonContentContainer/LessonContentContainerEditor";
import {LessonContentContainerVersionsView} from "views/lessonContentContainer/LessonContentContainerVersions";
import {LessonContentContainerMergeView} from "views/lessonContentContainer/LessonContentContainerMerge";
import {LessonContentContainerMergeHistoryView} from "views/lessonContentContainer/LessonContentContainerMergeHistory";
import {LessonContentContainerAccessibilityView} from "views/lessonContentContainer/LessonContentContainerAccessibility";

import {navRoutes, useNav} from "reducers/nav";

export const LessonContentContainerRoutes = ({match: {url, path, params: {lessonContentContainerId}}}) => {
  const selectedLessonContentContainer = useNav(navRoutes.LESSON_CONTENT_CONTAINER, lessonContentContainerId);
  if (!selectedLessonContentContainer) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/editor`} />
      <Route path={`${path}/mergeHistory`} component={LessonContentContainerMergeHistoryView} />
      <Route path={`${path}/merge`} component={LessonContentContainerMergeView} />
      <Route path={`${path}/accessibility`} component={LessonContentContainerAccessibilityView} />
      <Route path={`${path}/:subRoute/:elementType`} component={LessonContentContainerElementTypeRoutes} />
      <Route path={`${path}/:subRoute`} component={LessonContentContainerElementTypeRoutes} />
      <Redirect to="/404" />
    </Switch>
  );
};
