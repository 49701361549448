import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import {useClientHook} from "reducers/client";
import {
  bookSections,
  bookSectionOrder,
  bookCreateSection,
  updateSection,
  deleteSection
} from "reducers/client/requestTypes";

import {draggableTable} from 'components/common/Table/DraggableTable';
import {formatDate} from "constants/Date";

const InternalTable = draggableTable([
  {key: 'id', hidden: true},
  {key: 'name', name: 'Name'},
  {key: 'type', name: 'Type'},
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
], {
  draggable: true,
  getRowKey: row => row.id,
  getRowName: row => row.type,
});

const sectionsHook = bookSections();
const sectionOrderHook = bookSectionOrder();
const createSectionHook = bookCreateSection();
const updateSectionHook = updateSection();
const deleteSectionHook = deleteSection();
export const BookSectionsTable = ({bookId}) => {
  const history = useHistory();

  const sections = useClientHook(sectionsHook, bookId);
  const sectionOrder = useClientHook(sectionOrderHook, bookId);
  const createSection = useClientHook(createSectionHook, bookId);
  const updateSection = useClientHook(updateSectionHook);
  const deleteSection = useClientHook(deleteSectionHook);

  const handleSave = useCallback(section => {
    if (section.id) {
      return updateSection.sendRequest(section.id, section)
        .then(() => sections.sendRequest());
    } else {
      return createSection.sendRequest(section)
        .then(() => sections.sendRequest());
    }
  }, [sections, updateSection, createSection]);
  const handleDelete = useCallback(section => {
    if (section.id) {
      return deleteSection.sendRequest(section.id)
        .then(() => sections.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [sections, deleteSection]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/sections/${sections.get()[index].id}/details`);
  }, [history,sections]);
  const handleOrder = useCallback(order => {
    const currentSections = sections.get();
    sectionOrder.sendRequest(order.map(i => currentSections[i].id))
      .then(() => sections.sendRequest());
  }, [sections, sectionOrder])

  const isWaiting = useMemo(
    () =>
      sections.isLoading() ||
      createSection.isLoading() ||
      updateSection.isLoading() ||
      deleteSection.isLoading() ||
      sectionOrder.isLoading(),
    [sections, createSection, updateSection, deleteSection, sectionOrder]
  );

  return (
    <InternalTable
      initialState={sections.get()}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      onOrder={handleOrder}
      waiting={isWaiting}
    />
  )
};
