import {onDoubleClick} from "util/onDoubleClick";
import MathJax from 'MathJax';
import {promptMathquill} from "prosemirror/commands";
import {LogLevels, logProsemirror} from "prosemirror/log";

export class FormulaMediaView {
  constructor(outerView, media) {
    this.outerView = outerView;
    this.media = media;

    const latex = media.latex;
    logProsemirror(`CONSTRUCT CpmMediaView [${this.outerView.id}]::FormulaMediaView`, {
      latex
    }, LogLevels.INFO);

    this.dom = document.createElement('script');
    this.dom.setAttribute('type','math/tex');
    this.dom.innerText = latex;
    this.outerView.dom.appendChild(this.dom);

    this.offDoubleClick = onDoubleClick(this.outerView.dom, () => {
      this.outerView.execute(promptMathquill, latex);
    });

    window.requestAnimationFrame(() => MathJax.Hub.Queue(["Typeset",MathJax.Hub,this.dom]));
  }

  destroy() {
    const math = MathJax.Hub.getAllJax(this.outerView.dom);
    logProsemirror(`DESTROY CpmMediaView [${this.outerView.id}]::FormulaMediaView`, {
      math
    }, LogLevels.INFO);
    math.forEach(jax => MathJax.Hub.Queue(["Remove",jax]));

    this.offDoubleClick();
    this.outerView.dom.removeChild(this.dom);
  }
}