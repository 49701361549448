import React from "react";
import {Field, reduxForm} from "redux-form";

import {renderField} from "components/common/Forms/fields";

const SaveElementDialogForm = () => {
  return (<form>
    <Field
      name="submitterComment"
      component={renderField}
      label="Comment"
      placeholder="Please include a comment when you submit"
      fullWidth
      multiline
    />
  </form>);
};

export default reduxForm({
  form: 'saveElementDialog'
})(SaveElementDialogForm);
