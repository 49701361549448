import {useRef, useCallback} from "react";
import config from "constants/Config";
const {inputDebounce} = config;

export default (callback, {cacheProps = (...args) => args, delay = inputDebounce}) => {
  const pending = useRef();
  return useCallback((...args) => {
    let props = cacheProps(...args);
    if (pending.current) window.clearTimeout(pending.current);
    pending.current = window.setTimeout(() => callback(...props),delay);
  },[callback]);
}