export const PROSEMIRROR_COLUMNS = 'prosemirror-columns';
export const PROSEMIRROR_DESMOS = 'prosemirror-desmos';
export const PROSEMIRROR_EMBED = 'prosemirror-embed';
export const PROSEMIRROR_HOMEWORK = 'prosemirror-homework';
export const PROSEMIRROR_IMAGE = 'prosemirror-image';
export const PROSEMIRROR_LESSON_CONTENT_CONTAINER = 'prosemirror-lessonContentContainer';
export const PROSEMIRROR_LESSON_TITLE_IMAGE = 'prosemirror-lessonTitleImage';
export const PROSEMIRROR_LINK = 'prosemirror-link';
export const PROSEMIRROR_GLOSSARY_LINK = 'prosemirror-glossaryLink';
export const PROSEMIRROR_MATHML = 'prosemirror-mathml';
export const PROSEMIRROR_MATHPIX = 'prosemirror-mathpix';
export const PROSEMIRROR_MATHQUILL = 'prosemirror-mathquill';
export const PROSEMIRROR_SAVE = 'prosemirror-save';
export const PROSEMIRROR_TABLE = 'prosemirror-table';

export const MERGE_LESSON_CONTENT_CONTAINERS_CONFIRMATION = 'merge-lessonContentContainer-confirmation';
export const MERGE_GLOSSARY_ITEMS_CONFIRMATION = 'merge-glossaryItem-confirmation';
export const MERGE_SECTIONS_CONFIRMATION = 'merge-section-confirmation';
export const MERGE_TABS_CONFIRMATION = 'merge-tab-confirmation';

export const LESSON_CONTENT_CONTAINER_ADD_ELEMENT = 'lessonContentContainer-addElement';
export const LESSON_CONTENT_CONTAINER_LINK = 'lessonContentContainer-link';

export const ASSESSMENT_PROBLEM_LINK = 'assessmentProblem-link';

export const CATEGORY_TRANSFER_BOOK = 'category-transferBook';
