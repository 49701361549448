import {apiClient} from 'constants/Clients';

import PagedTable, {invalidateState, resetState} from "../../util/pagedTable";
import {CLIENT_REQUEST_SUCCESS} from "../../util/clientRequest";
import {DIALOG_IMAGE_OPEN, DIALOG_IMAGE_UPLOAD} from '../../LegacyDialogs/Image';

const ImageBrowser = PagedTable('imageBrowser',
  () => apiClient.getMedias(),
  (state, action) => {
    if (action) {
      if (action.type === DIALOG_IMAGE_OPEN) {
        return resetState(state);
      }
      if (action.type === CLIENT_REQUEST_SUCCESS && action.requestType === DIALOG_IMAGE_UPLOAD) {
        return invalidateState(state);
      }
    }
    return state;
  }
);

export default ImageBrowser;