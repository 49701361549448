import React, {useCallback, useRef, useState} from "react";

import MenuItem from "@material-ui/core/MenuItem";

import {useClientHook} from "reducers/client";
import {bookGlossaryItems, categories, manual} from "reducers/client/requestTypes";

import Autocomplete from '.';

import config from "constants/Config";
const {inputDebounce} = config;


const getSuggestionValue = suggestion => suggestion.displayName;

const renderSuggestion = ({displayName}, {query, isHighlighted}) => (
  <MenuItem selected={isHighlighted} component="div">
    <div>
      {displayName}
    </div>
  </MenuItem>
);

// export default AutocompleteBook;

const categoriesHook = manual(categories);
const {request} = categoriesHook;
categoriesHook.request = (key, value) => {
  let req = request(key);
  req.limit(5);
  req.setFilter(`displayName='%${value}%' OR shortName='%${value}%'`);
  req.setSortFields(['displayName']);
  req.setOrderByAsc();
  return req;
};
const AutocompleteCategory = props => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const categories = useClientHook(categoriesHook);

  const pending = useRef(null);

  const handleFetchSuggestions = useCallback(({value}) => {
    if (pending.current) window.clearTimeout(pending.current);
    pending.current = window.setTimeout(() => {
      categories.sendRequest(value).then(results => {
        setSuggestions(results);
      });
    }, inputDebounce);
  }, []);
  const handleClearSuggestions = () => {
    if (pending.current) {
      window.clearTimeout(pending.current);
      pending.current = null;
    }
    categories.clear();
    setSuggestions([]);
  };
  const handleChange = useCallback(
    (ev, {newValue}) => setValue(newValue),
    [setValue]
  );

  return (<Autocomplete
    value={value}
    waiting={categories.isLoading()}
    suggestions={suggestions}
    onSuggestionsFetchRequested={handleFetchSuggestions}
    onSuggestionsClearRequested={handleClearSuggestions}
    onChange={handleChange}
    getSuggestionValue={getSuggestionValue}
    renderSuggestion={renderSuggestion}
    {...props}
  />);
};

export default AutocompleteCategory;