import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {SectionDetailsView} from "views/section/SectionDetails";
import {SectionEditorView} from "views/section/SectionEditor";
import {SectionMergeView} from "views/section/SectionMerge";
import {SectionMergeHistoryView} from "views/section/SectionMergeHistory";
import {SectionVersionsView} from "views/section/SectionVersions";
import {SectionAccessibilityView} from "views/section/SectionAccessibility";
import {SectionMediaView} from "views/section/SectionMedia";

import {navRoutes, useNav} from "reducers/nav";

export const SectionRoutes = ({match: {url, path, params: {sectionId}}}) => {
  const selectedSection = useNav(navRoutes.SECTION, sectionId);
  if (!selectedSection) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={SectionDetailsView}/>
      <Route path={`${path}/editor/:elementType`} component={SectionEditorView} />
      <Route path={`${path}/editor`} component={SectionEditorView} />
      <Route path={`${path}/versions/:elementType`} component={SectionVersionsView} />
      <Route path={`${path}/versions`} component={SectionVersionsView} />
      <Route path={`${path}/mergeHistory`} component={SectionMergeHistoryView} />
      <Route path={`${path}/merge`} component={SectionMergeView} />
      <Route path={`${path}/accessibility`} component={SectionAccessibilityView} />
      <Route path={`${path}/media/:elementType`} component={SectionMediaView} />
      <Route path={`${path}/media`} component={SectionMediaView} />
      <Redirect to="/404" />
    </Switch>
  );
};
