// Helper for iterating through the nodes in a document that changed
// compared to the given previous document. Useful for avoiding
// duplicate work on each transaction.
// DIRECTLY COPIED FROM PROSEMIRROR-TABLES
// I don't really know how this works
function changedDescendants(old, cur, offset, f) {
  let oldSize = old.childCount;
  let curSize = cur.childCount;
  outer: for (let i = 0, j = 0; i < curSize; i++) {
    let child = cur.child(i);
    for (let scan = j, e = Math.min(oldSize, i + 3); scan < e; scan++) {
      if (old.child(scan) === child) {
        j = scan + 1;
        offset += child.nodeSize;
        continue outer;
      }
    }
    f(child, offset);
    if (j < oldSize && old.child(j).sameMarkup(child))
      changedDescendants(old.child(j), child, offset + 1, f);
    else
      child.nodesBetween(0, child.content.size, f, offset + 1);
    offset += child.nodeSize;
  }
}

function isBroken(node) {
  let totalWidth = 0;
  for (let i = 0; i < node.childCount; ++i) {
    let child = node.child(i);
    if (child.type.name !== 'column') return true;
    totalWidth += child.attrs.width;
    if (isNaN(totalWidth) || totalWidth > 12) return true;
  }
  return totalWidth !== 12;
}

export function anyBrokenColumns(state, oldState) {
  let anyBroken = false;
  let check = (node, pos) => {
    if (anyBroken) return false;
    if (node.type.name === "columnSet") {
      anyBroken = isBroken(node);
      return !anyBroken;
    }
    return true;
  };
  if (!oldState) state.doc.descendants(check);
  else if (oldState.doc !== state.doc) changedDescendants(oldState.doc, state.doc, 0, check);
  return anyBroken;
}
