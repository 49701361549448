import React from 'react';
import {getFormValues, getFormSyncErrors} from 'redux-form';
import {connect} from 'react-redux';

import { Button, Dialog, DialogTitle, DialogActions, DialogContent, CircularProgress } from '@material-ui/core';
import IconError from '@material-ui/icons/Error';
import IconCheckCircle from '@material-ui/icons/CheckCircle';

import {
  STATUS_READY,
  STATUS_WAITING,
  STATUS_SUCCESS,
  STATUS_FAILURE
} from 'constants/Dialogs';
import {getValidElementTypesForContentable} from "constants/ElementTypes";

import {dialogs, reduxDialog, createDialogDataSelector} from "reducers/dialog";
import {withClient} from "reducers/client/index";
import {lessonContentContainerElements, lessonContentContainerCreateElement, elementCreateCurrentVersion} from "reducers/client/requestTypes";

import CreateLessonContentContainerElementDialogForm from './form';

const types = getValidElementTypesForContentable('lessonContentContainer');

class CreateLessonContentContainerElementDialogBase extends React.Component {
  handleSubmit = () => {
    const {
      lessonContentContainerElements,
      lessonContentContainerCreateElement,
      elementCreateCurrentVersion,
      dialog: {actions},
      onSubmit,
    } = this.props;
    const {elementType} = this.props.formData;
    actions.setState({status: STATUS_WAITING});
    lessonContentContainerCreateElement.sendRequest({elementType: elementType.name, language: 'English'})
      .then(result => elementCreateCurrentVersion.sendRequest(result.id, {html: ''}))
      .then(() => lessonContentContainerElements.sendRequest())
      .then(() => {
        actions.setState({status: STATUS_SUCCESS});
        if (typeof onSubmit === 'function') return onSubmit();
      })
      .catch(() => actions.setState({status: STATUS_FAILURE}));
  };

  render() {
    const {
      dialog: {isOpen, state: {status}, actions},
      formData,
      formErrors,
      lessonContentContainerElements
    }  = this.props;

    let content = false;
    let allowCancel = true;
    let showSubmit = false;
    let allowSubmit = false;
    let closeText = 'Cancel';

    switch(status) {
      case STATUS_READY:
        if (lessonContentContainerElements.isLoaded()) {
          let elements = lessonContentContainerElements.get();
          let validTypes = types.filter(t => !elements.some(e => e.elementType === t.name));

          content = <CreateLessonContentContainerElementDialogForm
            elementTypes={types}
            initialValues={{elementType: validTypes[0]}}
            blacklist={lessonContentContainerElements.get().map(e => e.elementType)}
          />;
        }
        showSubmit = true;
        allowSubmit = formData && !!formData.elementType && !formErrors.elementType;
        break;
      case STATUS_SUCCESS:
        content = (<div><IconCheckCircle /> Done</div>);
        closeText = 'Ok';
        break;
      case STATUS_WAITING:
        content = (<div style={{textAlign:'center'}}>
          <CircularProgress size={100} />
        </div>);
        allowCancel = false;
        break;
      case STATUS_FAILURE:
        content = (<div>
          <IconError /> An error occurred
        </div>);
        closeText = 'Ok';
        break;
      default:
        break;
    }

    return (<Dialog open={isOpen} maxWidth={'md'} fullWidth onClose={actions.close}>
      <DialogTitle>Add Content to Lesson Content Container</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={actions.close} color="primary" disabled={!allowCancel}>
          {closeText}
        </Button>
        {showSubmit && <Button onClick={this.handleSubmit} color="primary" disabled={!allowSubmit}>
          Submit
        </Button>}
      </DialogActions>
    </Dialog>);
  }
}

const formValuesSelector = getFormValues('createLessonContentContainerElementDialog');
const formErrorsSelector = getFormSyncErrors('createLessonContentContainerElementDialog');
const mapStateToProps = state => ({
  formData: formValuesSelector(state),
  formErrors: formErrorsSelector(state),
});

const getDialogData = createDialogDataSelector(dialogs.LESSON_CONTENT_CONTAINER_ADD_ELEMENT);
const getContainerId = state => {
  const data = getDialogData(state);
  return data && data.containerId;
};
const CreateLessonContentContainerElementDialog = withClient({
  hooks: {
    lessonContentContainerElements: lessonContentContainerElements(getContainerId),
    lessonContentContainerCreateElement: lessonContentContainerCreateElement(getContainerId),
    elementCreateCurrentVersion: elementCreateCurrentVersion(),
  }
})(connect(
  mapStateToProps
)(reduxDialog({
  dialog: dialogs.LESSON_CONTENT_CONTAINER_ADD_ELEMENT,
  initialState: {status: STATUS_READY}
})(CreateLessonContentContainerElementDialogBase)));
CreateLessonContentContainerElementDialog.displayName = 'CreateLessonContentContainerElementDialog';

export {CreateLessonContentContainerElementDialog}