import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {GradeRoutes} from './grade/index';

export const GradesRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.path}/:gradeId`} component={GradeRoutes}/>
    <Redirect to="/404" />
  </Switch>
);
