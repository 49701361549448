import React from "react";
import * as commands from 'prosemirror-tables';

import {
  MenuGroup,
  MenuButton,
  MenuSelect,
  MenuExpand,
  Menu2AxisAlignment,
  MenuTableBorder
} from 'prosemirror/components/menu';

import * as icons from 'prosemirror/icons';
import {getAttribute} from 'prosemirror/commands/readers';
import {setTableAlignment, getCellAlignment, setCellBorderWidth, addColumnBefore, addColumnAfter} from "prosemirror/commands/table";
import {promptTable} from 'prosemirror/commands';

import {asArray} from "constants/util/map";
import Colors from "constants/Colors";
import {BORDER_WIDTHS} from "../constants/table";

const TABLE_COLORS = asArray(Colors).map(c => {
  const color = (c.color && c.color.table) ? c.color.table : c.color;
  return {
    ...c,
    color,
    icon: color ? icons.highlightColor(color) : icons.highlight,
  }
});

// ==================== TABLE PROPERTIES ====================
export const properties = (<MenuButton key="table-properties"
  command={promptTable}
  title="Table Properties">
  {icons.cog}
</MenuButton>);
// ==================== ADD ROWS OR COLUMNS ====================
const columnBefore = (<MenuButton key="table-add-columnBefore"
  enabled={addColumnBefore}
  command={addColumnBefore}
  title="Insert column before">
  {icons.table.columnBefore}
</MenuButton>);
const columnAfter = (<MenuButton key="table-add-columnAfter"
  enabled={addColumnAfter}
  command={addColumnAfter}
  title="Insert column after">
  {icons.table.columnAfter}
</MenuButton>);
const rowBefore = (<MenuButton key="table-add-rowBefore"
  enabled={commands.addRowBefore}
  command={commands.addRowBefore}
  title="Insert row before">
  {icons.table.rowBefore}
</MenuButton>);
const rowAfter = (<MenuButton key="table-add-rowAfter"
  enabled={commands.addRowAfter}
  command={commands.addRowAfter}
  title="Insert row after">
  {icons.table.rowAfter}
</MenuButton>);
export const addCells = {
  columnBefore,
  columnAfter,
  rowBefore,
  rowAfter,
  all: (<MenuExpand key="table-addCells" enabled={commands.addColumnAfter} title="Insert rows/columns" icon={icons.plus}>
    {[columnBefore, columnAfter, rowBefore, rowAfter]}
  </MenuExpand>)
};
// ==================== MERGE CELLS ====================
export const mergeCells = (<MenuButton key="table-mergeCells"
  enabled={commands.mergeCells}
  command={commands.mergeCells}
  title="Merge Cells">
  {icons.compress}
</MenuButton>);
// ==================== SPLIT CELL ====================
export const splitCell = (<MenuButton key="table-splitCell"
  enabled={commands.splitCell}
  command={commands.splitCell}
  title="Split Cell">
  {icons.columns[0]}
</MenuButton>);
// ==================== TOGGLE HEADER ====================
export const toggleHeader = (<MenuButton key="table-toggleHeader"
  enabled={commands.toggleHeaderCell}
  command={commands.toggleHeaderCell}
  title="Toggle Header">
  {icons.h_square}
</MenuButton>);
// ==================== SET ALIGNMENT ====================
export const alignment = (<Menu2AxisAlignment key="table-alignment"
  enabled={commands.isInTable}
  command={alignment => setTableAlignment(alignment)}
  selected={getCellAlignment}
  title="Cell Alignment"
/>);
// ==================== SET BACKGROUND COLOR ====================
export const backgroundColor = (<MenuSelect key="table-backgroundColor"
  enabled={commands.isInTable}
  command={color => commands.setCellAttr('color', color)}
  selected={getAttribute(['table_cell', 'table_header'], 'color')}
  options={TABLE_COLORS}
  title="Background Color"
/>);
// ==================== TOGGLE BORDERS ====================
export const borders = <MenuTableBorder key="table-borders" />;
// ==================== SET BORDER WIDTH ====================
const borderOption = (width, title) => (<MenuButton
  key={width}
  className="ProseMirror-MenuLongButton"
  command={setCellBorderWidth(width)}
  title={title}>
  <span className="ProseMirror-MenuTable-BorderWidthIcon" style={{borderLeftWidth: `${width}px`}}>
    {title}
  </span>
</MenuButton>);
export const borderWidth = (<MenuExpand
  key="table-borderWidth"
  enabled={setCellBorderWidth(1)}
  icon={icons.grip_vertical}
  title="Set Cell Border Width">
  {[
    borderOption(BORDER_WIDTHS.THIN,"Thin"),
    borderOption(BORDER_WIDTHS.MEDIUM,"Medium"),
    borderOption(BORDER_WIDTHS.THICK,"Thick"),
  ]}
</MenuExpand>);

export const TableMenuGroup = props => (
  <MenuGroup {...props} visible={commands.isInTable}>
    {props.children}
  </MenuGroup>
);
