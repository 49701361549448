import { apiClient } from 'constants/Clients';
import PagedTable, { invalidateState } from '../../util/pagedTable';
import {createNavRouteSelector, navRoutes} from "../../nav";

const getNavStandard = createNavRouteSelector(navRoutes.STANDARD);
const StandardLessonContentContainersTable = PagedTable(
  'standardLessonContentContainers',
  state => apiClient.getClassificationStandardsProblems(getNavStandard(state)),
  (state, action) => {
    return state;
  }
);

export default StandardLessonContentContainersTable;