export const ASSESSMENT_GROUP = 'assessmentGroup';
export const ASSESSMENT_PROBLEM = 'assessmentProblem';
export const BOOK = 'book';
export const BOOK_MENU = 'bookMenu';
export const CATEGORY = 'category';
export const CHAPTER = 'chapter';
export const CLASSIFICATION = 'classification';
export const ELEMENT = 'element';
export const ELEMENT_TYPE = 'elementType';
export const GLOSSARY_ITEM = 'glossaryItem';
export const GRADE = 'grade';
export const LESSON = 'lesson';
export const LESSON_CONTENT_CONTAINER = 'lessonContentContainer';
export const MEDIA = 'media';
export const SECTION = 'section';
export const STANDARD = 'standard';
export const TABBED_VIEW = 'tabbedView';
export const TAB = 'tab';
