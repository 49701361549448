import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {BookMenuEditorView} from "views/book/menu/BookMenuEditor";

import {navRoutes, useNav} from "reducers/nav";

export const BookMenuRoutes = ({match: {path, params: {menuID}}}) => {
  const selectedMenu = useNav(navRoutes.BOOK_MENU, menuID);
  if (!selectedMenu) return null;

  return (
    <Switch>
      <Route path={`${path}`} component={BookMenuEditorView} />
      <Redirect to="/404" />
    </Switch>
  );
};
