import React from 'react';

import {MenuButton, MenuSelect} from 'prosemirror/components/menu';

import {
  splitOn,
  toggleBlock,
  toggleMark,
  setAttributes,
  removeMark,
} from "prosemirror/commands";
import {markActive, selectionNotEmpty, selectionInside, getAttribute, or} from 'prosemirror/commands/readers';
import {toggleLink, toggleGlossaryLink} from "prosemirror/commands/link";
import {getHighlight, setHighlight} from "prosemirror/commands/textBlock";
import * as icons from "prosemirror/icons";

import Colors from 'constants/Colors';
import {asArray} from "constants/util/map";
import {MenuBookSpecific} from "../components/menu/MenuBookSpecific";

const HIGHLIGHT_COLORS = asArray(Colors).map(c => {
  const color = (c.color && c.color.highlight) ? c.color.highlight : c.color;
  return {
    ...c,
    color,
    icon: color ? icons.highlightColor(color) : icons.highlight,
  }
});

// ==================== REMOVE FONT ====================
export const font = (<MenuButton key="marks-font"
  active={() => true}
  visible={markActive('font')}
  command={removeMark('font')}
  title="Remove font">
  {icons.remove_font}
</MenuButton>);
// ==================== TOGGLE EM ====================
export const em = (<MenuButton key="marks-em"
  active={markActive('em')}
  command={toggleMark('em')}
  title="Toggle emphasis">
  {icons.em}
</MenuButton>);
// ==================== TOGGLE STRONG ====================
export const strong = (<MenuButton key="marks-strong"
  active={markActive('strong')}
  command={toggleMark('strong')}
  title="Toggle strong">
  {icons.strong}
</MenuButton>);
// ==================== TOGGLE UNDERLINE ====================
export const underline = (<MenuButton key="marks-underline"
  active={markActive('underline')}
  command={toggleMark('underline')}
  title="Toggle underline">
  {icons.underline}
</MenuButton>);
// ==================== TOGGLE LINK ====================
export const link = (<MenuButton key="marks-link"
  active={markActive('link')}
  enabled={or(selectionNotEmpty,markActive('link'))}
  command={toggleLink}
  title="Add or remove link">
  {icons.link}
</MenuButton>);
// ==================== TOGGLE GLOSSARY LINK ====================
export const glossary = (<MenuButton key="marks-glossary"
  active={markActive('glossaryLink')}
  enabled={or(selectionNotEmpty,markActive('glossaryLink'))}
  command={toggleGlossaryLink}
  title="Add or remove glossary link">
  {icons.comment_alt}
</MenuButton>);
// ==================== TOGGLE HIGHLIGHT ====================
const HIGHLIGHT_NODES = ['paragraph', 'homeworkHint', 'media'];
export const highlight = (<MenuSelect key="marks-highlight"
  enabled={selectionInside(HIGHLIGHT_NODES)}
  // command={color => setAttributes(HIGHLIGHT_NODES, {color})}
  command={setHighlight}
  selected={getHighlight}
  options={HIGHLIGHT_COLORS}
  title="Highlight" />);
// ==================== TOGGLE BOX ====================
export const box = (<MenuButton key="marks-box"
  active={markActive('box')}
  command={toggleMark('box')}
  title="Toggle box">
  {icons.square}
</MenuButton>);
// ==================== TOGGLE SCREEN READER ONLY ====================
export const screenReaderOnly = (<MenuButton key="marks-screenReaderOnly"
  active={markActive('markScreenReaderOnly')}
  command={toggleMark('markScreenReaderOnly')}
  title="Toggle screen reader only">
  {icons.eye_slash}
</MenuButton>);
// ==================== TOGGLE BOOK SPECIFIC CONTENT ====================
export const bookSpecific = <MenuBookSpecific key="marks-bookSpecific" />;
// ==================== TOGGLE EBOOKS ONLY (MARK) ====================
export const ebooks = (<MenuButton key="marks-ebooks"
  active={markActive('markEbooksOnly')}
  command={toggleMark('markEbooksOnly')}
  title="Toggle eBooks only content (inline)">
  {icons.book}
</MenuButton>);
// ==================== TOGGLE EBOOKS ONLY (NODE) ====================
export const ebooksNode = (<MenuButton key="marks-ebooksNode"
  active={selectionInside('nodeEbooksOnly')}
  enabled={toggleBlock('nodeEbooksOnly')}
  command={toggleBlock('nodeEbooksOnly')}
  title="Toggle eBooks only content (block)">
  {icons.withPlus(icons.book)}
</MenuButton>);
// ==================== TOGGLE HOMEWORK ONLY (MARK) ====================
export const homework = (<MenuButton key="marks-homework"
  active={markActive('markHomeworkOnly')}
  command={toggleMark('markHomeworkOnly')}
  title="Toggle Homework Help only content (inline)">
  {icons.pen_square}
</MenuButton>);
// ==================== TOGGLE HOMEWORK ONLY (NODE) ====================
export const homeworkNode = (<MenuButton key="marks-homeworkNode"
  active={selectionInside('nodeHomeworkOnly')}
  enabled={toggleBlock('nodeHomeworkOnly')}
  command={toggleBlock('nodeHomeworkOnly')}
  title="Toggle Homework Help only content (block)">
  {icons.withPlus(icons.pen_square)}
</MenuButton>);
// ==================== SPLIT EBOOKS/HOMEWORK BLOCK ====================
export const splitBlockNode = (<MenuButton key="marks-splitBlockNode"
  enabled={splitOn(['nodeHomeworkOnly', 'nodeEbooksOnly'])}
  command={splitOn(['nodeHomeworkOnly', 'nodeEbooksOnly'])}
  title="Split eBooks or Homework Block">
  {icons.split_ebooks_homework}
</MenuButton>);
