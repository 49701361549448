import {apiClient} from "constants/Clients";
import {DEFAULT_KEY} from "./util";

export const books = {
  hookName: 'books',
  request: () =>  apiClient.getBooks()
    .limit(100)
    .setSortFields(['name'])
    .setOrderByAsc(),
  key: DEFAULT_KEY,
  auto: true,
};
export const bookDetails = key => ({
  hookName: 'bookDetails',
  request: bookId =>  apiClient.getBook(bookId),
  key,
  auto: true,
});
export const updateBook = key => ({
  hookName: 'updateBook',
  request: (bookId, book) => apiClient.updateBook({id: bookId, ...book}),
  key,
});
export const deleteBook = key => ({
  hookName: 'deleteBook',
  request: (bookId) => apiClient.deleteBook(bookId),
  key,
});
export const bookCreateChapter = key => ({
  hookName: 'bookCreateChapter',
  request: (bookId, chapter) => apiClient.createChapter({...chapter, bookId}),
  key,
});
export const bookChapters = key => ({
  hookName: 'bookChapters',
  request: bookId => apiClient.getBookChapters(bookId)
    .limit(100)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const bookCoverMediaDetails = key => ({
  hookName: 'bookCoverMediaDetails',
  request: bookId => apiClient.getBookCoverMedia(bookId),
  key,
  auto: true,
});
export const bookChapterOrder = key => ({
  hookName: 'bookChapterOrder',
  request: (bookId, chapterIds) => apiClient.setBookChapterOrder(bookId, chapterIds),
  key,
});
export const bookCreateSection = key => ({
  hookName: 'bookCreateSection',
  request: (bookId, section) => apiClient.createSection({...section, bookId}),
  key,
});
export const bookSections = key => ({
  hookName: 'bookSections',
  request: bookId => apiClient.getBookSections(bookId)
    .limit(100)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const bookSectionOrder = key => ({
  hookName: 'bookSectionOrder',
  request: (bookId, sectionIds) => apiClient.setBookSectionOrder(bookId, sectionIds),
  key,
});
export const bookAddGlossaryItem = key => ({
  hookName: 'bookAddGlossaryItem',
  request: (bookId, glossaryItemId) => apiClient.addGlossaryItemToBook(bookId, glossaryItemId),
  key,
});
export const bookGlossaryItems = key => ({
  hookName: 'bookGlossaryItems',
  request: bookId => apiClient.getBookGlossaryItems(bookId)
    .limit(100)
    .setSortFields(['title'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const bookCreateGlossaryItem = key => ({
  hookName: 'bookCreateGlossaryItem',
  request: (bookId, glossaryItem) => apiClient.createBookGlossaryItem(bookId, glossaryItem),
  key,
});
export const bookUpdateStyleSheet = key => ({
  hookName: 'bookUpdateStyleSheet',
  request: (bookId, stylesheet) => apiClient.updateBookStyleSheet(bookId, stylesheet),
  key,
});
export const bookRevertStyleSheet = key => ({
  hookName: 'bookRevertStyleSheet',
  request: bookId => apiClient.rollbackBookStyleSheet(bookId),
  key,
});

export const bookChildren = key => ({
  hookName: 'bookChildren',
  request: bookId => apiClient.getBookChildren(bookId)
    .limit(100)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const bookChildOrder = key => ({
  hookName: 'bookChildOrder',
  request: (bookId, children) => apiClient.setBookChildOrder(bookId, children),
  key,
});

export const bookLessons = key => ({
  hookName: 'bookLessons',
  request: (bookId) => apiClient.getBookLessons(bookId)
    .limit(1000)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const bookSectionElements = key => ({
  hookName: 'bookSectionElements',
  request: (bookId) => apiClient.getBookSectionElements(bookId)
    .limit(1000),
});
export const bookByEbooksKey = key => ({
  hookName: 'bookByEbooksKey',
  request: ebooksKey => apiClient.getBookByEbooksKey(ebooksKey),
  key,
  auto: true,
});
