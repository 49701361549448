import React, {useCallback, useEffect} from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

import {
  LinearProgress,
  makeStyles,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow, TextField
} from "@material-ui/core";

import {useClientHook} from "reducers/client";
import {paging, useTable} from "reducers/table";
import {useCachedClientRequest} from "hooks/useCachedClientRequest";

const useStyles = makeStyles(theme => ({
  blacklist: {
    color: theme.palette.action.disabled,
    cursor: 'not-allowed',
  },
  disabledTable: {
    '& > tr > td': {
      color: theme.palette.action.disabled,
    }
  },
  paginationRoot: {
    width: `400px`,
  }
}), {
  index: 1,
  name: 'BrowserList'
});

export const makeBrowserList = ({name, clientHook, getKey, render, tableProps}) => {
  const internalName = 'BrowserList#'+name.replace(/\s/g,'');
  const pagingClientHook = paging(internalName)(clientHook);
  const dataColumns = render({}).length;

  return props => {
    const {onClick, blacklist, selected, terminal} = props;
    const classes = useStyles(props);
    const {pagination, actions} = useTable({
      table: internalName,
      ...tableProps
    });
    const clientKey = useCallback(selector => selector(props), [props]);
    const clientRequest = useClientHook(
      pagingClientHook,
      clientKey
    );
    const {results, totalCount} = useCachedClientRequest(clientRequest) || {results: [], totalCount: 0};

    useEffect(() => {
      if (pagination.page * pagination.pageSize > totalCount) {
        actions.setPage(Math.floor(totalCount / pagination.pageSize));
      }
    }, [results, totalCount]);

    const totalColumns = (terminal ? 1 : 0) + dataColumns + 1;
    const disabled = !clientRequest.isLoaded();

    return (
      <Table>
        <TableHead>
          <TableRow>
            {terminal && <TableCell padding="checkbox" />}
            <TableCell colSpan={dataColumns}>
              <TextField
                fullWidth
                label={name}
                value={pagination.search}
                onChange={ev => actions.setSearch(ev.target.value)}
              />
            </TableCell>
            <TablePagination
              className={classes.paginationRoot}
              padding="none"
              colSpan={1}
              count={totalCount}
              page={pagination.page}
              rowsPerPage={pagination.pageSize}
              onChangePage={(ev, page) => actions.setPage(page)}
              onChangeRowsPerPage={ev => actions.setPageSize(ev.target.value)}
            />
          </TableRow>
          {clientRequest.isLoading() && (
            <TableRow style={{height:0}}>
              <TableCell colSpan={totalColumns} padding="none"><LinearProgress/></TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody className={disabled ? classes.disabledTable : ''}>
          {results && results.map(row => {
            const id = getKey(row);
            const isSelected = selected === id;
            const isBlacklisted = Array.isArray(blacklist) ? blacklist.includes(id) : blacklist && blacklist(row, name);
            const className = isBlacklisted ? classes.blacklist : '';
            return (
              <TableRow
                key={id}
                className={className}
                hover={!isBlacklisted}
                onClick={ev => !disabled && !isBlacklisted && onClick(ev, row)}
                selected={isSelected}
              >
                {terminal && (
                  <TableCell>
                    <Radio checked={isSelected} disabled={isBlacklisted} />
                  </TableCell>
                )}
                {render(row, {className}).map((cell,i) => <TableCell key={i} className={className}>{cell}</TableCell>)}
                <TableCell />
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    );
  };
};

export const browserListProps = {
  onClick: PropTypes.func.isRequired,
  blacklist: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  selected: PropTypes.string,
  terminal: PropTypes.bool.isRequired,
};
