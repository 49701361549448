import React from 'react';
import PropTypes from 'prop-types';

import IconWarning from '@material-ui/icons/Warning';

import {useClientHook} from "reducers/client";
import {lessonContentContainerElements, elementDetails} from "reducers/client/requestTypes";

import {ContentPreview} from "./ContentPreview";

const lessonContentContainerElementsHook = lessonContentContainerElements();
const elementDetailsHook = elementDetails();
const LessonContentContainerPreview = ({ containerId, elementType, ...innerProps }) => {
  // Get Container Elements and find one of the correct type
  const lessonContentContainerElements = useClientHook(lessonContentContainerElementsHook, containerId);
  let elementId = null;
  if (lessonContentContainerElements.isLoaded()) {
    const containerElement = lessonContentContainerElements.get().find(e => e.elementType === elementType);
    if (containerElement) elementId = containerElement.id;
  }
  const elementDetails = useClientHook(elementDetailsHook, elementId);

  // Render nothing if not loaded, render a warning if element not found
  if (!lessonContentContainerElements.isLoaded()) return null;
  if (!lessonContentContainerElements.get().find(e => e.elementType === elementType)) {
    return (
      <p><IconWarning style={{verticalAlign:'middle'}}/> No "{elementType}" element found.</p>
    );
  }
  if (!elementDetails.isLoaded()) return null;
  return <ContentPreview contentVersionId={elementDetails.get().publishedVersionId} {...innerProps} />;
};
LessonContentContainerPreview.propTypes = {
  containerId: PropTypes.string.isRequired,
  elementType: PropTypes.string.isRequired,
};

export {LessonContentContainerPreview}
