import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {
  gradeStandards,
  gradeCreateStandard,
  updateStandard,
  deleteStandard
} from "reducers/client/requestTypes";

import {pagedTable} from 'components/common/Table/PagedTable';
import {formatDate} from "constants/Date";
import {paging} from "reducers/table";


const InternalTable = pagedTable([
  {key: 'id', hidden: true},
  {key: 'title', sort: 'title', name: 'Title'},
  {key: 'description', sort: 'description', name: 'Description', inputProps: {multiline: true}},
  {key: 'type', sort: 'type', name: 'Type'},
  {key: 'depth', sort: 'depth', name: 'Depth'},
  {key: 'position', sort: 'position', name: 'Position'},
  {key: 'sourceUrl', sort: 'sourceUrl', name: 'Source URL'},
  {key: 'asnIdentifier', sort: 'asnIdentifier', name: 'ASN ID'},
  {key: 'commonGuid', sort: 'commonGuid', name: 'Common Core GUID'},
  {key: 'createdAt', sort: 'createdAt,title', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', sort: 'updatedAt,title', name: "Last Updated", render: formatDate, readOnly: true},
], {
  table: 'GradeStandardsTable',
  initialState: {
    searchColumns: ['title']
  },
  getRowKey: row => row.id,
  getRowName: row => row.title,
  paginationColSpan: 4
});

const gradeStandardsHook = paging('GradeStandardsTable')(gradeStandards((state, props) => props.gradeId));
const gradeCreateStandardHook = gradeCreateStandard();
const updateStandardHook = updateStandard();
const deleteStandardHook = deleteStandard();
const GradeStandardsTable = (props) => {
  const [totalCount, setTotalCount] = useState(0);
  const [tablePage, setTablePage] = useState([]);
  const history = useHistory();

  const clientKey = useCallback(selector => selector(props), [props]);
  const gradeStandards = useClientHook(gradeStandardsHook, clientKey);
  const gradeCreateStandard = useClientHook(gradeCreateStandardHook, props.gradeId);
  const updateStandard = useClientHook(updateStandardHook);
  const deleteStandard = useClientHook(deleteStandardHook);

  const handleSave = useCallback(standard => {
    if (standard.id) {
      return updateStandard.sendRequest(standard.id, standard)
        .then(() => gradeStandards.sendRequest());
    } else {
      return gradeCreateStandard.sendRequest(standard)
        .then(() => gradeStandards.sendRequest());
    }
  }, [gradeStandards, updateStandard, gradeCreateStandard]);
  const handleDelete = useCallback(standard => {
    if (standard.id) {
      return deleteStandard.sendRequest(standard.id)
        .then(() => gradeStandards.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [gradeStandards, deleteStandard]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/standards/${tablePage[index].id}/details`);
  }, [history,tablePage]);

  const isWaiting = useMemo(
    () =>
      gradeStandards.isLoading() ||
      gradeCreateStandard.isLoading() ||
      updateStandard.isLoading() ||
      deleteStandard.isLoading(),
    [gradeStandards, gradeCreateStandard, updateStandard, deleteStandard]
  );

  useEffect(() => {
    if (gradeStandards.isLoaded()) {
      const {totalCount, results} = gradeStandards.get();
      setTotalCount(totalCount);
      setTablePage(results);
    }
  }, [gradeStandards, setTotalCount, setTablePage]);

  return (
    <InternalTable
      page={tablePage}
      totalCount={totalCount}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      waiting={isWaiting}
    />
  )
};
GradeStandardsTable.propTypes = {
  gradeId: PropTypes.string.isRequired,
}

export {GradeStandardsTable}
