import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {MediaDetailsView} from "views/media/MediaDetails";

import {navRoutes, useNav} from "reducers/nav";

export const MediaRoutes = ({match: {url, path, params: {mediaId}}}) => {
  const selectedMedia = useNav(navRoutes.MEDIA, mediaId);
  if (!selectedMedia) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={MediaDetailsView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
