import { Schema } from 'prosemirror-model';
import spec from '@cpmmath/c3po-schema';

const extend = (nodeSpec, options) => {
  nodeSpec.attrs = {
    ...nodeSpec.attrs,
    ...options.attrs
  };
  if (options.toDOM) {
    const {toDOM} = nodeSpec;
    nodeSpec.toDOM = node => options.toDOM(node, toDOM(node));
  }
  return nodeSpec;
}

const temporaryChanges = spec => {
  // extend(spec.nodes.column, {
  //   attrs: {
  //     borderWidth: {default: 0}
  //   },
  //   toDOM: (node, [element, attrs, ...children]) => {
  //     if (node.attrs.borderWidth > 0) {
  //       attrs.style = attrs.style || "";
  //       attrs.style += `border: ${node.attrs.borderWidth}px solid black`;
  //     }
  //     return [element, attrs, ...children];
  //   }
  // });

  return spec;
};

const finalSpec = temporaryChanges(spec);

export const defaultSchema = new Schema(finalSpec);
export {updateDoc} from './update';
