import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {TabbedViewDetailsView} from "views/tabbedView/TabbedViewDetails";

import {navRoutes, useNav} from "reducers/nav";

export const TabbedViewRoutes = ({match: {url, path, params: {tabbedViewId}}}) => {
  const selectedTabbedView = useNav(navRoutes.TABBED_VIEW, tabbedViewId);
  if (!selectedTabbedView) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={TabbedViewDetailsView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
