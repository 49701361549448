import React, {useCallback, useState} from 'react';
import {Button, makeStyles, createStyles} from "@material-ui/core";
import classNames from 'classnames';
import PropTypes from 'prop-types';

import BreadcrumbButton from './BreadcrumbButton';
import {useClientHook} from "reducers/client";

const navHeight = 33;

export const makeBreadcrumb = options => {
  let link = options.link || (details => '/');
  let title = options.title || (details => details.id);

  const useStyles = makeStyles(theme => createStyles({
    root: {
      margin: `${theme.spacing(1)}px 0`,
    },
    button: {
      margin: `0 ${theme.spacing(1)}px`,
    },
    scrolling: {
      maxHeight: `${140}px`,
      overflowY: 'scroll',
    },
    spacer: {}
  }), {name: `Breadcrumb.${options.displayName}`})

  const Breadcrumb = ({child, ...props}) => {
    const classes = useStyles(props);
    const [parentsShown, setParentsShown] = useState(false);
    const [parentsLoaded, setParentsLoaded] = useState(4);

    const clientKey = useCallback(selector => selector(props), [props])
    const detailsRequest = useClientHook(options.request, clientKey);

    const handleButtonClick = useCallback(() => {
      setParentsShown(true);
    }, [setParentsShown]);
    const handleScroll = useCallback(ev => {
      const scrollParent = Math.ceil(ev.target.scrollTop / navHeight) + 4;
      if (scrollParent > parentsLoaded) {
        setParentsLoaded(scrollParent);
      }
    }, [parentsLoaded, setParentsLoaded]);

    let content = null;
    if (detailsRequest.isLoaded()) {
      const details = detailsRequest.get();

      let button = (<BreadcrumbButton
        className={classes.button}
        link={link(details, props)}
        active={!child}>
        {title(details, props)}
      </BreadcrumbButton>);

      if (options.parents) {
        const parents = options.parents(details, props);
        if (parentsShown) {
          return (<nav
            className={classNames({
              [classes.root]: true,
              [classes.scrolling]: parents.length > 4
            })}
            onScroll={handleScroll}
          >
            {parents.slice(0,parentsLoaded).map((parent, i) => (
              <div key={parent.key}>
                {parent} &gt; {button}
              </div>
            ))}
            {parentsLoaded < parents.length && (
              <div
                className={classes.spacer}
                style={{height:`${(parents.length - parentsLoaded) * navHeight}px`}}
              />
            )}
          </nav>);
        } else if (parents.length > 1) {
          content = (<React.Fragment>
            <Button color="default" onClick={handleButtonClick}>(Multiple Parents)</Button>
            &gt;
            {button}
          </React.Fragment>);
        } else if (parents.length > 0) {
          content = (<React.Fragment>
            {parents}
            &gt;
            {button}
          </React.Fragment>);
        } else {
          content = (<React.Fragment>
            <BreadcrumbButton>No parents found</BreadcrumbButton>
            &gt;
            {button}
          </React.Fragment>);
        }
      } else {
        content = button;
      }
    } else {
      content = <BreadcrumbButton>???</BreadcrumbButton>;
    }

    if (!child) return (<nav className={classes.root}>{content}</nav>);
    return content;
  }
  Breadcrumb.propTypes = {
    ...options.propTypes,
    child: PropTypes.bool,
  }
  Breadcrumb.displayName = options.displayName;

  return Breadcrumb;
};
