import React from 'react';
import {Redirect} from 'react-router-dom';

// Material
import LinearProgress from "@material-ui/core/LinearProgress";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";

// Icons
import IconEdit from '@material-ui/icons/Edit';

// Client
import {withClient} from "reducers/client";
import {sectionDetails, sectionElements} from "reducers/client/requestTypes";

// Components
import {SectionBreadcrumb} from 'components/common/Breadcrumb';
import ContentVersionMediasTable from "components/contentVersion/ContentVersionMediasTable";

// Constants
import {getElementTypeByName} from "constants/ElementTypes";

import {createNavRouteSelector, navRoutes} from "../../reducers/nav";
import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";

class SectionMedia extends React.Component {
  showElementEditor = () => {
    const {
      history,
      sectionElements,
      match: {
        url,
        params: {elementType}
      }
    } = this.props;
    let elementId = sectionElements.get().find(el => el.elementType === elementType).id;
    history.push(`/app/sections/${sectionElements.getParams()}/editor/${elementId}/`);
  };
  handleChangeElementType = (ev, value) => {
    const { history, match: {path, url, params} } = this.props;
    let newPath = path.replace(':sectionId',params.sectionId).replace(':elementType', value);
    if (url !== newPath) history.push(newPath);
  };

  render() {
    const {
      sectionElements:elementsRequest,
      match: {
        url,
        params: {elementType}
      }
    } = this.props;

    if (!elementsRequest.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Section media...</h2>
      </div>);
    }

    const sectionElements = elementsRequest.get().sort((a,b) => {
      const typeA = getElementTypeByName(a.elementType);
      const typeB = getElementTypeByName(b.elementType);
      return typeA.displayOrder - typeB.displayOrder;
    });
    if (!elementType) {
      return <Redirect to={`${url}/${sectionElements[0].elementType}`} />;
    }

    let element = sectionElements.find(el => el.elementType === elementType);

    return (
      <ViewportRoot>
        <SectionBreadcrumb sectionId={elementsRequest.getParams()} />
        <ButtonBox>
          <ButtonBoxButton title="Section Editor" onClick={this.showElementEditor}>
            <IconEdit />
          </ButtonBoxButton>
        </ButtonBox>
        <AppBar position="static">
          <Tabs value={elementType} onChange={this.handleChangeElementType}>
            {sectionElements.map(element => {
              const elementType = getElementTypeByName(element.elementType);
              return (
                <Tab
                  key={element.elementType}
                  value={element.elementType}
                  label={elementType ? elementType.displayName || elementType.name : element.elementType}
                />
              )
            })}
          </Tabs>
        </AppBar>
        <ContentVersionMediasTable contentVersionId={element.publishedVersionId} />
      </ViewportRoot>
    );
  }
}

const getNavSection = createNavRouteSelector(navRoutes.SECTION);
export const SectionMediaView = withClient({
  hooks: {
    sectionDetails: sectionDetails(getNavSection),
    sectionElements: sectionElements(getNavSection),
  },
})(SectionMedia);
