import React from 'react';

import {Paper, withStyles} from "@material-ui/core";
import ArrowBack from '@material-ui/icons/ArrowBack';

import {ElementVersionsTable} from 'components/element/ElementVersionsTable';
import {ContentPreview} from 'components/editor/previews/ContentPreview';
import {desmos, homework, mathjax} from "prosemirror/processors";

import {withClient} from "reducers/client";
import {
  elementDetails,
  lessonDetails,
} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";
import {connect} from "react-redux";
import {LessonElementBreadcrumb} from "components/common/Breadcrumb";

const gutter = 30;

const styles = theme => ({
  versionsContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  previewPane: {
    minHeight: `${180}px`,
    marginRight: `${gutter}px`,
    padding: `${10}px`,
    flex: 1,
    border: `${1}px solid #dddddd`,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    backgroundColor: '#ffffff'
  },
  listSpacer: {
    flex: `0 ${600}px`
  }
});

class BaseComponent extends React.Component {
  state = {
    selected: null
  }

  showEditor = () => {
    this.props.history.push(`/app/lessons/${this.props.lessonId}/editor/${this.props.elementDetails.getParams()}`);
  };

  getSelectedVersionId() {
    const {selected} = this.state;
    return selected && selected.id;
  }

  handleSelectionChange = selected => {
    this.setState({selected});
  };

  render() {
    const {elementDetails: elementRequest, lessonDetails: lessonRequest, lessonId, classes} = this.props;

    if (!lessonRequest.isLoaded()) {
      return <div />;
    }
    let elementType = elementRequest.isLoaded() ? elementRequest.get().elementType : '';

    const lessonDetails = lessonRequest.get();
    return (
      <ViewportRoot>
        <ButtonBox style={{width:600}}>
          <ButtonBoxButton title="back" onClick={this.showEditor}>
            <ArrowBack />
          </ButtonBoxButton>
          <Paper>
            <ElementVersionsTable
              elementId={elementRequest.getParams()}
              onSelectionChange={this.handleSelectionChange}
            />
          </Paper>
        </ButtonBox>
        <h2 className="article-title">{lessonDetails.name} '{elementType}' versions</h2>
        <div>
          <LessonElementBreadcrumb lessonId={lessonId} elementId={elementRequest.getParams()}/>
        </div>
        <div className={classes.versionsContainer}>
          <div className={classes.previewPane}>
            <ContentPreview contentVersionId={this.getSelectedVersionId()} postProcess={[mathjax, homework, desmos]} />
          </div>
          <div className={classes.listSpacer} />
        </div>
      </ViewportRoot>
    );
  }
}

const getNavLesson = createNavRouteSelector(navRoutes.LESSON);
const getElementId = (state, props) => props.match.params.elementId;
export const LessonVersionsView = withClient({
  hooks: {
    lessonDetails: lessonDetails(getNavLesson),
    elementDetails: elementDetails(getElementId),
  }
})(connect(
  state => ({
    lessonId: getNavLesson(state),
  }),
)(
  withStyles(styles)(BaseComponent)
));