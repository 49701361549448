import {displayModes,sidebarModes} from "constants/Prosemirror";

export const SNACKBAR_HIDDEN = 'SNACKBAR_HIDDEN';
export const SNACKBAR_SAVING = 'SNACKBAR_SAVING_IN_PROGRESS';
export const SNACKBAR_SAVE_SUCCESS = 'SNACKBAR_SAVE_SUCCESS';
export const SNACKBAR_SAVE_ERROR = 'SNACKBAR_SAVE_ERROR';

// ACTION TYPES
export const PROSEMIRROR_EDITOR_SET_DOC_CHANGED = 'PROSEMIRROR_EDITOR_SET_DOC_CHANGED';
export const PROSEMIRROR_EDITOR_SET_DISPLAY_MODE = 'PROSEMIRROR_EDITOR_SET_DISPLAY_MODE';
export const PROSEMIRROR_EDITOR_SET_EDIT_MODE = 'PROSEMIRROR_EDITOR_SET_EDIT_MODE';
export const PROSEMIRROR_EDITOR_SET_SIDEBAR_MODE = 'PROSEMIRROR_EDITOR_SET_SIDEBAR_MODE';
export const PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS = 'PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS';
export const PROSEMIRROR_EDITOR_SET_CONTEXT = 'PROSEMIRROR_EDITOR_SET_CONTEXT';
export const PROSEMIRROR_EDITOR_SET_ELEMENT_ID = 'PROSEMIRROR_EDITOR_SET_ELEMENT_ID';
export const PROSEMIRROR_EDITOR_SET_CONTENT_VERSION_ID = 'PROSEMIRROR_EDITOR_SET_CONTENT_VERSION_ID';
export const PROSEMIRROR_EDITOR_SET_LANGUAGE = 'PROSEMIRROR_EDITOR_SET_LANGUAGE';
export const PROSEMIRROR_EDITOR_SET_CONTENT_VERSION_PROPERTIES = 'PROSEMIRROR_EDITOR_SET_CONTENT_VERSION_PROPERTIES';
export const PROSEMIRROR_EDITOR_UPDATE_CONTENT_VERSION_PROPERTIES = 'PROSEMIRROR_EDITOR_UPDATE_CONTENT_VERSION_PROPERTIES';

// ACTION CREATORS
export function setEditorDocChanged(payload) {
  return { type: PROSEMIRROR_EDITOR_SET_DOC_CHANGED, payload };
}
export function setEditorDisplayMode(payload) {
  return { type: PROSEMIRROR_EDITOR_SET_DISPLAY_MODE, payload };
}
export function setEditorMode(payload) {
  return { type: PROSEMIRROR_EDITOR_SET_EDIT_MODE, payload };
}
export function setSidebarMode(payload) {
  return { type: PROSEMIRROR_EDITOR_SET_SIDEBAR_MODE, payload };
}
export function hideSnackbar() {
  return { type: PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS, payload: SNACKBAR_HIDDEN };
}
export function showSnackbarSaving() {
  return { type: PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS, payload: SNACKBAR_SAVING };
}
export function showSnackbarSaveSuccess() {
  return { type: PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS, payload: SNACKBAR_SAVE_SUCCESS };
}
export function showSnackbarSaveError() {
  return { type: PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS, payload: SNACKBAR_SAVE_ERROR };
}
export function setContext(bookId, contextType, contextId) {
  return { type: PROSEMIRROR_EDITOR_SET_CONTEXT, payload: {bookId, contextType, contextId} };
}
export function setElementId(payload) {
  return { type: PROSEMIRROR_EDITOR_SET_ELEMENT_ID, payload };
}
export function setContentVersionProperties(payload) {
  return { type: PROSEMIRROR_EDITOR_SET_CONTENT_VERSION_PROPERTIES, payload };
}
export function updateContentVersionProperties(payload) {
  return { type: PROSEMIRROR_EDITOR_UPDATE_CONTENT_VERSION_PROPERTIES, payload };
}

// INITIAL STATE
const initialContentVersionProperties = {
  language: null
}
const initialState = {
  docChanged: false,
  displayMode: displayModes.DISPLAY_ALL.id,
  editMode: true,
  sidebarMode: sidebarModes.NONE.id,
  snackbarStatus: SNACKBAR_HIDDEN,
  bookId: null,
  contextType: null,
  contextId: null,
  elementId: null,
  contentVersionId: 'latest',
  contentVersionProperties: {
    _originalValues: { initialContentVersionProperties },
    ...initialContentVersionProperties
  },
};

// SELECTOR
export const getContentVersionChanged = state => {
  if (state.docChanged) return true;
  return Object.keys(initialContentVersionProperties)
    .some(key => state.contentVersionProperties[key] !== state.contentVersionProperties._originalValues[key]);
};
export const getContentVersionProperties = state => {
  return Object.keys(initialContentVersionProperties)
    .reduce((acc, v) => {
      acc[v] = state.contentVersionProperties[v];
      return acc;
    }, {});
};

// REDUCER
function setProperties(prev, next, overwrite) {
  const filteredNext = Object.keys(initialContentVersionProperties)
    .reduce((acc, v) => {
      acc[v] = next[v];
      return acc;
    }, {});

  if (overwrite) {
    return {
      ...filteredNext,
      _originalValues: filteredNext
    };
  } else {
    return {
      ...prev,
      ...filteredNext,
      _originalValues: prev._originalValues
    };
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PROSEMIRROR_EDITOR_SET_DOC_CHANGED:
      return {
        ...state,
        docChanged: action.payload
      };
    case PROSEMIRROR_EDITOR_SET_DISPLAY_MODE:
      return {
        ...state,
        displayMode: action.payload.id ? action.payload.id : action.payload
      };
    case PROSEMIRROR_EDITOR_SET_EDIT_MODE:
      return {
        ...state,
        editMode: action.payload
      };
    case PROSEMIRROR_EDITOR_SET_SIDEBAR_MODE:
      const mode = action.payload.id ? action.payload.id : action.payload;
      if (sidebarModes[mode].disableWhenDirty) {
        if (state.docChanged) {
          return state;
        }
        return {
          ...state,
          editMode: false,
          sidebarMode: mode,
        };
      } else {
        return {
          ...state,
          sidebarMode: mode,
        };
      }
    case PROSEMIRROR_EDITOR_SET_SNACKBAR_STATUS:
      return {
        ...state,
        snackbarStatus: action.payload,
      };
    case PROSEMIRROR_EDITOR_SET_CONTEXT:
      return {
        ...state,
        bookId: action.payload.bookId,
        contextType: action.payload.contextType,
        contextId: action.payload.contextId,
      };
    case PROSEMIRROR_EDITOR_SET_ELEMENT_ID:
      return {
        ...state,
        elementId: action.payload,
        contentVersionId: initialState.contentVersionId
      };
    case PROSEMIRROR_EDITOR_SET_CONTENT_VERSION_ID:
      return {
        ...state,
        contentVersionId: action.payload,
      };
    case PROSEMIRROR_EDITOR_SET_CONTENT_VERSION_PROPERTIES:
      return {
        ...state,
        contentVersionProperties: setProperties(state.contentVersionProperties, action.payload, true)
      }
    case PROSEMIRROR_EDITOR_UPDATE_CONTENT_VERSION_PROPERTIES:
      return {
        ...state,
        contentVersionProperties: setProperties(state.contentVersionProperties, action.payload, false)
      }
    default:
      return state;
  }
};
