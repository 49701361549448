import React, {useCallback, useEffect, useImperativeHandle, useMemo, useReducer, useState} from "react";

import {
  createStyles,
  LinearProgress, makeStyles,
  Table,
  TableBody,
  TableCell, TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";

import ConfirmationDialog from "components/Dialogs/Confirmation";
import DraggableList from "components/common/DraggableList";
import IconAdd from "@material-ui/icons/Add";
import IconDots from "@material-ui/icons/MoreHoriz";
import {makeTableRow} from "components/common/Table/TableRowBase";
import {makeTableHeader} from "components/common/Table/TableHeaderBase";
import {processColumns} from "components/common/Table/TableUtils";
import {initialState, makeReducer, ActionCreators} from "components/common/Table/reducer";
import {makeTableFooter} from "components/common/Table/TableFooterBase";

const useStyles = makeStyles(theme => createStyles({
  dragHandle: {
    cursor: "move"
  },
  button: {},
  dragging: {
    cursor: "move",
    background: 'white',
    boxShadow: `0 2px 4px 0 rgba(0,0,0,0.2)`,
  },
}), {name: "DraggableTable-Base"});

const defaultOptions = {
  getRowName: (row, i) => i,
  readOnly: false,
  reducer: (state, action) => state,
  actions: {
    hidden: false,
    override: false,
    Component: null,
  }
};

export const draggableTable = (columnSpec, options) => {
  const {getRowName, readOnly, reducer} = Object.assign({}, defaultOptions, options);
  const actions = Object.assign({}, defaultOptions.actions, (options || {}).actions);

  const {columns, visibleColumns, totalColumns, getRowPayload, getPagePayload} = processColumns(columnSpec, options);
  const tableReducer = makeReducer(columns, reducer);

  const visibleActions = !actions.hidden && (actions.Component || !readOnly);
  const TableHeaderBase = makeTableHeader({visibleColumns, visibleActions, draggable: true});
  const TableRowBase = makeTableRow({visibleColumns, actions, draggable: true, readOnly});
  const TableFooterBase = makeTableFooter({visibleColumns, visibleActions, draggable: true, TableRowBase});


  const DraggableTableComponent = (props, ref) => {
    const {
      classes: propClasses,
      initialState: data,
      onSave,
      onDelete,
      onClick,
      onOrder,
      onStateChange,
      waiting
    } = props;
    const classes = useStyles({classes: propClasses});

    // State reducer (get state updates from text fields)
    const [state, dispatch] = useReducer(tableReducer, initialState);
    // Reset state whenever new initial state is passed in
    useEffect(() => {
      if (data) dispatch({type: 'reset', payload: getPagePayload(data, true)})
    }, [data]);

    const handleConfirmDelete = useCallback(() => {
      dispatch(ActionCreators.setDeleting(false))
      if (typeof onDelete === 'function') {
        onDelete(getRowPayload(state.page[state.editing])).then(() => dispatch(ActionCreators.selectToEdit(-1)));
      }
    }, [state, dispatch, onDelete]);
    const handleDragStart = useCallback(
      (ev, index) => {
        let t = ev.target;
        while (t && t !== ev.currentTarget) {
          // Only trigger drag if clicking on the handle
          if (t.classList.contains(classes.dragHandle)) {
            return true;
          } else if (t.classList.contains(classes.button)) {
            return false;
          }
          t = t.parentElement;
        }
        // If not clicking on handle or button, trigger external click handler
        if (typeof onClick === 'function') onClick(ev, index);
        return false;
      },
      [classes, onClick]
    );
    const handleSave = useCallback(index => {
      if (typeof onSave === 'function') {
        const row = index >= 0 ? state.page[index] : state.newRow;
        onSave(getRowPayload(row)).then(() => {
          if (index >= 0) dispatch(ActionCreators.selectToEdit(-1));
          else dispatch(ActionCreators.setAdding(false))
        });
      }
    }, [state, dispatch, onSave]);

    useEffect(() => {
      if (typeof onStateChange === 'function') onStateChange(state)
    }, [state]);

    const disableDrag = waiting || state.adding || state.editing >= 0;
    const selectedRowName = (state.editing >= 0 && state.editing < state.page.length) &&
      getRowName(state.page[state.editing],state.editing);

    useImperativeHandle(ref, () => ({
      dispatch
    }), [dispatch]);

    return (
      <React.Fragment>
        <ConfirmationDialog
          title="Delete"
          message={`Are you sure you want to delete ${selectedRowName}?`}
          isOpen={state.deleting}
          onClose={() => dispatch(ActionCreators.setDeleting(false))}
          onConfirm={handleConfirmDelete}
        />
        <Table>
          <TableHeaderBase waiting={Boolean(waiting)} />
          <DraggableList
            classes={{dragging: classes.dragging}}
            component={TableBody}
            itemComponent={TableRowBase}
            items={state.page}
            itemProps={{
              classes,
              state,
              dispatch,
              disabled: waiting,
              onSave: handleSave
            }}
            onDragStart={handleDragStart}
            onChange={onOrder}
            disabled={disableDrag}
          />
          <TableFooterBase
            classes={classes}
            state={state}
            dispatch={dispatch}
            waiting={waiting}
            onSave={handleSave}
          />
        </Table>
      </React.Fragment>
    );
  };

  return React.forwardRef(DraggableTableComponent);
};
