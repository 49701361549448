import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

import {useClientHook} from "reducers/client";
import {
  mergeSections as mergeSectionsHook,
  sectionDetails,
  sectionElements,
} from "reducers/client/requestTypes";
import {clearRequest} from "reducers/client/actions";

import {SectionReference} from "components/ContentReference";
import {MergeInformationTable} from 'components/common/Merge/MergeInformationTable';
import {useDialog} from "reducers/dialog";
import {MERGE_SECTIONS_CONFIRMATION} from "reducers/dialog/dialogIds";

const refreshElements = (containerElements, dispatch) => {
  return containerElements.sendRequest().then(result => {
    result.forEach(element => {
      dispatch(clearRequest('elementParents', element.id));
    });
  });
};

const sectionDetailsHook = sectionDetails();
const sectionElementsHook = sectionElements();
const MergeSectionsConfirmationDialog = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {isOpen, data, actions} = useDialog({dialog: MERGE_SECTIONS_CONFIRMATION});
  const [mainId, mergeId] = (data && data.sectionIds) || [];

  const mainDetails = useClientHook(sectionDetailsHook, mainId);
  const mergeDetails = useClientHook(sectionDetailsHook, mergeId);
  const mainElements = useClientHook(sectionElementsHook, mainId);
  const mergeElements = useClientHook(sectionElementsHook, mergeId);
  const mergeSections = useClientHook(mergeSectionsHook);

  const handleConfirm = useCallback(
    () => {
      mergeSections.sendRequest(data)
        .then(() => {
          // Refresh data that may be affected by the merge
          refreshElements(mainElements, dispatch);
          refreshElements(mergeElements, dispatch);
          dispatch(clearRequest('mergeHistory', mainId));
          dispatch(clearRequest('mergeHistory', mergeId));
          actions.close();
          history.push(`/app/sections/${mainId}/editor/${Object.keys(data.selection)[0]}`);
        });
    },
    [
      mergeSections,
      mainDetails,
      mergeDetails,
      mainElements,
      mergeElements,
      mainId,
      mergeId,
      history,
      actions,
      dispatch
    ]
  );

  if (!data) return null;
  return (<Dialog open={isOpen} onClose={actions.close} {...props}>
    <DialogTitle>Confirm merge</DialogTitle>
    <MergeInformationTable
      mainId={mainId}
      mergeId={mergeId}
      mainElements={mainElements.isLoaded() ? mainElements.get() : []}
      mergeElements={mergeElements.isLoaded() ? mergeElements.get() : []}
      selection={data && data.selection}
      ReferenceComponent={({contentableId, ...props}) => <SectionReference sectionId={contentableId} {...props} />}
    />
    <DialogActions>
      <Button onClick={actions.close} color="default" disabled={mergeSections.isLoading()}>
        Cancel
      </Button>
      <Button onClick={handleConfirm} color="primary" disabled={mergeSections.isLoading()}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>);
};

export {MergeSectionsConfirmationDialog}
