import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {AssessmentGroupDetailsView} from "views/assessmentGroup/AssessmentGroupDetails";

import {useNav, navRoutes} from "reducers/nav";

export const AssessmentGroupRoutes = ({match: {url, path, params: {assessmentGroupId}}}) => {
  const selectedAssessmentGroup = useNav(navRoutes.ASSESSMENT_GROUP, assessmentGroupId);
  if (!selectedAssessmentGroup) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={AssessmentGroupDetailsView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
