import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {useProsemirror} from "./ProsemirrorProvider";
import {LogLevels, logProsemirror} from "../log";

import '../styles/nodes.css';
import '../styles/prosemirror.css';
import '../styles/ebooks.css';

const ProsemirrorView = ({className, contentClassName, disabled, ...other}) => {
  const prosemirror = useProsemirror();
  const createView = useCallback((node) => {
    logProsemirror('CREATE VIEW', {node}, LogLevels.INFO);
    if (node) {
      prosemirror.connect(node, {
        attributes: { class: contentClassName },
        editable: () => !disabled
      });
    } else if (prosemirror.isConnected()) {
      prosemirror.disconnect();
    }
  }, [contentClassName, prosemirror]);

  return prosemirror && (
    <div
      ref={createView}
      className={classNames('ProseMirror-View',className)}
      {...other}
    />
  );
};
ProsemirrorView.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export {ProsemirrorView}
