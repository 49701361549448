import React from "react";
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {withTheme} from "@material-ui/core";

class MenuPopout extends React.Component {
  static propTypes = {
    style: PropTypes.object,
    columns: PropTypes.number,
    anchorEl: PropTypes.instanceOf(HTMLElement)
  };
  
  constructor(props) {
    super(props);
    this.documentBody = document.getElementsByTagName('body')[0];
    this.rootElement = document.createElement('div');
    this.rootElement.style.backgroundColor = props.theme.palette.primary.main;
    this.rootElement.setAttribute('class','ProseMirror-MenuPopout');
  }

  componentDidMount() {
    const {style, columns, anchorEl} = this.props;
    if (anchorEl) {
      this.showSelf();
    }
    if (style) {
      Object.keys(style).forEach(prop => {
        this.rootElement.style[prop] = style[prop];
      });
    }
    if (columns > 0) {
      this.rootElement.setAttribute('class',classNames(
        'ProseMirror-MenuPopout',
        'ProseMirror-MenuPopout-Columns'
      ));
      this.rootElement.style.gridTemplateColumns = `repeat(${columns}, 1fr)`;
    }
  }
  componentDidUpdate(prevProps) {
    const {style, columns, anchorEl} = this.props;
    if (anchorEl && !prevProps.anchorEl) {
      this.showSelf();
    } else if (!anchorEl && prevProps.anchorEl) {
      this.hideSelf();
    }
    if (style !== prevProps.style) {
      Object.keys(style).forEach(prop => {
        if (style[prop] !== prevProps.style[prop])
          this.rootElement.style[prop] = style[prop];
      });
    }
    if (columns !== prevProps.columns) {
      if (columns > 0) {
        if (!prevProps.columns || prevProps.columns <= 0) {
          this.rootElement.setAttribute('class',classNames(
            'ProseMirror-MenuPopout',
            'ProseMirror-MenuPopout-Columns'
          ));
        }
        this.rootElement.style.gridTemplateColumns = `repeat(${columns}, 1fr)`;
      } else if (prevProps.columns > 0) {
        this.rootElement.style.gridTemplateColumns = '';
      }
    }
  }

  showSelf() {
    const {anchorEl} = this.props;
    if (anchorEl) {
      this.documentBody.appendChild(this.rootElement);

      let t = anchorEl;
      let left = 0;
      let top = 0;
      while (t) {
        left += t.offsetLeft;
        top += t.offsetTop;
        t = t.offsetParent;
      }

      this.rootElement.style.left = `${left}px`;
      this.rootElement.style.top = `${top}px`;
    }
  }
  hideSelf() {
    this.documentBody.removeChild(this.rootElement);
  }

  render() {
    const {anchorEl} = this.props;
    if (!anchorEl) return null;

    return ReactDOM.createPortal(
      this.props.children,
      this.rootElement
    )
  }
}
const ThemedMenuPopout = withTheme(MenuPopout);
export {ThemedMenuPopout as MenuPopout}
