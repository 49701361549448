import React, {useCallback, useEffect, useMemo, useState, useReducer} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@material-ui/core";

import {EditModeToggle} from "components/common/Table/EditModeToggle";

const DetailsTableCell = React.memo(({fieldKey, readOnly, render = a=>a, value, editMode, dispatch}) => {
  const handleChange = useCallback(
    ev => dispatch({type: 'update', key: fieldKey, value: ev.target.value}),
    [fieldKey]
  );
  return (editMode ? (
    <TableCell>
      <TextField
        disabled={readOnly}
        value={readOnly ? render(value) : value || ''}
        onChange={handleChange}
      />
    </TableCell>
  ) : (<TableCell>{render(value)}</TableCell>));
});

export const detailsTable = (fields) => {
  // Default getters and setters for fields
  fields.forEach(field => {
    if (!field.get) field.get = state => state[field.key];
    if (!field.set) field.set = (state, value) => state[field.key] = value;
  });

  // Headers never change, they can be pre-cached
  const detailsTableHead = (
    <TableHead>
      <TableRow>
        {fields.map(field => (
          <TableCell key={field.key}>{field.name || field.key}</TableCell>
        ))}
        <TableCell padding="none"/>
      </TableRow>
    </TableHead>
  );

  const getPayload = (state, includeReadOnly) => {
    let payload = {};
    if (!state) return payload;
    fields.forEach(({get, set, readOnly}) => (includeReadOnly || !readOnly) && (set(payload, get(state))));
    return payload;
  };

  return ({initialState, onSave, disabled}) => {
    // Edit mode
    const [editMode, setEditMode] = useState(false);
    // State reducer (get state updates from text fields)
    const [state, dispatch] = useReducer((state, action) => {
      if (action.type === 'reset') {
        return action.payload;
      } else if (action.type === 'update') {
        const field = fields.find(f => f.key === action.key);
        let nextState = {...state};
        field.set(nextState, action.value);
        return nextState;
      }
      return state;
    }, {...initialState});
    // Reset state whenever new initial state is passed in
    useEffect(() => dispatch({type: 'reset', payload: getPayload(initialState, true)}), [initialState]);

    const pendingChanges = useMemo(
      () => fields.some(field => (initialState && field.get(initialState)) !== (state && field.get(state))),
      [initialState, state]
    );

    // Edit mode toggle button click handler
    const handleClickEdit = useCallback(() => {
      if (editMode && pendingChanges && typeof onSave === 'function') onSave(getPayload(state));
      setEditMode(!editMode);
    }, [editMode, pendingChanges, state]);

    return (
      <Table>
        {detailsTableHead}
        <TableBody>
          <TableRow>
            {fields.map(field => (
              <DetailsTableCell
                key={field.key}
                fieldKey={field.key}
                {...field}
                value={state && field.get(state)}
                editMode={editMode}
                dispatch={dispatch}
              />
            ))}
            <TableCell padding="none" align="right">
              <EditModeToggle
                editMode={editMode}
                pendingChanges={pendingChanges}
                onClick={handleClickEdit}
                disabled={disabled}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };
};
