import React from 'react';
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';

import IconArrowBack from '@material-ui/icons/ArrowBack';

import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";
import {GlossaryItemBreadcrumb} from 'components/common/Breadcrumb'
import MergeHistoryTable from 'components/common/Merge/MergeHistoryTable';
import {createNavRouteSelector, navRoutes} from "reducers/nav";

const getNavGlossaryItem = createNavRouteSelector(navRoutes.GLOSSARY_ITEM);
const GlossaryItemMergeHistory = () => {
    const history = useHistory();
    const glossaryItemId = useSelector(getNavGlossaryItem);
    return (
        <ViewportRoot>
            <GlossaryItemBreadcrumb glossaryItemId={glossaryItemId}/>
            <h1>Merge History</h1>
            <ButtonBox>
              <ButtonBoxButton title="Back" onClick={() => history.goBack()}>
                <IconArrowBack />
              </ButtonBoxButton>
            </ButtonBox>
            <MergeHistoryTable contentableId={glossaryItemId} />
        </ViewportRoot>
    );
};

export {GlossaryItemMergeHistory as GlossaryItemMergeHistoryView}
