import React from 'react';

import {
  Typography,
  makeStyles,
  createStyles
} from '@material-ui/core';

import {DownloadSharingTabVisibilitySpreadsheetButton} from "./DownloadSharingTabVisibilitySpreadsheetButton";

const useStyles = makeStyles(theme => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  flexShrink: {
    flex: 0,
    whiteSpace: 'noWrap',
    margin: theme.spacing(1)
  },
  flexGrow: {
    flex: 1,
    margin: theme.spacing(1)
  }
}));

const SharingTabVisibilitySpreadsheet = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.flexShrink}>
        <Typography variant="h6">Download Sharing Tab Visibility Spreadsheet</Typography>
      </div>
      <div className={classes.flexGrow} />
      <div className={classes.flexShrink}>
        <DownloadSharingTabVisibilitySpreadsheetButton  />
      </div>
    </div>
  )
};

export {
  SharingTabVisibilitySpreadsheet
}