import {onDoubleClick} from "util/onDoubleClick";
import MathJax from 'MathJax';
import {promptMathml} from "prosemirror/commands";
import {LogLevels, logProsemirror} from "prosemirror/log";

export class MathMLMediaView {
  constructor(outerView, media) {
    this.outerView = outerView;
    this.media = media;

    logProsemirror(`CONSTRUCT CpmMediaView[${this.outerView.id}]::MathMLMediaView`, {media}, LogLevels.INFO);

    this.dom = document.createElement('script');
    this.dom.setAttribute('type','math/tex');
    this.dom.innerText = media.latex;

    window.requestAnimationFrame(() => MathJax.Hub.Queue(["Typeset",MathJax.Hub,this.dom]));

    this.outerView.dom.appendChild(this.dom);

    this.badge = document.createElement('div');
    this.badge.setAttribute('class',"ProseMirror-Media-Badge ProseMirror-MathML-Badge");
    this.badge.innerText = "MML";
    this.outerView.dom.appendChild(this.badge);

    this.offDoubleClick = onDoubleClick(this.outerView.dom, () => {
      this.outerView.execute(promptMathml);
    });
  }

  destroy() {
    logProsemirror(`DESTROY CpmMediaView[${this.outerView.id}]::MathMLMediaView`, {
      media: this.media
    }, LogLevels.INFO);

    const math = MathJax.Hub.getAllJax(this.outerView.dom);
    math.forEach(jax => MathJax.Hub.Queue(["Remove",jax]));

    this.offDoubleClick();
    this.outerView.dom.removeChild(this.dom);
    this.outerView.dom.removeChild(this.badge);
  }
}