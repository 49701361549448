export default {
  NONE: {
    value: null,
    title: 'No Highlight',
  },
  HINT: {
    value: 'hint',
    title: 'Hint (blue)',
    color: '#e6e6fa',
  },
  STEP: {
    value: 'step',
    title: 'Step (green)',
    color: '#98fb98',
  },
  ANSWER: {
    value: 'answer',
    title: 'Answer (red)',
    color: '#ffc0cb',
  },
  ETOOL: {
    value: 'etool',
    title: 'eTool (yellow)',
    color: '#f0e68c',
  },
  EXAMPLE: {
    value: 'example',
    title: 'Example (purple)',
    color: '#dda0dd',
  },
  ASIDE: {
    value: 'aside',
    title: 'Aside (grey)',
    color: {
      highlight: '#e8e8e8',
      table: '#a6a6a6'
    },
  },
  HIGHLIGHT: {
    value: 'highlight',
    title: 'Highlight (bright yellow)',
    color: '#ffff99',
  },
};
