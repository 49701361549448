import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

import {useClientHook} from "reducers/client";
import {
  mergeLessonContentContainers as mergeLessonContentContainersHook,
  lessonContentContainerDetails,
  lessonContainers,
  lessonContentContainerElements,
} from "reducers/client/requestTypes";
import {clearRequest} from "reducers/client/actions";

import {LessonContentContainerReference} from "components/ContentReference";
import {MergeInformationTable} from 'components/common/Merge/MergeInformationTable';
import {useDialog} from "reducers/dialog";
import {MERGE_LESSON_CONTENT_CONTAINERS_CONFIRMATION} from "reducers/dialog/dialogIds";

const refreshElements = (containerElements, dispatch) => {
  return containerElements.sendRequest().then(result => {
    result.forEach(element => {
      dispatch(clearRequest('elementParents', element.id));
    });
  });
};

const lessonContentContainerDetailsHook = lessonContentContainerDetails();
const lessonContentContainerElementsHook = lessonContentContainerElements();
const MergeContainersConfirmationDialog = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {isOpen, data, actions} = useDialog({dialog: MERGE_LESSON_CONTENT_CONTAINERS_CONFIRMATION});
  const [mainId, mergeId] = (data && data.lessonContentContainerIds) || [];

  const mainDetails = useClientHook(lessonContentContainerDetailsHook, mainId);
  const mergeDetails = useClientHook(lessonContentContainerDetailsHook, mergeId);
  const mainElements = useClientHook(lessonContentContainerElementsHook, mainId);
  const mergeElements = useClientHook(lessonContentContainerElementsHook, mergeId);
  const mergeLessonContentContainers = useClientHook(mergeLessonContentContainersHook);

  const handleConfirm = useCallback(
    () => {
      mergeLessonContentContainers.sendRequest(data)
        .then(() => {
          // Refresh data that may be affected by the merge
          if (mainDetails.isLoaded()) dispatch(clearRequest('lessonContainers', mainDetails.get().lessonId));
          if (mergeDetails.isLoaded()) dispatch(clearRequest('lessonContainers', mergeDetails.get().lessonId));
          refreshElements(mainElements, dispatch);
          refreshElements(mergeElements, dispatch);
          dispatch(clearRequest('mergeHistory', mainId));
          dispatch(clearRequest('mergeHistory', mergeId));
          actions.close();
          history.push(`/app/lessonContentContainers/${mainId}/editor/${Object.keys(data.selection)[0]}`);
        });
    },
    [
      mergeLessonContentContainers,
      mainDetails,
      mergeDetails,
      mainElements,
      mergeElements,
      mainId,
      mergeId,
      history,
      actions,
      dispatch
    ]
  );

  if (!data) return null;
  return (<Dialog open={isOpen} onClose={actions.close} {...props}>
    <DialogTitle>Confirm merge</DialogTitle>
    <MergeInformationTable
      mainId={mainId}
      mergeId={mergeId}
      mainElements={mainElements.isLoaded() ? mainElements.get() : []}
      mergeElements={mergeElements.isLoaded() ? mergeElements.get() : []}
      selection={data && data.selection}
      ReferenceComponent={({contentableId, ...props}) => (
        <LessonContentContainerReference containerId={contentableId} {...props} />
      )}
    />
    <DialogActions>
      <Button onClick={actions.close} color="default" disabled={mergeLessonContentContainers.isLoading()}>
        Cancel
      </Button>
      <Button onClick={handleConfirm} color="primary" disabled={mergeLessonContentContainers.isLoading()}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>);
};

export {MergeContainersConfirmationDialog}
