import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconEdit from '@material-ui/icons/Edit';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';

import {ElementBreadcrumb} from 'components/common/Breadcrumb';
import AccessibilityCompliance from 'components/AccessibilityCompliance';

import {
  getSelectedElement,
  requestContentVersionControlRequirementStatuses,
  requestElementDetails,
} from "reducers/elements";
import {createNavRouteSelector, navRoutes} from "../../reducers/nav";
import {ContentPreview} from "components/editor/previews";
import {desmos, mathjax} from "../../prosemirror/processors";

const sideNavWidth = 75;
const headerHeight = 60;
const footerHeight = 43;
const gutter = 30;

const styles = theme => ({
  root: {
    position: 'absolute',
    left: `${sideNavWidth}px`,
    top: `${headerHeight}px`,
    right: `${0}px`,
    bottom: `${footerHeight}px`,
    paddingLeft: `${gutter}px`,
    paddingRight: `${gutter}px`,
    paddingBottom: `${20}px`,
    display: 'flex',
    flexDirection: 'column',
  },
  buttonBox: {
    position: 'fixed',
    top: `${65}px`,
    right: `${20}px`
  },
  button: {
    margin: theme.spacing(1),
  },
  complianceWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  preview: {
    backgroundColor: '#ffffff',
    border: `${1}px solid #dddddd`,
    boxShadow: `0 0 ${1}px 0 #dddddd`,
    minHeight: `${180}px`,
    overflowY: 'scroll',
    padding: `${10}px`,
    width: '50%',
  },
  complianceTable: {
    minHeight: `${180}px`,
    overflowY: 'scroll',
    width: '30%',
  },
  paper: {
    alignItems: 'center',
    backgroundColor: '#ffab99',
    // justifyContent: 'center',
  },
  paperChild: {
    alignSelf: 'center',
  },
});

class BaseComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentVersionId: null,
    };
  }

  componentDidMount() {
    this.tryGetDetails();
  }

  componentDidUpdate(prevProps) {
    this.tryGetDetails();

    if (
      this.props.elementDetails &&
      this.props.elementDetails[this.props.selectedElementId] &&
      this.props.elementDetails[this.props.selectedElementId].data &&
      this.props.elementDetails[this.props.selectedElementId].data.currentVersionId
    ) {
      const { currentVersionId } = this.props.elementDetails[this.props.selectedElementId].data;
      this.props.requestComplianceDetails(currentVersionId);
    }
  }

  tryGetDetails() {
    const {selectedElementId, requestElementDetails, elementRequest} = this.props;
    if (selectedElementId && elementRequest.isFetchRequired()) {
      requestElementDetails(selectedElementId);
    }
  }

  showElementEditor = () => {
    this.props.history.push(`/app/elements/${this.props.selectedElementId}/editor`);
  };

  render() {
    const {elementRequest, classes } = this.props;

    let contentVersionId = null;
    if (elementRequest.isLoaded()) {
      contentVersionId = elementRequest.get().currentVersionId;
    }

    return (
      <div className={classes.root}>
        <div className={classes.buttonBox}>
          <Tooltip title="Edit this Element">
            <Button variant="fab" color={"primary"} className={classes.button}>
              <IconEdit onClick={this.showElementEditor} />
            </Button>
          </Tooltip>
        </div>
        <h2 className="article-title">Element Compliance Review</h2>
        <div>
          <ElementBreadcrumb elementID={elementRequest.id} />
        </div>
        <div className={classnames(classes.complianceWrapper)}>

          <div
            className={classnames(classes.preview, 'displayEbooks')}
          >
            <ContentPreview contentVersionId={contentVersionId} postProcess={[mathjax, desmos]} />
          </div>

          <AccessibilityCompliance className={classnames(classes.complianceTable)} />
        </div>
      </div>);
  }
}

const StyledComponent = withStyles(styles)(BaseComponent);
const getNavElement = createNavRouteSelector(navRoutes.ELEMENT);

const mapStateToProps = state => ({
  selectedElementId: getNavElement(state),
  elementDetails: state.elements.details,
  elementRequest: getSelectedElement(state),
  prosemirrorContentVersionID: state.prosemirror.contentVersionID,
  prosemirrorContent: state.prosemirror.savedContent,
});

const mapDispatchToProps = dispatch => ({
  requestElementDetails: elementId => dispatch(requestElementDetails(elementId)),
  requestComplianceDetails: contentVersionId => dispatch(requestContentVersionControlRequirementStatuses(contentVersionId)),
  requestStandardsDetails: contentVersionId => dispatch(requestContentVersionControlRequirementStatuses(contentVersionId)),
});

export const ElementAccessibilityView = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledComponent);
