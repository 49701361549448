import React, {useMemo} from "react";

import {MergeView} from "components/common/Merge/MergeView";
import {LessonContentContainerBreadcrumb} from "components/common/Breadcrumb";

import {useClientHook} from "reducers/client";
import {lessonContentContainerElements} from "reducers/client/requestTypes";
import PropTypes from "prop-types";
import {LessonContentContainerPreview} from "components/editor/previews";

const lessonContentContainerElementsHook = lessonContentContainerElements();
const MergeLessonContentContainers = props => {
  const {mainContainerId, mergeContainerId, onCancel, onSubmit} = props;
  const mainElements = useClientHook(lessonContentContainerElementsHook, mainContainerId);
  const mergeElements = useClientHook(lessonContentContainerElementsHook, mergeContainerId);

  const sources = useMemo(() => [
    {id: mainContainerId, elements: mainElements.get() || []},
    {id: mergeContainerId, elements: mergeElements.get() || []}
  ], [mainElements, mergeElements]);

  return (
    <MergeView
      sources={sources}
      onCancel={onCancel}
      onSubmit={onSubmit}
      Breadcrumb={({id}) => <LessonContentContainerBreadcrumb containerId={id} />}
      Preview={({containerId, element}) => {
        let elementType = element && element.elementType;
        if (!elementType) return null;
        return <LessonContentContainerPreview containerId={containerId} elementType={elementType} />;
      }}
    />
  );
};
MergeLessonContentContainers.propTypes = {
  mainContainerId: PropTypes.string.isRequired,
  mergeContainerId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export {MergeLessonContentContainers}