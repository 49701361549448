import { Plugin } from 'prosemirror-state';
import {Decoration, DecorationSet} from 'prosemirror-view';

import './homework.css';

export const homeworkPlugin = () => new Plugin({
  props: {
    decorations: state => {
      const {schema, doc, selection:{$anchor}} = state;
      let decs = [];
      let hints = [];
      // If the node at the given position is a hint, save its ID
      if ($anchor.nodeAfter) {
        if ($anchor.nodeAfter.type === schema.nodes.homeworkHint) {
          hints.push($anchor.nodeAfter.attrs.id);
        } else if ($anchor.nodeAfter.type === schema.nodes.homeworkHintTarget) {
          hints.push($anchor.nodeAfter.attrs.hintId);
        }
      }
      // Go up the document tree, checking the node type at every depth.
      for (let d = $anchor.depth; d >= 0; --d) {
        let node = $anchor.node(d);
        if (node.type === schema.nodes.homeworkHint) hints.push(node.attrs.id);
        if (node.type === schema.nodes.homeworkHintTarget) hints.push(node.attrs.hintID);
      }
      doc.descendants(({type, attrs, nodeSize}, pos) => {
        let hintIndex;
        if (type === schema.nodes.homeworkHint && (hintIndex = hints.indexOf(attrs.id)) !== -1) {
          decs.push(Decoration.node(pos, pos+nodeSize, {class: `homework-hint-${hintIndex+1}`}));
        } else if (type === schema.nodes.homeworkHintTarget && (hintIndex = hints.indexOf(attrs.hintID)) !== -1) {
          decs.push(Decoration.node(pos, pos+nodeSize, {class: `homework-hint-${hintIndex+1}`}));
        }
      });
      return DecorationSet.create(doc, decs);
    }
  },
});
