import {
  Book,
  Create,
  Build,
  Fullscreen,
  Compare,
  History,
  Code,
  CheckCircle,
  Settings
} from '@material-ui/icons';

export const displayModes = {
  DISPLAY_EBOOKS: {
    id: 'DISPLAY_EBOOKS',
    name: 'eBooks',
    className: 'displayEbooks',
    icon: Book,
  },
  DISPLAY_HOMEWORK: {
    id: 'DISPLAY_HOMEWORK',
    name: 'Homework Help',
    className: 'displayHomework',
    icon: Create,
  },
  DISPLAY_ALL: {
    id: 'DISPLAY_ALL',
    name: 'Debug',
    className: 'displayAll',
    icon: Build,
  }
};

export const sidebarModes = {
  NONE: {
    id: 'NONE',
    name: 'Fullscreen Editor',
    icon: Fullscreen,
    disableWhenDirty: false,
  },
  PROPERTIES: {
    id: 'PROPERTIES',
    name: 'Content Properties',
    icon: Settings,
    disableWhenDirty: false,
  },
  MARKUP: {
    id: 'MARKUP',
    name: 'Markup',
    icon: Code,
    disableWhenDirty: false,
  },
  WCAG: {
    id: 'WCAG',
    name: 'WCAG 2.0 Compliance Status',
    icon: CheckCircle,
    disableWhenDirty: false,
    badgeContent: 'wcag'
  },
  // HISTORY: {
  //   id: 'HISTORY',
  //   name: 'History',
  //   icon: History,
  //   disableWhenDirty: true,
  // },
  // COMPLIANCE: {
  //   id: 'COMPLIANCE',
  //   name: 'Approval Status',
  //   icon: CheckCircle,
  //   disableWhenDirty: true,
  // },
};