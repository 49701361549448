import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo} from "react";
import {navSelect, navUpdate} from "./actions";
import {createNavRouteSelector} from "./selectors";

export const useNav = (route, id, silent) => {
  const routeSelector = useMemo(() => createNavRouteSelector(route), [route]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(silent ? navUpdate(route, id) : navSelect(route, id))
  }, [route, id]);

  return useSelector(routeSelector);
};
