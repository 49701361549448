import {dialog as dialogAction} from "prosemirror/connectors/actions";
import {dialogs} from "reducers/dialog";
import {getSelectionHTML} from "./readers";
import {findDescendants} from "./helpers";
import {selectionTable, getColumnWidth} from "./table";
import {DOMSerializer} from "prosemirror-model";
import {logProsemirror, LogLevels} from "../log";

export const dialog = (dialogId, data, dialogState) =>
  (state, dispatch) =>
    dispatch(dialogAction(dialogId, data, dialogState));

const getNodeViewData = nodeView => nodeView ? { attrs: nodeView.node.attrs, pos: nodeView.getPos() } : null;

export const promptColumns = nodeView => dialog(dialogs.PROSEMIRROR_COLUMNS, getNodeViewData(nodeView));
export const promptContentContainerReference = (state, dispatch) => {
  const {selection, schema} = state;
  let data;
  if (selection.$cursor) {
    // If the selection is currently empty
    data = null;
  } else {
    // Get current selection
    const slice = selection.content();
    const fragment = DOMSerializer.fromSchema(schema).serializeFragment(slice.content);
    const converter = document.createElement('article');
    converter.appendChild(fragment);

    logProsemirror('promptContentContainerReference()', {slice, fragment}, LogLevels.DEBUG);

    // Convert selection to markup & html
    data = {
      markup: slice.content.toJSON(),
      html: converter.innerHTML
    };
  }
  dispatch(dialogAction(dialogs.PROSEMIRROR_LESSON_CONTENT_CONTAINER, data));
};
export const promptDesmos = nodeView => dialog(dialogs.PROSEMIRROR_DESMOS, getNodeViewData(nodeView));
export const promptEmbed = nodeView => dialog(dialogs.PROSEMIRROR_EMBED, getNodeViewData(nodeView));
export const promptImage = nodeView => {
  let data = getNodeViewData(nodeView);
  let state = undefined;
  if (data && data.attrs && data.attrs['media-id']) {
    state = {mediaId: data.attrs['media-id']};
  }
  return dialog(dialogs.PROSEMIRROR_IMAGE, data, state);
};
export const promptLessonTitleImage = nodeView => {
  let data = getNodeViewData(nodeView);
  let state = undefined;
  if (data && data.attrs) {
    const {left, top} = data.attrs['media-offset'];
    state = {
      mediaId: data.attrs['media-id'],
      scale: parseInt(data.attrs['media-scale']) || '',
      left,
      top
    };
  }
  return dialog(dialogs.PROSEMIRROR_LESSON_TITLE_IMAGE, data, state);
};
export const promptMathquill = (nodeView, latex) => (state, dispatch) => {
  let data = getNodeViewData(nodeView) || {};
  data.latex = latex;
  if (!nodeView && !state.selection.$cursor) data.html = getSelectionHTML(state);
  dispatch(dialogAction(dialogs.PROSEMIRROR_MATHQUILL, data));
};
export const promptMathpix = nodeView => dialog(dialogs.PROSEMIRROR_MATHPIX, getNodeViewData(nodeView));
export const promptMathml = nodeView => dialog(dialogs.PROSEMIRROR_MATHML, getNodeViewData(nodeView));
export const promptLink = activeLink => {
  let data = activeLink ? {
    attrs: activeLink.node ? activeLink.node.attrs : activeLink.mark.attrs,
    start: activeLink.start,
    end: activeLink.end
  } : null;
  return dialog(dialogs.PROSEMIRROR_LINK, data);
};
export const promptGlossary = active => {
  let data = active ? {
    elementId: this.contentVersion.elementId,
    attrs: active.node ? active.node.attrs : active.mark.attrs,
    start: active.start,
    end: active.end
  } : null;
  return dialog(dialogs.PROSEMIRROR_GLOSSARY_LINK, data);
};

export const promptHomework = (state, dispatch) => {
  const hints = findDescendants(state.doc, node => node.type.name === 'homeworkHint');
  let options = hints.map(node => ({id: node.attrs.id, text: node.textContent }));
  dispatch(dialogAction(dialogs.PROSEMIRROR_HOMEWORK, options));
};
export const promptSave = (state, dispatch) => {
  // Get current selection
  const fragment = DOMSerializer.fromSchema(state.schema).serializeFragment(state.doc.content);
  const converter = document.createElement('article');
  converter.appendChild(fragment);

  logProsemirror('promptSave()', {fragment}, LogLevels.DEBUG);

  // Convert selection to markup & html
  const data = {
    markup: state.doc.toJSON(),
    html: converter.innerHTML
  };

  dispatch(dialogAction(dialogs.PROSEMIRROR_SAVE, data));
};
export const promptTable = (state, dispatch) => {
  const $table = selectionTable(state);
  if ($table) {
    let columnWidth = getColumnWidth(state);
    let data = {
      attrs: {
        ...$table.nodeAfter.attrs,
        columnWidth
      },
      pos: $table.pos
    };
    dispatch(dialogAction(dialogs.PROSEMIRROR_TABLE, data));
  }
};
