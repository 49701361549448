import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {CategoryDetailsView} from "views/category/CategoryDetails";

import {navRoutes, useNav} from "reducers/nav";

export const CategoryRoutes = ({match: {url, path, params: {categoryId}}}) => {
  const selectedCategory = useNav(navRoutes.CATEGORY, categoryId);
  if (!selectedCategory) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={CategoryDetailsView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
