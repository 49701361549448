import {DOMParser, DOMSerializer, Node} from "prosemirror-model";
import {EditorState} from "prosemirror-state";
import {EditorView} from "prosemirror-view";

import {
  logProsemirror,
  logProsemirrorStart,
  logProsemirrorKey,
  logProsemirrorLine,
  logProsemirrorEnd,
  LogLevels
} from "./log";

import {interfacePlugin, defaultPlugins} from "./plugins";
import {defaultSchema, updateDoc} from "./schema";

import {setEditorDocChanged} from "reducers/Prosemirror";

const defaultOptions = {
  schema: defaultSchema,
  plugins: defaultPlugins
};
const mergeOptions = (options) => {
  if (!options) return defaultOptions;
  let finalOptions = Object.assign({}, defaultOptions, options);
  if (options.plugins) finalOptions.plugins = defaultPlugins.concat(options.plugins);
  return finalOptions;
};

const getDoc = options => {
  if (options.markup) {
    // Load from JSON
    let documentJSON = options.markup;
    if (typeof documentJSON === 'string') { documentJSON = JSON.parse(documentJSON); }
    if (!documentJSON.type) {
      logProsemirrorLine(['documentJSON missing "type" key, attempting to repair'], LogLevels.WARN);
      documentJSON = {
        type: 'doc',
        content: documentJSON
      };
    }

    logProsemirrorKey('documentJSON from markup', documentJSON, LogLevels.INFO);
    return Node.fromJSON(options.schema, documentJSON);
  } else {
    // Load from HTML if no JSON is present
    const fragment = document.createElement('article');
    fragment.innerHTML = options.html;

    logProsemirrorKey('document HTML', options.html, LogLevels.INFO);
    return DOMParser.fromSchema(options.schema).parse(fragment, {preserveWhitespace: true});
  }
};

export class ProsemirrorInterface {
  constructor(reduxStore, history, theme) {
    this.redux = {store: reduxStore};
    this.dispatch = {
      toRedux: reduxStore.dispatch,
      toProsemirror: (transaction) => this.handle(transaction),
      toRouter: history.push
    };
    this.theme = theme;
    this.__observers = [];
    this.__handlers = {};
  }

  init(options) {
    options = mergeOptions(options);
    const { schema } = options;
    const plugins = options.plugins.concat(interfacePlugin(this));

    logProsemirrorStart('INTERFACE INIT', LogLevels.INFO);
    logProsemirrorKey('options', options, LogLevels.INFO);

    let doc = getDoc(options);

    if (options.handlers) {
      Object.entries(options.handlers).forEach(([name, callback]) => this.addHandler(name, callback));
    }

    this.options = {
      plugins,
      schema,
      doc
    };
    this.initialDoc = doc;

    this.state = EditorState.create(this.options);
    logProsemirrorKey('State', this.state, LogLevels.INFO);
    logProsemirrorEnd(LogLevels.INFO);
    if (this.view) {
      this.view.updateState(this.state);
    }
    this.dispatch.toRedux(setEditorDocChanged(false));
    // Perform any necessary updates
    updateDoc(this.state, this.dispatch.toProsemirror);
    return this.state;
  }
  backgroundInit(options) {
    if (!this.state) this.init(options);
    options = mergeOptions(options);

    logProsemirrorStart('INTERFACE BACKGROUND INIT', LogLevels.INFO);
    logProsemirrorKey('options', options, LogLevels.INFO);

    this.initialDoc = getDoc(options);
    const wasPreviouslyChanged = this.getReduxState().prosemirror.docChanged;
    if (this.state.doc.eq(this.initialDoc)) {
      if (wasPreviouslyChanged) this.dispatch.toRedux(setEditorDocChanged(false));
    } else {
      if (!wasPreviouslyChanged) this.dispatch.toRedux(setEditorDocChanged(true));
    }

    this.ping();

    logProsemirrorKey('State', this.state, LogLevels.INFO);
    logProsemirrorEnd(LogLevels.INFO);
    return this.state;
  }

  getReduxStore() {
    return this.redux.store;
  }
  getReduxState() {
    return this.redux.store.getState();
  }
  observeRedux(selector, observer) {
    const {store} = this.redux;
    let currentValue;

    const handleChange = () => {
      let previousValue = currentValue;
      currentValue = selector(store.getState());

      if (previousValue !== currentValue) {
        observer(currentValue, previousValue);
      }
    };

    const unsubscribe =  store.subscribe(handleChange);
    handleChange();
    return unsubscribe;
  }

  observe(callback) {
    this.__observers.push(callback);
    return () => this.__observers.splice(this.__observers.indexOf(callback), 1);
  }
  onStateChange(...params) {
    this.__observers.forEach(fn => fn(...params));
  }

  isConnected() {
    return this.view != null;
  }
  connect(node, options) {
    this.view = new EditorView(node, {
      state: this.state,
      dispatchTransaction: this.dispatch.toProsemirror,
      ...options,
    });
  }
  disconnect() {
    this.view.destroy();
    this.view = null;
  }

  addHandler(name, callback) {
    this.__handlers[name] = callback;
  }
  removeHandler(name) {
    this.__handlers[name] = null;
  }
  handle(transaction) {
    logProsemirror(
      `Transaction {${transaction.external ? transaction.method : transaction.time}}`,
      {transaction},
      LogLevels.SILL
    );
    if (transaction.external) {
      // External transactions are handled by custom handlers
      if (typeof this.__handlers[transaction.method] === 'function') {
        return this.__handlers[transaction.method](transaction, this);
      } else {
        logProsemirror('Unhandled transaction type', {type: transaction.method}, LogLevels.ERROR);
      }
      return false;
    } else {
      // Internal transactions are handled directly by Prosemirror
      if (this.isConnected()) {
        this.state = this.view.state.apply(transaction);
        this.view.updateState(this.state);
      } else {
        this.state = this.state.apply(transaction);
      }
      if (transaction.docChanged) {
        const wasPreviouslyChanged = this.getReduxState().prosemirror.docChanged;
        if (this.state.doc.eq(this.initialDoc)) {
          if (wasPreviouslyChanged) this.dispatch.toRedux(setEditorDocChanged(false));
        } else {
          if (!wasPreviouslyChanged) this.dispatch.toRedux(setEditorDocChanged(true));
        }
      }
      this.onStateChange(this.state, transaction);
      return true;
    }
  }
  ping() {
    // Update the state with a blank transaction to make the editor
    // account for changes in the view
    this.handle(this.state.tr.setMeta('ping',true));
  }

  execute(command) {
    return command(this.state, this.dispatch.toProsemirror, this.view);
  }
}