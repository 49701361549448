import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faListOl, faListUl} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const list = {
  ordered_numeric: <FontAwesomeIcon icon={faListOl} />,
  ordered_lower_alpha: <b style={{fontSize:'120%'}}>a</b>,
  ordered_upper_alpha: <b style={{fontSize:'120%'}}>A</b>,
  ordered_lower_roman: <b><i style={{fontFamily:'serif',fontSize:'120%'}}>i</i></b>,
  ordered_upper_roman: <b style={{fontFamily:'serif',fontSize:'120%'}}>I</b>,
  bullet: <FontAwesomeIcon icon={faListUl} />,
};
