import {apiClient} from "constants/Clients";

export const elementDetails = key => ({
  hookName: 'elementDetails',
  request: elementId => apiClient.getElement(elementId),
  key,
  auto: true,
});
export const elementStandards = key => ({
  hookName: 'elementStandards',
  request: elementId => apiClient.getClassificationStandardsForElementPublishedVersion(elementId),
  key,
  auto: true,
});
export const elementRemoveStandardFromPublishedVersion = key => ({
  hookName: 'elementRemoveStandardFromPublishedVersion',
  request: (elementId, standardId) => apiClient.removeClassificationStandardFromElementPublishedVersion(elementId, standardId),
  key,
});
export const elementAddStandardToPublishedVersion = key => ({
  hookName: 'elementAddStandardToPublishedVersion',
  request: (elementId, standardId) => apiClient.addClassificationStandardToElementPublishedVersion(elementId, standardId),
  key,
});
export const elementParents = key => ({
  hookName: 'elementParents',
  request: elementId => apiClient.getParentsForElement(elementId),
  key,
  auto: true,
});
export const updateElement = key => ({
  hookName: 'updateElement',
  request: (elementId, element) => apiClient.updateElement({id: elementId, ...element}),
  key,
});
export const deleteElement = key => ({
  hookName: 'deleteElement',
  request: elementId => apiClient.deleteElement(elementId),
  key,
});
export const elementVersions = key => ({
  hookName: 'elementVersions',
  request: elementId => apiClient.getElementVersions(elementId),
  key,
  auto: true,
});
export const elementSetCurrentVersion = key => ({
  hookName: 'elementSetCurrentVersion',
  request: (elementId, contentVersionId) => {
    return apiClient.setElementCurrentVersion(elementId, contentVersionId);
  },
  key
});
export const elementSetPublishedVersion = key => ({
  hookName: 'elementSetPublishedVersion',
  request: (elementId, contentVersionId, approvalStatusInfo) => {
    return apiClient.publishElementCurrentVersion(elementId, contentVersionId, approvalStatusInfo);
  },
  key
});
export const elementCreateCurrentVersion = key => ({
  hookName: 'elementCreateCurrentVersion',
  request: (elementId, contentVersion, isDraft) => {
    let req = apiClient.createElementCurrentVersion(elementId, contentVersion);
    if (isDraft) req.setQueryParams({isDraft});
    return req;
  },
  key
});
export const elementSplitCurrentVersion = key => ({
  hookName: 'elementSplitCurrentVersion',
  request: (elementId, contentVersion, isDraft, splitId) => {
    console.log('elementSplitCurrentVersion',elementId, contentVersion, isDraft, splitId);
    let req = apiClient.createElementCurrentVersion(elementId, contentVersion);
    let params = {
      splitId,
      comment: "SAVE_AND_UNLINK"
    };
    if (isDraft) params.isDraft = "true";
    req.setQueryParams(params);
    return req;
  },
  key
});
export const elementUnicodeCSV = key => ({
  hookName: 'elementUnicodeCSV',
  request: query => apiClient.searchElementsForUnicodeAndGetCSV(query),
  key
});
