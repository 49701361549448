import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {AssessmentProblemElementTypeRoutes} from "./elementType";

import {navRoutes, useNav} from "reducers/nav";

export const AssessmentProblemRoutes = ({match: {url, path, params: {assessmentProblemId}}}) => {
  const selectedAssessmentProblem = useNav(navRoutes.ASSESSMENT_PROBLEM, assessmentProblemId);
  if (!selectedAssessmentProblem) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/editor`} />
      <Route path={`${path}/:subRoute/:elementType`} component={AssessmentProblemElementTypeRoutes} />
      <Route path={`${path}/:subRoute`} component={AssessmentProblemElementTypeRoutes} />
      <Redirect to="/404" />
    </Switch>
  );
};
