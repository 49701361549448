import React from "react";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {createStyles, Fab, makeStyles, Tooltip} from "@material-ui/core";

const useStyles = makeStyles(theme => createStyles({
  button: {
    margin: theme.spacing(1),
  }
}), {name: "ButtonBoxButton"});

const ButtonBoxButton = ({title, color, className, onClick, children, ...props}) => {
  const classes = useStyles(props);
  return (
    <Tooltip title={title}>
      <Fab color={color} className={classNames(classes.button, className)} onClick={onClick}>
        {children}
      </Fab>
    </Tooltip>
  );
};
ButtonBoxButton.propTypes = {
  title: PropTypes.string,
  color: PropTypes.oneOf(['default','inherit','primary','secondary']),
  onClick: PropTypes.func,
};
ButtonBoxButton.defaultProps = {
  color: "primary"
};

export {ButtonBoxButton}
