export const DEFAULT_KEY = Symbol();

export const auto = hook => ({
  ...hook,
  auto: true,
});
export const manual = hook => ({
  ...hook,
  auto: false,
});
export const alias = hookName => hook => ({
  ...hook,
  hookName,
});
