import {apiClient} from "constants/Clients";
import {createCompoundKeyGenerator} from "../compoundKey";

export const createContainerKey = createCompoundKeyGenerator(['id','ebooksTypeNumber']);
export const containerRender = key => ({
  hookName: 'containerRender',
  request: ({id, ebooksTypeNumber}) =>
    apiClient.renderEbooksContainerContent(id, ebooksTypeNumber)
      .setQueryParam('noTargetService', 'true'),
  key,
  auto: true,
});