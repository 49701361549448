import {apiClient} from "constants/Clients";
import {createCompoundKeyGenerator} from "../compoundKey";

export const lessonContentContainerDetails = key => ({
  hookName: 'lessonContentContainerDetails',
  request: containerId => apiClient.getLessonContentContainer(containerId),
  key,
  auto: true,
});
export const updateLessonContentContainer = key => ({
  hookName: 'updateLessonContentContainer',
  request: (containerId, container) => apiClient.updateLessonContentContainer({id: containerId, ...container}),
  key,
});
export const saveLessonContentContainerWithContent = {
  hookName: 'saveLessonContentContainerWithContent',
  request: data => apiClient.createLessonContentContainerWithContent(data),
};
export const deleteLessonContentContainer = key => ({
  hookName: 'deleteLessonContentContainer',
  request: containerId => apiClient.deleteLessonContentContainer(containerId),
  key,
});
export const mergeLessonContentContainers = {
  hookName: 'mergeLessonContentContainers',
  request: mergeSpec =>  apiClient.mergeLessonContentContainers(mergeSpec)
};
export const lessonContentContainerCreateElement = key => ({
  hookName: 'lessonContentContainerCreateElement',
  request: (containerId, element) => apiClient.createLessonContentContainerElement(containerId, element),
  key,
});
export const lessonContentContainerElements = key => ({
  hookName: 'lessonContentContainerElements',
  request: containerId => apiClient.getLessonContentContainerElements(containerId)
    .limit(100)
    .setSortFields(['elementType'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const lessonContentContainerAddElement = key => ({
  hookName: 'lessonContentContainerAddElement',
  request: (containerId, elementId) => apiClient.addLessonContentContainerElement(containerId, elementId),
  key,
});
export const lessonContentContainerRemoveElement = key => ({
  hookName: 'lessonContentContainerRemoveElement',
  request: (containerId, elementId) => apiClient.removeLessonContentContainerElement(containerId, elementId),
  key,
});
export const homeworkResources = key => ({
  hookName: 'homeworkResources',
  request: problemId => apiClient.getHomeworkHelpProblemResources(problemId),
  key,
  auto: true,
});
export const createLessonContentContainerElementKey = createCompoundKeyGenerator(['id','elementType']);
export const lessonContentContainerReference = key => ({
  hookName: 'lessonContentContainerReference',
  request: ({id, elementType}) => apiClient.getLessonContentContainerReferenceForEditorByElementType(id, elementType),
  key,
  auto: true,
});
