import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector} from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";

import {withClient} from "reducers/client";
import {lessonContentContainerElements} from "reducers/client/requestTypes";

import {renderField, renderSelect} from "../../../common/Forms/fields";

const LessonContentContainerDialogInsertForm = ({parentType, containers, selectedContainer}) => {
  let elementTypes = [];
  if (selectedContainer) elementTypes = selectedContainer.elementTypes;
  return (
    <form>
      {(containers.length > 0) && (
        <Field name="selectedContainer" component={renderSelect} label="Lesson Content Container" fullWidth>
          {containers.map(p => <MenuItem key={p.id} value={p}>{p.name}</MenuItem>)}
        </Field>
      )}
      {elementTypes.length > 0 && (
        <Field
          name="elementType"
          component={renderSelect}
          label="Element Type"
          disabled={elementTypes.length <= 1}
          fullWidth
        >
          {elementTypes.map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)}
        </Field>
      )}
    </form>
  );
};

const formValue = formValueSelector('lessonContentContainerDialogInsert');

const mapStateToProps = state => ({
  selectedContainer: formValue(state, 'selectedContainer')
});

export default reduxForm({
  form: 'lessonContentContainerDialogInsert'
})(connect(
  mapStateToProps
)(LessonContentContainerDialogInsertForm));
