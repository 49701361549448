import React from 'react';
import { connect } from 'react-redux';

import QueueAnim from 'rc-queue-anim';

import { requestStandardDetails, makeStandardSelector } from 'reducers/standards';

import StandardLessonsTable from 'components/Tables/PagedTable/StandardLessonsTable';
import StandardLessonContentContainersTable from 'components/Tables/PagedTable/StandardLessonContentContainersTable';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core";
import {createNavRouteSelector, navRoutes} from "../../reducers/nav";

class StandardDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0,
    }
  }

  componentDidMount() {
    this.tryGetDetails();
    console.log(this.props);
  };

  componentDidUpdate() {
    this.tryGetDetails();
    console.log(this.props);

  };

  tryGetDetails() {
    const { selectedStandardId, requestStandardDetails, standardRequest } = this.props;
    if (selectedStandardId && standardRequest.isFetchRequired()) {
      requestStandardDetails(selectedStandardId)
        .catch(err => {
          console.log(err);
        });
    }
  };

  handleSelectTab = (event, value) => {
    this.setState({
      selectedTab: value,
    });
  };

  renderLessonsOrContainers = () => {
    return (
      this.state.selectedTab === 0 ?
        <StandardLessonsTable /> :
        <StandardLessonContentContainersTable />
    );
  };

  render() {
    const { standardRequest } = this.props;

    let standardDetailContent = (
      <div>
        <h2 className={"article-title"}>Loading Standard details...</h2>
      </div>
    );

    if (standardRequest.isLoaded()) {
      const standardDetails = standardRequest.get();
      standardDetailContent = (
        <article className="article">
          <h2 className="article-title">Standard -> {standardDetails.shortTitle}</h2>

          <Grid container spacing={10}>
            <Grid item xs={3}>
              <h4>Classification</h4>
              <p>{standardDetails.classification.name}</p>
            </Grid>
            <Grid item xs={3}>
              <h4>Type</h4>
              <p>{standardDetails.type}</p>
            </Grid>
            <Grid item xs={3}>
              <h4>Short Title</h4>
              <p>{standardDetails.shortTitle}</p>
            </Grid>
            <Grid item xs={3}>
              <h4>Grades</h4>
              <p>{standardDetails.grades}</p>
            </Grid>
            <Grid item xs={12}>
              <h4>Description</h4>
              <p>{standardDetails.description}</p>
            </Grid>
          </Grid>

          <AppBar position={"static"}>
            <Tabs value={this.state.selectedTab} onChange={this.handleSelectTab} indicatorColor={'primary'}>
              <Tab label={"Lessons"} />
              <Tab label={"Lesson Content Containers"} />
            </Tabs>
          </AppBar>
          {this.renderLessonsOrContainers()}
        </article>
      );
    }

    return (
      <div>
        <section className="container-fluid chapter">
          <QueueAnim type="bottom" className="ui-animate">
            <div key="1">
              {standardDetailContent}
            </div>
          </QueueAnim>
        </section>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const getNavStandard = createNavRouteSelector(navRoutes.STANDARD);
const getSelectedStandard = makeStandardSelector(getNavStandard);
const mapStateToProps = state => ({
  selectedStandardId: getNavStandard(state),
  standardRequest: getSelectedStandard(state),
});

const mapDispatchToProps = dispatch => ({
  requestStandardDetails: standardId => dispatch(requestStandardDetails(standardId))
});

export const StandardDetailsView = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StandardDetails));
