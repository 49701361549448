export const LogLevels = {
  NONE: -1,
  ERROR: 0,
  WARN: 1,
  INFO: 2,
  DEBUG: 3,
  SILL: 4,
  ALL: 5
};
export const DEFAULT_LOG_LEVEL = LogLevels.INFO;
