import {findAncestor, matchTypes, typeNamesMiddleware} from "./helpers";
import {DOMSerializer} from "prosemirror-model";

export const and = (...conditions) => state => {
  for (let i = 0; i < conditions.length; ++i) {
    if (!conditions[i](state)) return false;
  }
  return true;
};
export const or = (...conditions) => state => {
  for (let i = 0; i < conditions.length; ++i) {
    if (conditions[i](state)) return true;
  }
  return false;
};
export const not = condition => state => {
  return !condition(state);
};

const convertToHTML = (schema, content) => {
  const fragment = DOMSerializer.fromSchema(schema).serializeFragment(content);
  const converter = document.createElement('article');
  converter.appendChild(fragment);
  return converter.innerHTML;
};
export const getDocumentHTML = state => convertToHTML(state.schema, state.doc.content);
export const getSelectionHTML = state => convertToHTML(state.schema, state.selection.content().content);

export const selectionNotEmpty = state => !state.selection.empty;

export const exists = typeNamesMiddleware('nodes', nodeTypes => state => {
  let found = false;
  state.doc.descendants(node => {
    if (found) return false;
    if (nodeTypes.includes(node.type)) {
      found = true;
      return false;
    }
  });
  return found;
});

export const canInsert = typeNamesMiddleware('nodes', nodeTypes => state => {
  const { $from } = state.selection;

  for (let d = $from.depth; d >= 0; d--) {
    const index = $from.index(d);

    if (nodeTypes.some(type => $from.node(d).canReplaceWith(index, index, type))) {
      return true;
    }
  }

  return false;
});

export const markActive = typeNamesMiddleware('marks', markTypes => {
  // Shortcut logic for 0 or 1 types
  if (markTypes.length === 0) return () => false;
  if (markTypes.length === 1) {
    const type = markTypes[0];
    return state => {
      const { from, $from, to, empty } = state.selection;
      return empty
        ? type.isInSet(state.storedMarks || $from.marks())
        : state.doc.rangeHasMark(from, to, type);
    };
  }
  // Only return true if all mark types match
  return state => {
    const { from, $from, to, empty } = state.selection;
    return empty
      ? markTypes.some(t => t.isInSet(state.storedMarks || $from.marks()))
      : markTypes.some(t => state.doc.rangeHasMark(from, to, t));
  };
});

export const selectionInside = typeNamesMiddleware('nodes', nodeTypes => state => {
  const { $from } = state.selection;

  for (let d = $from.depth; d >= 0; d--) {
    if (nodeTypes.includes($from.node(d).type)) return true;
    if ($from.node(d).type.spec.isolating) return false;
  }

  return false;
});

export const selectionIs = typeNamesMiddleware('nodes', nodeTypes => state => {
  const { node } = state.selection;
  return !!(node && nodeTypes.includes(node.type));
});

export const selectionContains = typeNamesMiddleware('nodes', nodeTypes => state => {
  const { $from, $to } = state.selection;
  if ($from.nodeAfter && nodeTypes.includes($from.nodeAfter.type)) return true;
  if ($to.nodeBefore && nodeTypes.includes($to.nodeBefore.type)) return true;

  let found = false;
  state.selection.content().content.descendants(node => {
    if (found) return false;
    if (nodeTypes.includes(node.type)) {
      found = true;
      return false;
    }
  });

  return found;
});

export const getAttribute = typeNamesMiddleware('nodes',(nodeTypes, attributeName) => {
  const match = matchTypes(nodeTypes);
  return state => {
    const { $from } = state.selection;
    const $node = findAncestor($from, match);
    if ($node) return $node.nodeAfter.attrs[attributeName];
    return null;
  };
});

export const getMarkAttribute = typeNamesMiddleware('marks',(markTypes, attributeName) => {
  const match = matchTypes(markTypes);
  return state => {
    const { $from, $to } = state.selection;
    const fromMark = $from.marks().find(match);
    const toMark = $to.marks().find(match);
    if (fromMark && toMark && fromMark.eq(toMark)) return fromMark.attrs[attributeName];
    return null;
  };
});
