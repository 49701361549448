import {apiClient} from "constants/Clients";

export const standardDetails = key => ({
  hookName: 'standardDetails',
  request: (standardId) => apiClient.getClassificationStandard(standardId),
  key,
  auto: true,
});
export const updateStandard = key => ({
  hookName: 'updateStandard',
  request: (standardId, standard) => apiClient.updateClassificationStandard({id: standardId, ...standard}),
  key,
});
export const deleteStandard = key => ({
  hookName: 'deleteStandard',
  request: (standardId) => apiClient.deleteClassificationStandard(standardId),
  key,
});