import React from 'react';
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";

import {AssessmentProblemEditorView} from "views/assessmentProblem/AssessmentProblemEditor";
import {AssessmentProblemMediaView} from "views/assessmentProblem/AssessmentProblemMedia";
import {AssessmentProblemVersionsView} from "views/assessmentProblem/AssessmentProblemVersions";
import {AssessmentProblemStandardsView} from "views/assessmentProblem/AssessmentProblemStandards";

import {assessmentProblemElements} from "reducers/client/requestTypes";
import {navRoutes, createNavRouteSelector} from "reducers/nav";
import {useElementTypeNav} from "hooks/useElementTypeNav";

const getNavAssessmentProblem = createNavRouteSelector(navRoutes.ASSESSMENT_PROBLEM);
const assessmentProblemRequest = assessmentProblemElements();
export const AssessmentProblemElementTypeRoutes = (props) => {
  const {match: {params: {subRoute, elementType}}} = props;
  const assessmentProblemId = useSelector(getNavAssessmentProblem);
  const {redirect, render} = useElementTypeNav(
    assessmentProblemRequest,
    assessmentProblemId,
    elementType
  );
  if (redirect) {
    return <Redirect to={`/app/assessmentProblems/${assessmentProblemId}/${subRoute}/${redirect}`} />;
  }
  if (!render) return false;

  switch (subRoute) {
    case 'editor': return <AssessmentProblemEditorView {...props} />;
    case 'media': return <AssessmentProblemMediaView {...props} />;
    case 'versions': return <AssessmentProblemVersionsView {...props} />;
    case 'standards': return <AssessmentProblemStandardsView {...props} />;
    default: return <Redirect to="/404" />;
  }
};
