import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeading} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const makeHeadingIcon = level => (<span className="fa-layers fa-fw">
  <FontAwesomeIcon icon={faHeading} transform="left-2 up-2" />
  <span className="fa-layers-text" style={{
    fontWeight:900,
    transform: "translate(0.25em, -1.0em) scale(0.75)"
  }}>{level}</span>
</span>);
export const heading = [<FontAwesomeIcon icon={faHeading} />].concat([1,2,3,4,5,6].map(makeHeadingIcon));
