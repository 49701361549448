export const insertContentContainerReference = (containerType, containerId, elementType) => (state, dispatch) => {
  let {tr, schema} = state;
  const node = schema.nodes.contentContainerReference.createAndFill({
    containerType,
    containerId,
    elementType,
  });
  tr.replaceSelectionWith(node);
  tr.setMeta('addToHistory', false);
  return dispatch(tr);
};
