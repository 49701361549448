import React, {useCallback} from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';

const MenuOption = ({className, option, onClick, children}) => {
  const handleClick = useCallback(
    ev => onClick(ev, option.value),
    [onClick, option.value]
  );

  return (
    <div
      className={classNames("ProseMirror-MenuButton", className)}
      onMouseDown={handleClick}>
      <span title={option.title}>{children}</span>
    </div>
  );
};
MenuOption.propTypes = {
  option: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.any
  }).isRequired,
  onClick: PropTypes.func.isRequired
};

export {MenuOption}