import React from 'react';

// Material
import Typography from "@material-ui/core/Typography";

// Icons
import IconHistory from '@material-ui/icons/History';
import IconCallMerge from '@material-ui/icons/CallMerge';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconHourglass from '@material-ui/icons/HourglassEmpty';
import IconImage from '@material-ui/icons/Image';

// Styles
import withStyles from '@material-ui/core/styles/withStyles';

// Components
import { TabBreadcrumb } from 'components/common/Breadcrumb';

import PublishStatusIndicator from 'components/editor/PublishStatusIndicator';
import {LinkedTabs} from "components/LinkList";

// Constants
import config from "constants/Config";
const {color} = config;
import {displayModes} from "constants/Prosemirror";

// Actions
import {desmos, mathjax} from "prosemirror/processors";

// Client
import {withClient, createLoadedSelector} from "reducers/client";
import {tabDetails, tabElements, tabbedViewDetails, elementDetails} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ElementEditor} from "components/editor/ElementEditor";
import {LessonMenu} from "prosemirror/menu";
import {setBookId, setContext} from "../../reducers/Prosemirror";
import {connect} from "react-redux";
import {ButtonBox, ButtonBoxButton, EditorViewport, FlexColumns} from "components/layout";

const styles = theme => ({
  title: {
    margin: `0 ${theme.spacing(1)}px`,
    color: theme.palette.primary.main,
  },
});

class TabEditor extends React.Component {
  componentDidMount() {
    const {tabbedViewDetails, tabDetails, prosemirrorContextId, setProsemirrorContext} = this.props;
    if (tabbedViewDetails.isLoaded()) {
      const id = tabDetails.getParams();
      const {bookId} = tabbedViewDetails.get();
      if (id !== prosemirrorContextId) {
        setProsemirrorContext(bookId, 'tab', id);
      }
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {tabbedViewDetails, tabDetails, prosemirrorContextId, setProsemirrorContext} = this.props;
    if (tabbedViewDetails.isLoaded()) {
      const id = tabDetails.getParams();
      const {bookId} = tabbedViewDetails.get();
      if (id !== prosemirrorContextId) {
        setProsemirrorContext(bookId, 'tab', id);
      }
    }
  }

  showElementImages = () => {
    const {tabDetails} = this.props;
    this.props.history.push(`/app/tabs/${tabDetails.getParams()}/media`);
  };
  showElementVersions = () => {
    const {tabDetails} = this.props;
    this.props.history.push(`/app/tabs/${tabDetails.getParams()}/versions`);
  };
  showMerge = () => {
    const {tabDetails} = this.props;
    this.props.history.push(`/app/tabs/${tabDetails.getParams()}/merge`);
  };
  showMergeHistory = () => {
    const {tabDetails} = this.props;
    this.props.history.push(`/app/tabs/${tabDetails.getParams()}/mergeHistory`);
  };
  showElementAccessibility = () => {
    const {elementDetails} = this.props;
    if (elementDetails.isLoaded()) {
      this.props.history.push(`/app/elements/${elementDetails.get().id}/accessibility`);
    }
  };


  render() {
    const {
      classes,
      tabDetails,
      elementDetails,
    } = this.props;

    let selectedElementId = elementDetails.getParams() || '';
    let contentVersionId = elementDetails.isLoaded() ? elementDetails.get().currentVersionId : '';

    return (
      <EditorViewport>
        <ButtonBox>
          <PublishStatusIndicator contentVersionId={contentVersionId} />
          <ButtonBoxButton title="Images Overview" onClick={this.showElementImages}>
            <IconImage />
          </ButtonBoxButton>
          <ButtonBoxButton title="Version History" onClick={this.showElementVersions}>
            <IconHistory />
          </ButtonBoxButton>
          <ButtonBoxButton title="Share with another Tab" onClick={this.showMerge}>
            <IconCallMerge />
          </ButtonBoxButton>
          <ButtonBoxButton title="Merge History" onClick={this.showMergeHistory}>
            <IconHourglass />
          </ButtonBoxButton>
          <ButtonBoxButton title="Show Accessibility Compliance" onClick={this.showElementAccessibility}>
            <IconCheckCircle />
          </ButtonBoxButton>
        </ButtonBox>
        <FlexColumns noShrink>
          <Typography className={classes.title} variant="h6">Tab Content Editor</Typography>
          <TabBreadcrumb tabId={tabDetails.getParams()} />
          <div className={classes.spacer} />
          <LinkedTabs tabId={tabDetails.getParams()} />
        </FlexColumns>
        <ElementEditor
          elementId={selectedElementId}
          menu={LessonMenu}
          previewProps={{ postProcess: [mathjax, desmos] }}
          displayMode={displayModes.DISPLAY_EBOOKS}
        />
      </EditorViewport>);
  }
}

const mapStateToProps = (state, props) => ({
  prosemirrorContextId: state.prosemirror.contextId,
});
const mapDispatchToProps = dispatch => ({
  setProsemirrorContext: (...context) => dispatch(setContext(...context))
});

const getNavTab = createNavRouteSelector(navRoutes.TAB);
const getElementId = createLoadedSelector('tabElements', getNavTab, tabElements => tabElements[0].id);
const getTabbedViewId = createLoadedSelector('tabDetails', getNavTab, tabDetails => tabDetails.tabbedViewId);
export const TabEditorView = withClient({
  hooks: {
    tabDetails: tabDetails(getNavTab),
    tabElements: tabElements(getNavTab),
    tabbedViewDetails: tabbedViewDetails(getTabbedViewId),
    elementDetails: elementDetails(getElementId)
  }
})(connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(TabEditor)
));
