import React from 'react';
import {useSelector} from "react-redux";

import {Typography, Paper} from "@material-ui/core";

import {ViewportRoot, ViewportSection} from "components/layout";
import { GradeBreadcrumb } from 'components/common/Breadcrumb';
import {GradeStandardsTable} from 'components/grade/GradeStandardsTable';
import {GradeDetailsTable} from "components/grade/GradeDetailsTable";

import {createNavRouteSelector, navRoutes} from "reducers/nav";

const getNavGrade = createNavRouteSelector(navRoutes.GRADE);
const GradeDetails = () => {
  const gradeId = useSelector(getNavGrade);

  return (
    <ViewportRoot>
      <ViewportSection>
        <GradeBreadcrumb gradeId={gradeId} />
        <Typography variant="h3" gutterBottom>Grade Information</Typography>
        <Paper><GradeDetailsTable gradeId={gradeId} /></Paper>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Standards</Typography>
        <Paper><GradeStandardsTable gradeId={gradeId} /></Paper>
      </ViewportSection>
    </ViewportRoot>
  );
};

export {GradeDetails as GradeDetailsView}
