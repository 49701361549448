import React from 'react';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {assessmentProblemDetails, assessmentProblemRender} from "reducers/client/requestTypes";
import {HtmlPreview} from "./HtmlPreview";

import ElementTypes from 'constants/ElementTypes';

const assessmentProblemDetailsHook = assessmentProblemDetails();
const assessmentProblemRenderHook = assessmentProblemRender();
const AssessmentPreview = ({assessmentProblemId, elementType, ...innerProps}) => {
  const assessmentProblemDetails = useClientHook(assessmentProblemDetailsHook, assessmentProblemId);
  let assessmentQuestionNumber = null;
  if (assessmentProblemDetails.isLoaded()) {
    assessmentQuestionNumber = assessmentProblemDetails.get().assessmentQuestionId;
  }
  const assessmentProblemRender = useClientHook(assessmentProblemRenderHook, assessmentQuestionNumber);

  if (assessmentProblemRender.isLoaded()) {
    if (elementType === ElementTypes.assessmentproblem.name) {
      return <HtmlPreview html={assessmentProblemRender.get().question_html} {...innerProps} />;
    } else if (elementType === ElementTypes.assessmentproblemanswer.name) {
      return <HtmlPreview html={assessmentProblemRender.get().answer_html} {...innerProps} />;
    }
  }
  return null;
};
AssessmentPreview.propTypes = {
  ...HtmlPreview.propTypes,
  assessmentProblemId: PropTypes.string,
  elementType: PropTypes.string,
};

export {AssessmentPreview}
