import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {GlossaryItemRoutes} from './glossaryItem';

export const GlossaryRoutes = ({match}) => (
  <Switch>
    <Route path={`${match.path}/:glossaryItemId`} component={GlossaryItemRoutes} />
    <Redirect to="/404" />
  </Switch>
);
