import React, { useMemo} from "react";
import PropTypes from 'prop-types';

import {GlossaryItemBreadcrumb} from "components/common/Breadcrumb";

import {useClientHook} from "reducers/client";
import {glossaryItemElements} from "reducers/client/requestTypes";
import {MergeView} from "components/common/Merge/MergeView";


const glossaryItemElementsHook = glossaryItemElements();
const MergeGlossaryItems = props => {
  const {mainGlossaryItemId, mergeGlossaryItemId, onCancel, onSubmit} = props;
  const mainElements = useClientHook(glossaryItemElementsHook, mainGlossaryItemId);
  const mergeElements = useClientHook(glossaryItemElementsHook, mergeGlossaryItemId);

  const sources = useMemo(() => [
    {id: mainGlossaryItemId, elements: mainElements.get() || []},
    {id: mergeGlossaryItemId, elements: mergeElements.get() || []}
  ], [mainElements, mergeElements]);

  return (
    <MergeView
      sources={sources}
      onCancel={onCancel}
      onSubmit={onSubmit}
      Breadcrumb={({id}) => <GlossaryItemBreadcrumb glossaryItemId={id} />}
    />
  );
};
MergeGlossaryItems.propTypes = {
  mainGlossaryItemId: PropTypes.string.isRequired,
  mergeGlossaryItemId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export {MergeGlossaryItems}
