import React, {useMemo} from "react";

import {MergeView} from "components/common/Merge/MergeView";
import {TabBreadcrumb} from "components/common/Breadcrumb";

import {useClientHook} from "reducers/client";
import {tabElements} from "reducers/client/requestTypes";
import PropTypes from "prop-types";

const tabElementsHook = tabElements();
const MergeTabs = props => {
  const {mainTabId, mergeTabId, onCancel, onSubmit} = props;
  const mainElements = useClientHook(tabElementsHook, mainTabId);
  const mergeElements = useClientHook(tabElementsHook, mergeTabId);

  const sources = useMemo(() => [
    {id: mainTabId, elements: mainElements.get() || []},
    {id: mergeTabId, elements: mergeElements.get() || []}
  ], [mainElements, mergeElements]);

  return (
    <MergeView
      sources={sources}
      onCancel={onCancel}
      onSubmit={onSubmit}
      Breadcrumb={({id}) => <TabBreadcrumb tabId={id} />}
    />
  );
};
MergeTabs.propTypes = {
  mainTabId: PropTypes.string.isRequired,
  mergeTabId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export {MergeTabs}