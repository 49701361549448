import {liftListItem as pmLiftListItem, sinkListItem as pmSinkListItem} from "prosemirror-schema-list";
import {chainCommands} from "prosemirror-commands";
import {goToNextCell} from "prosemirror-tables";

import {indent} from "prosemirror/commands/textBlock";
import {typeNameMiddleware} from "prosemirror/commands/helpers";

const liftListItem = typeNameMiddleware('nodes', pmLiftListItem);
const sinkListItem = typeNameMiddleware('nodes', pmSinkListItem);

export const tabKeys = {
  Tab: chainCommands(sinkListItem('list_item'), indent(1), goToNextCell(1)),
  'Shift-Tab': chainCommands(indent(-1), liftListItem('list_item'), goToNextCell(-1))
};
