import React from "react";
import {Field, reduxForm} from "redux-form";

import MenuItem from "@material-ui/core/MenuItem";
import {renderSelect} from "components/common/Forms/fields";

const CreateLessonContentContainerElementDialogForm = ({blacklist, elementTypes}) => (<form>
  <Field name="elementType" component={renderSelect} label="Type" disabled={elementTypes.length <= 1}>
    {elementTypes.map(t => (
      <MenuItem key={t.name} value={t} disabled={blacklist.includes(t.name)}>{t.displayName || t.name}</MenuItem>
    ))}
  </Field>
</form>);

export default reduxForm({
  form: 'createLessonContentContainerElementDialog'
})(CreateLessonContentContainerElementDialogForm);
