import {createSelector as reselect} from "reselect";
import {RequestState} from "./util";

export const createSelector = (hookName, key, getState) => {
  const hookResolver = getState ? state => getState(state)[hookName] : state => state.client[hookName];
  const keyResolver = typeof key === 'function' ? key : () => key;
  const requestResolver = reselect([hookResolver, keyResolver], (hookState, resolvedKey) => {
    if (!hookState) return null;
    return hookState.find(r => r.metadata.params === resolvedKey);
  });
  return reselect(
    [requestResolver, keyResolver],
    (requestState, resolvedKey) => new RequestState(requestState, resolvedKey)
  );
};

export const createLoadedSelector = (requestName, key, transform, getState) => {
  let requestResolver = createSelector(requestName, key, getState);
  return reselect([requestResolver], requestState => {
    if (requestState && requestState.isLoaded()) return transform(requestState.get());
    return null;
  });
};
