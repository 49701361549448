import React from 'react';
import {connect} from 'react-redux';

import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';

import PagedTableToolbar from './toolbar';
import { PagedTableHeadCells, PagedTableBodyCells } from './tableColumns';

/**
 * @typedef {Object} PagedTable~Options
 */

// Default options
const defaults = {
  select: 'none',
  actions: false
};

/**
 * PagedTable - Higher-Order Component for Paged Tables
 * @param options
 * @returns {React.Component}
 */
export default (options) => {
  options = {...defaults, ...options};
  // Styles
  const styles = theme => ({
    root: {
      width: '100%',
    },
    tableWrapper: {
      overflowX: 'auto',
    },
  });

  class pagedComponent extends React.Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      this.tryGetPage();
    }

    componentDidUpdate() {
      this.tryGetPage();
    }


    tryGetPage() {
      const { requestPage, pageRequest, outerProps } = this.props;
      const {valid} = this.props.tableState;
      if (!valid && !pageRequest.pending) {
        requestPage(outerProps);
      }
    }

    /**
     * handleClick - toggle selection on current row when clicked
     * @param event - DOM event
     * @param row - row data
     */
    handleClick = (event, row) => {
      switch(options.select) {
        case 'single':
          const { selected } = this.props.tableState;
          if (selected[0] !== row.id) {
            this.props.setSelection(row.id);
          } else {
            this.props.clearSelection();
          }
          break;
        case 'multiple':
          this.props.toggleSelection(row.id);
          break;
      }
      if (this.props.outerProps.onClick) {
        this.props.outerProps.onClick(event,row);
      }
    };

    /**
     * handleRequestSort - update sort state when a column header is clicked
     * @param event - DOM event
     * @param property - column name
     */
    handleRequestSort = (event, property) => {
      this.props.changeSort(property);
    };

    /**
     * handleSelectAllClick - toggle selection when the 'all' checkbox is clicked
     * @param event - DOM event
     * @param checked - new checked state
     */
    handleSelectAllClick = (event, checked) => {
      if (checked) {
        this.props.addSelection(this.props.data.map(n => n.id));
      } else {
        this.props.clearSelection();
      }
    };

    /**
     * handleChangePage - update the pagination state when the pagination control is used
     * @param event - DOM event
     * @param page - new page
     */
    handleChangePage = (event, page) => {
      this.props.changePage(page);
    };

    /**
     * handleChangeRowsPerPage - update the pagination state when a new number of rows is chosen
     * @param event - DOM event
     */
    handleChangeRowsPerPage = (event) => {
      this.props.changePageSize(event.target.value);
    };

    /**
     * setSearchValue - update the search filter state when the search input is changed
     * @param value - new search filter value
     */
    handleSearch(value) {
      this.props.changeSearch(value);
    }

    /**
     * isSelected
     * @param id - row ID
     * @param selected - selected rows (from Redux)
     * @return {boolean} - whether the row is currently selected
     */
    isSelected = (id, selected) => selected.indexOf(id) !== -1;

    render() {
      const { classes, data, totalCount, outerProps } = this.props;
      const { selected } = this.props.tableState;
      const { sort, pageSize, page, search } = this.props.tableState.pagination;
      const {title, columnData, actions} = options;

      let renderedActions = false;
      if (typeof actions === 'function') {
        renderedActions = actions(outerProps);
      } else if (React.isValidElement(actions)) {
        renderedActions = actions;
      }

      return (
        <Paper className={classes.root}>
          <PagedTableToolbar
            title={title}
            onSearch={value => this.handleSearch(value)}
            searchValue={search}
            select={options.select}
            numSelected={selected.length}
            actions={renderedActions} />
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <PagedTableHeadCells
                    select={options.select}
                    numSelected={selected.length}
                    sort={sort}
                    onSelectAllClick={this.handleSelectAllClick}
                    onRequestSort={this.handleRequestSort}
                    count={data.length}
                    columnData={columnData}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => {
                  const isSelected = this.isSelected(row.id, selected);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isSelected}>
                      <PagedTableBodyCells
                        row={row}
                        select={options.select}
                        isSelected={isSelected}
                        columnData={columnData} />
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={totalCount}
                    rowsPerPage={pageSize}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      );
    }
  }

  // Return new component type with styles, connected to Redux
  const {Selectors, Actions} = options.reducer;
  const mapStateToProps = state => ({
    pageRequest: Selectors.getPageRequest(state),
    data: Selectors.getPageContent(state),
    totalCount: Selectors.getTotalCount(state),
    tableState: Selectors.getState(state),
  });
  const mapDispatchToProps = dispatch => ({
    requestPage: props => dispatch(Actions.requestPage(props)),
    changePage: page => dispatch(Actions.changePage(page)),
    changePageSize: pageSize => dispatch(Actions.changePageSize(pageSize)),
    changeSort: column => dispatch(Actions.changeSort(column)),
    changeSearch: value => dispatch(Actions.changeSearch(value)),

    setSelection: values => dispatch(Actions.setSelection(values)),
    addSelection: values => dispatch(Actions.addSelection(values)),
    removeSelection: values => dispatch(Actions.removeSelection(values)),
    toggleSelection: values => dispatch(Actions.toggleSelection(values)),
    clearSelection: () => dispatch(Actions.clearSelection()),
  });
  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({ outerProps: ownProps }, stateProps, dispatchProps);
  };

  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(withStyles(styles)(pagedComponent));
};
