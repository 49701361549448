import React, {useCallback, useMemo} from 'react';

import {useClientHook} from "reducers/client";
import {assessmentGroupDetails, updateAssessmentGroup} from "reducers/client/requestTypes";

import {detailsTable} from "components/common/DetailsTable";
import {formatDate} from "constants/Date";
import {LinearProgress} from "@material-ui/core";

const InternalDetailsTable = detailsTable([
  {key: 'name', name: "Name"},
  {key: 'createdAt', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', name: "Last Updated", render: formatDate, readOnly: true},
]);
InternalDetailsTable.displayName = "AssessmentGroupDetailsTableInternal";

const assessmentGroupDetailsHook = assessmentGroupDetails();
const updateAssessmentGroupHook = updateAssessmentGroup();
export const AssessmentGroupDetailsTable = ({assessmentGroupId}) => {
  const assessmentGroupDetails = useClientHook(assessmentGroupDetailsHook, assessmentGroupId);
  const updateAssessmentGroup = useClientHook(updateAssessmentGroupHook, assessmentGroupId);

  const handleSave = useCallback(state => {
    updateAssessmentGroup.sendRequest({id: assessmentGroupId, ...state}).then(() => assessmentGroupDetails.sendRequest());
  }, [updateAssessmentGroup, assessmentGroupDetails, assessmentGroupId]);

  const waiting = useMemo(
    () => assessmentGroupDetails.isLoading() || updateAssessmentGroup.isLoading(),
    [assessmentGroupDetails, updateAssessmentGroup]
  );

  return (
    <React.Fragment>
      {waiting && <LinearProgress />}
      <InternalDetailsTable
        initialState={assessmentGroupDetails.get()}
        onSave={handleSave}
        disabled={waiting}
      />
    </React.Fragment>
  )
};