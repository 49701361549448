import {PLUGIN_INTERFACE} from "prosemirror/plugins/interface";
import {onDoubleClick} from "util/onDoubleClick";
import {dialog} from "prosemirror/connectors/actions";
import {dialogs} from "reducers/dialog";
import {LogLevels, logProsemirror} from "prosemirror/log";

import './embed.css';

let count = 0;

// https://stackoverflow.com/a/27728417
const getYoutubeId = url => {
  let m = url.match(/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/);
  return m && m[1];
};

export class CpmEmbedView {
  constructor(node, view, getPos) {
    this.interface = PLUGIN_INTERFACE.get(view.state).interface;

    this.id = count++;

    logProsemirror(`CONSTRUCT CpmEmbedView [${this.id}]`, {node}, LogLevels.INFO);

    this.node = node;
    this.outerView = view;
    this.getPos = getPos;

    this.dom = document.createElement('div');
    this.dom.setAttribute('class','c3po-embed');
    this.setStyleAttributes(this.node.attrs);

    onDoubleClick(this.dom, () => {
      this.outerView.dispatch(dialog(dialogs.PROSEMIRROR_EMBED, {
        attrs: this.node.attrs,
        pos: this.getPos()
      }));
    });
  }

  selectNode() {
    logProsemirror(`CpmEmbedView [${this.id}] :: selectNode()`, null, LogLevels.SILL);
    this.dom.classList.add('ProseMirror-selectednode');
  }
  deselectNode() {
    logProsemirror(`CpmEmbedView [${this.id}] :: deselectNode()`, null, LogLevels.SILL);
    this.dom.classList.remove('ProseMirror-selectednode');
  }

  setStyleAttributes(attrs) {
    console.log(attrs);
    if (this.innerDom) this.dom.removeChild(this.innerDom);

    let videoId = attrs.src && getYoutubeId(attrs.src);
    if (videoId) {
      logProsemirror(`CpmEmbedView [${this.id}] :: setStyleAttributes()`, {
        youtube: videoId
      }, LogLevels.DEBUG);
      this.innerDom = document.createElement('img');
      this.innerDom.setAttribute('src',`https://img.youtube.com/vi/${videoId}/default.jpg`);
    } else if (/vimeo/i.exec(attrs.src) !== null) {
      this.innerDom = document.createElement('img');
      fetch(`https://vimeo.com/api/oembed.json?url=${attrs.src}`)
        .then(response => response.json())
        .then(json => {
          logProsemirror(`CpmEmbedView [${this.id}] :: setStyleAttributes()`, {
            vimeo: json
          }, LogLevels.DEBUG);
          this.innerDom.setAttribute('src',json.thumbnail_url);
        });
    } else {
      this.innerDom = document.createElement('iframe');
      this.innerDom.setAttribute('src',attrs.src);

      if (attrs.fullscreen) {
        this.innerDom.setAttribute('allow','fullscreen');
        this.innerDom.setAttribute('allowFullscreen', '');
      }
    }

    if (attrs.width > 0) { this.innerDom.style.width = `${attrs.width}px`; }
    else { this.innerDom.style.width = '100%'; }
    if (attrs.height > 0) { this.innerDom.style.height = `${attrs.height}px`; }
    else { this.innerDom.style.height = '300px'; }
    this.dom.appendChild(this.innerDom);
  }

  update(node) {
    if (node.type.name !== 'embed') return false;
    logProsemirror(`UPDATE CpmEmbedView [${this.id}]`, {node}, LogLevels.DEBUG);
    this.node = node;
    this.setStyleAttributes(this.node.attrs);
    return true;
  }

  destroy() {
    logProsemirror(`DESTROY CpmEmbedView [${this.id}]`, null, LogLevels.INFO);
  }

  stopEvent() { return false; }
  ignoreMutation() { return true; }
}
