import React from 'react';

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import {CategoriesTable} from 'components/category/CategoriesTable';
import {ViewportRoot, ViewportSection} from "components/layout";

const CategoryList = () => (
  <ViewportRoot>
    <ViewportSection>
      <Typography variant="h3" gutterBottom>CPM eBook Categories</Typography>
      <Paper>
        <CategoriesTable />
      </Paper>
    </ViewportSection>
  </ViewportRoot>
);

export {CategoryList as CategoryListView}
