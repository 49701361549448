import React from 'react';

import {MenuGroup} from "../components/menu";
import {MenuBookSpecific} from "../components/menu/MenuBookSpecific";
import * as blocks from "./blocks";
import * as marks from './marks';
import * as insert from "./insert";
import * as table from './table';
import * as columns from './columns';
import * as list from './list';
import * as history from "./history";
import * as hr from "./hr";
import {ListTypes} from "../commands/list";

const baseBlocks = [
  blocks.publish,
  blocks.quickSave,
  blocks.textBlock,
  blocks.align,
    blocks.metadata
];
const allListOptions = blocks.makeListSelect(Object.values(ListTypes));
const assessmentListOptions = blocks.makeListSelect([ListTypes.NUMERIC, ListTypes.BULLET]);
const lessonBlocks = [
  blocks.mathNotes,
  blocks.contentContainerReference,
  allListOptions,
  blocks.lessonTitle
];

const baseMarks = [
  marks.font,
  marks.em,
  marks.strong,
  marks.underline,
  marks.link,
  marks.glossary,
  marks.highlight,
  marks.box,
  marks.screenReaderOnly,
  marks.bookSpecific,
];
const containerMarks = [
  marks.ebooks,
  marks.ebooksNode,
  marks.homework,
  marks.homeworkNode,
  marks.splitBlockNode
];

const baseInsert = [
  insert.anchor,
  insert.hr,
  insert.image,
  insert.mathQuill,
  insert.table,
];
const lessonInsert = [
  insert.columns,
  insert.desmos,
  insert.embed,
];
const containerInsert = [
  insert.homeworkLink,
  insert.homeworkHint,
  insert.homeworkHintTarget,
  insert.sequenceContainer,
];

const baseTable = [
  table.properties,
  table.addCells.all,
  table.mergeCells,
  table.splitCell,
  table.toggleHeader,
  table.alignment,
  table.backgroundColor
];
const tableBorders = [
  table.borders,
  table.borderWidth
];

const columnsGroup = [columns.alignment, columns.breakpoint, columns.borderWidth, columns.remove];
const listGroup = [list.columns];
const historyGroup = [history.undo, history.redo];
const hrGroup = [hr.thickness];

export const LessonMenu = (
  <React.Fragment>
    <MenuGroup key="blocks">{baseBlocks.concat(lessonBlocks)}</MenuGroup>
    <MenuGroup key="marks">{baseMarks}</MenuGroup>
    <MenuGroup key="insert">{baseInsert.concat(lessonInsert)}</MenuGroup>
    <table.TableMenuGroup key="table">{baseTable.concat(tableBorders)}</table.TableMenuGroup>
    <columns.ColumnsMenuGroup key="columns">{columnsGroup}</columns.ColumnsMenuGroup>
    <list.ListMenuGroup key="list">{listGroup}</list.ListMenuGroup>
    <hr.HrMenuGroup key="hr">{hrGroup}</hr.HrMenuGroup>
    <MenuGroup key="history">{historyGroup}</MenuGroup>
  </React.Fragment>
);

export const ContainerMenu = (
  <React.Fragment>
    <MenuGroup key="blocks">{baseBlocks.concat(allListOptions)}</MenuGroup>
    <MenuGroup key="marks">{baseMarks.concat(containerMarks)}</MenuGroup>
    <MenuGroup key="insert">{baseInsert.concat(lessonInsert,containerInsert)}</MenuGroup>
    <table.TableMenuGroup key="table">{baseTable.concat(tableBorders)}</table.TableMenuGroup>
    <columns.ColumnsMenuGroup key="columns">{columnsGroup}</columns.ColumnsMenuGroup>
    <list.ListMenuGroup key="list">{listGroup}</list.ListMenuGroup>
    <hr.HrMenuGroup key="hr">{hrGroup}</hr.HrMenuGroup>
    <MenuGroup key="history">{historyGroup}</MenuGroup>
  </React.Fragment>
);

export const AssessmentMenu = (
  <React.Fragment>
    <MenuGroup key="blocks">{baseBlocks.concat(assessmentListOptions)}</MenuGroup>
    <MenuGroup key="marks">{baseMarks}</MenuGroup>
    <MenuGroup key="insert">{baseInsert}</MenuGroup>
    <table.TableMenuGroup key="table">{baseTable}</table.TableMenuGroup>
    <columns.ColumnsMenuGroup key="columns">{columnsGroup}</columns.ColumnsMenuGroup>
    <list.ListMenuGroup key="list">{listGroup}</list.ListMenuGroup>
    <hr.HrMenuGroup key="hr">{hrGroup}</hr.HrMenuGroup>
    <MenuGroup key="history">{historyGroup}</MenuGroup>
  </React.Fragment>
);

export const GlossaryItemMenu = (
  <React.Fragment>
    <MenuGroup key="blocks">{baseBlocks.concat(lessonBlocks)}</MenuGroup>
    <MenuGroup key="marks">{baseMarks}</MenuGroup>
    <MenuGroup key="insert">{baseInsert.concat(lessonInsert)}</MenuGroup>
    <table.TableMenuGroup key="table">{baseTable.concat(tableBorders)}</table.TableMenuGroup>
    <columns.ColumnsMenuGroup key="columns">{columnsGroup}</columns.ColumnsMenuGroup>
    <list.ListMenuGroup key="list">{listGroup}</list.ListMenuGroup>
    <hr.HrMenuGroup key="hr">{hrGroup}</hr.HrMenuGroup>
    <MenuGroup key="history">{historyGroup}</MenuGroup>
  </React.Fragment>
);