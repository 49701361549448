import React from 'react';
import PropTypes from 'prop-types';

import ElementParents from './ElementParents';
import {withClient} from "reducers/client";
import {sectionElements} from "reducers/client/requestTypes";

const LinkedSections = ({sectionElements, elementType}) => {
  if (!sectionElements.isLoaded()) return null;
  const element = sectionElements.get().find(e => e.elementType === elementType);
  if (element) return <ElementParents elementId={element.id} />;
  return null;
};

LinkedSections.propTypes = {
  containerId: PropTypes.string.isRequired,
  elementType: PropTypes.string.isRequired,
};

export default withClient({
  hooks: {
    sectionElements: sectionElements((state, props) => props.containerId),
  }
})(LinkedSections);
