import React, {useMemo} from "react";
import PropTypes from "prop-types";

import {SectionBreadcrumb} from "components/common/Breadcrumb";
import {MergeView} from "components/common/Merge/MergeView";

import {useClientHook} from "reducers/client";
import {sectionElements} from "reducers/client/requestTypes";

const sectionElementsHook = sectionElements();
const MergeSections = props => {
  const {mainSectionId, mergeSectionId, onCancel, onSubmit} = props;
  const mainElements = useClientHook(sectionElementsHook, mainSectionId);
  const mergeElements = useClientHook(sectionElementsHook, mergeSectionId);

  const sources = useMemo(() => [
    {id: mainSectionId, elements: mainElements.get() || []},
    {id: mergeSectionId, elements: mergeElements.get() || []}
  ], [mainElements, mergeElements]);

  return (
    <MergeView
      sources={sources}
      onCancel={onCancel}
      onSubmit={onSubmit}
      Breadcrumb={({id}) => <SectionBreadcrumb sectionId={id} />}
      initSelected={false}
    />
  );
};
MergeSections.propTypes = {
  mainSectionId: PropTypes.string.isRequired,
  mergeSectionId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export {MergeSections}
