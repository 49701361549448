import React from 'react';

import IconArrowBack from '@material-ui/icons/ArrowBack';

import {TabBreadcrumb} from 'components/common/Breadcrumb'
import MergeHistoryTable from 'components/common/Merge/MergeHistoryTable';

import {withClient} from "reducers/client";
import {tabDetails} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";

class TabMergeHistory extends React.Component {
  render() {
    const {tabDetails, history} = this.props;
    return (
      <ViewportRoot>
        <TabBreadcrumb tabId={tabDetails.getParams()}/>
        <h1>Merge History</h1>
        <ButtonBox>
          <ButtonBoxButton title="Back" onClick={() => history.goBack()}>
            <IconArrowBack />
          </ButtonBoxButton>
        </ButtonBox>
        <MergeHistoryTable contentableId={tabDetails.getParams()} />
      </ViewportRoot>
    )
  }
}

const getNavTab = createNavRouteSelector(navRoutes.TAB);
export const TabMergeHistoryView = withClient({
  hooks: {
    tabDetails: tabDetails(getNavTab),
  }
})(TabMergeHistory);