import {TextSelection} from "prosemirror-state";

import {PLUGIN_INTERFACE} from "prosemirror/plugins/interface";

import MediaTypes from "constants/MediaTypes";
import {promptLessonTitleImage} from "prosemirror/commands";
import {LogLevels, logProsemirror} from "prosemirror/log";
import {createObserver} from "reducers/client";
import {mediaDetails} from "reducers/client/requestTypes";

import './lessonTitle.css';

const defaultInputAttributes = {type: 'text'};
const input = (root, getValue, setValue, attrs) => {
  attrs = { ...defaultInputAttributes, ...attrs };
  const dom = document.createElement('input');
  dom.setAttribute('type','text');
  for (let name in attrs) { if (attrs.hasOwnProperty(name)) {
    dom.setAttribute(name, attrs[name]);
  }}
  dom.value = getValue();

  dom.addEventListener('mousedown', ev => {
    // Prevent dragging the title node while clicking inside input
    root.setAttribute('draggable', false);
    const reset = ev => {
      root.setAttribute('draggable', false);
      root.removeEventListener('mouseup', reset);
    };
    root.addEventListener('mouseup', reset);
    ev.stopPropagation();
  });
  dom.addEventListener('mousemove', ev => ev.stopPropagation());
  dom.addEventListener('input', ev => {
    if (dom.value !== getValue()) setValue(dom.value);
  });
  return dom;
};

let count = 0;

const createMediaObserver = (nodeView) => createObserver(
  {
    hooks: {
      mediaDetails: mediaDetails((state, props) => props.mediaId)
    },
    props: {mediaId: nodeView.mediaId}
  },
  nodeView.interface.getReduxStore(),
  clientState => nodeView.onMediaUpdated(clientState)
);

export class LessonTitleView {
  constructor(node, view, getPos) {
    this.interface = PLUGIN_INTERFACE.get(view.state).interface;

    this.id = count++;
    logProsemirror(`CONSTRUCT LessonTitleView [${this.id}]`, {node}, LogLevels.INFO);

    this.node = node;
    this.outerView = view;
    this.getPos = getPos;

    this.mediaId = node.attrs['media-id'];
    this.dom = document.createElement('header');
    this.dom.setAttribute('class', 'lessonTitle');

    this.numberInput = input(
      this.dom,
      () => this.node.attrs.number,
      value => this.updateAttribute('number', value),
      {placeholder: '#', class:'lessonNumber'}
    );
    this.titleInput = input(
      this.dom,
      () => this.node.attrs.title,
      value => this.updateAttribute('title', value),
      {placeholder: 'Title', class:'lessonTitle'}
    );
    this.subtitleInput = input(
      this.dom,
      () => this.node.attrs.subtitle,
      value => this.updateAttribute('subtitle', value),
      {placeholder: 'Subtitle', class:'lessonSubtitle'}
    );

    this.titleImage = document.createElement('div');
    this.titleImage.setAttribute('class','imagePlaceholder');
    this.titleImage.style.width = this.node.attrs['media-scale'];
    this.titleImage.innerHTML = '?';

    this.titleImage.addEventListener('click', this.onImageClick);

    this.numberInput.addEventListener('keydown', ev => this.cycleInput(ev, null, this.titleInput));
    this.titleInput.addEventListener('keydown', ev => this.cycleInput(ev, this.numberInput, this.subtitleInput));
    this.subtitleInput.addEventListener('keydown', ev => this.cycleInput(ev, this.titleInput, null));

    this.titleHeading = document.createElement('h1');
    this.titleHeading.appendChild(this.numberInput);
    this.titleHeading.appendChild(this.titleInput);
    this.subtitleHeading = document.createElement('p');
    this.subtitleHeading.appendChild(this.subtitleInput);

    this.inputContainer = document.createElement('div');
    this.inputContainer.setAttribute('class','lessonTitleInputs');
    this.inputContainer.appendChild(this.titleHeading);
    this.inputContainer.appendChild(this.subtitleHeading);

    this.imageContainer = document.createElement('div');
    this.imageContainer.setAttribute('class','lessonTitleImage');
    this.imageContainer.appendChild(this.titleImage);

    this.dom.appendChild(this.inputContainer);
    this.dom.appendChild(this.imageContainer);

    if (this.mediaId) this.clientObserver = createMediaObserver(this);
  }

  onImageClick = ev => {
    this.interface.execute(promptLessonTitleImage({
      node: this.node,
      view: this.outerView,
      getPos: this.getPos,
    }));
  };

  onMediaUpdated({mediaDetails}) {
    if (mediaDetails.getParams() !== this.mediaId) return;
    if (mediaDetails.isLoaded()) {
      let media = mediaDetails.get();
      logProsemirror(`CpmMediaView [${this.id}] :: onMediaUpdated()`, {media, node: this.node}, LogLevels.DEBUG);
      if (media.type === MediaTypes.IMAGE.name || media.type === MediaTypes.MATHPIX.name) {
        let img = document.createElement('img');
        img.setAttribute('src',media.publicUrl);
        // img.setAttribute('alt',this.node.attrs['media-alt']);
        img.style.width = this.node.attrs['media-scale'];
        const {left, top} = this.node.attrs['media-offset'];
        img.style.marginRight = `${-left}px`;
        img.style.marginTop = `${top}px`;

        img.addEventListener('click', this.onImageClick);

        this.imageContainer.replaceChild(img, this.titleImage);
        this.titleImage = img;
      } else {
        logProsemirror(`LessonTitleView [${this.id}] : Unsupported media type`, {media}, LogLevels.WARN);
      }
    } else if (mediaDetails.isError()) {
      logProsemirror(`LessonTitleView [${this.id}] : Redux error`, {
        error: mediaDetails.get()
      }, LogLevels.ERROR);
    }
  }

  selectNode() {
    // this.numberInput.focus();
  }

  update(node) {
    if (node.type.name !== 'lessonTitle') return false;
    const {number, title, subtitle, 'media-id': nextId} = node.attrs;
    logProsemirror(`UPDATE LessonTitleView [${this.id}]`, node.attrs, LogLevels.DEBUG);

    this.node = node;

    if (number !== this.numberInput.value) this.numberInput.value = number;
    if (title !== this.titleInput.value) this.titleInput.value = title;
    if (subtitle !== this.subtitleInput.value) this.subtitleInput.value = subtitle;
    if (this.titleImage.style.width !== node.attrs['media-scale']) {
      this.titleImage.style.width = node.attrs['media-scale'];
    }
    const {left, top} = node.attrs['media-offset'];
    this.titleImage.style.marginRight = `${-left}px`;
    this.titleImage.style.marginTop = `${top}px`;
    if (this.mediaId !== nextId) {
      this.mediaId = nextId;

      if (this.clientObserver) {
        this.clientObserver.setProps({mediaId: nextId});
      } else if (this.mediaId) {
        this.clientObserver = createMediaObserver(this);
      }
    }

    return true;
  }

  updateAttribute(name, value) {
    const {tr} = this.outerView.state;
    tr.setNodeMarkup(this.getPos(), null, {
      ...this.node.attrs,
      [name]: value
    });
    this.outerView.dispatch(tr);
  }
  cycleInput(ev, prev, next) {
    let exitPos = null;
    if (ev.key === 'ArrowUp') {
      if (prev) { prev.focus(); }
      else { exitPos = this.getPos(); }
    } else if (ev.key === 'ArrowDown') {
      if (next) { next.focus(); }
      else { exitPos = this.getPos() + this.node.nodeSize; }
    }

    if (exitPos !== null) {
      this.outerView.focus();

      const {tr, doc} = this.outerView.state;
      tr.setSelection(TextSelection.create(doc, exitPos));
      this.outerView.dispatch(tr);
    }
  }

  destroy() {
    logProsemirror(`DESTROY LessonTitleView [${this.id}]`, null, LogLevels.INFO);
    if (this.clientObserver) {
      this.clientObserver.unsubscribe();
    }
  }

  stopEvent(ev) {
    return !!ev.key || ev.type === 'input' || ev.type === 'copy' || ev.type === 'paste';
  }
  ignoreMutation() { return true; }
}
