import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";

import {Paper} from '@material-ui/core';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconHistory from '@material-ui/icons/History';

import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";
import {SectionBreadcrumb} from 'components/common/Breadcrumb';
import {SectionBrowser} from 'components/common/Browser';
import {MergeSectionsConfirmationDialog} from 'components/Dialogs/Merge/MergeSectionsConfirmationDialog';

import {useClientHook} from "reducers/client";
import {sectionDetails} from "reducers/client/requestTypes";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {dialogOpen} from "reducers/dialog/actions";
import {MERGE_SECTIONS_CONFIRMATION} from "reducers/dialog/dialogIds";
import {MergeSections} from "components/section/MergeSections";

const getNavSection = createNavRouteSelector(navRoutes.SECTION);
const sectionDetailsHook = sectionDetails();
const SectionMergeView = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [mergeId, setMergeId] = useState(null);

  const sectionId = useSelector(getNavSection);
  const sectionDetails = useClientHook(sectionDetailsHook, sectionId);

  const handleNavHistory = useCallback(
    () => history.push(`/app/sections/${sectionId}/mergeHistory`),
    [history, sectionId]
  );
  const handleNavEditor = useCallback(
    () => history.push(`/app/sections/${sectionId}/editor`),
    [history, sectionId]
  );
  const handleSectionSelected = useCallback(
    selection => {
      if (selection && selection[0]) setMergeId(selection[0].id);
    },
    [setMergeId]
  );
  const handleCancel = useCallback(() => setMergeId(null), [setMergeId]);
  const handleConfirm = useCallback(
    selection => {
      dispatch(dialogOpen(MERGE_SECTIONS_CONFIRMATION, {
        sectionIds: [sectionId, mergeId],
        selection,
        comment: "MANUAL_MERGE"
      }));
    },
    [dispatch, sectionId, mergeId]
  );


  if (!sectionDetails.isLoaded() || sectionId == null) {
    return <div />;
  }
  return (
    <ViewportRoot>
      <MergeSectionsConfirmationDialog />
      <ButtonBox>
        <ButtonBoxButton title="Merge History" onClick={handleNavHistory}>
          <IconHistory />
        </ButtonBoxButton>
        <ButtonBoxButton title="Back" onClick={handleNavEditor}>
          <IconArrowBack />
        </ButtonBoxButton>
      </ButtonBox>
      <h2 className="article-title">Find duplicate for Section '{sectionDetails.get().type}'</h2>
      {mergeId === null ? (
        <div>
          <SectionBreadcrumb sectionId={sectionId} />
          <Paper>
            <SectionBrowser onChange={handleSectionSelected} blacklist={[sectionId]} />
          </Paper>
        </div>
      ) : (
        <div>
          <MergeSections
            mainSectionId={sectionId}
            mergeSectionId={mergeId}
            onCancel={handleCancel}
            onSubmit={handleConfirm}
          />
        </div>
      )}
    </ViewportRoot>
  );
};

export {SectionMergeView}
