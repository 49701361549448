import React, {useCallback, useEffect, useImperativeHandle, useMemo, useReducer, useState} from "react";

import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
} from "@material-ui/core";

import {useTable} from "reducers/table";
import ConfirmationDialog from "components/Dialogs/Confirmation";
import {makeTableRow} from "./TableRowBase";
import {makeTableHeader} from "./TableHeaderBase";
import {makeTableFooter} from "./TableFooterBase";
import {processColumns} from "./TableUtils";
import {makeReducer, initialState, ActionCreators} from "./reducer";

const useStyles = makeStyles(theme => createStyles({
  dragHandle: {
    cursor: "move"
  },
  button: {},
  dragging: {
    cursor: "move",
    background: 'white',
    boxShadow: `0 2px 4px 0 rgba(0,0,0,0.2)`,
  },
  paginationRoot: {
    width: `400px`,
  }
}), {name: "PagedTable-Base"});

const defaultOptions = {
  table: 'unnamed-paged-table',
  initialState: {},
  readOnly: false,
  getRowKey: (row, i) => i,
  getRowName: (row, i) => i,
  reducer: (state, action) => state,
  paginationColSpan: 1,
  actions: {
    hidden: false,
    override: false,
    Component: null,
  }
};

export const pagedTable = (columnSpec, options) => {
  const {
    table,
    initialState: initialReduxState,
    readOnly,
    getRowKey,
    getRowName,
    reducer,
    paginationColSpan,
  } = Object.assign({}, defaultOptions, options);
  const actions = Object.assign({}, defaultOptions.actions, (options || {}).actions);

  const {columns, visibleColumns, totalColumns, getRowPayload, getPagePayload} = processColumns(columnSpec, options);
  const tableReducer = makeReducer(columns, reducer);

  const visibleActions = !actions.hidden && (actions.Component || !readOnly);
  const TableHeaderBase = makeTableHeader({visibleColumns, visibleActions, paged: true, paginationColSpan});
  const TableRowBase = makeTableRow({visibleColumns, actions, readOnly});
  const TableFooterBase = !readOnly && makeTableFooter({visibleColumns, visibleActions, TableRowBase});

  const PagedTableComponent = (props, ref) => {
    const {
      classes: propClasses,
      page,
      totalCount,
      onSave,
      onDelete,
      onClick,
      onStateChange,
      waiting
    } = props;

    const classes = useStyles({classes: propClasses});
    const {pagination, actions} = useTable({table, initialState: initialReduxState});
    // State reducer (get state updates from text fields)
    const [state, dispatch] = useReducer(tableReducer, initialState);
    // Reset state whenever new initial state is passed in
    useEffect(() => {
      if (page) dispatch({type: 'reset', payload: getPagePayload(page, true)})
    }, [page]);

    const handleConfirmDelete = useCallback(() => {
      dispatch(ActionCreators.setDeleting(false))
      if (typeof onDelete === 'function') {
        onDelete(getRowPayload(state.page[state.editing])).then(() => dispatch(ActionCreators.selectToEdit(-1)));
      }
    }, [state, dispatch, onDelete]);
    const handleClickRow = useCallback(
      (ev, index) => {
        // Don't trigger click events when waiting or editing
        if (waiting || state.adding || state.editing >= 0) return false;
        let t = ev.target;
        while (t && t !== ev.currentTarget) {
          // Don't trigger click events on row buttons
          if (t.classList.contains(classes.button)) return false;
          t = t.parentElement;
        }
        // If not clicking on button, trigger external click handler
        if (typeof onClick === 'function') return onClick(ev, index);
        return false;
      },
      [classes, onClick, waiting, state]
    );
    const handleSave = useCallback(index => {
      if (typeof onSave === 'function') {
        const row = index >= 0 ? state.page[index] : state.newRow;
        onSave(getRowPayload(row)).then(() => {
          if (index >= 0) dispatch(ActionCreators.selectToEdit(-1));
          else dispatch(ActionCreators.setAdding(false))
        });
      }
    }, [state.page, state.newRow, dispatch, onSave]);

    useEffect(() => {
      if (typeof onStateChange === 'function') onStateChange(state)
    }, [state]);

    const selectedRowName =
      (state.editing >= 0 && state.editing < state.page.length) &&
      getRowName(state.page[state.editing],state.editing);

    useImperativeHandle(ref, () => ({
      dispatch
    }), [dispatch]);

    return (
      <React.Fragment>
        <ConfirmationDialog
          title="Delete"
          message={`Are you sure you want to delete ${selectedRowName}?`}
          isOpen={state.deleting}
          onClose={() => dispatch(ActionCreators.setDeleting(false))}
          onConfirm={handleConfirmDelete}
        />
        <Table>
          <TableHeaderBase
            classes={classes}
            waiting={Boolean(waiting)}
            pagination={pagination}
            actions={actions}
            totalCount={totalCount}
          />
          <TableBody>
            {state.page.map((row, i) => (
              <TableRowBase
                key={getRowKey(row,i)}
                index={i}
                classes={classes}
                state={state}
                dispatch={dispatch}
                disabled={waiting}
                onSave={handleSave}
                onClick={ev => handleClickRow(ev, i)}
              />
            ))}
          </TableBody>
          {!readOnly && <TableFooterBase
            classes={classes}
            state={state}
            dispatch={dispatch}
            waiting={waiting}
            onSave={handleSave}
          />}
        </Table>
      </React.Fragment>
    );
  }

  return React.forwardRef(PagedTableComponent);
};
