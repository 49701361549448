import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {Tooltip, Fab, withStyles} from "@material-ui/core";

import IconHistory from '@material-ui/icons/History';
import IconListAlt from '@material-ui/icons/ListAlt';
import IconImage from '@material-ui/icons/Image';

import {AssessmentMenu} from "prosemirror/menu";
import {desmos, mathjax} from 'prosemirror/processors';
import {setContext} from "reducers/Prosemirror";

import {createLoadedSelector, withClient} from "reducers/client";
import {elementDetails, assessmentProblemDetails, chapterDetails} from "reducers/client/requestTypes";

import PublishStatusIndicator from "components/editor/PublishStatusIndicator"
import {ButtonBox, ButtonBoxButton, EditorViewport, FlexColumns} from "components/layout";
import AssessmentProblemNavigation from "components/Navigation/AssessmentProblemNavigation";
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ElementEditor} from "components/editor/ElementEditor";

class AssessmentProblemEditor extends React.Component {
  componentDidMount() {
    const {chapterDetails, assessmentProblemId, prosemirrorContextId, setProsemirrorContext} = this.props;
    if (chapterDetails.isLoaded()) {
      const {bookId} = chapterDetails.get();
      if (assessmentProblemId !== prosemirrorContextId) {
        setProsemirrorContext(bookId, 'assessmentProblem', assessmentProblemId);
      }
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {chapterDetails, assessmentProblemId, prosemirrorContextId, setProsemirrorContext} = this.props;
    if (chapterDetails.isLoaded()) {
      const {bookId} = chapterDetails.get();
      if (assessmentProblemId !== prosemirrorContextId) {
        setProsemirrorContext(bookId, 'assessmentProblem', assessmentProblemId);
      }
    }
  }

  navigateTo(path) {
    if (path.startsWith('/')) {
      this.props.history.push(path);
    } else {
      const {assessmentProblemId} = this.props;
      this.props.history.push(`/app/assessmentProblems/${assessmentProblemId}/${path}`);
    }
  }

  render() {
    const {
      elementType,
      assessmentProblemId,
      elementDetails,
    } = this.props;

    let contentVersionId = elementDetails.isLoaded() ? elementDetails.get().currentVersionId : '';

    return (
      <EditorViewport>
        <ButtonBox>
          <PublishStatusIndicator contentVersionId={contentVersionId} />
          <ButtonBoxButton title="Images Overview" onClick={e => this.navigateTo(`media/${elementType}`)}>
            <IconImage />
          </ButtonBoxButton>
          <ButtonBoxButton title="Version History" onClick={e => this.navigateTo(`versions/${elementType}`)}>
            <IconHistory />
          </ButtonBoxButton>
          <ButtonBoxButton title="View/Edit Associated Standards" onClick={e => this.navigateTo(`standards/${elementType}`)}>
            <IconListAlt />
          </ButtonBoxButton>
        </ButtonBox>
        <FlexColumns noShrink>
          <AssessmentProblemNavigation
            elementType={elementType}
            assessmentProblemId={assessmentProblemId}
          />
        </FlexColumns>
        <ElementEditor
          elementId={elementDetails.getParams()}
          menu={AssessmentMenu}
          previewProps={{ postProcess: [mathjax, desmos] }}
        />
      </EditorViewport>);
  }
}

const getNavAssessmentProblem = createNavRouteSelector(navRoutes.ASSESSMENT_PROBLEM);
const getNavElementType = createNavRouteSelector(navRoutes.ELEMENT_TYPE);
const getNavElement = createNavRouteSelector(navRoutes.ELEMENT);
const mapStateToProps = (state, props) => ({
  prosemirrorContextId: state.prosemirror.contextId,
  assessmentProblemId: getNavAssessmentProblem(state),
  elementType: getNavElementType(state)
});
const mapDispatchToProps = dispatch => ({
  setProsemirrorContext: (...context) => dispatch(setContext(...context))
});

const getChapterId = createLoadedSelector(
  'assessmentProblemDetails',
  getNavAssessmentProblem,
  assessmentProblem => assessmentProblem.assessmentGroups[0].chapterId
);
export const AssessmentProblemEditorView = withClient({
  hooks: {
    assessmentProblemDetails: assessmentProblemDetails(getNavAssessmentProblem),
    chapterDetails: chapterDetails(getChapterId),
    elementDetails: elementDetails(getNavElement),
  }
})(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssessmentProblemEditor));
