import {
  liftListItem as pmLiftListItem,
  sinkListItem as pmSinkListItem,
  splitListItem as pmSplitListItem,
} from "prosemirror-schema-list";

import {wrapInList, rotateList} from "prosemirror/commands/list";
import {typeNameMiddleware} from "prosemirror/commands/helpers";

const liftListItem = typeNameMiddleware('nodes', pmLiftListItem);
const sinkListItem = typeNameMiddleware('nodes', pmSinkListItem);
const splitListItem = typeNameMiddleware('nodes', pmSplitListItem);

export const listKeys = {
  'Shift-Ctrl-8': wrapInList('bullet_list'),
  'Shift-Ctrl-9': wrapInList('ordered_list'),
  'Mod-_': rotateList(-1),
  'Mod-+': rotateList(1),
  Enter: splitListItem('list_item'),
  'Mod-[': liftListItem('list_item'),
  'Mod-]': sinkListItem('list_item'),
};
