import {markActive} from "./readers";
import {removeMark} from "./base";
import {toggleMark} from "prosemirror-commands";
import {PLUGIN_LINK_EDITOR} from "../plugins/linkEditor";
import {promptGlossary} from "./dialog";

export const toggleLink = (state, dispatch, view) => {
  const {link} = state.schema.marks;
  if (markActive(link)(state)) {
    removeMark('link')(state, dispatch);
    return true;
  }

  toggleMark(link,{href: ''})(state, dispatch);
  PLUGIN_LINK_EDITOR.get(state).getView(view).focus();
  return true;
};

export const toggleGlossaryLink = (state, dispatch) => {
  const {glossaryLink} = state.schema.marks;
  if (markActive(glossaryLink)(state)) {
    removeMark('glossaryLink')(state, dispatch);
    return true;
  }

  promptGlossary()(state, dispatch);
  return true;
};