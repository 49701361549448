import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {
  bookGlossaryItems,
  bookCreateGlossaryItem,
  updateGlossaryItem,
  deleteGlossaryItem
} from "reducers/client/requestTypes";

import {pagedTable} from 'components/common/Table/PagedTable';
import {ElementLinks, select} from "components/common/Table/TableControls";
import {formatDate} from "constants/Date";
import {getElementTypeByName, getValidElementTypesForContentable} from "constants/ElementTypes";
import {paging} from "reducers/table";

const elementTypes = getValidElementTypesForContentable('glossaryItem');

const renderElementType = elementTypeName => {
  const elementType = getElementTypeByName(elementTypeName);
  return (elementType && elementType.displayName) || elementTypeName;
};

const InternalTable = pagedTable([
  {key: 'id', hidden: true},
  {key: 'elements', hidden: true},
  {key: 'title', sort: 'title', name: 'Title'},
  {
    key: 'elementType',
    sort: 'elements.elementType,title',
    name: 'Type',
    Editor: select(elementTypes.map(type => ({value:type.name, name: type.displayName || type.name}))),
    render: renderElementType
  },
  {key: 'createdAt', sort: 'createdAt,title', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', sort: 'updatedAt,title', name: "Last Updated", render: formatDate, readOnly: true},
  {
    key: 'links',
    name: '',
    get: glossaryItem => glossaryItem.elements && glossaryItem.elements[0] && glossaryItem.elements[0].id,
    Editor: () => null,
    render: elementId => <ElementLinks elementId={elementId} />,
    cellProps: {
      padding: 'none'
    },
    readOnly: true
  }
], {
  table: 'BookGlossaryItemsTable',
  initialState: {
    searchColumns: ['title']
  },
  getRowKey: row => row.id,
  getRowName: row => row.title,
});

const bookGlossaryItemsHook = paging('BookGlossaryItemsTable')(bookGlossaryItems((state, props) => props.bookId));
const bookCreateGlossaryItemHook = bookCreateGlossaryItem();
const updateGlossaryItemHook = updateGlossaryItem();
const deleteGlossaryItemHook = deleteGlossaryItem();
const BookGlossaryItemsTable = (props) => {
  const [totalCount, setTotalCount] = useState(0);
  const [tablePage, setTablePage] = useState([]);
  const history = useHistory();

  const clientKey = useCallback(selector => selector(props), [props]);
  const bookGlossaryItems = useClientHook(bookGlossaryItemsHook, clientKey);
  const bookCreateGlossaryItem = useClientHook(bookCreateGlossaryItemHook, props.bookId);
  const updateGlossaryItem = useClientHook(updateGlossaryItemHook);
  const deleteGlossaryItem = useClientHook(deleteGlossaryItemHook);

  const handleSave = useCallback(glossaryItem => {
    if (glossaryItem.id) {
      return updateGlossaryItem.sendRequest(glossaryItem.id, glossaryItem)
        .then(() => bookGlossaryItems.sendRequest());
    } else {
      return bookCreateGlossaryItem.sendRequest(glossaryItem)
        .then(() => bookGlossaryItems.sendRequest());
    }
  }, [bookGlossaryItems, updateGlossaryItem, bookCreateGlossaryItem]);
  const handleDelete = useCallback(glossaryItem => {
    if (glossaryItem.id) {
      return deleteGlossaryItem.sendRequest(glossaryItem.id)
        .then(() => bookGlossaryItems.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [bookGlossaryItems, deleteGlossaryItem]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/glossaryItems/${tablePage[index].id}/editor`);
  }, [history,tablePage]);

  const isWaiting = useMemo(
    () =>
      bookGlossaryItems.isLoading() ||
      bookCreateGlossaryItem.isLoading() ||
      updateGlossaryItem.isLoading() ||
      deleteGlossaryItem.isLoading(),
    [bookGlossaryItems, bookCreateGlossaryItem, updateGlossaryItem, deleteGlossaryItem]
  );

  useEffect(() => {
    if (bookGlossaryItems.isLoaded()) {
      const {totalCount, results} = bookGlossaryItems.get();
      setTotalCount(totalCount);
      setTablePage(results.map(g => ({
        ...g,
        elementType: g.elements && g.elements.length > 0 && g.elements[0].elementType
      })));
    }
  }, [bookGlossaryItems, setTotalCount, setTablePage]);

  return (
    <InternalTable
      page={tablePage}
      totalCount={totalCount}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      waiting={isWaiting}
    />
  )
};
BookGlossaryItemsTable.propTypes = {
  bookId: PropTypes.string.isRequired,
}

export {BookGlossaryItemsTable}
