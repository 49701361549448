import React, {useCallback, useMemo, useState} from "react";
import classNames from 'classnames';

import {usePopup} from "hooks/usePopup";

import {Dropdown} from "prosemirror/icons";
import {useProsemirror} from "prosemirror/components/ProsemirrorProvider";
import {MenuPopout} from "./MenuPopout";
import PropTypes from "prop-types";

const MenuExpand = ({title, visible, active, enabled, icon, columns, children, style, ...props}) => {
  const [anchor, open, close] = usePopup();
  const prosemirror = useProsemirror();

  return (prosemirror && (!visible || visible(prosemirror.state))) ? (
    <span className="ProseMirror-MenuSelect" style={style} {...props}>
      <button
        className={classNames({
          "ProseMirror-MenuButton": true,
          "ProseMirror-MenuButton-Active": active && active(prosemirror.state)
        })}
        title={title}
        disabled={enabled && !enabled(prosemirror.state)}
        onMouseDown={open}
      ><Dropdown>{icon}</Dropdown></button>
      <MenuPopout anchorEl={anchor} columns={columns} style={style}>{children}</MenuPopout>
    </span>
  ) : null;
};
MenuExpand.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.func,
  active: PropTypes.func,
  enabled: PropTypes.func,
  icon: PropTypes.node,
  columns: PropTypes.number,
  style: PropTypes.object,
};
MenuExpand.defaultProps = {
  columns: 1,
};

export {MenuExpand}
