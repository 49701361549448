import {Plugin, PluginKey} from 'prosemirror-state';
import {AddMarkStep, ReplaceStep} from "prosemirror-transform";
import {Decoration, DecorationSet} from "prosemirror-view";

import {icon} from '@fortawesome/fontawesome-svg-core';
import {faBook} from "@fortawesome/free-solid-svg-icons";

import {createSelector} from "reducers/client";
import {sendRequest} from "reducers/client/actions";
import {bookDetails} from "reducers/client/requestTypes";
import {defaults} from "reducers/client/util";

import {getMarkedRange} from "prosemirror/commands/helpers";
import {PLUGIN_INTERFACE} from "../interface";

import './bookSpecific.css';

export const PLUGIN_BOOK_SPECIFIC = new PluginKey('bookSpecific');
let bookIcon = null;

const initStyleSheet = (id) => {
  const styleDec = document.createElement('style');
  styleDec.setAttribute('data-name', PLUGIN_BOOK_SPECIFIC.key+id);
  document.head.appendChild(styleDec);
  return styleDec;
};
const destroyStyleSheet = styleDec => {
  document.head.removeChild(styleDec);
};

const getBooksInDoc = (doc) => {
  let books = {};
  doc.descendants((node, pos) => {
    if (node.type.attrs.bookId && node.attrs.bookId) {
      books[node.attrs.bookId] = true;
    }
    node.marks.forEach(mark => {
      if (mark.type.attrs.bookId) {
        books[mark.attrs.bookId] = true;
      }
    })
  });
  return Object.keys(books);
};
const getBooksInTransaction = (tr) => {
  let books = {};
  if (tr.docChanged) {
    tr.steps.forEach(step => {
      if (step instanceof AddMarkStep && step.mark.type.attrs.bookId) {
        books[step.mark.attrs.bookId] = true;
      }
      if (step instanceof ReplaceStep) {
        step.slice.content.descendants(node => {
          if (node.type.attrs.bookId) {
            books[node.attrs.bookId] = true;
          }
        });
      }
    });
  }
  return Object.keys(books);
};

const makeStyleRule = (bookId, color) => {
  let rule = `.bookSpecificContent[data-book-id="${bookId}"]{`;
  rule += `background-color:${color};`;
  rule += `color:white;`;
  rule += '}';
  return rule;
};
const makeDecoration = (pos, bookName) => {
  return Decoration.widget(pos, () => {
    const anchor = document.createElement('span');
    anchor.setAttribute('class','ProseMirror-BookSpecific-Anchor');
    const tooltip = document.createElement('div');
    tooltip.setAttribute('class','ProseMirror-BookSpecific-Tooltip');
    tooltip.appendChild(bookIcon);
    tooltip.appendChild(document.createTextNode(` ${bookName}`));
    anchor.appendChild(tooltip);
    return anchor;
  }, {
    side: -100,
    key: bookName
  });
};
const makeTooltip = view => {
  const tooltip = document.createElement('div');
  tooltip.style.display = 'none';
  tooltip.setAttribute('class','ProseMirror-BookSpecific-Tooltip');
  tooltip.appendChild(bookIcon);
  const tooltipText = document.createTextNode('');
  tooltip.appendChild(tooltipText);
  view.root.body.appendChild(tooltip);

  const tooltipMarginLeft = 5;
  const tooltipMarginTop = 4;

  return {
    lastPos: -1,
    pendingAnimationFrame: null,
    isVisible() {
      return tooltip.style.display === '';
    },
    set(pos, bookName) {
      if (bookName) tooltipText.nodeValue = ` ${bookName}`;
      tooltip.style.display = '';
      const editorBounds = view.dom.parentNode.getBoundingClientRect();
      const {left: viewLeft, top: viewTop} = view.coordsAtPos(pos);
      const top = Math.min(
          editorBounds.bottom - tooltip.offsetHeight - tooltipMarginTop,
          Math.max(editorBounds.top, viewTop - tooltipMarginTop)
      );
      const left = Math.min(
          editorBounds.right - tooltipMarginLeft,
          Math.max(editorBounds.left, viewLeft - tooltip.offsetWidth - tooltipMarginLeft)
      );

      tooltip.style.left = `${left}px`;
      tooltip.style.top = `${top}px`;
      this.lastPos = pos;
    },
    reposition() {
      if (this.lastPos >= 0 && !this.pendingAnimationFrame) {
        this.pendingAnimationFrame = window.requestAnimationFrame(() => {
          this.set(this.lastPos);
          this.pendingAnimationFrame = null;
        });
      }
    },
    clear() {
      tooltip.style.display = 'none';
    },
    destroy() {
      if (this.pendingAnimationFrame) window.cancelAnimationFrame(this.pendingAnimationFrame);
      tooltip.parentNode.removeChild(tooltip);
    }
  }
};

const bookDetailsHook = bookDetails();
const handleNewBooks = (books, store) => {
  const reduxState = store.getState();
  let pending = {};
  let colors = {};
  let names = {};
  books.forEach(book => {
    let bookDetails = createSelector('bookDetails', book)(reduxState);
    if (bookDetails.isLoaded()) {
      colors[book] = bookDetails.get().metadata.color;
      names[book] = bookDetails.get().name;
    } else {
      pending[book] = bookDetails;
      if (!bookDetails.isLoading()) {
        const request = bookDetailsHook.request(book);
        const action = sendRequest(bookDetailsHook.hookName, defaults.transform, request, book);
        store.dispatch(action);
      }
    }
  });
  return {
    colors,
    names,
    pending,
    books
  };
};
const handleResolved = (prevState, resolved) => {
  let colors = {...prevState.colors};
  let names = {...prevState.names};
  let pending = {...prevState.pending};
  resolved.forEach(bookRequest => {
    const bookId = bookRequest.getParams();
    colors[bookId] = bookRequest.get().metadata.color;
    names[bookId] = bookRequest.get().name;
    delete pending[bookId];
  });
  return {...prevState, colors, names, pending};
};
const mergeState = (prev, next) => ({
  ...prev,
  colors: {
    ...prev.colors,
    ...next.colors
  },
  names: {
    ...prev.names,
    ...next.names
  },
  pending: {
    ...prev.pending,
    ...next.pending
  },
  books: prev.books.concat(next.books)
});

export const bookSpecificPlugin = () => {
  let pluginViewCounter = 0;
  if (!bookIcon) bookIcon = icon(faBook).node[0];

  return new Plugin({
    key: PLUGIN_BOOK_SPECIFIC,
    state: {
      init: (config, state) => {
        const {doc} = state;
        const store = PLUGIN_INTERFACE.get(state).interface.getReduxStore();
        const books = getBooksInDoc(doc);

        return handleNewBooks(books, store);
      },
      apply: (tr, value, prevState, state) => {
        const store = PLUGIN_INTERFACE.get(state).interface.getReduxStore();

        let newBooks = getBooksInTransaction(tr)
          .filter(book => !value.books.includes(book));

        if (newBooks.length > 0) {
          const newState = handleNewBooks(newBooks, store);
          return mergeState(value, newState);
        }
        if (tr.getMeta(PLUGIN_BOOK_SPECIFIC)) {
          return handleResolved(value, tr.getMeta(PLUGIN_BOOK_SPECIFIC));
        }
        return value;
      },
      toJSON: value => {
        let json = {};
        value.books.forEach(book => {
          json[book] = {
            color: value.colors[book],
            name: value.names[book],
            pending: !!value.pending[book]
          };
        });
        return json;
      },
      fromJSON: (config, value, state) => {
        const store = PLUGIN_INTERFACE.get(state).interface.getReduxStore();
        let pluginState = {
          pending: {},
          colors: {},
          names: {},
          books: [],
        };
        let pendingBooks = [];
        Object.entries(value).forEach(([bookId, bookState]) => {
          if (bookState.pending) {
            pendingBooks.push(bookId);
          } else {
            pluginState.colors[bookId] = bookState.color;
            pluginState.names[bookId] = bookState.name;
            pluginState.books.push(bookId);
          }
        });
        return mergeState(pluginState, handleNewBooks(pendingBooks, store));
      }
    },
    view: editorView => {
      let viewInstance = editorView;
      const store = PLUGIN_INTERFACE.get(viewInstance.state).interface.getReduxStore();
      let pluginState = PLUGIN_BOOK_SPECIFIC.getState(viewInstance.state);
      const unsubscribe = store.subscribe(() => {
        let pending = Object.keys(pluginState.pending);
        if (pending.length > 0) {
          const reduxState = store.getState();
          let resolved = [];
          pending.forEach(book => {
            let bookDetails = createSelector('bookDetails', book)(reduxState);
            if (bookDetails.isLoaded()) resolved.push(bookDetails);
          });
          if (resolved.length > 0) {
            const {dispatch, state: {tr}} = viewInstance;
            dispatch(tr.setMeta(PLUGIN_BOOK_SPECIFIC, resolved));
          }
        }
      });

      const styleDec = initStyleSheet(++pluginViewCounter);
      const styles = {};

      const tooltip = makeTooltip(viewInstance);

      viewInstance.dom.parentNode.addEventListener('scroll', ev => {
        if (tooltip.isVisible()) tooltip.reposition();
      });

      return {
        update: (editorView) => {
          viewInstance = editorView;
          pluginState = PLUGIN_BOOK_SPECIFIC.getState(viewInstance.state);
          const {books, names, colors} = pluginState;
          const {doc, selection:{$anchor, node}} = viewInstance.state;

          // Update book styles
          books.forEach(book => {
            if (!styles[book] && colors[book]) {
              styles[book] = makeStyleRule(book, colors[book]);
              styleDec.sheet.insertRule(styles[book]);
            }
          });

          // Update tooltip
          if (node && node.attrs.bookId) {
            tooltip.set($anchor.pos, names[node.attrs.bookId]);
          } else if ($anchor.nodeAfter) {
            let bookMark = $anchor.marks().find(mark => mark.type.attrs.bookId);
            if (bookMark) {
              const {start, end} = getMarkedRange(doc, bookMark, $anchor.pos);
              tooltip.set(start, names[bookMark.attrs.bookId]);
            } else {
              tooltip.clear();
            }
          } else {
            tooltip.clear();
          }
        },
        destroy: () => {
          unsubscribe();
          destroyStyleSheet(styleDec);
          tooltip.destroy();
        }
      }
    }
  });
};
