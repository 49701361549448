import React from "react";
import * as commands from 'prosemirror-history';

import {MenuButton} from 'prosemirror/components/menu';
import * as icons from 'prosemirror/icons';

export const undo = (<MenuButton key="history-undo"
  enabled={commands.undo}
  command={commands.undo}
  title="Undo last change">
  {icons.undo}
</MenuButton>);
export const redo = (<MenuButton key="history-redo"
  enabled={commands.redo}
  command={commands.redo}
  title="Redo last undone change">
  {icons.redo}
</MenuButton>);
