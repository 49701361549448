import {combineReducers} from 'redux';

import {apiClient} from '../constants/Clients';
import MultiRequest, {makeRequestSelector} from "./util/multiRequest";

// ACTION TYPES
export const CHAPTER_LIST_FOR_BOOK = 'CHAPTER_LIST_FOR_BOOK';

// CHILD REDUCERS
const listByBook = MultiRequest(CHAPTER_LIST_FOR_BOOK);

// ACTION CREATORS
export function requestChapterListForBook(bookId) {
  const req = apiClient.getBookChapters(bookId);
  req.setSortFields(['displayOrder']);
  req.setOrderByAsc();
  req.limit(9999);
  return listByBook.sendRequest(req, {key: bookId});
}

// SELECTORS
const getBookChapterListRequests = state => state.chapters.listByBook;
export const makeBookChapterListSelector = bookIdResolver =>
  makeRequestSelector(bookIdResolver, getBookChapterListRequests);

export default combineReducers({
  listByBook
});
