import React from 'react';

import LinearProgress from "@material-ui/core/LinearProgress";
import IconEdit from '@material-ui/icons/Edit';

import {withClient} from "reducers/client";
import {tabElements} from "reducers/client/requestTypes";

import {TabBreadcrumb} from 'components/common/Breadcrumb';

import ContentVersionMediasTable from 'components/contentVersion/ContentVersionMediasTable';
import {createNavRouteSelector, navRoutes} from "reducers/nav";
import {ButtonBox, ButtonBoxButton, ViewportRoot} from "components/layout";

class TabMedia extends React.Component {
  showTabEditor = () => {
    const {
      history,
      tabElements
    } = this.props;
    history.push(`/app/tabs/${tabElements.getParams()}/editor`);
  };

  render() {
    const {tabElements} = this.props;

    if (!tabElements.isLoaded()) {
      return (<div>
        <LinearProgress />
        <h2 className="article-title">Loading Tab media...</h2>
      </div>);
    }

    const tabElement = tabElements.get()[0];

    return (
      <ViewportRoot>
        <TabBreadcrumb tabId={tabElements.getParams()} />
        <ButtonBox>
          <ButtonBoxButton title="Tab Editor" onClick={this.showTabEditor}>
            <IconEdit />
          </ButtonBoxButton>
        </ButtonBox>

        <ContentVersionMediasTable contentVersionId={tabElement.publishedVersionId} />
      </ViewportRoot>
    );
  }
}

const getNavTab = createNavRouteSelector(navRoutes.TAB);
export const TabMediaView = withClient({
  hooks: {
    tabElements: tabElements(getNavTab),
  },
})(TabMedia);
