import {apiClient} from "constants/Clients";
import {DEFAULT_KEY} from "./util";

export const classifications = {
  hookName: 'classifications',
  request: () =>  apiClient.getClassifications()
    .limit(100)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key: DEFAULT_KEY,
  auto: true,
};
export const classificationDetails = key => ({
  hookName: 'classificationDetails',
  request: (classificationId) => apiClient.getClassification(classificationId),
  key,
  auto: true,
});
export const createClassification = {
  hookName: 'createClassification',
  request: classification => apiClient.createClassification(classification)
};
export const updateClassification = key => ({
  hookName: 'updateClassification',
  request: (classificationId, classification) => apiClient.updateClassification({id: classificationId, ...classification}),
  key,
});
export const deleteClassification = key => ({
  hookName: 'deleteClassification',
  request: classificationId => apiClient.deleteClassification(classificationId),
  key,
});
export const classificationOrder = {
  hookName: 'classificationOrder',
  request: (classificationIds) => apiClient.setClassificationOrder(classificationIds),
};
export const classificationCreateGrade = key => ({
  hookName: 'classificationCreateGrade',
  request: (classificationId, grade) => apiClient.createGrade({...grade, classificationId}),
  key,
});
export const classificationGrades = key => ({
  hookName: 'classificationGrades',
  request: (classificationId) => apiClient.getGradesForClassification(classificationId)
    .limit(100)
    .setSortFields(['displayOrder'])
    .setOrderByAsc(),
  key,
  auto: true,
});
export const classificationGradeOrder = key => ({
  hookName: 'classificationGradeOrder',
  request: (classificationId, gradeIds) => apiClient.setClassificationGradeOrder(classificationId, gradeIds),
  key,
});
