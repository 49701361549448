import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {ChapterDetailsView} from "views/chapter/ChapterDetails";

import {navRoutes, useNav} from "reducers/nav";

export const ChapterRoutes = ({match: {url, path, params: {chapterId}}}) => {
  const selectedChapter = useNav(navRoutes.CHAPTER, chapterId);
  if (!selectedChapter) return null;

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${url}/details`} />
      <Route path={`${path}/details`} component={ChapterDetailsView}/>
      <Redirect to="/404" />
    </Switch>
  );
};
