import React from 'react';
import {useSelector} from "react-redux";
import PropTypes from 'prop-types';
import classNames from "classnames";

import {useClientHook} from "reducers/client";
import {elementDetails} from "reducers/client/requestTypes";
import {ContainerPreview, AssessmentPreview, HtmlPreview} from ".";

const elementDetailsHook = elementDetails();
const ProsemirrorPreview = props => {
  const {
    contextId,
    contextType,
    elementId,
  } = useSelector(state => state.prosemirror);
  const elementDetails = useClientHook(elementDetailsHook, elementId);

  if (elementDetails.isLoaded()) {
    switch(contextType) {
      case 'assessmentProblem': return (<AssessmentPreview
        assessmentProblemId={contextId}
        elementType={elementDetails.get().elementType}
        {...props}
      />);
      default: return (<ContainerPreview
        containerId={contextId}
        elementType={elementDetails.get().elementType}
        {...props}
      />);
    }
  }
  return null;
};
ProsemirrorPreview.propTypes = {
  ...HtmlPreview.propTypes,
};

export {ProsemirrorPreview}
