import {useCallback, useState} from "react";

export const usePopup = () => {
  const [anchor, setAnchor] = useState(null);

  const open = useCallback(ev => {
    ev.preventDefault();
    setAnchor(ev.currentTarget);

    const close = () => {
      setAnchor(null);
      document.removeEventListener('mousedown',close,false);
    };
    document.addEventListener('mousedown',close, false);
  }, [setAnchor]);
  const close = useCallback(() => setAnchor(null), [setAnchor]);

  return [anchor, open, close];
};
