import React, {useCallback} from "react";
import PropTypes from "prop-types";

import {useProsemirror} from "prosemirror/components/ProsemirrorProvider";
import {MenuOption} from "./MenuOption";
import {MenuExpand} from "./MenuExpand";

const MenuSelect = ({options, selected, command, ...props}) => {
  const prosemirror = useProsemirror();

  const handleOptionClick = useCallback((ev, value) => {
    ev.preventDefault();
    prosemirror.execute(command(value));
  }, [prosemirror, command]);

  if (!Array.isArray(options)) return false;

  const selectedValue = selected(prosemirror.state);
  let selectedItem = options.find(option => option.value === selectedValue);
  if (!selectedItem) selectedItem = options[0];

  return (
    <MenuExpand {...props} icon={selectedItem.icon}>
      {options.map((option, i) => (
        <MenuOption
          key={i}
          option={option}
          onClick={handleOptionClick}>
          {option.icon}
        </MenuOption>
      ))}
    </MenuExpand>
  );
};
MenuSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.any,
    icon: PropTypes.node,
  })).isRequired,
  selected: PropTypes.any,
  command: PropTypes.func
};

export {MenuSelect}
