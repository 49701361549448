import React from "react";
import * as icons from "prosemirror/icons";
import {MenuSelect} from "./MenuSelect";

const ALIGN_LEFT_TOP = { value: 'left/top', title: 'Top Left', icon: icons.table.align.leftTop };
const ALIGN_CENTER_TOP = { value: 'center/top', title: 'Top Center', icon: icons.table.align.centerTop };
const ALIGN_RIGHT_TOP = { value: 'right/top', title: 'Top Right', icon: icons.table.align.rightTop };
const ALIGN_LEFT_MIDDLE = { value: 'left/middle', title: 'Middle Left', icon: icons.table.align.leftMiddle };
const ALIGN_CENTER_MIDDLE = { value: 'center/middle', title: 'Middle Center', icon: icons.table.align.centerMiddle };
const ALIGN_RIGHT_MIDDLE = { value: 'right/middle', title: 'Middle Right', icon: icons.table.align.rightMiddle };
const ALIGN_LEFT_BOTTOM = { value: 'left/bottom', title: 'Bottom Left', icon: icons.table.align.leftBottom };
const ALIGN_CENTER_BOTTOM = { value: 'center/bottom', title: 'Bottom Center', icon: icons.table.align.centerBottom };
const ALIGN_RIGHT_BOTTOM = { value: 'right/bottom', title: 'Bottom Right', icon: icons.table.align.rightBottom };

const alignments = [
  ALIGN_LEFT_TOP,
  ALIGN_CENTER_TOP,
  ALIGN_RIGHT_TOP,
  ALIGN_LEFT_MIDDLE,
  ALIGN_CENTER_MIDDLE,
  ALIGN_RIGHT_MIDDLE,
  ALIGN_LEFT_BOTTOM,
  ALIGN_CENTER_BOTTOM,
  ALIGN_RIGHT_BOTTOM
];

export const Menu2AxisAlignment = props => (<MenuSelect
  style={{fontSize: `${110}%`}}
  columns={3}
  options={alignments}
  {...props}
/>);
