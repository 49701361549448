import React, { Component  } from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import IconArrowUp from '@material-ui/icons/ArrowUpward';
import IconArrowDown from '@material-ui/icons/ArrowDownward';
import {withClient} from "reducers/client";
import {menuItemsForMenu, menuRender, updateMenuItem} from "reducers/client/requestTypes";
import {setActiveMenuItem} from "reducers/menus";
import {createNavRouteSelector, navRoutes} from "../../reducers/nav";

const styles = theme => ({
  flexSpaceEvenly: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20
  },
});

class MenuItemTools extends Component {
  state = {
    type: 'chapter',
    value: '',
    properties: { ebooksUrl: ''},
    displayName: '',
    name: '',
    altTitle: '',
    selectedLesson: '',
    selectedElement: '',
  };

  componentDidMount(){
    let activeMenuItem = this.props.activeMenuItem;
    activeMenuItem.properties = activeMenuItem.properties || {};
    this.setState({...activeMenuItem})
  }

  componentWillReceiveProps(nextProps){
    let activeMenuItem = nextProps.activeMenuItem;
    activeMenuItem.properties = activeMenuItem.properties || {};
    this.setState({...activeMenuItem})
  };

  handleChangeText = name => event => {
    this.setState({[name]: event.target.value})
  };

  moveElement = (upOrDown) => {
    console.log(this.state);
    this.setState({displayOrder: this.state.displayOrder + upOrDown},()=>{
      this.saveMenuItem()
    })
  };

  saveMenuItem = () => {
    const {menuItemsForMenu, menuRender, updateMenuItem, setActiveMenuItem} = this.props;
    updateMenuItem.sendRequest(this.state)
      .then((result) => {
        setActiveMenuItem(result);
        menuItemsForMenu.sendRequest();
        menuRender.sendRequest();
      });
  };

  handleChangeEbooksUrl = (event) => {
    this.setState({properties: {...this.state.properties, ebooksUrl: event.target.value}})
  };

  render(){
    const {classes, activeMenuItem} = this.props;
    const properties = this.state.properties || {};
    return (
        <Paper className={classes.formWrapper}>
          Edit Menu Item
          <TextField
            id="display-name-field"
            label="Display Name"
            value={this.state.displayName}
            onChange={this.handleChangeText('displayName')}
            margin="normal"
          />

          <TextField
            id="name-field"
            label="Name"
            value={this.state.name}
            onChange={this.handleChangeText('name')}
            margin="normal"
          />

          <TextField
            id="value-field"
            label="Ebooks URL"
            value={properties.ebooksUrl}
            onChange={this.handleChangeEbooksUrl}
            margin="normal"
          />

          <TextField
            id="alt-title-field"
            label="Alt Title"
            value={this.state.altTitle}
            onChange={this.handleChangeText('altTitle')}
            margin="normal"
          />
          <div className={classes.flexSpaceEvenly}>
            <IconButton aria-label="Move Up" onClick={()=>{this.moveElement(-1)}}>
              <IconArrowUp />
            </IconButton>
            <IconButton aria-label="Move Down" onClick={()=>{this.moveElement(1)}}>
              <IconArrowDown />
            </IconButton>
          </div>
          <Button variant={"contained"} color={'primary'} onClick={this.saveMenuItem} >Save</Button>
        </Paper>
      )
  }
}


const mapStateToProps = (state) => ({
  activeMenuItem: state.menus.activeMenuItem
});

const mapDispatchToProps = (dispatch) => ({
  setActiveMenuItem: (payload) => dispatch(setActiveMenuItem(payload)),
});

const getNavBookMenu = createNavRouteSelector(navRoutes.BOOK_MENU);
export default  withClient({
  hooks: {
    menuItemsForMenu: menuItemsForMenu(getNavBookMenu),
    menuRender: menuRender(getNavBookMenu),
    updateMenuItem: updateMenuItem()
  }
})(connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(MenuItemTools)));