import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';

import TextField from "@material-ui/core/TextField";

import {useDebounce} from 'hooks';


const styles = theme => ({
  root: {
    margin: theme.spacing(1),
    minWidth: 180,
  }
});

const FilterFilename = props => {
  const [view, setView] = useState(props.filterState.filename);
  const handleChange = useDebounce(
    value => props.onChange('filename', value),
    {
      cacheProps: event => {
        setView(event.target.value);
        return [event.target.value]
      }
    }
  );

  useEffect(() => {
    if (view !== props.filterState.filename) setView(props.filterState.filename);
  }, [props.filterState.filename]);

  return (
    <TextField
      className={props.classes.root}
      label="Filename"
      value={view}
      onChange={handleChange} />
  );
};

export default withStyles(styles)(FilterFilename);