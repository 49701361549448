import React from 'react';
import PropTypes from 'prop-types';

import {makeBreadcrumb} from "./Breadcrumb";
import {
  categoryDetails,
  bookDetails,
  chapterDetails,
  lessonDetails,
  lessonContentContainerDetails,
  sectionDetails,
  tabbedViewDetails,
  tabDetails,
  glossaryItemDetails,
  assessmentGroupDetails,
  assessmentProblemDetails,
  elementParents, classificationDetails, gradeDetails, standardDetails,
} from "reducers/client/requestTypes";

const CategoryBreadcrumb = makeBreadcrumb({
  displayName: 'CategoryBreadcrumb',
  request: categoryDetails((state, props) => props.categoryId),
  link: details => `/app/categories/${details.id}/details`,
  title: details => details.shortName,
  propTypes: {
    categoryId: PropTypes.string.isRequired
  }
});

const BookBreadcrumb = makeBreadcrumb({
  displayName: 'BookBreadcrumb',
  request: bookDetails((state, props) => props.bookId),
  link: details => `/app/books/${details.id}/details`,
  title: details => details.name,
  parents: details => [<CategoryBreadcrumb key={details.categoryId} categoryId={details.categoryId} child={true} />],
  propTypes: {
    bookId: PropTypes.string.isRequired
  }
});

const ChapterBreadcrumb = makeBreadcrumb({
  displayName: 'ChapterBreadcrumb',
  request: chapterDetails((state, props) => props.chapterId),
  link: details => `/app/chapters/${details.id}/details`,
  title: details => details.name,
  parents: details => [<BookBreadcrumb key={details.bookId} bookId={details.bookId} child={true} />],
  propTypes: {
    chapterId: PropTypes.string.isRequired
  }
});

const LessonBreadcrumb = makeBreadcrumb({
  displayName: 'LessonBreadcrumb',
  request: lessonDetails((state, props) => props.lessonId),
  link: details => `/app/lessons/${details.id}/details`,
  title: details => details.name,
  parents: details => [<ChapterBreadcrumb key={details.chapterId} chapterId={details.chapterId} child={true} />],
  propTypes: {
    lessonId: PropTypes.string.isRequired
  }
});

const LessonContentContainerBreadcrumb = makeBreadcrumb({
  displayName: 'LessonContentContainerBreadcrumb',
  request: lessonContentContainerDetails((state, props) => props.containerId),
  link: details => `/app/lessonContentContainers/${details.id}/editor`,
  title: details => details.name,
  parents: details => [<LessonBreadcrumb key={details.lessonId} lessonId={details.lessonId} child={true} />],
  propTypes: {
    containerId: PropTypes.string.isRequired
  }
});

const SectionBreadcrumb = makeBreadcrumb({
  displayName: 'SectionBreadcrumb',
  request: sectionDetails((state, props) => props.sectionId),
  link: details => `/app/sections/${details.id}/details`,
  title: details => details.type,
  parents: details => [<BookBreadcrumb key={details.bookId} bookId={details.bookId} child={true} />],
  propTypes: {
    sectionId: PropTypes.string.isRequired
  }
});

const TabbedViewBreadcrumb = makeBreadcrumb({
  displayName: 'TabbedViewBreadcrumb',
  request: tabbedViewDetails((state, props) => props.tabbedViewId),
  link: details => `/app/tabbedViews/${details.id}/details`,
  title: details => details.type,
  parents: details => [<SectionBreadcrumb key={details.sectionId} sectionId={details.sectionId} child={true} />],
  propTypes: {
    tabbedViewId: PropTypes.string.isRequired
  }
});

const TabBreadcrumb = makeBreadcrumb({
  displayName: 'TabBreadcrumb',
  request: tabDetails((state, props) => props.tabId),
  link: details => `/app/tabs/${details.id}/editor`,
  title: details => details.name,
  parents: details => [<TabbedViewBreadcrumb
    key={details.tabbedViewId}
    tabbedViewId={details.tabbedViewId}
    child={true}
  />],
  propTypes: {
    tabId: PropTypes.string.isRequired
  }
});

const GlossaryItemBreadcrumb = makeBreadcrumb({
  displayName: 'GlossaryItemBreadcrumb',
  request: glossaryItemDetails((state, props) => props.glossaryItemId),
  link: details => `/app/glossaryItems/${details.id}/editor`,
  title: details => details.title,
  parents: details => [<BookBreadcrumb key={details.book.id} bookId={details.book.id} child={true} />],
  propTypes: {
    glossaryItemId: PropTypes.string.isRequired
  }
});

const AssessmentGroupBreadcrumb = makeBreadcrumb({
  displayName: 'AssessmentGroupBreadcrumb',
  request: assessmentGroupDetails((state, props) => props.assessmentGroupId),
  link: details => `/app/assessmentGroups/${details.id}/details`,
  title: details => details.name,
  parents: details => [<ChapterBreadcrumb key={details.chapterId} chapterId={details.chapterId} child={true} />],
  propTypes: {
    assessmentGroupId: PropTypes.string.isRequired
  }
});

const AssessmentProblemBreadcrumb = makeBreadcrumb({
  displayName: 'AssessmentProblemBreadcrumb',
  request: assessmentProblemDetails((state, props) => props.assessmentProblemId),
  link: details => `/app/assessmentProblems/${details.id}/editor`,
  title: details => details.name,
  parents: details => details.assessmentGroups.map(parent => (
    <AssessmentGroupBreadcrumb key={parent.id} assessmentGroupId={parent.id} child={true} />
  )),
  propTypes: {
    assessmentProblemId: PropTypes.string.isRequired
  }
});

const LessonElementBreadcrumb = makeBreadcrumb({
  displayName: 'LessonElementBreadcrumb',
  request: elementParents((state, props) => props.elementId),
  link: (details, props) => `/app/lessons/${props.lessonId}/editor/${details.id}`,
  title: (details, props) => details.elementType,
  parents: (details, props) => [<LessonBreadcrumb key={props.lessonId} lessonId={props.lessonId} child={true} />],
  propTypes: {
    elementId: PropTypes.string.isRequired,
    lessonId: PropTypes.string.isRequired,
  }
});

const ElementBreadcrumb = makeBreadcrumb({
  displayName: 'ElementBreadcrumb',
  request: elementParents((state, props) => props.elementId),
  link: details => `/app/elements/${details.id}/editor`,
  title: details => details.elementType,
  parents: details => details.parents.filter(parent => {
      switch (parent.type) {
        case 'lesson': return true;
        case 'lessonContentContainer': return true;
        case 'glossaryItem': return true;
        case 'section': return true;
        case 'tab': return true;
        default: return false;
      }
    }).map(parent => {
      switch (parent.type) {
        case 'lesson': return <LessonBreadcrumb key={parent.id} lessonId={parent.id} child={true} />;
        case 'lessonContentContainer': return (
            <LessonContentContainerBreadcrumb
              key={parent.id}
              containerId={parent.id}
              child={true}
            />
          );
        case 'assessmentProblem': return null;
        case 'glossaryItem': return <GlossaryItemBreadcrumb key={parent.id} glossaryItemId={parent.id} child={true} />;
        case 'section': return <SectionBreadcrumb key={parent.id} sectionId={parent.id} child={true} />;
        case 'tab': return <TabBreadcrumb key={parent.id} tabId={parent.id} child={true} />;
        default: return null;
      }
    }),
  propTypes: {
    elementId: PropTypes.string.isRequired
  }
});

const ClassificationBreadcrumb = makeBreadcrumb({
  displayName: 'ClassificationBreadcrumb',
  request: classificationDetails((state, props) => props.classificationId),
  link: details => `/app/classifications/${details.id}/details`,
  title: details => details.name,
  propTypes: {
    classificationId: PropTypes.string.isRequired
  }
});

const GradeBreadcrumb = makeBreadcrumb({
  displayName: 'GradeBreadcrumb',
  request: gradeDetails((state, props) => props.gradeId),
  link: details => `/app/grades/${details.id}/details`,
  title: details => details.title,
  parents: details => [<ClassificationBreadcrumb
    key={details.classificationId}
    classificationId={details.classificationId}
    child={true}
  />],
  propTypes: {
    gradeId: PropTypes.string.isRequired
  }
});

const StandardBreadcrumb = makeBreadcrumb({
  displayName: 'StandardBreadcrumb',
  request: standardDetails((state, props) => props.standardId),
  link: details => `/app/standards/${details.id}/details`,
  title: details => details.name,
  parents: details => [<GradeBreadcrumb key={details.gradeId} gradeId={details.gradeId} child={true} />],
  propTypes: {
    standardId: PropTypes.string.isRequired
  }
});

export {
  CategoryBreadcrumb,
  BookBreadcrumb,
  ChapterBreadcrumb,
  LessonBreadcrumb,
  LessonContentContainerBreadcrumb,
  SectionBreadcrumb,
  TabbedViewBreadcrumb,
  TabBreadcrumb,
  GlossaryItemBreadcrumb,
  AssessmentGroupBreadcrumb,
  AssessmentProblemBreadcrumb,
  LessonElementBreadcrumb,
  ElementBreadcrumb,
  ClassificationBreadcrumb,
  GradeBreadcrumb,
  StandardBreadcrumb
};