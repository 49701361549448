import React from 'react';
import {useSelector} from "react-redux";

import {Paper, Typography} from "@material-ui/core";

import {ViewportRoot, ViewportSection} from "components/layout";
import {AssessmentGroupDetailsTable} from "components/assessmentGroup/AssessmentGroupDetailsTable";
import {AssessmentGroupProblemsTable} from 'components/assessmentGroup/AssessmentGroupProblemsTable';
// import AssessmentGroupProblemsTable from 'components/Tables/GroupAssessmentProblemsTable';
import {AssessmentGroupBreadcrumb} from 'components/common/Breadcrumb';

import {createNavRouteSelector, navRoutes} from "reducers/nav";

const getNavAssessmentGroup = createNavRouteSelector(navRoutes.ASSESSMENT_GROUP);
const AssessmentGroupDetails = () => {
  const assessmentGroupId = useSelector(getNavAssessmentGroup);

  return (
    <ViewportRoot>
      <ViewportSection>
        <AssessmentGroupBreadcrumb assessmentGroupId={assessmentGroupId}/>
        <Typography variant="h3" gutterBottom>AssessmentGroup</Typography>
        <Paper>
          <AssessmentGroupDetailsTable assessmentGroupId={assessmentGroupId} />
        </Paper>
      </ViewportSection>
      <ViewportSection>
        <Typography variant="h3" gutterBottom>Assessment Problems</Typography>
        <Paper>
          <AssessmentGroupProblemsTable assessmentGroupId={assessmentGroupId} />
        </Paper>
      </ViewportSection>
    </ViewportRoot>
  );
};

export {AssessmentGroupDetails as AssessmentGroupDetailsView}
