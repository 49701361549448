import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {useClientHook} from "reducers/client";
import {
  bookMenus,
  createMenuForBook,
  updateMenu,
  deleteMenu
} from "reducers/client/requestTypes";

import {pagedTable} from 'components/common/Table/PagedTable';
import {formatDate} from "constants/Date";
import {paging} from "reducers/table";

const InternalTable = pagedTable([
  {key: 'id', hidden: true},
  {key: 'name', sort: 'name', name: 'Name'},
  {key: 'type', sort: 'type', name: 'Type'},
  {key: 'createdAt', sort: 'createdAt,title', name: "Created", render: formatDate, readOnly: true},
  {key: 'updatedAt', sort: 'updatedAt,title', name: "Last Updated", render: formatDate, readOnly: true},
], {
  table: 'BookMenusTable',
  initialState: {
    searchColumns: ['title']
  },
  getRowKey: row => row.id,
  getRowName: row => row.name,
});

const bookMenusHook = paging('BookMenusTable')(bookMenus((state, props) => props.bookId));
const createMenuForBookHook = createMenuForBook();
const updateMenuHook = updateMenu();
const deleteMenuHook = deleteMenu();
const BookMenusTable = (props) => {
  const [totalCount, setTotalCount] = useState(0);
  const [tablePage, setTablePage] = useState([]);
  const history = useHistory();

  const clientKey = useCallback(selector => selector(props), [props]);
  const bookMenus = useClientHook(bookMenusHook, clientKey);
  const createMenuForBook = useClientHook(createMenuForBookHook, props.bookId);
  const updateMenu = useClientHook(updateMenuHook);
  const deleteMenu = useClientHook(deleteMenuHook);

  const handleSave = useCallback(menu => {
    if (menu.id) {
      return updateMenu.sendRequest(menu.id, menu)
        .then(() => bookMenus.sendRequest());
    } else {
      return createMenuForBook.sendRequest(menu)
        .then(() => bookMenus.sendRequest());
    }
  }, [bookMenus, updateMenu, createMenuForBook]);
  const handleDelete = useCallback(menu => {
    if (menu.id) {
      return deleteMenu.sendRequest(menu.id)
        .then(() => bookMenus.sendRequest());
    } else {
      return Promise.reject();
    }
  }, [bookMenus, deleteMenu]);
  const handleClick = useCallback((ev, index) => {
    history.push(`/app/books/${props.bookId}/menus/${tablePage[index].id}/`);
  }, [history,tablePage]);

  const isWaiting = useMemo(
    () =>
      bookMenus.isLoading() ||
      createMenuForBook.isLoading() ||
      updateMenu.isLoading() ||
      deleteMenu.isLoading(),
    [bookMenus, createMenuForBook, updateMenu, deleteMenu]
  );

  useEffect(() => {
    if (bookMenus.isLoaded()) {
      const {totalCount, results} = bookMenus.get();
      setTotalCount(results.length);
      setTablePage(results);
    }
  }, [bookMenus, setTotalCount, setTablePage]);

  return (
    <InternalTable
      page={tablePage}
      totalCount={totalCount}
      onSave={handleSave}
      onDelete={handleDelete}
      onClick={handleClick}
      waiting={isWaiting}
    />
  )
};
BookMenusTable.propTypes = {
  bookId: PropTypes.string.isRequired,
}

export {BookMenusTable}
