import React, {useCallback, useState, forwardRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Badge,
  makeStyles
} from '@material-ui/core';
import IconMenu from '@material-ui/icons/Menu';

import {sidebarModes} from "constants/Prosemirror";
import {asArray} from "constants/util/map";
import {setSidebarMode} from "reducers/Prosemirror";

const useOptionStyles = makeStyles({
  badge: {
    top: '50%',
    right: '15px',
  }
});

const SidebarOptionBase = ({id, icon: IconComponent, title, badgeContent, onClick, ...menuProps}, ref) => {
  const {badge} = useOptionStyles({});
  const handleClick = useCallback(() => onClick(id), [onClick, id]);
  const content = useSelector(state => badgeContent ? state.prosemirror[badgeContent] : null);

  let menuItem = (
    <MenuItem
      ref={ref}
      onClick={handleClick}
      {...menuProps}
    >
      <ListItemIcon><IconComponent/></ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </MenuItem>
  );
  if (content) {
    return (
      <Badge classes={{badge}} badgeContent={content} color="error">
        {menuItem}
      </Badge>
    );
  }
  return menuItem;
};
SidebarOptionBase.displayName = "SidebarOption";

const SidebarOption = forwardRef(SidebarOptionBase);
SidebarOption.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string,
  badgeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  iconBadge: {
    top: '10px',
    right: '10px',
  },
});

const ProsemirrorSidebarMenu = ({badgeContent, ...innerProps}) => {
  const dispatch = useDispatch();
  const content = useSelector(state => badgeContent ? state.prosemirror[badgeContent] : null);
  const {sidebarMode, docChanged} = useSelector(state => state.prosemirror);
  const {badge} = useStyles({});
  const [menuAnchor, setMenuAnchor] = useState(null);
  const handleClick = useCallback(ev => setMenuAnchor(ev.currentTarget), [setMenuAnchor]);
  const handleClose = useCallback(() => setMenuAnchor(null), [setMenuAnchor]);
  const handleMenu = useCallback(optionId => {
    dispatch(setSidebarMode(sidebarModes[optionId]));
    handleClose();
  }, []);

  return (
    <div {...innerProps}>
      <Tooltip title="Views">
        <Badge classes={{badge}} badgeContent={content} color="error">
          <IconButton onClick={handleClick}>
            <IconMenu/>
          </IconButton>
        </Badge>
      </Tooltip>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleClose}
      >
        {asArray(sidebarModes).map(m => (
          <SidebarOption
            key={m.id}
            id={m.id}
            title={m.name}
            icon={m.icon}
            onClick={handleMenu}
            badgeContent={m.badgeContent}
            selected={sidebarMode === m.id}
            disabled={m.disableWhenDirty && docChanged}
          />
        ))}
      </Menu>
    </div>
  );
};
SidebarOption.propTypes = {
  badgeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export {ProsemirrorSidebarMenu}
